import { all, put, takeLatest } from "redux-saga/effects";

import { defaultApi } from "../../utils/axiosApi";

import {
  GET_CLOCK_IN_OUT_LIST,
  GET_CLOCK_IN_OUT_LIST_SUCCESS,
  GET_CLOCK_IN_OUT_LIST_FAILURE,
} from "../../modules/constants";

export function* getClockInOutList({ payload }) {
  const { start_timestamp, end_timestamp, name, page, office } = payload;
  try {
    const url = `albait/attendaces/?from=${start_timestamp}&to=${end_timestamp}${
      name ? `&name=${name}` : ``
    }${page ? `&page=${page}` : ``}${office ? `&office=${office}` : ``}`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_CLOCK_IN_OUT_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_CLOCK_IN_OUT_LIST_FAILURE,
      payload: err,
    });
  }
}

export default function* clockInOutSagas() {
  yield all([takeLatest(GET_CLOCK_IN_OUT_LIST, getClockInOutList)]);
}
