import AddMultipleAssigneeDropdown from 'components/CustomDropdown/AddMultipleAssigneeDropdown';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import MapsAutocompleteDropdown from '../../../components/MapsAutocompleteDropdown/MapsAutocompleteDropdown';
import { PrimaryButton, RadiusButton } from '../../../styledComponents/buttons';
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonImage,
  CommonText,
  FormAlert,
  InputField,
  InputLabel,
  InputWithLabelSection,
  RedStar,
} from '../../../styledComponents/common';
import { checkHtml, checkUrl } from '../../../utils/helper';
import { InfoSection } from '../officesStyles';

const radiusOptions = [100, 150, 200, 300];

const OfficeDetails = ({
  handleChangePage,
  officeDetails,
  addressMarker,
  setAddressMarker,
  addressInput,
  setAddressInput,
  radiusInput,
  setRadiusInput,
  // autocomplete and geocoding service
  getGeocode,
  getPlacePredictions,
  placePredictions,
  isPredictionsLoading,

  // api and reducer
  createOffice,
  updateOffice,
  addOrUpdateOfficeLoading,
  officeCreateUpdateSuccess,

  clearOfficeDetails,

  toggleDeleteModal,
  getMonazimList,
  monazimList,
  createdOffice,
  addOfficeAssignee,
  removeOfficeAssignee,
  isAssigneesLoading,
}) => {
  const { t } = useTranslation();

  const [officeName, setOfficeName] = useState('');
  const [officeCode, setOfficeCode] = useState('');

  const [saveErrors, setSaveErrors] = useState({});

  const [action, setAction] = useState('add');
  const [selectedAssignees, setSelectedAssignees] = useState();

  const handleAssignMember = member => {
    officeDetails?.id &&
      !isAssigneesLoading &&
      addOfficeAssignee({ office_id: officeDetails.id, user_id: member.id });
  };
  const handleRemoveMember = member => {
    officeDetails?.id &&
      removeOfficeAssignee({
        office_id: officeDetails.id,
        assignee_id: member.id,
      });
  };

  const clearStateAndProps = () => {
    clearOfficeDetails();
    setOfficeName('');
    setOfficeCode('');
    setAddressInput('');
    setRadiusInput(100);
    setAction('add');

    setAddressMarker(null);
  };

  useEffect(() => {
    return () => {
      clearStateAndProps();
    };
  }, []);

  useEffect(() => {
    if (officeCreateUpdateSuccess) {
      clearStateAndProps();
      createdOffice
        ? handleChangePage('details', createdOffice)
        : handleChangePage('list');
    }
  }, [officeCreateUpdateSuccess]);

  useEffect(() => {
    if (officeDetails && officeDetails.id) {
      setAction('update');
      setOfficeName(officeDetails.name);
      setOfficeCode(officeDetails.code);
      setAddressInput(officeDetails.address || '');
      setAddressMarker({
        lat: parseFloat(officeDetails.latitude),
        lng: parseFloat(officeDetails.longitude),
      });
      setRadiusInput(officeDetails.radius);
      getMonazimList();
    }
  }, [officeDetails]);
  useEffect(() => {
    if (officeDetails?.monazim) {
      setSelectedAssignees(
        officeDetails.monazim.map(assignee => {
          return {
            ...assignee,
            name: assignee.first_name + ' ' + assignee.last_name,
          };
        }),
      );
    } else setSelectedAssignees([]);
  }, [officeDetails?.monazim]);

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;

    if (checkUrl(officeName)) {
      formIsValid = false;
      saveErrors['name'] = 'Office name can not contain url';
    } else if (checkHtml(officeName)) {
      formIsValid = false;
      saveErrors['name'] = 'Invalid office name';
    }
    if (checkUrl(officeCode)) {
      formIsValid = false;
      saveErrors['code'] = 'Office code can not contain url';
    } else if (checkHtml(officeCode)) {
      formIsValid = false;
      saveErrors['code'] = 'Invalid office code';
    }
    if (checkUrl(addressInput)) {
      formIsValid = false;
      saveErrors['address'] = 'Address can not contain url';
    } else if (checkHtml(addressInput)) {
      formIsValid = false;
      saveErrors['address'] = 'Invalid address input';
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleAddOrUpdateOffice = () => {
    if (checkError()) {
      const payload = {
        name: officeName,
        address: addressInput,
        code: officeCode,
        radius: radiusInput,
      };

      if (addressMarker && addressMarker.lat) {
        payload.latitude =
          typeof addressMarker.lat === 'function'
            ? addressMarker.lat()
            : addressMarker.lat;
        payload.longitude =
          typeof addressMarker.lng === 'function'
            ? addressMarker.lng()
            : addressMarker.lng;
      }

      if (action === 'update' && officeDetails && officeDetails.id) {
        payload.office_id = officeDetails.id;
        updateOffice(payload);
      } else {
        createOffice(payload);
      }
    }
  };

  const onOfficeNameChange = e => {
    let errors = { ...saveErrors };
    delete errors['name'];
    setSaveErrors(errors);
    setOfficeName(e.target.value);
  };

  const onOfficeCodeChange = e => {
    let errors = { ...saveErrors };
    delete errors['code'];
    setSaveErrors(errors);
    setOfficeCode(e.target.value);
  };

  return (
    <CommonFlex gap="20px" direction="column" padding="20px" alignItems="start">
      <CommonText name title fontSize="16px" margin="0">
        {t('add_office_details')}
      </CommonText>
      <CommonFlex gap="20px" direction="column" alignItems="start">
        <InputWithLabelSection width="100%">
          <InputLabel>
            {t('office_name')}
            <RedStar>*</RedStar>
          </InputLabel>
          <InputField
            type="text"
            placeholder={t('type_office_name')}
            value={officeName}
            onChange={e => onOfficeNameChange(e)}
            error={
              saveErrors &&
              saveErrors['name'] &&
              saveErrors['name'] !== undefined
            }
          />
          {saveErrors &&
            saveErrors['name'] &&
            saveErrors['name'] !== undefined && (
              <FormAlert margin="0 0 0 5px">{saveErrors['name']}</FormAlert>
            )}
        </InputWithLabelSection>

        <InputWithLabelSection width="100%">
          <InputLabel>
            {t('office_code')}
            <RedStar>*</RedStar>
          </InputLabel>
          <InputField
            type="text"
            placeholder={t('type_office_code')}
            value={officeCode}
            onChange={e => onOfficeCodeChange(e)}
            error={
              saveErrors &&
              saveErrors['code'] &&
              saveErrors['code'] !== undefined
            }
          />
          {saveErrors &&
            saveErrors['code'] &&
            saveErrors['code'] !== undefined && (
              <FormAlert margin="0 0 0 5px">{saveErrors['code']}</FormAlert>
            )}
        </InputWithLabelSection>

        <InputWithLabelSection style={{ position: 'relative' }} width="100%">
          <InputLabel>
            {t('office_address')}
            <RedStar>*</RedStar>
          </InputLabel>
          <InputField
            type="text"
            placeholder={t('type_here_to_search_address')}
            value={addressInput}
            onChange={e => {
              const { value } = e.target;
              getPlacePredictions({ input: value });
              setAddressInput(value);
              if (saveErrors && saveErrors['address']) {
                delete saveErrors['address'];
              }
            }}
            error={
              saveErrors &&
              saveErrors['address'] &&
              saveErrors['address'] !== undefined
            }
          />
          {saveErrors &&
            saveErrors['address'] &&
            saveErrors['address'] !== undefined && (
              <FormAlert margin="0 0 0 5px">{saveErrors['address']}</FormAlert>
            )}
          {!isPredictionsLoading &&
          placePredictions &&
          placePredictions.length &&
          addressInput ? (
            <MapsAutocompleteDropdown
              dropdownOptions={placePredictions}
              handleSelect={getGeocode}
            />
          ) : null}
        </InputWithLabelSection>

        <InputWithLabelSection width="100%">
          <CommonGrid>
            <InputLabel>
              {t('location_radius')}
              <RedStar>*</RedStar>
            </InputLabel>
          </CommonGrid>
          <CommonFlex wrap="wrap" justifyContent="space-between" gap="12px">
            {radiusOptions.map((option, i) => {
              return (
                <RadiusButton
                  key={option}
                  active={radiusInput === option}
                  onClick={() => {
                    if (radiusInput !== option) {
                      setRadiusInput(option);
                      if (saveErrors && saveErrors['radius']) {
                        delete saveErrors['radius'];
                      }
                    }
                  }}
                >
                  {option} m
                </RadiusButton>
              );
            })}
          </CommonFlex>
        </InputWithLabelSection>
        <PrimaryButton
          // onClick={() => handleSave(true)}
          width="100%"
          margin="20px 0 0"
          disabled={
            !officeName.trim() ||
            !addressInput ||
            !addressInput.trim() ||
            !officeCode.trim()
          }
          onClick={() => handleAddOrUpdateOffice()}
        >
          {addOrUpdateOfficeLoading ? (
            <ButtonTextLoader
              fontSize="14px"
              loadingText={
                action === 'update' ? t('update_office') : t('create_office')
              }
            />
          ) : action === 'update' ? (
            t('update_office')
          ) : (
            t('create_office')
          )}
        </PrimaryButton>
        {action === 'update' && (
          <CommonFlex direction="column" alignItems="start">
            <InputLabel>{t('assignees')}</InputLabel>
            <AddMultipleAssigneeDropdown
              numberOfVisibleMembers={8}
              isLoading={isAssigneesLoading}
              assigneesList={selectedAssignees}
              membersList={monazimList}
              handleAssign={handleAssignMember}
              handleRemove={handleRemoveMember}
              alwaysTop={true}
            />
          </CommonFlex>
        )}
      </CommonFlex>
    </CommonFlex>
  );
};

export default OfficeDetails;
