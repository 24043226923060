import { useTheme } from "styled-components";
import {
  commonContainerStyles,
  commonControlStyles,
  commonOptionStyles,
  commonMenuListStyles,
  commonMenuStyles,
  commonSingleValueStyles,
  commonPlaceholderStyles,
  commonDropdownIndicatorStyles,
  commonInputStyles,
} from "./commonDropdownStyles";

const DropdownStyles = () => {
  const theme = useTheme();
  return {
    container: (styles) => {
      return {
        ...commonContainerStyles(styles, theme),
      };
    },
    control: (styles, state) => {
      return {
        ...commonControlStyles(styles, state, theme),
      };
    },
    option: (styles, state) => {
      return {
        ...commonOptionStyles(styles, state, theme),
      };
    },
    menuList: (styles) => {
      return {
        ...commonMenuListStyles(styles, theme),
      };
    },
    menu: (styles, state) => {
      return {
        ...commonMenuStyles(styles, state, theme),
      };
    },
    singleValue: (styles) => ({
      ...commonSingleValueStyles(styles, theme),
    }),
    placeholder: (styles) => ({
      ...commonPlaceholderStyles(styles, theme),
    }),
    dropdownIndicator: (styles, state) => {
      return {
        ...commonDropdownIndicatorStyles(styles, state, theme),
      };
    },
    input: (styles, state) => ({
      ...commonInputStyles(styles, state, theme),
    }),
  };
};

export default DropdownStyles;
