import { colors, borderColors, hoverBorderColors } from "./colors";
import { fontWeightConfig, radiusConfig } from "../common";

export const inputConfiguration = {
  textColor: colors.textMain,
  fontSize: "13px",
  minHeight: "40px",
  bgColor: {
    default: colors.white,
    error: colors.errorLight,
  },
  borderColor: {
    default: borderColors.secondaryLight,
    hover: hoverBorderColors.secondaryLight,
    focus: borderColors.primary,
    error: borderColors.error,
  },
  focusShadow: `0 0 0 4px ${colors.focusShadow}`,
  borderRadius: radiusConfig.input,

  placeholder: {
    size: "13px",
    color: colors.placeholderText,
  },
};
