import styled, { css, keyframes } from "styled-components";

export const RightSlidingPanelWrapper = styled.aside``;

export const SlidingPanelOverlay = styled.div`
  position: fixed;
  display: ${({ isOpen }) => (isOpen ? `block` : `none`)};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
`;

export const RightSlidingPanelContainer = styled.div`
  position: fixed;
  width: ${({ width }) => width || "450px"};
  height: 100vh;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.main.white};
  box-shadow: -10px 63px 99px #c2ccde4d;
  transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(100%)")};
  z-index: 2001;

  transition: transform 0.35s ease;

  overflow-y: auto;
  ::-webkit-scrollbar {
    width: ${({ theme }) => theme.dropdown.scrollbar.width};
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.dropdown.scrollbar.trackBgColor};
    border-radius: ${({ theme }) => theme.dropdown.scrollbar.thumbBorderRadius};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.dropdown.scrollbar.thumbBgColor};
    border-radius: ${({ theme }) => theme.dropdown.scrollbar.thumbBorderRadius};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.dropdown.scrollbar.thumbHoverBgColor};
  }
`;

export const RightSlidingPanelContent = styled.div``;
