//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../../utils/axiosApi";

//Constants
import {
  GET_JOIN_REQUESTS,
  GET_JOIN_REQUESTS_SUCCESS,
  GET_JOIN_REQUESTS_FAILURE,
  REQUEST_TO_JOIN_ORGANIZATION,
  REQUEST_TO_JOIN_ORGANIZATION_SUCCESS,
  REQUEST_TO_JOIN_ORGANIZATION_FAILURE,
  ACCEPT_ORG_JOIN_REQUEST,
  ACCEPT_ORG_JOIN_REQUEST_SUCCESS,
  ACCEPT_ORG_JOIN_REQUEST_FAILURE,
} from "../../../modules/constants";

export function* getOrganizationJoinRequest() {
  try {
    let url = `onboarding/join-request/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_JOIN_REQUESTS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_JOIN_REQUESTS_FAILURE,
      payload: err,
    });
  }
}
export function* requestToJoinOrganizationRequest({ payload }) {
  if (!payload) return;
  try {
    let url = `onboarding/join-request/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: REQUEST_TO_JOIN_ORGANIZATION_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: REQUEST_TO_JOIN_ORGANIZATION_FAILURE,
      payload: err,
    });
  }
}
export function* acceptOrganizationJoinRequest({ payload }) {
  if (!payload) return;
  const { organization_id, ...details } = payload;
  try {
    let url = `${organization_id}/members/accept-request/`;
    const response = yield defaultApi(url, "POST", details);
    yield put({
      type: ACCEPT_ORG_JOIN_REQUEST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: ACCEPT_ORG_JOIN_REQUEST_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_JOIN_REQUESTS, getOrganizationJoinRequest),
    takeLatest(REQUEST_TO_JOIN_ORGANIZATION, requestToJoinOrganizationRequest),
    takeLatest(ACCEPT_ORG_JOIN_REQUEST, acceptOrganizationJoinRequest),
  ]);
}
