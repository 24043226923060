import {
  ADD_MEMBER,
  ADD_MEMBER_FAILURE,
  ADD_MEMBER_SUCCESS,
  CLEAR_MEMBER_DETAILS_DATA,
  CLEAR_MEMBER_INVITED,
  DELETE_MEMBER_INVITATION,
  DELETE_MEMBER_INVITATION_FAILURE,
  DELETE_MEMBER_INVITATION_SUCCESS,
  DELETE_PROJECT_ASSIGNEE_SUCCESS,
  GET_INVITED_MEMBERS,
  GET_INVITED_MEMBERS_FAILURE,
  GET_INVITED_MEMBERS_SUCCESS,
  GET_MEMBERS,
  GET_MEMBERS_FAILURE,
  GET_MEMBERS_SUCCESS,
  GET_MEMBER_DETAILS,
  GET_MEMBER_DETAILS_FAILURE,
  GET_MEMBER_DETAILS_SUCCESS,
  GET_MEMBER_MONTHLY_EARNING,
  GET_MEMBER_MONTHLY_EARNING_FAILURE,
  GET_MEMBER_MONTHLY_EARNING_SUCCESS,
  GET_MEMBER_WEEKLY_EARNING,
  GET_MEMBER_WEEKLY_EARNING_FAILURE,
  GET_MEMBER_WEEKLY_EARNING_SUCCESS,
  GET_MONAZIM_LIST,
  GET_MONAZIM_LIST_FAILURE,
  GET_MONAZIM_LIST_SUCCESS,
  INVITE_MEMBER,
  INVITE_MEMBER_FAILURE,
  INVITE_MEMBER_SUCCESS,
  LOGIN_PROFILE_CREATED_RESET,
  REMOVE_MEMBER,
  REMOVE_MEMBER_FAILURE,
  REMOVE_MEMBER_SUCCESS,
  REMOVE_TEAM_MEMBER_SUCCESS, // payment configuration
  SET_PAYMENT_CONFIGURATION,
  SET_PAYMENT_CONFIGURATION_FAILURE,
  SET_PAYMENT_CONFIGURATION_SUCCESS,
  UPDATE_MEMBER, // settings
  UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_SUCCESS,
  UPDATE_MEMBER_FAILURE,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_PAYMENT_CONFIGURATION,
  UPDATE_PAYMENT_CONFIGURATION_FAILURE,
  UPDATE_PAYMENT_CONFIGURATION_SUCCESS,
} from '../../modules/constants';
import { createNotification } from '../../modules/notificationManager';

// ------------------------------------
// Action handler methods
// ------------------------------------

export const membersListRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const membersListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    totalMembersCount: data.data.count,
    pageSize: data.data.page_size,
    members: data.data.results,
  };
};
export const membersListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    totalMembersCount: null,
    pageSize: null,
    members: [],
  };
};

export const invitedMembersListRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const invitedMembersListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    invitedMembersList: data.data.results,
  };
};
export const invitedMembersListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    invitedMembersList: [],
  };
};

export const inviteMemberRequestHandler = (state, action) => {
  return {
    ...state,
    inviteMemberLoading: true,
    memberInvited: false,
  };
};
export const inviteMemberRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, resend },
  } = action;
  if (resend) {
    createNotification('success', 'Invitation resent', 3000);
  }
  return {
    ...state,
    inviteMemberLoading: false,
    memberInvited: true,
  };
};
export const inviteMemberRequestFailureHandler = (state, action) => {
  return {
    ...state,
    inviteMemberLoading: false,
    memberInvited: false,
  };
};

export const deleteMemberInvitationRequestHandler = (state, action) => {
  return {
    ...state,
    deleteInvitationLoading: true,
  };
};
export const deleteMemberInvitationRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    deleteInvitationLoading: false,
  };
};
export const deleteMemberInvitationRequestFailureHandler = (state, action) => {
  return {
    ...state,
    deleteInvitationLoading: false,
  };
};

export const membersDetailsRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const membersDetailsRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    memberDetails: data.data,
  };
};
export const membersDetailsRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    membersDetails: null,
  };
};

export const memberWeeklyEarningRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const memberWeeklyEarningRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    weeklyEarning: data.data,
  };
};
export const memberWeeklyEarningRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    weeklyEarning: null,
  };
};

export const memberMonthlyEarningRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const memberMonthlyEarningRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    monthlyEarning: data.data,
  };
};
export const memberMonthlyEarningRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    monthlyEarning: null,
  };
};

// create member
export const addMemberRequest = (state, action) => {
  return {
    ...state,
    createMemberLoading: true,
  };
};
export const addMemberRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    createNotification('success', 'Member created successfully', 2000);
    return {
      ...state,
      createMemberLoading: false,
      createdMemberInfo: data.data,
    };
  }
  return {
    ...state,
    createMemberLoading: false,
    createdMemberInfo: null,
  };
};
export const addMemberRequestFailure = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification('error', 'Something went Wrong.', 3000);
    return {
      ...state,
      createMemberLoading: false,
      createdMemberInfo: null,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification(
      'error',
      'Sorry! could not add member. Please try again.',
      2000,
    );
  }
  return {
    ...state,
    createMemberLoading: false,
    createdMemberInfo: null,
  };
};

export const updateMemberRequestHandler = (state, action) => {
  return {
    ...state,
    updateMemberLoading: true,
  };
};
export const updateMemberRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  createNotification('success', 'User Details updated successfully.', 4000);
  if (data.status === 200 && data.data) {
    const newMemberList = state.members?.map(member => {
      if (member.id === data.data?.id) return data.data;
      return member;
    });
    return {
      ...state,
      updateMemberLoading: false,
      members: newMemberList,
      updatedData: data.data,
    };
  }
  return {
    ...state,
    updateMemberLoading: false,
    updatedData: null,
  };
};
export const updateMemberRequestFailureHandler = (state, action) => {
  createNotification(
    'error',
    'Could not update member. Please try again!',
    3000,
  );
  return {
    ...state,
    updateMemberLoading: false,
    updatedData: null,
  };
};

export const deleteMemberRequestHandler = (state, action) => {
  return {
    ...state,
    deleteMemberLoading: true,
  };
};
export const deleteMemberRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  createNotification('success', 'Member deleted successfully.', 3000);
  return {
    ...state,
    deleteMemberLoading: false,
  };
};
export const deleteMemberRequestFailureHandler = (state, action) => {
  createNotification(
    'error',
    'Could not delete member. Please try again!',
    3000,
  );
  return {
    ...state,
    deleteMemberLoading: false,
  };
};

export const clearMembersDetailsDataRequest = (state, action) => {
  return {
    ...state,
    memberDetails: null,
    weeklyEarning: null,
    monthlyEarning: null,
  };
};

export const handleRemoveMemberFromProject = (state, action) => {
  const { payload, userData } = action;

  if (payload.data && payload.data.status === 204) {
    if (
      state.memberDetails &&
      state.memberDetails.user &&
      state.memberDetails.user.id &&
      state.memberDetails.user.id === userData.userId &&
      userData.projectId &&
      state.memberDetails.projects &&
      state.memberDetails.projects.length > 0
    ) {
      const memberData = { ...state.memberDetails };
      memberData.projects = state.memberDetails.projects.filter(
        project => project.id !== userData.projectId,
      );
      return {
        ...state,
        memberDetails: { ...memberData },
      };
    }
  }
  return {
    ...state,
  };
};

export const handleRemoveMemberFromTeam = (state, action) => {
  const { payload, userData } = action;

  if (payload.data && payload.data.status === 204) {
    if (
      state.memberDetails &&
      state.memberDetails.user &&
      state.memberDetails.user.id &&
      state.memberDetails.user.id === userData.userId &&
      userData.teamId &&
      state.memberDetails.teams &&
      state.memberDetails.teams.length > 0
    ) {
      const memberData = { ...state.memberDetails };
      memberData.teams = state.memberDetails.teams.filter(
        team => team.id !== userData.teamId,
      );
      return {
        ...state,
        memberDetails: { ...memberData },
      };
    }
  }
  return {
    ...state,
  };
};

export const handleSetPaymentConfiguration = (state, action) => {
  return {
    ...state,
    paymentConfigurationLoading: true,
  };
};
export const handleSetPaymentConfigurationSuccess = (state, action) => {
  createNotification(
    'success',
    "Member's payment configuration added successfully.",
    3000,
  );
  return {
    ...state,
    paymentConfigurationLoading: false,
  };
};
export const handleSetPaymentConfigurationFailure = (state, action) => {
  createNotification(
    'error',
    'Could not add payment configuration. Please try again!',
    3000,
  );
  return {
    ...state,
    paymentConfigurationLoading: false,
  };
};

export const handleUpdatePaymentConfiguration = (state, action) => {
  return {
    ...state,
    paymentConfigurationLoading: true,
  };
};
export const handleUpdatePaymentConfigurationSuccess = (state, action) => {
  createNotification(
    'success',
    "Member's payment configuration updated successfully.",
    3000,
  );
  return {
    ...state,
    paymentConfigurationLoading: false,
  };
};
export const handleUpdatePaymentConfigurationFailure = (state, action) => {
  createNotification(
    'error',
    'Could not update payment configuration. Please try again!',
    3000,
  );
  return {
    ...state,
    paymentConfigurationLoading: false,
  };
};

export const handleUpdateTimesheetApprovalSettingsSuccessHandler = (
  state,
  action,
) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    const membersList = JSON.parse(JSON.stringify(state.members));
    const details = JSON.parse(JSON.stringify(state.memberDetails));
    if (membersList && membersList.length > 0) {
      membersList.some(member => {
        if (member.id === data.data.id) {
          member.require_timesheet_approval =
            data.data.require_timesheet_approval;
          return true;
        }
        return false;
      });
    }
    if (details && details.id && details.id === data.data.id) {
      details.require_timesheet_approval = data.data.require_timesheet_approval;
    }
    return {
      ...state,
      members: [...membersList],
      memberDetails: { ...details },
    };
  }
  return {
    ...state,
  };
};

export const clearMemberInvitedRequest = (state, action) => {
  return {
    ...state,
    memberInvited: false,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

export const monazimListRequestHandler = (state, action) => {
  return {
    ...state,
    // isLoading: true,
  };
};
export const monazimListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    // isLoading: false,
    totalMonazimCount: data.data.count,
    pageSize: data.data.page_size,
    monazimList: data.data.results?.map(result => {
      return { ...result, name: result.first_name + ' ' + result.last_name };
    }),
  };
};
export const monazimListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    // isLoading: false,
    totalMonazimCount: null,
    pageSize: null,
    monazimList: [],
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_MEMBERS]: membersListRequestHandler,
  [GET_MEMBERS_SUCCESS]: membersListRequestSuccessHandler,
  [GET_MEMBERS_FAILURE]: membersListRequestFailureHandler,

  [GET_MONAZIM_LIST]: monazimListRequestHandler,
  [GET_MONAZIM_LIST_SUCCESS]: monazimListRequestSuccessHandler,
  [GET_MONAZIM_LIST_FAILURE]: monazimListRequestFailureHandler,

  [GET_INVITED_MEMBERS]: invitedMembersListRequestHandler,
  [GET_INVITED_MEMBERS_SUCCESS]: invitedMembersListRequestSuccessHandler,
  [GET_INVITED_MEMBERS_FAILURE]: invitedMembersListRequestFailureHandler,

  [INVITE_MEMBER]: inviteMemberRequestHandler,
  [INVITE_MEMBER_SUCCESS]: inviteMemberRequestSuccessHandler,
  [INVITE_MEMBER_FAILURE]: inviteMemberRequestFailureHandler,

  [DELETE_MEMBER_INVITATION]: deleteMemberInvitationRequestHandler,
  [DELETE_MEMBER_INVITATION_SUCCESS]:
    deleteMemberInvitationRequestSuccessHandler,
  [DELETE_MEMBER_INVITATION_FAILURE]:
    deleteMemberInvitationRequestFailureHandler,

  [GET_MEMBER_DETAILS]: membersDetailsRequestHandler,
  [GET_MEMBER_DETAILS_SUCCESS]: membersDetailsRequestSuccessHandler,
  [GET_MEMBER_DETAILS_FAILURE]: membersDetailsRequestFailureHandler,

  [GET_MEMBER_WEEKLY_EARNING]: memberWeeklyEarningRequestHandler,
  [GET_MEMBER_WEEKLY_EARNING_SUCCESS]: memberWeeklyEarningRequestSuccessHandler,
  [GET_MEMBER_WEEKLY_EARNING_FAILURE]: memberWeeklyEarningRequestFailureHandler,

  [GET_MEMBER_MONTHLY_EARNING]: memberMonthlyEarningRequestHandler,
  [GET_MEMBER_MONTHLY_EARNING_SUCCESS]:
    memberMonthlyEarningRequestSuccessHandler,
  [GET_MEMBER_MONTHLY_EARNING_FAILURE]:
    memberMonthlyEarningRequestFailureHandler,

  [ADD_MEMBER]: addMemberRequest,
  [ADD_MEMBER_SUCCESS]: addMemberRequestSuccess,
  [ADD_MEMBER_FAILURE]: addMemberRequestFailure,

  [UPDATE_MEMBER]: updateMemberRequestHandler,
  [UPDATE_MEMBER_SUCCESS]: updateMemberRequestSuccessHandler,
  [UPDATE_MEMBER_FAILURE]: updateMemberRequestFailureHandler,

  [REMOVE_MEMBER]: deleteMemberRequestHandler,
  [REMOVE_MEMBER_SUCCESS]: deleteMemberRequestSuccessHandler,
  [REMOVE_MEMBER_FAILURE]: deleteMemberRequestFailureHandler,

  [SET_PAYMENT_CONFIGURATION]: handleSetPaymentConfiguration,
  [SET_PAYMENT_CONFIGURATION_SUCCESS]: handleSetPaymentConfigurationSuccess,
  [SET_PAYMENT_CONFIGURATION_FAILURE]: handleSetPaymentConfigurationFailure,

  [UPDATE_PAYMENT_CONFIGURATION]: handleUpdatePaymentConfiguration,
  [UPDATE_PAYMENT_CONFIGURATION_SUCCESS]:
    handleUpdatePaymentConfigurationSuccess,
  [UPDATE_PAYMENT_CONFIGURATION_FAILURE]:
    handleUpdatePaymentConfigurationFailure,

  [UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_SUCCESS]:
    handleUpdateTimesheetApprovalSettingsSuccessHandler,

  [CLEAR_MEMBER_DETAILS_DATA]: clearMembersDetailsDataRequest,

  [DELETE_PROJECT_ASSIGNEE_SUCCESS]: handleRemoveMemberFromProject,

  [REMOVE_TEAM_MEMBER_SUCCESS]: handleRemoveMemberFromTeam,

  [CLEAR_MEMBER_INVITED]: clearMemberInvitedRequest,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  isLoading: false,
  inviteMemberLoading: false,
  deleteInvitationLoading: false,
  createMemberLoading: false,
  updateMemberLoading: false,
  paymentConfigurationLoading: false,
  deleteMemberLoading: false,
  members: [],
  monazimList: [],
  memberDetails: null,
  updatedData: null,
  invitedMembersList: [],
  weeklyEarning: null,
  monthlyEarning: null,
  paymentConfHistory: undefined,
  removed: false,
  archive: null,
  unarchive: null,
  invitationList: [],
  memberInvited: false,
  createdMemberInfo: null,

  // pagination
  totalMembersCount: null,
  pageSize: null,
};

export default function memberReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
