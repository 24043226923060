import {
  ADD_SUB_TASK,
  ADD_SUB_TASK_FAILURE,
  ADD_SUB_TASK_SUCCESS,
  ADD_TASK_LOCATION,
  ADD_TASK_LOCATION_FAILURE,
  ADD_TASK_LOCATION_SUCCESS,
  CREATE_TASK,
  CREATE_TASK_FAILURE,
  CREATE_TASK_SUCCESS,
  DELETE_SUB_TASK,
  DELETE_SUB_TASK_FAILURE,
  DELETE_SUB_TASK_SUCCESS,
  DELETE_TASK,
  DELETE_TASK_FAILURE,
  DELETE_TASK_SUCCESS,
  DUPLICATE_TASK,
  DUPLICATE_TASK_FAILURE,
  DUPLICATE_TASK_SUCCESS,
  EDIT_SUB_TASK,
  EDIT_SUB_TASK_FAILURE,
  EDIT_SUB_TASK_SUCCESS,
  EDIT_TASK,
  EDIT_TASK_FAILURE,
  EDIT_TASK_SUCCESS,
  GET_TASKS_LIST,
  GET_TASKS_LIST_FAILURE,
  GET_TASKS_LIST_SUCCESS,
  GET_TASK_DETAILS,
  GET_TASK_DETAILS_FAILURE,
  GET_TASK_DETAILS_SUCCESS,
  GET_TASK_LOCATIONS,
  GET_TASK_LOCATIONS_FAILURE,
  GET_TASK_LOCATIONS_SUCCESS,
  GET_TASK_TYPE,
  GET_TASK_TYPE_FAILURE,
  GET_TASK_TYPE_SUCCESS,
  LOGIN_PROFILE_CREATED_RESET,
  REMOVE_TASK_ASSIGNEE,
  REMOVE_TASK_ASSIGNEE_FAILURE,
  REMOVE_TASK_ASSIGNEE_SUCCESS,
  UPDATE_PARTIAL_TASK,
  UPDATE_PARTIAL_TASK_FAILURE,
  UPDATE_PARTIAL_TASK_SUCCESS,
  UPDATE_TASK_ASSIGNEE,
  UPDATE_TASK_ASSIGNEE_FAILURE,
  UPDATE_TASK_ASSIGNEE_SUCCESS,
  UPDATE_TASK_STATUS,
  UPDATE_TASK_STATUS_FAILURE,
  UPDATE_TASK_STATUS_SUCCESS,
} from '../../modules/constants';
import { createNotification } from '../../modules/notificationManager';

const noInternetMsg =
  'No Internet Connection! Please connect to the internet and try again';

//get tasks list
export const getTasksListRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const getTasksListRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      tasksList: data.data.results,
      tasksListCount: data.data.count,
      taskListPageSize: data.data.page_size,
    };
  }
  return {
    ...state,
    isLoading: false,
    tasksList: [],
    tasksListCount: null,
    taskListPageSize: null,
  };
};
export const getTasksListRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    tasksList: [],
    tasksListCount: null,
    taskListPageSize: null,
  };
};

// create task
export const createTaskRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const createTaskRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    createNotification('success', 'Ticket created successfully', 2000);
    return {
      ...state,
      isLoading: false,
      createdTaskInfo: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    createdTaskInfo: null,
  };
};
export const createTaskRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (
    response &&
    response.status === 400 &&
    response.data &&
    response.data.detail &&
    response.data.detail === 'employee has another task within the given time'
  ) {
    createNotification(
      'error',
      `employee has another task within the given time`,
      5000,
    );
    return {
      ...state,
      isLoading: false,
      createdTaskInfo: null,
      overlap: true,
    };
  }
  if (action.payload.data === undefined) {
    createNotification('error', 'Something went Wrong.', 3000);
    return {
      ...state,
      isLoading: false,
      createdTaskInfo: null,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification(
      'error',
      'Sorry! could not create task. Please try again.',
      2000,
    );
  }
  return {
    ...state,
    isLoading: false,
    createdTaskInfo: null,
  };
};

// Task details by id
export const getTaskDetailsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const getTaskDetailsRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      taskDetails: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    taskDetails: null,
  };
};
export const getTaskDetailsRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    taskDetails: null,
  };
};

//edit task
export const editTaskRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    callback: false,
  };
};
export const editTaskRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    createNotification('success', 'Task updated successfully', 2000);
    return {
      ...state,
      isLoading: false,
      callback: true,
    };
  }
  return {
    ...state,
    isLoading: false,
    callback: false,
  };
};
export const editTaskRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (
    response &&
    response.status === 400 &&
    response.data &&
    response.data.detail &&
    response.data.detail === 'employee has another task within the given time'
  ) {
    createNotification(
      'error',
      `employee has another task within the given time`,
      5000,
    );
    return {
      ...state,
      isLoading: false,
      callback: false,
      overlap: true,
    };
  }
  if (action.payload.data === undefined) {
    createNotification('error', 'Something went wrong!', 2000);
    return {
      ...state,
      isLoading: false,
      callback: false,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification('error', 'Failed!! please try again', 2000);
  }
  return {
    ...state,
    isLoading: false,
    callback: false,
  };
};

//updatePartial task
export const updatePartialTaskRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    callback: false,
  };
};
export const updatePartialTaskRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    createNotification('success', 'Task updated successfully', 2000);
    return {
      ...state,
      taskDetails: data.data,
      isLoading: false,
      callback: true,
    };
  }
  return {
    ...state,
    isLoading: false,
    callback: false,
  };
};
export const updatePartialTaskRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (
    response &&
    response.status === 400 &&
    response.data &&
    response.data.detail &&
    response.data.detail === 'employee has another task within the given time'
  ) {
    createNotification(
      'error',
      `employee has another task within the given time`,
      5000,
    );
    return {
      ...state,
      isLoading: false,
      callback: false,
      overlap: true,
    };
  }
  if (action.payload.data === undefined) {
    createNotification('error', 'Something went wrong!', 2000);
    return {
      ...state,
      isLoading: false,
      callback: false,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification('error', 'Failed!! please try again', 2000);
  }
  return {
    ...state,
    isLoading: false,
    callback: false,
  };
};

//update task assignee
export const updateTaskAssigneeRequest = (state, action) => {
  return {
    ...state,
    isTaskAssigning: true,
    callback: false,
  };
};
export const updateTaskAssigneeRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    const newAssignee = data?.data?.user;
    const taskId = data?.data?.monazimtask;

    createNotification('success', 'Ticket Assigned successfully', 2000);

    let newList = JSON.parse(JSON.stringify(state.tasksList));
    if (state.tasksList && state.tasksList.length > 0 && taskId) {
      newList = newList.map(task => {
        if (task.id === taskId) {
          const tmp = task.assignees;
          tmp.push(newAssignee);
          task.assignees = [...tmp];
          if (task.status === 'new' && tmp?.length > 0)
            task.status = 'assigned';
        }
        return task;
      });
    }
    let updatedTask;

    if (state.taskDetails && taskId && state.taskDetails.id === taskId) {
      updatedTask = JSON.parse(JSON.stringify(state.taskDetails));
      const tmp = updatedTask.assignees;
      tmp.push(newAssignee);
      if (updatedTask.status === 'new' && tmp?.length > 0)
        updatedTask.status = 'assigned';
      updatedTask.assignees = [...tmp];
    }
    return {
      ...state,
      isLoading: false,
      callback: false,
      taskDetails: updatedTask ? JSON.parse(JSON.stringify(updatedTask)) : null,
      tasksList: [...newList],
      isTaskAssigning: false,
    };
  }
  return {
    ...state,
    isTaskAssigning: false,
    callback: false,
  };
};
export const updateTaskAssigneeRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (
    response &&
    response.status === 400 &&
    response.data &&
    response.data.detail
  ) {
    createNotification('error', response.data.detail, 5000);
    return {
      ...state,
      isTaskAssigning: false,
      callback: false,
      overlap: true,
    };
  }
  if (action.payload.data === undefined) {
    createNotification('error', 'Something went wrong!', 2000);
    return {
      ...state,
      isTaskAssigning: false,
      callback: false,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification('error', 'Failed!! please try again', 2000);
  }
  return {
    ...state,
    isTaskAssigning: false,
    callback: false,
  };
};

//remove task assignee
export const removeTaskAssigneeRequest = (state, action) => {
  return {
    ...state,
    isTaskAssigning: true,
    callback: false,
  };
};
export const removeTaskAssigneeRequestSuccess = (state, action) => {
  const {
    payload: { task_id, user_id },
  } = action;
  createNotification('success', 'Assignee Removed successfully', 2000);

  let newList = JSON.parse(JSON.stringify(state.tasksList));
  if (state.tasksList && state.tasksList.length > 0 && task_id) {
    newList = newList.map(task => {
      if (task.id === task_id) {
        const tmp = task.assignees?.filter(assignee => assignee.id !== user_id);
        task.assignees = [...tmp];
      }
      if (task.status === 'assigned' && task.assignees.length === 0)
        task.status = 'new';
      return task;
    });
  }
  let updatedTask;
  if (state.taskDetails && task_id && state.taskDetails.id === task_id) {
    updatedTask = JSON.parse(JSON.stringify(state.taskDetails));
    const tmp = updatedTask.assignees?.filter(
      assignee => assignee.id !== user_id,
    );
    updatedTask.assignees = [...tmp];
    if (
      updatedTask.status === 'assigned' &&
      updatedTask.assignees.length === 0
    ) {
      updatedTask.status = 'new';
    }
  }
  return {
    ...state,
    isLoading: false,
    callback: false,
    taskDetails: updatedTask ? JSON.parse(JSON.stringify(updatedTask)) : null,
    tasksList: [...newList],
    isTaskAssigning: false,
  };
};
export const removeTaskAssigneeRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (
    response &&
    response.status === 400 &&
    response.data &&
    response.data.detail
  ) {
    createNotification('error', response.data.detail, 5000);
    return {
      ...state,
      isTaskAssigning: false,
      callback: false,
      overlap: true,
    };
  }
  if (action.payload.data === undefined) {
    createNotification('error', 'Something went wrong!', 2000);
    return {
      ...state,
      isTaskAssigning: false,
      callback: false,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification('error', 'Failed!! please try again', 2000);
  }
  return {
    ...state,
    isTaskAssigning: false,
    callback: false,
  };
};

//add sub task
export const addSubTaskRequest = (state, action) => {
  return {
    ...state,
    isLoading: false,
    callback: false,
  };
};
export const addSubTaskRequestSuccess = (state, action) => {
  const {
    payload: { data, task_id },
  } = action;
  console.log('<><><><>', action.payload);

  if (data && data.status === 201) {
    const sub_task_data = data.data;
    createNotification('success', 'Sub Task added successfully', 2000);
    let newList = JSON.parse(JSON.stringify(state.tasksList));
    if (state.tasksList && state.tasksList.length > 0 && task_id) {
      newList = newList.map(task => {
        if (task.id === task_id) {
          console.log(
            '🚀🚀 ~ addSubTaskRequestSuccess ~ task:',
            task.sub_tasks,
          );
          task.sub_tasks.push(sub_task_data);
        }
        return task;
      });
    }
    console.log('🚀 ~ addSubTaskRequestSuccess ~ newList:', newList);
    let updatedTask;
    if (state.taskDetails && task_id && state.taskDetails.id === task_id) {
      updatedTask = JSON.parse(JSON.stringify(state.taskDetails));
      updatedTask.sub_tasks.push(sub_task_data);
    }
    console.log('🚀 ~ addSubTaskRequestSuccess ~ updatedTask:', updatedTask);
    return {
      ...state,
      isLoading: false,
      callback: false,
      taskDetails: updatedTask ? JSON.parse(JSON.stringify(updatedTask)) : null,
      tasksList: [...newList],
    };
  }
  return {
    ...state,
    isLoading: false,
    callback: false,
  };
};
export const addSubTaskRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (
    response &&
    response.status === 400 &&
    response.data &&
    response.data.detail &&
    response.data.detail === 'Could not update'
  ) {
    createNotification('error', `Could not add Sub Task`, 5000);
    return {
      ...state,
      isLoading: false,
      callback: false,
      overlap: true,
    };
  }
  if (action.payload.data === undefined) {
    createNotification('error', 'Something went wrong!', 2000);
    return {
      ...state,
      isLoading: false,
      callback: false,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification('error', 'Failed!! please try again', 2000);
  }
  return {
    ...state,
    isLoading: false,
    callback: false,
  };
};

//edit sub task
export const editSubTaskRequest = (state, action) => {
  return {
    ...state,
    isLoading: false,
    callback: false,
  };
};
export const editSubTaskRequestSuccess = (state, action) => {
  const {
    payload: { data, task_id, sub_task_id },
  } = action;
  console.log('🚀 ~ editSubTaskRequestSuccess ~ action:', action.payload);
  if (data && data.status === 200) {
    const sub_task_data = data.data;
    console.log(
      '🚀 ~ editSubTaskRequestSuccess ~ sub_task_data:',
      sub_task_data,
    );
    createNotification('success', 'Sub Task updated successfully', 2000);
    let newList = JSON.parse(JSON.stringify(state.tasksList));
    if (state.tasksList && state.tasksList.length > 0 && task_id) {
      newList = newList.map(task => {
        if (task.id === task_id) {
          console.log(
            '🚀🚀 ~ editSubTaskRequestSuccess ~ task:',
            task.sub_tasks,
          );
          task.sub_tasks = task.sub_tasks.map(subTask =>
            subTask.id === sub_task_id ? sub_task_data : subTask,
          );
          console.log(
            '🚀🚀🚀 ~ editSubTaskRequestSuccess ~ task:',
            task.sub_tasks,
          );
        }
        return task;
      });
    }
    console.log('🚀 ~ editSubTaskRequestSuccess ~ newList:', newList);
    let updatedTask;
    if (state.taskDetails && task_id && state.taskDetails.id === task_id) {
      updatedTask = JSON.parse(JSON.stringify(state.taskDetails));
      updatedTask.sub_tasks = updatedTask.sub_tasks.map(subTask => {
        return subTask.id === sub_task_id ? sub_task_data : subTask;
      });
    }
    console.log('🚀 ~ editSubTaskRequestSuccess ~ updatedTask:', updatedTask);
    return updatedTask
      ? {
          ...state,
          isLoading: false,
          callback: false,
          taskDetails: JSON.parse(JSON.stringify(updatedTask)),
        }
      : {
          ...state,
          isLoading: false,
          callback: false,
          tasksList: [...newList],
        };
  }
  return {
    ...state,
    isLoading: false,
    callback: false,
  };
};
export const editSubTaskRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (
    response &&
    response.status === 400 &&
    response.data &&
    response.data.detail &&
    response.data.detail === 'Could not update'
  ) {
    createNotification('error', `Could not update`, 5000);
    return {
      ...state,
      isLoading: false,
      callback: false,
      overlap: true,
    };
  }
  if (action.payload.data === undefined) {
    createNotification('error', 'Something went wrong!', 2000);
    return {
      ...state,
      isLoading: false,
      callback: false,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification('error', 'Failed!! please try again', 2000);
  }
  return {
    ...state,
    isLoading: false,
    callback: false,
  };
};

//delete sub task
export const deleteSubTaskRequest = (state, action) => {
  return {
    ...state,
    isLoading: false,
    callback: false,
  };
};
export const deleteSubTaskRequestSuccess = (state, action) => {
  const {
    payload: { data, task_id, sub_task_id },
  } = action;
  if (data && data.status === 204) {
    createNotification('success', 'Sub Task deleted successfully', 2000);
    let newList = JSON.parse(JSON.stringify(state.tasksList));
    if (state.tasksList && state.tasksList.length > 0 && task_id) {
      newList = newList.map(task => {
        if (task.id === task_id) {
          console.log(
            '🚀🚀 ~ deleteSubTaskRequestSuccess ~ task:',
            task.sub_tasks,
          );
          task.sub_tasks = task.sub_tasks.filter(
            sub_task => sub_task.id != sub_task_id,
          );
        }
        return task;
      });
    }
    console.log('🚀 ~ deleteSubTaskRequestSuccess ~ newList:', newList);
    let updatedTask;
    if (state.taskDetails && task_id && state.taskDetails.id === task_id) {
      updatedTask = JSON.parse(JSON.stringify(state.taskDetails));
      updatedTask.sub_tasks = updatedTask.sub_tasks.filter(
        sub_task => sub_task.id != sub_task_id,
      );
    }
    console.log('🚀 ~ deleteSubTaskRequestSuccess ~ updatedTask:', updatedTask);
    return updatedTask
      ? {
          ...state,
          isLoading: false,
          callback: false,
          taskDetails: JSON.parse(JSON.stringify(updatedTask)),
        }
      : {
          ...state,
          isLoading: false,
          callback: false,
          tasksList: [...newList],
        };
  }
  return {
    ...state,
    isLoading: false,
    callback: false,
  };
};
export const deleteSubTaskRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (
    response &&
    response.status === 400 &&
    response.data &&
    response.data.detail &&
    response.data.detail === 'Could not update'
  ) {
    createNotification('error', `Could not delete Sub Task`, 5000);
    return {
      ...state,
      isLoading: false,
      callback: false,
      overlap: true,
    };
  }
  if (action.payload.data === undefined) {
    createNotification('error', 'Something went wrong!', 2000);
    return {
      ...state,
      isLoading: false,
      callback: false,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification('error', 'Failed!! please try again', 2000);
  }
  return {
    ...state,
    isLoading: false,
    callback: false,
  };
};

//duplicate task
export const duplicateTaskRequest = (state, action) => {
  return {
    ...state,
    duplicateIsLoading: true,
  };
};
export const duplicateTaskRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    createNotification('success', 'task duplicated successfully', 2000);
    return {
      ...state,
      duplicateIsLoading: false,
      duplicateTaskInfo: data.data,
    };
  }
  return {
    ...state,
    duplicateIsLoading: false,
    duplicateTaskInfo: null,
  };
};
export const duplicateTaskRequestFailure = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification(
      'error',
      'No internet connection found. Please connect to the internet.',
      3000,
    );
    return {
      ...state,
      duplicateIsLoading: false,
      duplicateTaskInfo: null,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification(
      'error',
      'Sorry! could not duplicate task. Please try again.',
      2000,
    );
  }
  return {
    ...state,
    duplicateIsLoading: false,
    duplicateTaskInfo: null,
  };
};

//DELETE TASK
export const deleteTaskRequest = (state, action) => {
  return {
    ...state,
    deleteIsLoading: true,
    deletedCallback: false,
  };
};
export const deleteTaskRequestSuccess = (state, action) => {
  const {
    payload: { data, task_id },
  } = action;
  if (data && data.status === 204) {
    createNotification('success', 'task deleted successfully', 3000);

    let newList = JSON.parse(JSON.stringify(state.tasksList));
    if (state.tasksList && state.tasksList.length > 0 && task_id) {
      newList = newList.filter(task => task.id !== task_id);
    }
    return {
      ...state,
      deleteIsLoading: false,
      deletedCallback: true,
      tasksList: [...newList],
    };
  }
  return {
    ...state,
    deleteIsLoading: false,
    deletedCallback: false,
  };
};
export const deleteTaskRequestFailure = (state, action) => {
  createNotification('error', 'Could not delete task. Please try again!', 3000);
  return {
    ...state,
    deleteIsLoading: false,
    deletedCallback: false,
  };
};

//get task location
export const getTaskLocationsRequest = (state, action) => {
  return {
    ...state,
    locationListIsLoading: true,
  };
};
export const getTaskLocationsRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      locationsList: data.data,
      locationListIsLoading: false,
    };
  }
  return {
    ...state,
    locationsList: [],
    locationListIsLoading: false,
  };
};
export const getTaskLocationsRequestFailure = (state, action) => {
  return {
    ...state,
    locationsList: [],
    locationListIsLoading: false,
  };
};

// Add task location
export const addTaskLocationRequest = (state, action) => {
  return {
    ...state,
    addTaskLocationLoading: true,
  };
};
export const addTaskLocationRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    createNotification('success', 'Task location added successfully', 2000);
    const currentList = JSON.parse(JSON.stringify(state.locationsList));
    return {
      ...state,
      addTaskLocationLoading: false,
      createdTaskLocationInfo: data.data,
      locationsList: [data.data, ...currentList],
    };
  }
  return {
    ...state,
    addTaskLocationLoading: false,
    createdTaskLocationInfo: null,
  };
};
export const addTaskLocationRequestFailure = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification('error', 'There was an error. Please try again!', 3000);
    return {
      ...state,
      addTaskLocationLoading: false,
      createdTaskLocationInfo: null,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification(
      'error',
      'Sorry! could not add task location. Please try again.',
      2000,
    );
  }
  return {
    ...state,
    addTaskLocationLoading: false,
    createdTaskLocationInfo: null,
  };
};

export const taskUpdateRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const taskUpdateRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, task_id },
  } = action;
  if (data && data.status === 200) {
    createNotification('success', 'Task updated successfully', 2000);
    const currentList = JSON.parse(JSON.stringify(state.tasksList));
    const newList = currentList.filter(task => task.id !== task_id); // remove task from submitted tasks list
    let updatedTask;
    if (state.taskDetails && task_id && state.taskDetails.id === task_id) {
      updatedTask = JSON.parse(JSON.stringify(state.taskDetails));
      updatedTask.status = data.data.approval_status;
      updatedTask.supervisor_note = data.data.supervisor_note;
      updatedTask.approved_by = data.data.approved_by.id;
    }
    return updatedTask
      ? {
          ...state,
          isLoading: false,
          callback: false,
          taskDetails: JSON.parse(JSON.stringify(updatedTask)),
        }
      : {
          ...state,
          isLoading: false,
          callback: false,
          tasksList: [...newList],
        };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const taskUpdateRequestFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification(
      'error',
      'Something went wrong! Please try again.',
      3000,
    );
    return {
      ...state,
      isLoading: false,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification('error', 'Failed!! please try again', 2000);
  }
  return {
    ...state,
    isLoading: false,
  };
};

//get task type
export const getTaskTypeRequest = (state, action) => {
  return {
    ...state,
    taskTypeIsLoading: true,
  };
};
export const getTaskTypeRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      taskTypeList: data.data,
      taskTypeIsLoading: false,
    };
  }
  return {
    ...state,
    taskTypeList: [],
    taskTypeIsLoading: false,
  };
};
export const getTaskTypeRequestFailure = (state, action) => {
  return {
    ...state,
    taskTypeList: [],
    taskTypeIsLoading: false,
  };
};

// clear state on logout
export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_TASKS_LIST]: getTasksListRequest,
  [GET_TASKS_LIST_SUCCESS]: getTasksListRequestSuccess,
  [GET_TASKS_LIST_FAILURE]: getTasksListRequestFailure,

  [CREATE_TASK]: createTaskRequest,
  [CREATE_TASK_SUCCESS]: createTaskRequestSuccess,
  [CREATE_TASK_FAILURE]: createTaskRequestFailure,

  [GET_TASK_DETAILS]: getTaskDetailsRequest,
  [GET_TASK_DETAILS_SUCCESS]: getTaskDetailsRequestSuccess,
  [GET_TASK_DETAILS_FAILURE]: getTaskDetailsRequestFailure,

  [EDIT_TASK]: editTaskRequest,
  [EDIT_TASK_SUCCESS]: editTaskRequestSuccess,
  [EDIT_TASK_FAILURE]: editTaskRequestFailure,

  [UPDATE_PARTIAL_TASK]: updatePartialTaskRequest,
  [UPDATE_PARTIAL_TASK_SUCCESS]: updatePartialTaskRequestSuccess,
  [UPDATE_PARTIAL_TASK_FAILURE]: updatePartialTaskRequestFailure,

  [UPDATE_TASK_ASSIGNEE]: updateTaskAssigneeRequest,
  [UPDATE_TASK_ASSIGNEE_SUCCESS]: updateTaskAssigneeRequestSuccess,
  [UPDATE_TASK_ASSIGNEE_FAILURE]: updateTaskAssigneeRequestFailure,

  [REMOVE_TASK_ASSIGNEE]: removeTaskAssigneeRequest,
  [REMOVE_TASK_ASSIGNEE_SUCCESS]: removeTaskAssigneeRequestSuccess,
  [REMOVE_TASK_ASSIGNEE_FAILURE]: removeTaskAssigneeRequestFailure,

  [ADD_SUB_TASK]: addSubTaskRequest,
  [ADD_SUB_TASK_SUCCESS]: addSubTaskRequestSuccess,
  [ADD_SUB_TASK_FAILURE]: addSubTaskRequestFailure,

  [EDIT_SUB_TASK]: editSubTaskRequest,
  [EDIT_SUB_TASK_SUCCESS]: editSubTaskRequestSuccess,
  [EDIT_SUB_TASK_FAILURE]: editSubTaskRequestFailure,

  [DELETE_SUB_TASK]: deleteSubTaskRequest,
  [DELETE_SUB_TASK_SUCCESS]: deleteSubTaskRequestSuccess,
  [DELETE_SUB_TASK_FAILURE]: deleteSubTaskRequestFailure,

  [DUPLICATE_TASK]: duplicateTaskRequest,
  [DUPLICATE_TASK_SUCCESS]: duplicateTaskRequestSuccess,
  [DUPLICATE_TASK_FAILURE]: duplicateTaskRequestFailure,

  [DELETE_TASK]: deleteTaskRequest,
  [DELETE_TASK_SUCCESS]: deleteTaskRequestSuccess,
  [DELETE_TASK_FAILURE]: deleteTaskRequestFailure,

  [GET_TASK_LOCATIONS]: getTaskLocationsRequest,
  [GET_TASK_LOCATIONS_SUCCESS]: getTaskLocationsRequestSuccess,
  [GET_TASK_LOCATIONS_FAILURE]: getTaskLocationsRequestFailure,

  [ADD_TASK_LOCATION]: addTaskLocationRequest,
  [ADD_TASK_LOCATION_SUCCESS]: addTaskLocationRequestSuccess,
  [ADD_TASK_LOCATION_FAILURE]: addTaskLocationRequestFailure,

  [UPDATE_TASK_STATUS]: taskUpdateRequestHandler,
  [UPDATE_TASK_STATUS_SUCCESS]: taskUpdateRequestSuccessHandler,
  [UPDATE_TASK_STATUS_FAILURE]: taskUpdateRequestFailureHandler,

  [GET_TASK_TYPE]: getTaskTypeRequest,
  [GET_TASK_TYPE_SUCCESS]: getTaskTypeRequestSuccess,
  [GET_TASK_TYPE_FAILURE]: getTaskTypeRequestFailure,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  // task
  isLoading: false,
  duplicateIsLoading: false,
  deleteIsLoading: false,
  // location
  locationListIsLoading: false,
  addTaskLocationLoading: false,
  // task type
  taskTypeIsLoading: false,

  callback: false,
  deletedCallback: false,
  tasksList: [],
  taskDetails: null,
  createdTaskInfo: null,
  overlap: false,
  duplicateTaskInfo: null,
  locationsList: [],
  createdTaskLocationInfo: null,
  taskTypeList: [],
  // pagination
  tasksListCount: null,
  taskListPageSize: null,
};

export default function tasksReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
