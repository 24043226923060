import styled from 'styled-components';

export const DataAndMapContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || '30% 70%'};
  justify-content: space-between;
  margin: 0;

  @media screen and (min-width: 1500px) {
    grid-template-columns: 22% 78%;
  }
`;

export const MapBottomInfo = styled.div`
  position: absolute;
  height: 34px;
  width: max-content;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
  display: flex;
  align-items: center;
  grid-column-gap: 5px;
  padding: 0;

  // background-color: ${({ theme }) => theme.colors.main.textSecondary};
  background-color: rgba(52, 77, 105, 0.85);
  border-radius: 20px;
`;
export const MapInfoText = styled.span`
  font-size: 12px;
  padding-left: 15px;
  color: ${({ theme }) => theme.colors.main.white};
`;
export const InfoCrossButton = styled.div`
  width: 30px;
  height: 34px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0 20px 20px 0;

  &:hover {
    background-color: rgba(31, 41, 57, 0.2);
  }
`;

export const MapRadiusInfoDiv = styled.div`
  min-width: 60px;
  background-color: rgba(42, 168, 71, 0.6);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
`;
export const MapRadiusInfoText = styled.span`
  font-size: 11.5px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.white};
`;

export const SitesListContainer = styled.div`
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: ${({ theme }) => theme.dropdown.scrollbar.width};
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.dropdown.scrollbar.trackBgColor};
    border-radius: ${({ theme }) => theme.dropdown.scrollbar.thumbBorderRadius};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.dropdown.scrollbar.thumbBgColor};
    border-radius: ${({ theme }) => theme.dropdown.scrollbar.thumbBorderRadius};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.dropdown.scrollbar.thumbHoverBgColor};
  }
`;
export const ListHeaderContainer = styled.div`
  padding: 20px 15px 0;
`;

export const ListItemsContainer = styled.div`
  padding: 20px 0 0;
  max-height: 73vh;
  overflow: auto;
  @media screen and (max-height: 700px) {
    max-height: 70vh;
  }

  /* ::-webkit-scrollbar {
    width: ${({ theme }) => theme.dropdown.scrollbar.width};
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.dropdown.scrollbar.trackBgColor};
    border-radius: ${({ theme }) => theme.dropdown.scrollbar.thumbBorderRadius};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.dropdown.scrollbar.thumbBgColor};
    border-radius: ${({ theme }) => theme.dropdown.scrollbar.thumbBorderRadius};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.dropdown.scrollbar.thumbHoverBgColor};
  } */
`;
export const SingleSiteItem = styled.div`
  position: relative;
  padding: 18px 15px;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.main.primaryLight : theme.colors.main.white};
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid
      ${({ theme }) => theme.colors.border.secondaryLight};
  }
`;
export const HoveredSection = styled.div`
  position: absolute;
  top: ${({ top }) => top || '-14px'};
  right: ${({ right }) => right || '14px'};
  display: flex;
  grid-column-gap: 10px;
`;
export const EditButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  background-color: ${({ theme }) => theme.colors.main.gray};
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.hover.gray};
  }
`;
export const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  background-color: ${({ theme }) => theme.colors.main.error};
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.hover.error};
  }
`;
export const DetailsPageDeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  background-color: ${({ theme }) => theme.colors.main.white};
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};

  &:hover {
    border-color: ${({ theme }) => theme.colors.border.error};
  }
`;

export const JobSiteForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 15px 0;
`;

export const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  // grid-template-columns: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
export const NoDataArtwork = styled.div``;
