import {
  GET_SCREENSHOT_SETTINGS,
  GET_SCREENSHOT_SETTINGS_SUCCESS,
  GET_SCREENSHOT_SETTINGS_FAILURE,
  UPDATE_SCREENSHOT_SETTINGS,
  UPDATE_SCREENSHOT_SETTINGS_SUCCESS,
  UPDATE_SCREENSHOT_SETTINGS_FAILURE,
  GET_MEMBERWISE_ACTIVITY_SETTINGS,
  GET_MEMBERWISE_ACTIVITY_SETTINGS_SUCCESS,
  GET_MEMBERWISE_ACTIVITY_SETTINGS_FAILURE,
  UPDATE_MEMBERWISE_ACTIVITY_SETTINGS,
  UPDATE_MEMBERWISE_ACTIVITY_SETTINGS_SUCCESS,
  UPDATE_MEMBERWISE_ACTIVITY_SETTINGS_FAILURE,
  GET_IDLE_TIME_SETTINGS,
  GET_IDLE_TIME_SETTINGS_SUCCESS,
  GET_IDLE_TIME_SETTINGS_FAILURE,
  UPDATE_IDLE_TIME_SETTINGS,
  UPDATE_IDLE_TIME_SETTINGS_SUCCESS,
  UPDATE_IDLE_TIME_SETTINGS_FAILURE,
} from "../../../modules/constants";
import { createNotification } from "../../../modules/notificationManager";

// ------------------------------------
// Action handler methods
// ------------------------------------
export const getscreenshotsSettingsHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const getscreenshotsSettingsSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      screenshotsTracking: data.data.screenshot_track,
      screenshotsBlur: data.data.screenshot_blur,
      screenshotsFrequency: data.data.screenshot_frequency,
      screenshotsDelete: data.data.user_delete_screenshot,
      askInstantScreenshotPermission:
        data.data.ask_instant_screenshot_permission,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const getscreenshotsSettingsFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};

export const updateScreenshotSettingsHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};
export const updateScreenshotSettingsSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    createNotification("success", "Screenshot settings updated successfully.");
    return {
      ...state,
      isLoading: false,
      screenshotsTracking: data.data.screenshot_track,
      screenshotsBlur: data.data.screenshot_blur,
      screenshotsFrequency: data.data.screenshot_frequency,
      screenshotsDelete: data.data.user_delete_screenshot,
      askInstantScreenshotPermission:
        data.data.ask_instant_screenshot_permission,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const updateScreenshotSettingsFailureHandler = (state, action) => {
  createNotification(
    "error",
    "Screenshot settings update failed. Please try again."
  );
  return {
    ...state,
    isLoading: false,
  };
};

export const membersListRequestHandler = (state, action) => {
  return {
    ...state,
    membersListLoading: true,
  };
};
export const membersListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    membersListLoading: false,
    totalMembersCount: data.data.count,
    membersTrackingList: data.data.results,
  };
};
export const membersListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    membersListLoading: false,
    totalMembersCount: null,
    membersTrackingList: [],
  };
};

export const updateMemberListSettingsHandler = (state, action) => {
  return {
    ...state,
  };
};
export const updateMemberListSettingsSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    createNotification("success", "Member settings updated successfully.");
    const membersList = JSON.parse(JSON.stringify(state.membersTrackingList));
    if (membersList && membersList.length > 0) {
      membersList.some((member) => {
        if (member.id === data.data.id) {
          member.should_track = data.data.should_track;
          member.location_track = data.data.location_track;
          member.geofence_time_clock = data.data.geofence_time_clock;
          return true;
        } else {
          return false;
        }
      });
    }
    return {
      ...state,
      membersTrackingList: [...membersList],
    };
  }
  return {
    ...state,
  };
};
export const updateMemberListSettingsFailureHandler = (state, action) => {
  createNotification(
    "error",
    "Member settings update failed. Please try again."
  );
  return {
    ...state,
    isLoading: false,
  };
};

export const getIdleTimeSettingsHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const getIdleTimeSettingsSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      idleTimeSettings: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const getIdleTimeSettingsFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};

export const updateIdleTimeSettingsHandler = (state, action) => {
  return {
    ...state,
  };
};
export const updateIdleTimeSettingsSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    createNotification("success", "Idle time settings has been updated.", 2000);
    return {
      ...state,
      idleTimeSettings: data.data,
    };
  }
  return {
    ...state,
  };
};
export const updateIdleTimeSettingsFailureHandler = (state, action) => {
  return {
    ...state,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_SCREENSHOT_SETTINGS]: getscreenshotsSettingsHandler,
  [GET_SCREENSHOT_SETTINGS_SUCCESS]: getscreenshotsSettingsSuccessHandler,
  [GET_SCREENSHOT_SETTINGS_FAILURE]: getscreenshotsSettingsFailureHandler,

  [UPDATE_SCREENSHOT_SETTINGS]: updateScreenshotSettingsHandler,
  [UPDATE_SCREENSHOT_SETTINGS_SUCCESS]: updateScreenshotSettingsSuccessHandler,
  [UPDATE_SCREENSHOT_SETTINGS_FAILURE]: updateScreenshotSettingsFailureHandler,

  [GET_MEMBERWISE_ACTIVITY_SETTINGS]: membersListRequestHandler,
  [GET_MEMBERWISE_ACTIVITY_SETTINGS_SUCCESS]: membersListRequestSuccessHandler,
  [GET_MEMBERWISE_ACTIVITY_SETTINGS_FAILURE]: membersListRequestFailureHandler,

  [UPDATE_MEMBERWISE_ACTIVITY_SETTINGS]: updateMemberListSettingsHandler,
  [UPDATE_MEMBERWISE_ACTIVITY_SETTINGS_SUCCESS]:
    updateMemberListSettingsSuccessHandler,
  [UPDATE_MEMBERWISE_ACTIVITY_SETTINGS_FAILURE]:
    updateMemberListSettingsFailureHandler,

  [GET_IDLE_TIME_SETTINGS]: getIdleTimeSettingsHandler,
  [GET_IDLE_TIME_SETTINGS_SUCCESS]: getIdleTimeSettingsSuccessHandler,
  [GET_IDLE_TIME_SETTINGS_FAILURE]: getIdleTimeSettingsFailureHandler,

  [UPDATE_IDLE_TIME_SETTINGS]: updateIdleTimeSettingsHandler,
  [UPDATE_IDLE_TIME_SETTINGS_SUCCESS]: updateIdleTimeSettingsSuccessHandler,
  [UPDATE_IDLE_TIME_SETTINGS_FAILURE]: updateIdleTimeSettingsFailureHandler,
};

// default initial state
const initialState = {
  isLoading: false,
  membersListLoading: false,

  screenshotsTracking: false,
  screenshotsBlur: false,
  screenshotsFrequency: null,
  screenshotsDelete: false,

  idleTimeSettings: null,

  membersTrackingList: [],

  // pagination
  totalMembersCount: null,
};

export default function settingsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
