import styled, { keyframes } from "styled-components";

export const DropdownContainer = styled.div``;

export const DropdownBackgroundOverlay = styled.div`
  position: fixed;
  display: ${({ dropdownOpen }) => (dropdownOpen ? `block` : `none`)};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
`;
export const DropdownWrapper = styled.div`
  display: ${({ dropdownOpen }) => (dropdownOpen ? `block` : `none`)};
  position: ${({ dropdownOpen }) => (dropdownOpen ? `relative` : `absolute`)};
  left: ${({ dropdownOpen }) => (dropdownOpen ? `0` : `9999px`)};
  z-index: 2001;
`;
export const DropdownContent = styled.div`
  position: absolute;
  width: 100%;
  z-index: inherit;
  margin-bottom: 30px;
`;
const dropDown = keyframes`
  0% {
    top: 30px;
  }
  100% {
    top: 45px;
  }
`;
export const DropdownItemsContainer = styled.div`
  background-color: ${({ bgColor, theme }) =>
    bgColor || theme.colors.main.whiteBg};
  border-radius: ${({ theme }) => theme.dropdown.borderRadius};
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  width: 230px;
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  position: absolute;
  right: 0px;
  transform: translateX(100%);
  top: 45px;
  max-height: 550px;
  animation: 0.2s ${dropDown} ease;
  @media screen and (max-width: 991px) {
    top: 30px;
  }
`;

export const ActiveOrgSection = styled.div`
  padding: 10px 0px;
  border-bottom: 1px solid #f0f2f3;
`;
export const SingleOrgContainer = styled.div`
  padding: 7px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f0f2f3;
  }
`;
export const ArchivedOrgSection = styled.div`
  padding: 10px 0px;
  border-bottom: 1px solid #f0f2f3;
`;
export const CollapseSection = styled.div``;
export const CollapseHeaderText = styled.p`
  font-size: 13px !important;
  font-weight: ${({ theme }) => theme.fontWeights.semibold} !important;
  color: ${({ theme }) => theme.colors.main.labelText} !important;
`;
export const CollapseRotate = styled.div`
  transform: ${({ collapse }) => !collapse && `rotate(-90deg)`};
  transition: transform 0.4s ease;
`;
export const CollapseIcon = styled.img``;
export const CollapseHeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ active }) => (active ? `0px 12px 5px` : `0px 12px`)};
  cursor: pointer;
  &:hover {
    ${CollapseHeaderText} {
      color: ${({ theme }) => theme.colors.main.textSecondary} !important;
    }
    ${CollapseIcon} {
      filter: brightness(0) saturate(100%) invert(28%) sepia(12%)
        saturate(1446%) hue-rotate(180deg) brightness(97%) contrast(91%);
    }
  }
`;
export const CollapseContent = styled.div`
  max-height: ${({ collapse }) => (collapse ? `180px` : `0`)};
  overflow: ${({ collapse }) => (collapse ? `auto` : `hidden`)};
  opacity: ${({ collapse }) => (collapse ? 1 : 0)};
  transition: max-height 0.4s ease, opacity 0.4s ease;
`;

export const GoToOrgButtonDiv = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* cursor: pointer; */
`;
export const GoToOrgButton = styled.div`
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.primary};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.hover.primary};
  }
`;
