//Effects
import { all, call, put, takeLatest } from "redux-saga/effects";
//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  GET_TEAM_LIST,
  GET_TEAM_LIST_SUCCESS,
  GET_TEAM_LIST_FAILURE,
  GET_TEAM_SHORT_LIST,
  GET_TEAM_SHORT_LIST_SUCCESS,
  GET_TEAM_SHORT_LIST_FAILURE,
  GET_TEAM_DETAILS,
  GET_TEAM_DETAILS_SUCCESS,
  GET_TEAM_DETAILS_FAILURE,
  ADD_TEAM_REQUEST,
  ADD_TEAM_REQUEST_SUCCESS,
  ADD_TEAM_REQUEST_FAILURE,
  UPDATE_TEAM_REQUEST,
  UPDATE_TEAM_REQUEST_SUCCESS,
  UPDATE_TEAM_REQUEST_FAILURE,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_REQUEST_SUCCESS,
  DELETE_TEAM_REQUEST_FAILURE,
  GET_TEAM_MEMBERS_LIST,
  GET_TEAM_MEMBERS_LIST_SUCCESS,
  GET_TEAM_MEMBERS_LIST_FAILURE,
  ADD_TEAM_MEMBER,
  ADD_TEAM_MEMBER_SUCCESS,
  ADD_TEAM_MEMBER_FAILURE,
  UPDATE_TEAM_MEMBER,
  UPDATE_TEAM_MEMBER_SUCCESS,
  UPDATE_TEAM_MEMBER_FAILURE,
  REMOVE_TEAM_MEMBER,
  REMOVE_TEAM_MEMBER_SUCCESS,
  REMOVE_TEAM_MEMBER_FAILURE,
  GET_TEAM_PROJECTS_LIST,
  GET_TEAM_PROJECTS_LIST_SUCCESS,
  GET_TEAM_PROJECTS_LIST_FAILURE,
} from "../../modules/constants";

import { getMemberDetails } from "../Members/membersSagas";

export function* getTeamListRequest({ payload }) {
  try {
    const { organization_id, page, name } = payload;
    const url = `${organization_id}/teams/${page ? `?page=${page}` : ``}${
      name ? (page ? `&name=${name}` : `?name=${name}`) : ``
    }`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_TEAM_LIST_SUCCESS,
      payload: { data: response, notify: "GET" },
    });
  } catch (err) {
    yield put({
      type: GET_TEAM_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getTeamShortListRequest({ payload }) {
  try {
    let { organization_id } = payload;
    let url = `${organization_id}/teams/short/`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_TEAM_SHORT_LIST_SUCCESS,
      payload: { data: response, notify: "GET" },
    });
  } catch (err) {
    yield put({
      type: GET_TEAM_SHORT_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getTeamDetailsRequest({ payload }) {
  try {
    let { organization_id, team_id } = payload;
    let url = `${organization_id}/teams/${team_id}/`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_TEAM_DETAILS_SUCCESS,
      payload: { data: response, notify: "GET" },
    });
  } catch (err) {
    yield put({
      type: GET_TEAM_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export function* addTeamRequest({ payload }) {
  try {
    let { organization_id, ...details } = payload;
    let url = `${organization_id}/teams/`;
    let method = "POST";
    const response = yield defaultApi(url, method, details);
    yield put({
      type: ADD_TEAM_REQUEST_SUCCESS,
      payload: { data: response, notify: "POST" },
    });
  } catch (err) {
    yield put({
      type: ADD_TEAM_REQUEST_FAILURE,
      payload: err,
    });
  }
}

export function* updateTeamRequest({ payload }) {
  try {
    let { organization_id, team_id, ...details } = payload;
    let url = `${organization_id}/teams/${team_id}/`;
    let method = "PATCH";
    const response = yield defaultApi(url, method, details);
    yield put({
      type: UPDATE_TEAM_REQUEST_SUCCESS,
      payload: { data: response, notify: "PATCH" },
    });
  } catch (err) {
    yield put({
      type: UPDATE_TEAM_REQUEST_FAILURE,
      payload: err,
    });
  }
}

export function* deleteTeamRequest({ payload }) {
  try {
    let { organization_id, team_id } = payload;
    let url = `${organization_id}/teams/${team_id}/`;
    let method = "DELETE";
    const response = yield defaultApi(url, method);
    yield put({
      type: DELETE_TEAM_REQUEST_SUCCESS,
      payload: { data: response, team_id, notify: "DELETE" },
    });
  } catch (err) {
    yield put({
      type: DELETE_TEAM_REQUEST_FAILURE,
      payload: err,
    });
  }
}

export function* getTeamMembersListRequest({ payload }) {
  try {
    const { organization_id, team_id, page, name } = payload;
    const url = `${organization_id}/teams/${team_id}/members/${
      page ? `?page=${page}` : ``
    }${name ? (page ? `&name=${name}` : `?name=${name}`) : ``}`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_TEAM_MEMBERS_LIST_SUCCESS,
      payload: { data: response, notify: "GET" },
    });
  } catch (err) {
    yield put({
      type: GET_TEAM_MEMBERS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* addTeamMemberRequest({ payload }) {
  try {
    let { organization_id, team_id, getDetails, getMemberData, ...details } =
      payload;
    let url = `${organization_id}/teams/${team_id}/members/`;
    let method = "POST";
    const response = yield defaultApi(url, method, details);
    if (response.status === 201 && getMemberData && details.user_id) {
      let memberDetailsPayload = {
        organization_id,
        user_id: details.user_id,
      };
      yield call(getMemberDetails, { payload: memberDetailsPayload });
    }
    yield put({
      type: ADD_TEAM_MEMBER_SUCCESS,
      payload: { data: response, notify: "POST" },
    });
  } catch (err) {
    yield put({
      type: ADD_TEAM_MEMBER_FAILURE,
      payload: err,
    });
  }
}

export function* updateTeamMemberRequest({ payload }) {
  try {
    let { organization_id, team_id, member_id, ...details } = payload;
    let url = `${organization_id}/teams/${team_id}/members/${member_id}/`;
    let method = "PATCH";
    const response = yield defaultApi(url, method, details);
    // yield call(getTeamMembersListRequest, { payload });
    yield put({
      type: UPDATE_TEAM_MEMBER_SUCCESS,
      payload: { data: response, notify: "PATCH" },
    });
  } catch (err) {
    yield put({
      type: UPDATE_TEAM_MEMBER_FAILURE,
      payload: err,
    });
  }
}

export function* removeTeamMemberRequest({ payload }) {
  try {
    let { organization_id, team_id, user_id } = payload;
    let url = `${organization_id}/teams/${team_id}/members/${user_id}/`;
    let method = "DELETE";
    const response = yield defaultApi(url, method);
    yield put({
      type: REMOVE_TEAM_MEMBER_SUCCESS,
      payload: {
        data: response,
        notify: "DELETE",
      },
      userData: { teamId: team_id, userId: user_id },
    });
  } catch (err) {
    yield put({
      type: REMOVE_TEAM_MEMBER_FAILURE,
      payload: err,
    });
  }
}

export function* getTeamProjectsListRequest({ payload }) {
  try {
    const { organization_id, team_id, name, page } = payload;
    const url = `${organization_id}/projects/?team=${team_id}${
      page ? `&page=${page}` : ``
    }${name ? `&name=${name}` : ``}`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_TEAM_PROJECTS_LIST_SUCCESS,
      payload: { data: response, notify: "GET" },
    });
  } catch (err) {
    yield put({
      type: GET_TEAM_PROJECTS_LIST_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_TEAM_LIST, getTeamListRequest),
    takeLatest(GET_TEAM_SHORT_LIST, getTeamShortListRequest),
    takeLatest(GET_TEAM_DETAILS, getTeamDetailsRequest),
    takeLatest(ADD_TEAM_REQUEST, addTeamRequest),
    takeLatest(UPDATE_TEAM_REQUEST, updateTeamRequest),
    takeLatest(DELETE_TEAM_REQUEST, deleteTeamRequest),
    takeLatest(GET_TEAM_MEMBERS_LIST, getTeamMembersListRequest),
    takeLatest(ADD_TEAM_MEMBER, addTeamMemberRequest),
    takeLatest(UPDATE_TEAM_MEMBER, updateTeamMemberRequest),
    takeLatest(REMOVE_TEAM_MEMBER, removeTeamMemberRequest),
    takeLatest(GET_TEAM_PROJECTS_LIST, getTeamProjectsListRequest),
  ]);
}
