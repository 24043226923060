import { RoleOptions } from 'modules/constants';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import avatar5 from '../../assets/img/avatar_5.svg';
import ProjectDropdown from '../../components/CommonFilter/ProjectDropdown';
import AddSingleMemberDropdown from '../../components/CustomDropdown/AddSingleMemberDropdown';
import CreateProjectDropdown from '../../components/DropdownStyle/CreateProjectDropdown';
import FilterDropDownStyle from '../../components/DropdownStyle/FilterDropDownStyle';
import Select from '../../components/ReactSelectDropdown';
import { filterOption } from '../../components/ReactSelectDropdown/ReactSelectDropdown';
import SearchWithButton from '../../components/Search/SearchWithButton';
import DatePicker from '../../components/SingleDatePicker/SingleDatePicker';
import {
  FilterLabel,
  InputField,
  InputWithLabelSection,
} from '../../styledComponents/common';
import { FilterContainer } from '../../styledComponents/tasks';

const MembersFilter = ({
  getOfficesShortList,
  officesShortList,
  selectedOffice,
  selectOffice,
  handleSearchByName,
  handleClearSearch,
  searchTerm,
  onSearchTermChange,
  userCodeSearchTerm,
  onUserCodeSearchTermChange,
  handleSearchByUserCode,
  handleClearUserCodeSearch,
  selectedRole,
  onRoleChange,
}) => {
  const { t } = useTranslation();
  const [officeOptions, setOfficeOptions] = useState([]);

  const roleOptions = RoleOptions?.map(option => {
    if (option.value)
      return {
        ...option,
        label: option.key,
      };
    return {
      ...option,
      label: t(option.key),
    };
  });

  useEffect(() => {
    getOfficesShortList();
  }, []);

  useEffect(() => {
    if (officesShortList && officesShortList.length > 0) {
      let newArr = [
        {
          label: t('all_offices'),
          value: null,
        },
      ];
      officesShortList.forEach((office, i) => {
        newArr.push({
          label: office.name,
          value: office.id,
        });
      });
      setOfficeOptions(newArr);
    }
  }, [officesShortList]);

  return (
    <FilterContainer
      columns={
        localStorage.getItem('user_role') === 'admin'
          ? '160px 340px 250px 160px'
          : '340px 250px'
      }
      gap="15px"
    >
      {localStorage.getItem('user_role') === 'admin' && (
        <InputWithLabelSection>
          <FilterLabel>{t('office')}</FilterLabel>

          <Select
            isSearchable
            value={selectedOffice}
            options={officeOptions}
            placeholder="Select Office..."
            onChange={e => {
              selectOffice(e);
            }}
            styles={CreateProjectDropdown()}
            // clearable
            filterOption={filterOption}
          />
        </InputWithLabelSection>
      )}
      <SearchWithButton
        itemName={t('for_a_member')}
        searchTermName={`${t('member_name')} ${t('or_email')}`}
        searchInput={searchTerm}
        onInputChange={onSearchTermChange}
        handleSearch={handleSearchByName}
        handleClearSearch={handleClearSearch}
        fontSize="12.5px"
      />
      <SearchWithButton
        itemName={t('for_a_member')}
        searchTermName={t('user_id')}
        searchInput={userCodeSearchTerm}
        onInputChange={onUserCodeSearchTermChange}
        handleSearch={handleSearchByUserCode}
        handleClearSearch={handleClearUserCodeSearch}
        fontSize="12.5px"
      />
      {localStorage.getItem('user_role') === 'admin' && (
        <InputWithLabelSection>
          <FilterLabel>{t('role')}</FilterLabel>

          <Select
            isSearchable
            value={selectedRole}
            options={roleOptions}
            placeholder={t('select_role')}
            onChange={e => {
              onRoleChange(e);
            }}
            styles={CreateProjectDropdown()}
          />
        </InputWithLabelSection>
      )}
    </FilterContainer>
  );
};

export default MembersFilter;
