
import { Switch } from "react-router-dom";

import routes from "routes.js";
import { PrivateRoute } from "../../modules/privateRoute";

const Onboard = (props) => {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/setup") {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return <Switch>{getRoutes(routes)}</Switch>;
};

export default Onboard;
