export const fontWeightConfig = {
  regular: 500,
  semibold: 600,
  bold: 700,
};

export const radiusConfig = {
  button: "6px",
  input: "6px",
  dropdown: "6px",
  card: "10px",
  tooltip: "6px",
  circle: "50%",
  square: '2px'
};
