import {
  VERIFY_CODE,
  VERIFY_CODE_SUCCESS,
  VERIFY_CODE_FAILURE,
  RESEND_VERIFICATION_CODE,
  RESEND_VERIFICATION_CODE_SUCCESS,
  RESEND_VERIFICATION_CODE_FAILURE,
  VERIFY_CODE_RESET,
} from "../../modules/constants";
import { createNotification } from "../../modules/notificationManager";

// ------------------------------------
// Action handler methods
// ------------------------------------
export const verifyCodeRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const verifyCodeRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    createNotification("success", "Verification Success", 3000);
    return {
      ...state,
      isLoading: false,
      token: data.data.token,
      userData: data.data,
      redirect: true,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const verifyCodeRequestFailureHandler = (state, action) => {
  if (action.payload.response === undefined) {
    createNotification("error", "No internet connection found", 3000);
    return {
      ...state,
      isLoading: false,
      redirect: false,
    };
  }
  const {
    payload: {
      response: { data },
    },
  } = action;
  if (data.non_field_errors) {
    createNotification("error", data.non_field_errors[0], 3000);
  }
  return {
    ...state,
    isLoading: false,
    redirect: false,
  };
};

export const resendVerificationCodeRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const resendVerificationCodeRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    createNotification("success", "A new code has been sent.", 3000);
    return {
      ...state,
      isLoading: false,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const resendVerificationCodeRequestFailureHandler = (state, action) => {
  if (action.payload.response === undefined) {
    createNotification("error", "No internet connection found", 3000);
    return {
      ...state,
      isLoading: false,
      redirect: false,
    };
  }
  const {
    payload: {
      response: { data },
    },
  } = action;
  return {
    ...state,
    isLoading: false,
    redirect: false,
  };
};

export const resetVerificationCodeHandler = (state, action) => {
  return {
    isLoading: false,
    redirect: false,
    token: null,
    userData: null,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [VERIFY_CODE]: verifyCodeRequestHandler,
  [VERIFY_CODE_SUCCESS]: verifyCodeRequestSuccessHandler,
  [VERIFY_CODE_FAILURE]: verifyCodeRequestFailureHandler,

  [RESEND_VERIFICATION_CODE]: resendVerificationCodeRequestHandler,
  [RESEND_VERIFICATION_CODE_SUCCESS]:
    resendVerificationCodeRequestSuccessHandler,
  [RESEND_VERIFICATION_CODE_FAILURE]:
    resendVerificationCodeRequestFailureHandler,

  [VERIFY_CODE_RESET]: resetVerificationCodeHandler,
};

// default initial state
const initialState = {
  isLoading: false,
  redirect: false,
  token: null,
  userData: null,
};

export default function verifyReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
