import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Avatar5 from '../../assets/img/avatar_5.svg';
import editIcon from '../../assets/img/icons/edit-pen.svg';
import createProjectDropdown from '../../components/DropdownStyle/CreateProjectDropdown';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent';
import ProjectListPopup from '../../components/ProjectListPopup/ProjectListPopup';
import Select from '../../components/ReactSelectDropdown';
import TaskActionTooltip from '../../components/Tooltip/TaskActionTooltip';
import {
  AssigneeMoreText,
  ColoredText,
  CommonFlex,
  CommonImage,
  CommonText,
  Container,
  ResendInvite,
  RoundLetterSection,
  StyledLink,
  TableText,
} from '../../styledComponents/common';
import {
  ToggleButton,
  ToggleButtonRound,
} from '../../styledComponents/invoice';
import {
  InviteHeadSection,
  InviteLink,
  InviteProjectSection,
  InviteTableContainer,
  MemberNameDiv,
  MembersTableContainer,
  MembersTableHeadContainer,
  RadioButton,
  TeamSection,
  UserSettings,
} from '../../styledComponents/members';
import { AssignedProjectBox, TableItem } from '../../styledComponents/teams';
import { RoundIcon } from '../Tasks/tasksStyles';
import {
  ApprovalSettingsSection,
  CommFirstRoundLetterComp,
  CommImageInviteComp,
  CommImageNameComp,
} from './CommonComponents';
import { HiddenContent, HoverContainer } from './memberStyles';
import UserTypeChangeWarningModal from './userTypeChangeWarningModal';

const MembersTable = props => {
  const { t } = useTranslation();
  const [isUserTypeChangeModalOpen, setIsUserTypeChangeModalOpen] =
    useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const [role, setRole] = useState('');
  const updateUserType = val => {
    props.onGeoFenceTimeClockChange(selectedMember.id, val);
    setSelectedMember(null);
    setIsUserTypeChangeModalOpen(!isUserTypeChangeModalOpen);
  };
  useEffect(() => {
    setRole(localStorage.getItem('user_role'));
  }, []);
  return (
    <Container style={{ paddingBottom: `20px` }}>
      <UserTypeChangeWarningModal
        member={selectedMember}
        isOpen={isUserTypeChangeModalOpen}
        toggle={() => {
          setIsUserTypeChangeModalOpen(!isUserTypeChangeModalOpen);
        }}
        handleSave={updateUserType}
        isLoading={props.updateMemberLoading}
      />
      {props.isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          <MembersTableHeadContainer
            style={{ borderBottom: `1px solid #C2CCE1` }}
          >
            <TableItem>
              <TableText>{t('member_name')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('user_id')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('email')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('role')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('contact_number')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('office')}</TableText>
            </TableItem>
            {(role === 'admin' || role === 'gm' || role === 'agm') &&
            !localStorage.getItem('viewer_role') ? (
              <TableItem>
                <TableText>{t('employee_type')}</TableText>
              </TableItem>
            ) : null}
          </MembersTableHeadContainer>
          {props.membersList && props.membersList.length > 0 ? (
            props.membersList.map((member, index) => (
              <MembersTableContainer key={index}>
                <TableItem>
                  <div
                  // onClick={() =>
                  //   props.history.push(
                  //     `/user/member-details?id=${member.id}`
                  //   )
                  // }
                  >
                    <CommImageNameComp
                      imgSource={member.avatar ? member.avatar : Avatar5}
                      name={`${member.first_name} ${member.last_name}`}
                      size="36px"
                    />
                  </div>
                </TableItem>
                <TableItem>
                  <CommonText>{member.user_code || `-`}</CommonText>
                </TableItem>
                <TableItem>
                  <CommonText>{member.email || `-`}</CommonText>
                </TableItem>
                <TableItem>
                  <CommonFlex direction="column" gap="2px" alignItems="start">
                    <CommonText>{member.role ? member.role : `-`}</CommonText>
                    <CommonText>
                      {member.monazim_code ? member.monazim_code : ``}
                    </CommonText>
                  </CommonFlex>{' '}
                </TableItem>
                <TableItem>
                  <CommonText>{member.phone ? member.phone : `-`}</CommonText>
                </TableItem>
                <TableItem>
                  {member.role === 'monazim' ? (
                    <>
                      {' '}
                      <CommonText>
                        {member.office[0] && member.office[0].id
                          ? member.office[0].name
                          : `-`}
                      </CommonText>
                      {member.office?.length > 1 && (
                        <HoverContainer>
                          <CommonText color="#3d4d69b2">
                            {member.office?.length - 1} more
                          </CommonText>
                          <HiddenContent className="hover-content">
                            <CommonFlex
                              direction="column"
                              gap="6px"
                              alignItems="start"
                            >
                              {member.office.slice(1).map((office, index) => (
                                <CommonText key={index}>
                                  {office.name ? office.name : `-`}
                                </CommonText>
                              ))}
                            </CommonFlex>
                          </HiddenContent>
                        </HoverContainer>
                      )}
                    </>
                  ) : (
                    <CommonText>
                      {member.office && member.office.id
                        ? member.office.name
                        : `-`}
                    </CommonText>
                  )}
                </TableItem>
                {
                  // (
                  role === 'admin' ? (
                    //    ||
                    //   (role === 'gm' && member.role !== 'admin') ||
                    //   (role === 'agm' &&
                    //     (member.role === 'supervisor' ||
                    //       member.role === 'employee'))) &&
                    // !localStorage.getItem('viewer_role')
                    member.id !== localStorage.getItem('user_id') ? (
                      <TableItem>
                        <UserSettings>
                          <RadioButton
                            cursor={
                              // role === 'gm' || role === 'agm'
                              //   ? 'not-allowed'
                              //   :
                              'pointer'
                            }
                            onClick={() => {
                              if (
                                !member.geofence_time_clock
                                // ||
                                // role === 'gm' ||
                                // role === 'agm'
                              ) {
                                return;
                              }
                              setSelectedMember(member);
                              setIsUserTypeChangeModalOpen(
                                !isUserTypeChangeModalOpen,
                              );
                            }}
                            active={!member.geofence_time_clock}
                          />
                          <ColoredText
                            cursor={
                              // role === 'gm' || role === 'agm'
                              //   ? 'not-allowed'
                              //   :
                              'pointer'
                            }
                            type={!member.geofence_time_clock && 'success'}
                            fontSize="13px"
                          >
                            {t('remote_user')}
                          </ColoredText>
                          {/* <ToggleButton
                      onClick={() =>
                        props.onGeoFenceTimeClockChange(
                          index,
                          member.id,
                          member.geofence_time_clock
                        )
                      }
                      active={member.geofence_time_clock}
                    >
                      <ToggleButtonRound active={member.geofence_time_clock} />
                    </ToggleButton> */}
                          <RadioButton
                            cursor={
                              // role === 'gm' || role === 'agm'
                              //   ? 'not-allowed'
                              //   :
                              'pointer'
                            }
                            onClick={() => {
                              if (
                                member.geofence_time_clock
                                // ||
                                // role === 'gm' ||
                                // role === 'agm'
                              ) {
                                return;
                              }
                              setSelectedMember(member);
                              setIsUserTypeChangeModalOpen(
                                !isUserTypeChangeModalOpen,
                              );
                            }}
                            active={member.geofence_time_clock}
                          />
                          <ColoredText
                            cursor={
                              // role === 'gm' || role === 'agm'
                              //   ? 'not-allowed'
                              //   :
                              'pointer'
                            }
                            type={member.geofence_time_clock && 'success'}
                            fontSize="13px"
                          >
                            {t('On-site User')}
                          </ColoredText>
                        </UserSettings>
                      </TableItem>
                    ) : (
                      <TableText>-</TableText>
                    )
                  ) : (
                    <TableText>-</TableText>
                  )
                }
                {role === 'admin' && !localStorage.getItem('viewer_role') && (
                  <TableItem>
                    <RoundIcon
                      type="edit"
                      onClick={() =>
                        props.history.push(
                          `/user/member-add-update?id=${member.id}`,
                        )
                      }
                    >
                      <CommonImage src={editIcon} alt="" size="16px" />
                      <TaskActionTooltip toolTipText={t('edit_member')} />
                    </RoundIcon>
                  </TableItem>
                )}
              </MembersTableContainer>
            ))
          ) : (
            <NoDataComponent
              title="No Members Found!"
              padding="5vh 0"
              imageHeight="140px"
            />
          )}
        </>
      )}
    </Container>
  );
};

export default MembersTable;
