import AddMultipleAssigneeDropdown from 'components/CustomDropdown/AddMultipleAssigneeDropdown';
import TimeRangeComponentNew from 'custom_modules/Timesheet/Daily/TimeRangeComponentNew';
import TimeDuration from 'custom_modules/Timesheet/Daily/timeDuration';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BackButtonComponent from '../../../components/BackButtonComponent/BackButtonComponent';
import AddSingleMemberDropdown from '../../../components/CustomDropdown/AddSingleMemberDropdown';
import CreateProjectDropdown from '../../../components/DropdownStyle/CreateProjectDropdown';
import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import Select from '../../../components/ReactSelectDropdown';
import { filterOption } from '../../../components/ReactSelectDropdown/ReactSelectDropdown';
import { ClearSearchButton } from '../../../components/Search/searchStyles';
import DatePicker from '../../../components/SingleDatePicker/SingleDatePicker';
import { PrimaryButton, RadiusButton } from '../../../styledComponents/buttons';
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonImage,
  CommonText,
  Container,
  ContentHeader,
  FormAlert,
  InputField,
  InputLabel,
  InputTextArea,
  InputWithLabelSection,
  PageTitle,
  RedStar,
} from '../../../styledComponents/common';
import {
  ToggleButton,
  ToggleButtonRound,
} from '../../../styledComponents/invoice';
import { checkHtml, checkUrl } from '../../../utils/helper';
import TimeRangeComponent from '../../Timesheet/Daily/TimeRangeComponent';
import AddLocationModal from './AddLocationModal';
import {
  AssigneeSection,
  InputSection,
  TimeRangeSection,
} from './createTaskStyles';

const CreateTask = ({
  isLoading,
  orgMembersList,
  officesShortList,
  addTaskLocationLoading,
  createdTaskInfo,
  locationsList,
  createdTaskLocationInfo,
  memberShortListIsLoading,
  getOrganizationMembersList,
  createTask,
  getTaskLocation,
  addTaskLocation,
  getOfficesShortList,
  getTaskType,
  taskTypeIsLoading,
  taskTypeList,
  history,
}) => {
  const { t, i18n } = useTranslation();

  const [taskName, setTaskName] = useState('');
  const [selectedOffice, setSelectedOffice] = useState('');
  const [officeOptions, setOfficeOptions] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [requiresApproval, setRequiresApproval] = useState(false);
  const [taskDescription, setTaskDescription] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [taskLocationOptions, setTaskLocationOptions] = useState([]);
  // const [radiusInput, setRadiusInput] = useState(100);
  // const [isCustomRadius, setIsCustomRadius] = useState(false);
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [endDate, setEndDate] = useState(moment(new Date()));
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  // const [selectedTaskType, setSelectedTaskType] = useState('');
  const [popupOpen, setPopupOpen] = useState(false);

  const [saveErrors, setSaveErrors] = useState({});
  const radiusOptions = [20, 50, 100];
  const [statusOptions, setStatusOptions] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  useEffect(() => {
    getOfficesShortList();
    // getTaskLocation();
    getTaskType();
  }, []);

  useEffect(() => {
    if (officesShortList && officesShortList.length === 1) {
      getOrganizationMembersList({ office_id: officesShortList[0].id });
      getTaskLocation({ office_id: officesShortList[0].id });
    }
    if (officesShortList && officesShortList.length > 0) {
      setSelectedOffice({
        label: officesShortList[0].name,
        value: officesShortList[0].id,
      });
      let newArr = [];
      officesShortList.forEach((office, i) => {
        newArr.push({
          label: office.name,
          value: office.id,
        });
      });
      setOfficeOptions(newArr);
    }
  }, [officesShortList]);

  useEffect(() => {
    if (
      selectedOffice &&
      selectedOffice.value &&
      officesShortList &&
      officesShortList.length > 1
    ) {
      getOrganizationMembersList({ office_id: selectedOffice.value });
      getTaskLocation({ office_id: selectedOffice.value });
    }
  }, [selectedOffice]);

  useEffect(() => {
    if (locationsList && locationsList.length > 0) {
      let newArr = [];
      locationsList.forEach(location => {
        newArr.push({
          label: location.name,
          value: location.id,
          ...location,
        });
      });
      setTaskLocationOptions(newArr);
    }
  }, [locationsList]);

  useEffect(() => {
    if (taskTypeList && taskTypeList.length > 0) {
      let newArr = [];
      if (i18n.language && i18n.language === 'en') {
        taskTypeList.forEach(type => {
          newArr.push({
            label: type.name,
            value: type.id,
          });
        });
      } else {
        taskTypeList.forEach(type => {
          newArr.push({
            label: type.arabic_name,
            value: type.id,
          });
        });
      }
      setStatusOptions(newArr);
    }
  }, [taskTypeList]);

  useEffect(() => {
    if (
      selectedOffice &&
      selectedOffice.label &&
      taskLocationOptions &&
      taskLocationOptions.length > 0
    ) {
      taskLocationOptions.forEach(location => {
        if (
          location.label === selectedOffice.label &&
          location.office &&
          location.office.id === selectedOffice.value
        ) {
          setSelectedLocation(location);
        }
      });
    }
  }, [selectedOffice, taskLocationOptions]);

  useEffect(() => {
    if (createdTaskInfo && isSubmitted) {
      history.push({
        pathname: '/user/tickets',
        state: { from: 'create' },
      });
    }
  }, [createdTaskInfo]);

  const onTaskNameChange = e => {
    let errors = { ...saveErrors };
    delete errors['taskName'];
    setSaveErrors(errors);
    setTaskName(e.target.value);
  };

  const onOfficeChange = e => {
    setSelectedOffice(e);
  };

  const approvalToggle = () => {
    setRequiresApproval(!requiresApproval);
  };

  const handleAssignOrRemoveMember = (user, action) => {
    if (user && user.id) {
      let newArr = [...selectedAssignees];
      if (action === 'remove') {
        newArr = newArr.filter(member => member.id !== user.id);
      } else {
        newArr.push(user);
      }
      setSelectedAssignees(newArr);
    }
  };

  const handleAssignMember = user => {
    handleAssignOrRemoveMember(user, 'assign');
  };

  const handleRemoveMember = user => {
    handleAssignOrRemoveMember(user, 'remove');
  };

  const onTaskDescriptionChange = e => {
    let errors = { ...saveErrors };
    delete errors['taskDescription'];
    setSaveErrors(errors);
    setTaskDescription(e.target.value);
  };

  const onLocationChange = e => {
    setSelectedLocation(e);
  };

  const onStartDateChange = date => {
    let errors = { ...saveErrors };
    delete errors['timeDifferenceStartTime'];
    setSaveErrors(errors);
    setStartDate(date);
  };
  const onEndDateChange = date => {
    let errors = { ...saveErrors };
    delete errors['timeDifferenceEndTime'];
    setSaveErrors(errors);
    setEndDate(date);
  };

  const handleStartTime = time => {
    if (time) {
      let errors = { ...saveErrors };
      delete errors['timeDifferenceStartTime'];
      setSaveErrors(errors);
      const start_time = time.replace(/ /g, '');
      setStartTime(moment(start_time, 'h:mmA').format('HH:mm:ss'));
    } else {
      setStartTime('');
    }
  };
  const handleEndTime = time => {
    if (time) {
      let errors = { ...saveErrors };
      delete errors['timeDifferenceEndTime'];
      setSaveErrors(errors);
      const end_time = time.replace(/ /g, '');
      setEndTime(moment(end_time, 'h:mmA').format('HH:mm:ss'));
    } else {
      setEndTime('');
    }
  };

  const onTaskTypeChange = e => {
    setSelectedTaskType(e);
  };

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const handleSave = () => {
    if (checkError()) {
      if (selectedOffice && selectedOffice.value) {
        const start_date = moment(
          startDate.format('YYYY-MM-DD') + ' ' + startTime,
        );
        const end_date = moment(endDate.format('YYYY-MM-DD') + ' ' + endTime);
        let assigneeIds = [];
        if (selectedAssignees && selectedAssignees.length > 0) {
          selectedAssignees.forEach(element => {
            assigneeIds.push(element.id);
          });
        }
        let payload = {
          name: taskName,
          office_id: selectedOffice.value,
          assignees: assigneeIds,
          description: taskDescription,
          location_id: selectedLocation.value,
          // task_type: selectedTaskType.value,
          required_start_date: moment(start_date).format(
            'YYYY-MM-DDTHH:mm:ssZ',
          ),
          required_end_date: moment(end_date).format('YYYY-MM-DDTHH:mm:ssZ'),
        };
        createTask(payload);
        setIsSubmitted(true);
      }
    }
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!taskName.trim()) {
      formIsValid = false;
      saveErrors['taskName'] = 'Task name is required';
    } else if (checkUrl(taskName)) {
      formIsValid = false;
      saveErrors['taskName'] = 'Task name can not contain url.';
    } else if (checkHtml(taskName)) {
      formIsValid = false;
      saveErrors['taskName'] = 'Invalid Task name';
    } else if (taskName.length > 255) {
      formIsValid = false;
      saveErrors['taskName'] = 'Maximum length 255 characters';
    }
    if (selectedAssignees.length === 0) {
      formIsValid = false;
      saveErrors['selectedAssignees'] = 'Assignee is required';
    }
    if (checkUrl(taskDescription)) {
      formIsValid = false;
      saveErrors['taskDescription'] = 'Task description can not contain url.';
    } else if (checkHtml(taskDescription)) {
      formIsValid = false;
      saveErrors['taskDescription'] = 'Invalid Task description';
    } else if (taskDescription.length > 512) {
      formIsValid = false;
      saveErrors['taskDescription'] = 'Maximum length 512 characters';
    }
    if (!startDate) {
      formIsValid = false;
    }
    if (!startTime || !endTime) {
      formIsValid = false;
    }
    if (
      moment(endDate.format('YYYY-MM-DD') + ' ' + endTime).diff(
        moment(startDate.format('YYYY-MM-DD') + ' ' + startTime),
      ) < 1
    ) {
      formIsValid = false;
      saveErrors['timeDifferenceEndTime'] = 'End time must be after start time';
    } else if (
      moment(endDate.format('YYYY-MM-DD') + ' ' + endTime).diff(
        moment(startDate.format('YYYY-MM-DD') + ' ' + startTime),
      ) < 1800000
    ) {
      formIsValid = false;
      saveErrors['timeDifferenceEndTime'] = t(
        'Minimum 30 minutes time difference required',
      );
    }
    if (
      moment(startDate.format('YYYY-MM-DD') + ' ' + startTime).diff(moment()) <
      1
    ) {
      formIsValid = false;
      saveErrors['timeDifferenceStartTime'] = "Past time can't be set";
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  return (
    <div className="content">
      <AddLocationModal
        isOpen={popupOpen}
        toggle={popupToggle}
        addTaskLocation={addTaskLocation}
        addTaskLocationLoading={addTaskLocationLoading}
        createdTaskLocationInfo={createdTaskLocationInfo}
        getTaskLocation={getTaskLocation}
        getOfficesShortList={getOfficesShortList}
        officesShortList={officesShortList}
      />
      <PageTitle>{t('create_ticket')}</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => history.push('/user/tickets')}
          subTitle={t('back_to_all_tickets')}
        />
      </ContentHeader>
      <Container padding="30px" gap="20px">
        <CommonText name title fontSize="16px">
          {t('add_ticket_details')}
        </CommonText>
        <InputSection>
          <InputWithLabelSection>
            <InputLabel>
              {t('ticket_name')} <RedStar>*</RedStar>
            </InputLabel>
            <InputField
              type="text"
              placeholder={t('type_ticket_name')}
              value={taskName}
              onChange={e => onTaskNameChange(e)}
              style={
                saveErrors &&
                saveErrors[`taskName`] && {
                  border: `1px solid #fe5969`,
                  backgroundColor: `rgba(252, 87, 104, 0.05)`,
                }
              }
            />
            {saveErrors && saveErrors['taskName'] && (
              <FormAlert>{saveErrors['taskName']}</FormAlert>
            )}
          </InputWithLabelSection>
          <CommonGrid columns="1fr 1fr" justifyContent="none" gap="40px">
            {/* <div style={{ width: '100%' }}> */}
            <InputWithLabelSection>
              <InputLabel>
                {t('office')} <RedStar>*</RedStar>
              </InputLabel>
              <Select
                isSearchable
                value={selectedOffice}
                options={officeOptions}
                placeholder={t('select_office')}
                isDisabled={officesShortList && officesShortList.length === 1}
                onChange={e => {
                  onOfficeChange(e);
                }}
                styles={CreateProjectDropdown()}
                filterOption={filterOption}
              />
            </InputWithLabelSection>
            {/* </div> */}
            <CommonFlex justifyContent="center">
              <InputWithLabelSection>
                <InputLabel>
                  {t('assignee')}
                  <RedStar>*</RedStar>
                </InputLabel>

                <AddMultipleAssigneeDropdown
                  numberOfVisibleMembers={8}
                  isLoading={memberShortListIsLoading}
                  assigneesList={selectedAssignees}
                  membersList={orgMembersList}
                  handleAssign={handleAssignMember}
                  handleRemove={handleRemoveMember}
                />

                {saveErrors && saveErrors['selectedAssignees'] && (
                  <FormAlert>{saveErrors['selectedAssignees']}</FormAlert>
                )}
              </InputWithLabelSection>
            </CommonFlex>
          </CommonGrid>
          {/* <DatePicker
            id="issuedDate"
            date={startDate}
            onDateChange={setStartDate}
            dateDisplayFormat={'DD/MM/YYYY'}
            placeholder={'Select date'}
          /> */}
          {/* <InputWithLabelSection>
            <InputLabel>Requires Approval</InputLabel>
            <CommonFlex>
              <ToggleButton
                onClick={() => approvalToggle()}
                active={requiresApproval}
              >
                <ToggleButtonRound active={requiresApproval} />
              </ToggleButton>
              <InputLabel>{requiresApproval ? `Yes` : `No`}</InputLabel>
            </CommonFlex>
          </InputWithLabelSection> */}
          <InputWithLabelSection>
            <TimeRangeSection>
              <InputLabel>
                {t('start_date')}
                <RedStar>*</RedStar>
              </InputLabel>
              <InputLabel>
                {t('start_time')}
                <RedStar>*</RedStar>
              </InputLabel>
            </TimeRangeSection>
            <TimeRangeComponentNew
              selectedDate={startDate}
              onDateChange={onStartDateChange}
              setTime={handleStartTime}
            />
            {saveErrors &&
              saveErrors['timeDifferenceStartTime'] &&
              saveErrors['timeDifferenceStartTime'] !== undefined && (
                <FormAlert>{saveErrors['timeDifferenceStartTime']}</FormAlert>
              )}
          </InputWithLabelSection>
          <CommonFlex direction="column" alignItems="start" gap="5px">
            <InputWithLabelSection>
              <TimeRangeSection>
                <InputLabel>
                  {t('end_date')}
                  <RedStar>*</RedStar>
                </InputLabel>
                <InputLabel>
                  {t('end_time')}
                  <RedStar>*</RedStar>
                </InputLabel>
              </TimeRangeSection>
              <TimeRangeComponentNew
                selectedDate={endDate}
                onDateChange={onEndDateChange}
                setTime={handleEndTime}
                mode="end"
              />
              {saveErrors &&
                saveErrors['timeDifferenceEndTime'] &&
                saveErrors['timeDifferenceEndTime'] !== undefined && (
                  <FormAlert>{saveErrors['timeDifferenceEndTime']}</FormAlert>
                )}
            </InputWithLabelSection>
            <TimeDuration
              startDate={startDate}
              endDate={endDate}
              startTime={startTime}
              endTime={endTime}
            />
          </CommonFlex>

          <InputWithLabelSection>
            <CommonGrid>
              <InputLabel>
                {t('location')} <RedStar>*</RedStar>
              </InputLabel>
              <ColoredText
                fontSize="12px"
                type="success"
                hover
                semibold
                style={{ cursor: `pointer` }}
                onClick={() => popupToggle()}
              >
                {t('add_new_location')}
              </ColoredText>
            </CommonGrid>
            <Select
              isSearchable
              value={selectedLocation}
              options={taskLocationOptions}
              placeholder={t('select_location')}
              onChange={e => {
                onLocationChange(e);
              }}
              styles={CreateProjectDropdown()}
              filterOption={filterOption}
            />
          </InputWithLabelSection>
          <InputWithLabelSection style={{ gridColumn: `1 / span 2` }}>
            <InputLabel>{t('description')}</InputLabel>
            <InputTextArea
              text="Description"
              cols="35"
              rows="7"
              value={taskDescription}
              placeholder={t('type_description_here')}
              onChange={e => onTaskDescriptionChange(e)}
              height="auto"
              style={
                saveErrors &&
                saveErrors[`taskDescription`] && {
                  border: `1px solid #fe5969`,
                  backgroundColor: `rgba(252, 87, 104, 0.05)`,
                }
              }
            ></InputTextArea>
            {saveErrors &&
              saveErrors['taskDescription'] &&
              saveErrors['taskDescription'] !== undefined && (
                <FormAlert>{saveErrors['taskDescription']}</FormAlert>
              )}
          </InputWithLabelSection>

          {/* <InputWithLabelSection>
            <CommonGrid>
              <InputLabel>
                Location Radius <RedStar>*</RedStar>
              </InputLabel>
              <ColoredText
                fontSize="12px"
                type="warning"
                hover
                semibold
                style={{ cursor: `pointer` }}
                onClick={() => setIsCustomRadius(true)}
              >
                Custom Radius
              </ColoredText>
            </CommonGrid>
            <CommonFlex justifyContent="space-between" gap="12px">
              {isCustomRadius ? (
                <CommonGrid
                  columns="100px auto 20px"
                  gap="5px"
                  alignItem="center"
                >
                  <InputField
                    value={radiusInput}
                    type="number"
                    placeholder="Type radius"
                    min={50}
                    onChange={(e) => {
                      setRadiusInput(parseInt(e.target.value));
                      if (saveErrors && saveErrors["radius"]) {
                        delete saveErrors["radius"];
                      }
                    }}
                    error={
                      saveErrors &&
                      saveErrors["radius"] &&
                      saveErrors["radius"] !== undefined
                    }
                  />

                  <CommonText>Metres</CommonText>
                  <ClearSearchButton
                    onClick={() => {
                      setIsCustomRadius(false);
                      if (parseInt(radiusInput) === 50) {
                        setRadiusInput(50);
                      } else if (parseInt(radiusInput) === 200) {
                        setRadiusInput(200);
                      } else {
                        setRadiusInput(100);
                      }
                      if (saveErrors && saveErrors["radius"]) {
                        delete saveErrors["radius"];
                      }
                    }}
                    style={{ marginLeft: "10px" }}
                  >
                    <CommonImage src={crossWhite} alt="clear" />
                  </ClearSearchButton>
                </CommonGrid>
              ) : (
                radiusOptions.map((option, i) => {
                  return (
                    <RadiusButton
                      key={option}
                      active={radiusInput === option}
                      onClick={() => {
                        if (radiusInput !== option) {
                          setRadiusInput(option);
                          if (saveErrors && saveErrors["radius"]) {
                            delete saveErrors["radius"];
                          }
                        }
                      }}
                    >
                      {option} Metres
                    </RadiusButton>
                  );
                })
              )}
            </CommonFlex>
          </InputWithLabelSection> */}

          {/* <InputWithLabelSection>
            <InputLabel>{t('ticket_type')}</InputLabel>
            <Select
              isSearchable
              value={selectedTaskType}
              options={statusOptions}
              placeholder={t('select_type')}
              onChange={e => {
                onTaskTypeChange(e);
              }}
              styles={CreateProjectDropdown()}
            />
          </InputWithLabelSection> */}

          <PrimaryButton
            disabled={
              !taskName ||
              !selectedOffice ||
              selectedAssignees.length === 0 ||
              !selectedLocation ||
              !startTime ||
              !endTime
            }
            onClick={() => handleSave()}
          >
            {isLoading ? (
              <ButtonTextLoader
                loadingText={t('create_ticket')}
                fontSize="13px"
              />
            ) : (
              t('create_ticket')
            )}
          </PrimaryButton>
          <div />
        </InputSection>
      </Container>
    </div>
  );
};

export default CreateTask;
