import moment from "moment-timezone";
import {
  GET_WORK_NOTES,
  GET_WORK_NOTES_SUCCESS,
  GET_WORK_NOTES_FAILURE,
  LOGIN_PROFILE_CREATED_RESET,
} from "../../modules/constants";

import { createNotification } from "../../modules/notificationManager";

// ------------------------------------
// Action handler methods
// ------------------------------------
export const handleWorkNotesRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const handleWorkNotesRequestSuccess = (state, action) => {
  const {
    payload: { data, timezone },
  } = action;
  if (data && data.status === 200) {
    const notesList = JSON.parse(JSON.stringify(data.data.results));
    if (notesList && notesList.length > 0) {
      notesList.sort(
        (a, b) => a.timesheet.start_timestamp - b.timesheet_start_timestamp
      );
      notesList.forEach((note) => {
        if (timezone) {
          note.timesheet.start_timestamp = moment
            .unix(note.timesheet.start_timestamp)
            .tz(timezone);
          note.timesheet.end_timestamp = moment
            .unix(note.timesheet.end_timestamp)
            .tz(timezone);
          note.timestamp = moment.unix(note.timestamp).tz(timezone);
        } else {
          note.timesheet.start_timestamp = moment.unix(
            note.timesheet.start_timestamp
          );
          note.timesheet.end_timestamp = moment.unix(
            note.timesheet.end_timestamp
          );
          note.timestamp = moment.unix(note.timestamp);
        }
      });
    }
    return {
      ...state,
      isLoading: false,
      pageSize: 20,
      totalNotesCount: data.data.count,
      workNotes: [...notesList],
    };
  }
  return {
    ...state,
    isLoading: false,
    pageSize: null,
    totalNotesCount: null,
    workNotes: [],
  };
};
export const handleWorkNotesRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    pageSize: null,
    totalNotesCount: null,
    workNotes: [],
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

const ACTION_HANDLERS = {
  [GET_WORK_NOTES]: handleWorkNotesRequest,
  [GET_WORK_NOTES_SUCCESS]: handleWorkNotesRequestSuccess,
  [GET_WORK_NOTES_FAILURE]: handleWorkNotesRequestFailure,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

const initialState = {
  isLoading: false,

  workNotes: [],

  // pagination
  totalNotesCount: null,
  pageSize: null,
};

export default function workNotesReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
