/* eslint-disable no-undef */
import { useState, useEffect, useRef } from "react";

import { useDebouncedCallback } from "use-debounce";

const usePlacesAutocompleteService = (props) => {
  const { debounceTimeout, options, map } = props;

  const [placePredictions, setPlacePredictions] = useState([]);
  const [isPredictionsLoading, setIsPredictionsLoading] = useState(false);
  const [placeInputValue, setPlaceInputValue] = useState("");

  const autocompleteService = useRef(null);
  const autocompleteSession = useRef(null);
  const geocoderService = useRef(null);

  const getDebouncedPredictions = useDebouncedCallback((optionsArg) => {
    if (autocompleteService.current && optionsArg.input) {
      autocompleteService.current.getPlacePredictions(
        {
          input: optionsArg.input,
          sessionToken: autocompleteSession.current || null,
        },
        (result) => {
          setIsPredictionsLoading(false);
          setPlacePredictions(result || []);
        }
      );
    }
  }, debounceTimeout || 500);

  useEffect(() => {
    if (map) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();

      autocompleteSession.current =
        new window.google.maps.places.AutocompleteSessionToken();

      geocoderService.current = new window.google.maps.Geocoder();
    }
  }, [map]);

  return {
    autocompleteSessionToken: autocompleteSession.current,
    autocompleteService: autocompleteService.current,
    geocoderService: geocoderService.current,
    placePredictions: placeInputValue ? placePredictions : [],
    setPlacePredictions: setPlacePredictions,
    isPredictionsLoading: isPredictionsLoading,
    getPlacePredictions: (opt) => {
      if (opt.input) {
        setPlaceInputValue(opt.input);
        setIsPredictionsLoading(true);
        getDebouncedPredictions(opt);
        return;
      }

      setPlacePredictions([]);
      setPlaceInputValue("");
      setIsPredictionsLoading(false);

      // getDebouncedPredictions(opt)
    },
    refreshSessionToken: () => {
      if (map) {
        autocompleteSession.current =
          new window.google.maps.places.AutocompleteSessionToken();
      }
    },
  };
};

export default usePlacesAutocompleteService;
