// ------------------------------------
// Constants
// ------------------------------------

//Login
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_FAILURE = 'LOGIN_REQUEST_FAILURE';
export const LOGIN_REDIRECT_RESET = 'LOGIN_REDIRECT_RESET';
export const LOGIN_PROFILE_CREATED_RESET = 'LOGIN_PROFILE_CREATED_RESET';

//Register
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_REQUEST_SUCCESS = 'REGISTER_REQUEST_SUCCESS';
export const REGISTER_REQUEST_FAILURE = 'REGISTER_REQUEST_FAILURE';
export const REGISTER_RESET = 'REGISTER_RESET';

export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_REQUEST_SUCCESS = 'SIGN_UP_REQUEST_SUCCESS';
export const SIGN_UP_REQUEST_FAILURE = 'SIGN_UP_REQUEST_FAILURE';
export const SIGN_UP_RESET = 'SIGN_UP_RESET';

//Verify User
export const VERIFY_REQUEST = 'VERIFY_REQUEST';
export const VERIFY_REQUEST_SUCCESS = 'VERIFY_REQUEST_SUCCESS';
export const VERIFY_REQUEST_FAILURE = 'VERIFY_REQUEST_FAILURE';
export const VERIFY_RESET = 'VERIFY_RESET';

export const VERIFY_CODE = 'VERIFY_CODE';
export const VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS';
export const VERIFY_CODE_FAILURE = 'VERIFY_CODE_FAILURE';

export const RESEND_VERIFICATION_CODE = 'RESEND_VERIFICATION_CODE';
export const RESEND_VERIFICATION_CODE_SUCCESS =
  'RESEND_VERIFICATION_CODE_SUCCESS';
export const RESEND_VERIFICATION_CODE_FAILURE =
  'RESEND_VERIFICATION_CODE_FAILURE';

export const VERIFY_CODE_RESET = 'VERIFY_CODE_RESET';

//Checking Token
export const CHECK_TOKEN = 'CHECK_TOKEN';
export const CHECK_TOKEN_SUCCESS = 'CHECK_TOKEN_SUCCESS';
export const CHECK_TOKEN_FAILURE = 'CHECK_TOKEN_FAILURE';

//Onboarding
export const CHECK_ONBOARDING_APPLICABLE = 'CHECK_ONBOARDING_APPLICABLE';
export const CHECK_ONBOARDING_APPLICABLE_SUCCESS =
  'CHECK_ONBOARDING_APPLICABLE_SUCCESS';
export const CHECK_ONBOARDING_APPLICABLE_FAILURE =
  'CHECK_ONBOARDING_APPLICABLE_FAILURE';

export const GET_JOIN_REQUESTS = 'GET_JOIN_REQUESTS';
export const GET_JOIN_REQUESTS_SUCCESS = 'GET_JOIN_REQUESTS_SUCCESS';
export const GET_JOIN_REQUESTS_FAILURE = 'GET_JOIN_REQUESTS_FAILURE';

export const CLEAR_JOIN_REQUESTS = 'CLEAR_JOIN_REQUESTS';

// export const REQUEST_TO_JOIN = "REQUEST_TO_JOIN";
// export const REQUEST_TO_JOIN_SUCCESS = "REQUEST_TO_JOIN_SUCCESS";
// export const REQUEST_TO_JOIN_FAILURE = "REQUEST_TO_JOIN_FAILURE";

export const REQUEST_TO_JOIN_ORGANIZATION = 'REQUEST_TO_JOIN_ORGANIZATION';
export const REQUEST_TO_JOIN_ORGANIZATION_SUCCESS =
  'REQUEST_TO_JOIN_ORGANIZATION_SUCCESS';
export const REQUEST_TO_JOIN_ORGANIZATION_FAILURE =
  'REQUEST_TO_JOIN_ORGANIZATION_FAILURE';

export const ACCEPT_ORG_JOIN_REQUEST = 'ACCEPT_ORG_JOIN_REQUEST';
export const ACCEPT_ORG_JOIN_REQUEST_SUCCESS =
  'ACCEPT_ORG_JOIN_REQUEST_SUCCESS';
export const ACCEPT_ORG_JOIN_REQUEST_FAILURE =
  'ACCEPT_ORG_JOIN_REQUEST_FAILURE';

export const SAVE_ONBOARDING_DATA = 'SAVE_ONBOARDING_DATA';
export const SAVE_ONBOARDING_DATA_SUCCESS = 'SAVE_ONBOARDING_DATA_SUCCESS';
export const SAVE_ONBOARDING_DATA_FAILURE = 'SAVE_ONBOARDING_DATA_FAILURE';

//Dashboard
export const REQUEST_DASHBOARD_DATA = 'REQUEST_DASHBOARD_DATA';
export const REQUEST_DASHBOARD_DATA_SUCCESS = 'REQUEST_DASHBOARD_DATA_SUCCESS';
export const REQUEST_DASHBOARD_DATA_FAILURE = 'REQUEST_DASHBOARD_DATA_FAILURE';

export const GET_DASHBOARD_AGGREGATED_ACTIVITY =
  'GET_DASHBOARD_AGGREGATED_ACTIVITY';
export const GET_DASHBOARD_AGGREGATED_ACTIVITY_SUCCESS =
  'GET_DASHBOARD_AGGREGATED_ACTIVITY_SUCCESS';
export const GET_DASHBOARD_AGGREGATED_ACTIVITY_FAILURE =
  'GET_DASHBOARD_AGGREGATED_ACTIVITY_FAILURE';

export const GET_DASHBOARD_AGGREGATED_TIMESHEET =
  'GET_DASHBOARD_AGGREGATED_TIMESHEET';
export const GET_DASHBOARD_AGGREGATED_TIMESHEET_SUCCESS =
  'GET_DASHBOARD_AGGREGATED_TIMESHEET_SUCCESS';
export const GET_DASHBOARD_AGGREGATED_TIMESHEET_FAILURE =
  'GET_DASHBOARD_AGGREGATED_TIMESHEET_FAILURE';

export const GET_DASHBOARD_ACTIVITY_REPORT = 'GET_DASHBOARD_ACTIVITY_REPORT';
export const GET_DASHBOARD_ACTIVITY_REPORT_SUCCESS =
  'GET_DASHBOARD_ACTIVITY_REPORT_SUCCESS';
export const GET_DASHBOARD_ACTIVITY_REPORT_FAILURE =
  'GET_DASHBOARD_ACTIVITY_REPORT_FAILURE';

export const GET_DASHBOARD_TIMESHEET_REPORT = 'GET_DASHBOARD_TIMESHEET_REPORT';
export const GET_DASHBOARD_TIMESHEET_REPORT_SUCCESS =
  'GET_DASHBOARD_TIMESHEET_REPORT_SUCCESS';
export const GET_DASHBOARD_TIMESHEET_REPORT_FAILURE =
  'GET_DASHBOARD_TIMESHEET_REPORT_FAILURE';

export const GET_DASHBOARD_TOP_PROJECTS = 'GET_DASHBOARD_TOP_PROJECTS';
export const GET_DASHBOARD_TOP_PROJECTS_SUCCESS =
  'GET_DASHBOARD_TOP_PROJECTS_SUCCESS';
export const GET_DASHBOARD_TOP_PROJECTS_FAILURE =
  'GET_DASHBOARD_TOP_PROJECTS_FAILURE';

export const GET_DASHBOARD_ONGOING_TASKS = 'GET_DASHBOARD_ONGOING_TASKS';
export const GET_DASHBOARD_ONGOING_TASKS_SUCCESS =
  'GET_DASHBOARD_ONGOING_TASKS_SUCCESS';
export const GET_DASHBOARD_ONGOING_TASKS_FAILURE =
  'GET_DASHBOARD_ONGOING_TASKS_FAILURE';

//New Apis
export const GET_DASHBOARD_STATUS = 'GET_DASHBOARD_STATUS';
export const GET_DASHBOARD_STATUS_SUCCESS = 'GET_DASHBOARD_STATUS_SUCCESS';
export const GET_DASHBOARD_STATUS_FAILURE = 'GET_DASHBOARD_STATUS_FAILURE';

export const GET_DASHBOARD_TASK_PROGRESS = 'GET_DASHBOARD_TASK_PROGRESS';
export const GET_DASHBOARD_TASK_PROGRESS_SUCCESS =
  'GET_DASHBOARD_TASK_PROGRESS_SUCCESS';
export const GET_DASHBOARD_TASK_PROGRESS_FAILURE =
  'GET_DASHBOARD_TASK_PROGRESS_FAILURE';
export const GET_DASHBOARD_TASK_SUMMARY = 'GET_DASHBOARD_TASK_SUMMARY';
export const GET_DASHBOARD_TASK_SUMMARY_SUCCESS =
  'GET_DASHBOARD_TASK_SUMMARY_SUCCESS';
export const GET_DASHBOARD_TASK_SUMMARY_FAILURE =
  'GET_DASHBOARD_TASK_SUMMARY_FAILURE';

export const GET_DASHBOARD_PERFORMANCE = 'GET_DASHBOARD_PERFORMANCE';
export const GET_DASHBOARD_PERFORMANCE_SUCCESS =
  'GET_DASHBOARD_PERFORMANCE_SUCCESS';
export const GET_DASHBOARD_PERFORMANCE_FAILURE =
  'GET_DASHBOARD_PERFORMANCE_FAILURE';

export const GET_DASHBOARD_LATE_COUNT = 'GET_DASHBOARD_LATE_COUNT';
export const GET_DASHBOARD_LATE_COUNT_SUCCESS =
  'GET_DASHBOARD_LATE_COUNT_SUCCESS';
export const GET_DASHBOARD_LATE_COUNT_FAILURE =
  'GET_DASHBOARD_LATE_COUNT_FAILURE';

export const DASHBOARD_TOP_LOW_PERFORMERS = 'DASHBOARD_TOP_LOW_PERFORMERS';
export const DASHBOARD_TOP_LOW_PERFORMERS_SUCCESS =
  'DASHBOARD_TOP_LOW_PERFORMERS_SUCCESS';
export const DASHBOARD_TOP_LOW_PERFORMERS_FAILURE =
  'DASHBOARD_TOP_LOW_PERFORMERS_FAILURE';

export const GET_DASHBOARD_USER_ANALYTICS = 'GET_DASHBOARD_USER_ANALYTICS';
export const GET_DASHBOARD_USER_ANALYTICS_SUCCESS =
  'GET_DASHBOARD_USER_ANALYTICS_SUCCESS';
export const GET_DASHBOARD_USER_ANALYTICS_FAILURE =
  'GET_DASHBOARD_USER_ANALYTICS_FAILURE';

//admin panel
export const GET_TRIAL_INFO = 'GET_TRIAL_INFO';
export const GET_TRIAL_INFO_SUCCESS = 'GET_TRIAL_INFO_SUCCESS';
export const GET_TRIAL_INFO_FAILURE = 'GET_TRIAL_INFO_FAILURE';

//Forgot Password
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_REQUEST_SUCCESS =
  'FORGOT_PASSWORD_REQUEST_SUCCESS';
export const FORGOT_PASSWORD_REQUEST_FAILURE =
  'FORGOT_PASSWORD_REQUEST_FAILURE';

export const CLEAR_FORGOT_PASSWORD_LINK_SENT =
  'CLEAR_FORGOT_PASSWORD_LINK_SENT';

//Reset Password
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_STATE_RESET = 'RESET_PASSWORD_STATE_RESET';

//Resend Verification mail
export const RESEND_VERIFICATION_MAIL = 'RESEND_VERIFICATION_MAIL';
export const RESEND_VERIFICATION_MAIL_SUCCESS =
  'RESEND_VERIFICATION_MAIL_SUCCESS';
export const RESEND_VERIFICATION_MAIL_FAILURE =
  'RESEND_VERIFICATION_MAIL_FAILURE';

//Change Password
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_REQUEST_SUCCESS =
  'CHANGE_PASSWORD_REQUEST_SUCCESS';
export const CHANGE_PASSWORD_REQUEST_FAILURE =
  'CHANGE_PASSWORD_REQUEST_FAILURE';

//Send invite
export const SEND_INVITE_REQUEST = 'SEND_INVITE_REQUEST';
export const SEND_INVITE_REQUEST_SUCCESS = 'SEND_INVITE_REQUEST_SUCCESS';
export const SEND_INVITE_REQUEST_FAILURE = 'SEND_INVITE_REQUEST_FAILURE';

//Verify invite
export const VERIFY_INVITATION_REQUEST = 'VERIFY_INVITATION_REQUEST';
export const VERIFY_INVITATION_REQUEST_SUCCESS =
  'VERIFY_INVITATION_REQUEST_SUCCESS';
export const VERIFY_INVITATION_REQUEST_FAILURE =
  'VERIFY_INVITATION_REQUEST_FAILURE';
export const INVITATION_RESET = 'INVITATION_RESET';

//User Profile
export const REQUEST_USER_PROFILE = 'REQUEST_USER_PROFILE';
export const REQUEST_USER_PROFILE_SUCCESS = 'REQUEST_USER_PROFILE_SUCCESS';
export const REQUEST_USER_PROFILE_FAILURE = 'REQUEST_USER_PROFILE_FAILURE';
export const CREATE_USER_PROFILE = 'CREATE_USER_PROFILE';
export const CREATE_USER_PROFILE_SUCCESS = 'CREATE_USER_PROFILE_SUCCESS';
export const CREATE_USER_PROFILE_FAILURE = 'CREATE_USER_PROFILE_FAILURE';

//User Emails
export const GET_USER_EMAILS = 'GET_USER_EMAILS';
export const GET_USER_EMAILS_SUCCESS = 'GET_USER_EMAILS_SUCCESS';
export const GET_USER_EMAILS_FAILURE = 'GET_USER_EMAILS_FAILURE';

export const ADD_EMAIL = 'ADD_EMAIL';
export const ADD_EMAIL_SUCCESS = 'ADD_EMAIL_SUCCESS';
export const ADD_EMAIL_FAILURE = 'ADD_EMAIL_FAILURE';

export const DELETE_EMAIL = 'DELETE_EMAIL';
export const DELETE_EMAIL_SUCCESS = 'DELETE_EMAIL_SUCCESS';
export const DELETE_EMAIL_FAILURE = 'DELETE_EMAIL_FAILURE';

export const MAKE_EMAIL_DEFAULT = 'MAKE_EMAIL_DEFAULT';
export const MAKE_EMAIL_DEFAULT_SUCCESS = 'MAKE_EMAIL_DEFAULT_SUCCESS';
export const MAKE_EMAIL_DEFAULT_FAILURE = 'MAKE_EMAIL_DEFAULT_FAILURE';

export const GET_LIVE_MEMBERS_LIST = 'GET_LIVE_MEMBERS_LIST';
export const GET_LIVE_MEMBERS_LIST_SUCCESS = 'GET_LIVE_MEMBERS_LIST_SUCCESS';
export const GET_LIVE_MEMBERS_LIST_FAILURE = 'GET_LIVE_MEMBERS_LIST_FAILURE';

export const GET_LAST_FIVE_MIN_ACTIVE_LIST = 'GET_LAST_FIVE_MIN_ACTIVE_LIST';
export const GET_LAST_FIVE_MIN_ACTIVE_LIST_SUCCESS =
  'GET_LAST_FIVE_MIN_ACTIVE_LIST_SUCCESS';
export const GET_LAST_FIVE_MIN_ACTIVE_LIST_FAILURE =
  'GET_LAST_FIVE_MIN_ACTIVE_LIST_FAILURE';

export const GET_INSTANT_SCREENSHOTS_LIST = 'GET_INSTANT_SCREENSHOTS_LIST';
export const GET_INSTANT_SCREENSHOTS_LIST_SUCCESS =
  'GET_INSTANT_SCREENSHOTS_LIST_SUCCESS';
export const GET_INSTANT_SCREENSHOTS_LIST_FAILURE =
  'GET_INSTANT_SCREENSHOTS_LIST_FAILURE';

export const TAKE_INSTANT_SCREENSHOT = 'TAKE_INSTANT_SCREENSHOT';
export const TAKE_INSTANT_SCREENSHOT_SUCCESS =
  'TAKE_INSTANT_SCREENSHOT_SUCCESS';
export const TAKE_INSTANT_SCREENSHOT_FAILURE =
  'TAKE_INSTANT_SCREENSHOT_FAILURE';

// Team
export const GET_TEAM_LIST = 'GET_TEAM_LIST';
export const GET_TEAM_LIST_SUCCESS = 'GET_TEAM_LIST_SUCCESS';
export const GET_TEAM_LIST_FAILURE = 'GET_TEAM_LIST_FAILURE';

export const GET_TEAM_SHORT_LIST = 'GET_TEAM_SHORT_LIST';
export const GET_TEAM_SHORT_LIST_SUCCESS = 'GET_TEAM_SHORT_LIST_SUCCESS';
export const GET_TEAM_SHORT_LIST_FAILURE = 'GET_TEAM_SHORT_LIST_FAILURE';

export const GET_TEAM_DETAILS = 'GET_TEAM_DETAILS';
export const GET_TEAM_DETAILS_SUCCESS = 'GET_TEAM_DETAILS_SUCCESS';
export const GET_TEAM_DETAILS_FAILURE = 'GET_TEAM_DETAILS_FAILURE';

export const ADD_TEAM_REQUEST = 'ADD_TEAM_REQUEST';
export const ADD_TEAM_REQUEST_SUCCESS = 'ADD_TEAM_REQUEST_SUCCESS';
export const ADD_TEAM_REQUEST_FAILURE = 'ADD_TEAM_REQUEST_FAILURE';

export const CLEAR_CREATED_TEAM_ID = 'CLEAR_CREATED_TEAM_ID';

export const UPDATE_TEAM_REQUEST = 'UPDATE_TEAM_REQUEST';
export const UPDATE_TEAM_REQUEST_SUCCESS = 'UPDATE_TEAM_REQUEST_SUCCESS';
export const UPDATE_TEAM_REQUEST_FAILURE = 'UPDATE_TEAM_REQUEST_FAILURE';

export const DELETE_TEAM_REQUEST = 'DELETE_TEAM_REQUEST';
export const DELETE_TEAM_REQUEST_SUCCESS = 'DELETE_TEAM_REQUEST_SUCCESS';
export const DELETE_TEAM_REQUEST_FAILURE = 'DELETE_TEAM_REQUEST_FAILURE';

export const GET_TEAM_MEMBERS_LIST = 'GET_TEAM_MEMBERS_LIST';
export const GET_TEAM_MEMBERS_LIST_SUCCESS = 'GET_TEAM_MEMBERS_LIST_SUCCESS';
export const GET_TEAM_MEMBERS_LIST_FAILURE = 'GET_TEAM_MEMBERS_LIST_FAILURE';

export const CLEAR_TEAM_MEMBERS_LIST = 'CLEAR_TEAM_MEMBERS_LIST';

export const ADD_TEAM_MEMBER = 'ADD_TEAM_MEMBER';
export const ADD_TEAM_MEMBER_SUCCESS = 'ADD_TEAM_MEMBER_SUCCESS';
export const ADD_TEAM_MEMBER_FAILURE = 'ADD_TEAM_MEMBER_FAILURE';

export const UPDATE_TEAM_MEMBER = 'UPDATE_TEAM_MEMBER';
export const UPDATE_TEAM_MEMBER_SUCCESS = 'UPDATE_TEAM_MEMBER_SUCCESS';
export const UPDATE_TEAM_MEMBER_FAILURE = 'UPDATE_TEAM_MEMBER_FAILURE';

export const REMOVE_TEAM_MEMBER = 'REMOVE_TEAM_MEMBER';
export const REMOVE_TEAM_MEMBER_SUCCESS = 'REMOVE_TEAM_MEMBER_SUCCESS';
export const REMOVE_TEAM_MEMBER_FAILURE = 'REMOVE_TEAM_MEMBER_FAILURE';

export const GET_TEAM_PROJECTS_LIST = 'GET_TEAM_PROJECTS_LIST';
export const GET_TEAM_PROJECTS_LIST_SUCCESS = 'GET_TEAM_PROJECTS_LIST_SUCCESS';
export const GET_TEAM_PROJECTS_LIST_FAILURE = 'GET_TEAM_PROJECTS_LIST_FAILURE';

//Integrations
export const GET_ALL_INTEGRATIONS_LIST = 'GET_ALL_INTEGRATIONS_LIST';
export const GET_ALL_INTEGRATIONS_LIST_SUCCESS =
  'GET_ALL_INTEGRATIONS_LIST_SUCCESS';
export const GET_ALL_INTEGRATIONS_LIST_FAILURE =
  'GET_ALL_INTEGRATIONS_LIST_FAILURE';

export const GET_INTEGRATION_WORKFLOWS_LIST = 'GET_INTEGRATION_WORKFLOWS_LIST';
export const GET_INTEGRATION_WORKFLOWS_LIST_SUCCESS =
  'GET_INTEGRATION_WORKFLOWS_LIST_SUCCESS';
export const GET_INTEGRATION_WORKFLOWS_LIST_FAILURE =
  'GET_INTEGRATION_WORKFLOWS_LIST_FAILURE';

export const GET_ENABLED_INTEGRATIONS_LIST = 'GET_ENABLED_INTEGRATIONS_LIST';
export const GET_ENABLED_INTEGRATIONS_LIST_SUCCESS =
  'GET_ENABLED_INTEGRATIONS_LIST_SUCCESS';
export const GET_ENABLED_INTEGRATIONS_LIST_FAILURE =
  'GET_ENABLED_INTEGRATIONS_LIST_FAILURE';

export const GET_INTEGRATION_ENABLED_WORKFLOWS_LIST =
  'GET_INTEGRATION_ENABLED_WORKFLOWS_LIST';
export const GET_INTEGRATION_ENABLED_WORKFLOWS_LIST_SUCCESS =
  'GET_INTEGRATION_ENABLED_WORKFLOWS_LIST_SUCCESS';
export const GET_INTEGRATION_ENABLED_WORKFLOWS_LIST_FAILURE =
  'GET_ALL_INTEGRATIONS_LIST_FAILURE';

export const ENABLE_INTEGRATION_WORKFLOW = 'ENABLE_INTEGRATION_WORKFLOW';
export const ENABLE_INTEGRATION_WORKFLOW_SUCCESS =
  'ENABLE_INTEGRATION_WORKFLOW_SUCCESS';
export const ENABLE_INTEGRATION_WORKFLOW_FAILURE =
  'ENABLE_INTEGRATION_WORKFLOW_FAILURE';

export const RUN_INTEGRATION_WORKFLOW = 'RUN_INTEGRATION_WORKFLOW';
export const RUN_INTEGRATION_WORKFLOW_SUCCESS =
  'RUN_INTEGRATION_WORKFLOW_SUCCESS';
export const RUN_INTEGRATION_WORKFLOW_FAILURE =
  'RUN_INTEGRATION_WORKFLOW_FAILURE';

//Asana
export const INSTALL_ASANA = 'INSTALL_ASANA';
export const INSTALL_ASANA_SUCCESS = 'INSTALL_ASANA_SUCCESS';
export const INSTALL_ASANA_FAILURE = 'INSTALL_ASANA_FAILURE';

export const REMOVE_ASANA = 'REMOVE_ASANA';
export const REMOVE_ASANA_SUCCESS = 'REMOVE_ASANA_SUCCESS';
export const REMOVE_ASANA_FAILURE = 'REMOVE_ASANA_FAILURE';

export const GET_ASANA_WORKSPACES_LIST = 'GET_ASANA_WORKSPACES_LIST';
export const GET_ASANA_WORKSPACES_LIST_SUCCESS =
  'GET_ASANA_WORKSPACES_LIST_SUCCESS';
export const GET_ASANA_WORKSPACES_LIST_FAILURE =
  'GET_ASANA_WORKSPACES_LIST_FAILURE';

export const ASANA_WORKSPACE_PROJECT_LIST = 'ASANA_WORKSPACE_PROJECT_LIST';
export const ASANA_WORKSPACE_PROJECT_LIST_SUCCESS =
  'ASANA_WORKSPACE_PROJECT_LIST_SUCCESS';
export const ASANA_WORKSPACE_PROJECT_LIST_FAILURE =
  'ASANA_WORKSPACE_PROJECT_LIST_FAILURE';

export const ASANA_WORKSPACE_MEMBER_LIST = 'ASANA_WORKSPACE_MEMBER_LIST';
export const ASANA_WORKSPACE_MEMBER_LIST_SUCCESS =
  'ASANA_WORKSPACE_MEMBER_LIST_SUCCESS';
export const ASANA_WORKSPACE_MEMBER_LIST_FAILURE =
  'ASANA_WORKSPACE_MEMBER_LIST_FAILURE';

export const ASANA_USER_MAP_LIST = 'ASANA_USER_MAP_LIST';
export const ASANA_USER_MAP_LIST_SUCCESS = 'AASANA_USER_MAP_LIST_SUCCESS';
export const ASANA_USER_MAP_LIST_FAILURE = 'ASANA_USER_MAP_LIST_LIST_FAILURE';

export const ASANA_SINGLE_USER_MAP = 'ASANA_SINGLE_USER_MAP';
export const ASANA_SINGLE_USER_MAP_SUCCESS = 'ASANA_SINGLE_USER_MAP_SUCCESS';
export const ASANA_SINGLE_USER_MAP_FAILURE = 'ASANA_SINGLE_USER_MAP_FAILURE';

export const ASANA_CREATE_USER_MAP = 'ASANA_CREATE_USER_MAP';
export const ASANA_CREATE_USER_MAP_SUCCESS = 'ASANA_CREATE_USER_MAP_SUCCESS';
export const ASANA_CREATE_USER_MAP_FAILURE = 'ASANA_CREATE_USER_MAP_FAILURE';

export const ASANA_UPDATE_USER_MAP = 'ASANA_UPDATE_USER_MAP';
export const ASANA_UPDATE_USER_MAP_SUCCESS = 'ASANA_UPDATE_USER_MAP_SUCCESS';
export const ASANA_UPDATE_USER_MAP_FAILURE = 'ASANA_UPDATE_USER_MAP_FAILURE';

export const ASANA_DELETE_USER_MAP = 'ASANA_DELETE_USER_MAP';
export const ASANA_DELETE_USER_MAP_SUCCESS = 'ASANA_DELETE_USER_MAP_SUCCESS';
export const ASANA_DELETE_USER_MAP_FAILURE = 'ASANA_DELETE_USER_MAP_FAILURE';

export const ASANA_PROJECT_MAP_LIST = 'ASANA_PROJECT_MAP_LIST';
export const ASANA_PROJECT_MAP_LIST_SUCCESS = 'ASANA_PROJECT_MAP_LIST_SUCCESS';
export const ASANA_PROJECT_MAP_LIST_FAILURE = 'ASANA_PROJECT_MAP_LIST_FAILURE';

export const ASANA_CREATE_PROJECT_MAP = 'ASANA_CREATE_PROJECT_MAP';
export const ASANA_CREATE_PROJECT_MAP_SUCCESS =
  'ASANA_CREATE_PROJECT_MAP_SUCCESS';
export const ASANA_CREATE_PROJECT_MAP_FAILURE =
  'ASANA_CREATE_PROJECT_MAP_FAILURE';

export const ASANA_SINGLE_PROJECT_MAP = 'ASANA_SINGLE_PROJECT_MAP';
export const ASANA_SINGLE_PROJECT_MAP_SUCCESS =
  'ASANA_SINGLE_PROJECT_MAP_SUCCESS';
export const ASANA_SINGLE_PROJECT_MAP_FAILURE =
  'ASANA_SINGLE_PROJECT_MAP_FAILURE';

export const ASANA_UPDATE_PROJECT_MAP = 'ASANA_UPDATE_PROJECT_MAP';
export const ASANA_UPDATE_PROJECT_MAP_SUCCESS =
  'ASANA_UPDATE_PROJECT_MAP_SUCCESS';
export const ASANA_UPDATE_PROJECT_MAP_FAILURE =
  'ASANA_UPDATE_PROJECT_MAP_FAILURE';

export const ASANA_DELETE_PROJECT_MAP = 'ASANA_DELETE_PROJECT_MAP';
export const ASANA_DELETE_PROJECT_MAP_SUCCESS =
  'ASANA_DELETE_PROJECT_MAP_SUCCESS';
export const ASANA_DELETE_PROJECT_MAP_FAILURE =
  'ASANA_DELETE_PROJECT_MAP_FAILURE';

export const ASANA_INSTALL_PERMISSION = 'ASANA_INSTALL_PERMISSION';

//Invoice
export const GET_INVOICE_LIST = 'GET_INVOICE_LIST';
export const GET_INVOICE_LIST_SUCCESS = 'GET_INVOICE_LIST_SUCCESS';
export const GET_INVOICE_LIST_FAILURE = 'GET_INVOICE_LIST_FAILURE';

export const CREATE_INVOICE_REQUEST = 'CREATE_INVOICE_REQUEST';
export const CREATE_INVOICE_REQUEST_SUCCESS = 'CREATE_INVOICE_REQUEST_SUCCESS';
export const CREATE_INVOICE_REQUEST_FAILURE = 'CREATE_INVOICE_FAILURE';

export const GET_INVOICE_DETAILS = 'GET_INVOICE_DETAILS';
export const GET_INVOICE_DETAILS_SUCCESS = 'GET_INVOICE_DETAILS_SUCCESS';
export const GET_INVOICE_DETAILS_FAILURE = 'GET_INVOICE_DETAILS_FAILURE';

export const UPDATE_INVOICE_REQUEST = 'UPDATE_INVOICE_REQUEST';
export const UPDATE_INVOICE_REQUEST_SUCCESS = 'UPDATE_INVOICE_REQUEST_SUCCESS';
export const UPDATE_INVOICE_REQUEST_FAILURE = 'UPDATE_INVOICE_REQUEST_FAILURE';

export const DELETE_INVOICE = 'DELETE_INVOICE';
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_FAILURE = 'DELETE_INVOICE_FAILURE';

export const CREATE_INVOICE_ITEM = 'CREATE_INVOICE_ITEM';
export const CREATE_INVOICE_ITEM_SUCCESS = 'CREATE_INVOICE_ITEM_SUCCESS';
export const CREATE_INVOICE_ITEM_FAILURE = 'CREATE_INVOICE_ITEM_FAILURE';

export const UPDATE_INVOICE_ITEM = 'UPDATE_INVOICE_ITEM';
export const UPDATE_INVOICE_ITEM_SUCCESS = 'UPDATE_INVOICE_ITEM_SUCCESS';
export const UPDATE_INVOICE_ITEM_FAILURE = 'UPDATE_INVOICE_ITEM_FAILURE';

export const DELETE_INVOICE_ITEM = 'DELETE_INVOICE_ITEM';
export const DELETE_INVOICE_ITEM_SUCCESS = 'DELETE_INVOICE_ITEM_SUCCESS';
export const DELETE_INVOICE_ITEM_FAILURE = 'DELETE_INVOICE_ITEM_FAILURE';

export const CREATE_INVOICE_TAX = 'CREATE_INVOICE_TAX';
export const CREATE_INVOICE_TAX_SUCCESS = 'CREATE_INVOICE_TAX_SUCCESS';
export const CREATE_INVOICE_TAX_FAILURE = 'CREATE_INVOICE_TAX_FAILURE';

export const UPDATE_INVOICE_TAX = 'UPDATE_INVOICE_TAX';
export const UPDATE_INVOICE_TAX_SUCCESS = 'UPDATE_INVOICE_TAX_SUCCESS';
export const UPDATE_INVOICE_TAX_FAILURE = 'UPDATE_INVOICE_TAX_FAILURE';

export const GET_INVOICE_TAX = 'GET_INVOICE_TAX';
export const GET_INVOICE_TAX_SUCCESS = 'GET_INVOICE_TAX_SUCCESS';
export const GET_INVOICE_TAX_FAILURE = 'GET_INVOICE_TAX_FAILURE';

export const UPDATE_INVOICE_DISCOUNT = 'UPDATE_INVOICE_DISCOUNT';
export const UPDATE_INVOICE_DISCOUNT_SUCCESS =
  'UPDATE_INVOICE_DISCOUNT_SUCCESS';
export const UPDATE_INVOICE_DISCOUNT_FAILURE =
  'UPDATE_INVOICE_DISCOUNT_FAILURE';

export const SEND_INVOICE_EMAIL = 'SEND_INVOICE_EMAIL';
export const SEND_INVOICE_EMAIL_SUCCESS = 'SEND_INVOICE_EMAIL_SUCCESS';
export const SEND_INVOICE_EMAIL_FAILURE = 'SEND_INVOICE_EMAIL_FAILURE';

export const GET_INVOICE_NUMBER = 'GET_INVOICE_NUMBER';
export const GET_INVOICE_NUMBER_SUCCESS = 'GET_INVOICE_NUMBER_SUCCESS';
export const GET_INVOICE_NUMBER_FAILURE = 'GET_INVOICE_NUMBER_FAILURE';

export const RECORD_PAYMENT_INVOICE = 'RECORD_PAYMENT_INVOICE';
export const RECORD_PAYMENT_INVOICE_SUCCESS = 'RECORD_PAYMENT_INVOICE_SUCCESS';
export const RECORD_PAYMENT_INVOICE_FAILURE = 'RECORD_PAYMENT_INVOICE_FAILURE';

export const GET_RECORD_PAYMENT_INVOICE = 'GET_RECORD_PAYMENT_INVOICE';
export const GET_RECORD_PAYMENT_INVOICE_SUCCESS =
  'GET_RECORD_PAYMENT_INVOICE_SUCCESS';
export const GET_RECORD_PAYMENT_INVOICE_FAILURE =
  'GET_RECORD_PAYMENT_INVOICE_FAILURE';

export const DELETE_RECORD_PAYMENT_INVOICE = 'DELETE_RECORD_PAYMENT_INVOICE';
export const DELETE_RECORD_PAYMENT_INVOICE_SUCCESS =
  'DELETE_RECORD_PAYMENT_INVOICE_SUCCESS';
export const DELETE_RECORD_PAYMENT_INVOICE_FAILURE =
  'DELETE_RECORD_PAYMENT_INVOICE_FAILURE';

export const GET_INVOICE_HISTORY = 'GET_INVOICE_HISTORY';
export const GET_INVOICE_HISTORY_SUCCESS = 'GET_INVOICE_HISTORY_SUCCESS';
export const GET_INVOICE_HISTORY_FAILURE = 'GET_INVOICE_HISTORY_FAILURE';

export const INVOICE_PUBLIC_VIEW = 'INVOICE_PUBLIC_VIEW';
export const INVOICE_PUBLIC_VIEW_SUCCESS = 'INVOICE_PUBLIC_VIEW_SUCCESS';
export const INVOICE_PUBLIC_VIEW_FAILURE = 'INVOICE_PUBLIC_VIEW_FAILURE';

export const INVOICE_UPDATE_SEND_LOG = 'INVOICE_UPDATE_SEND_LOG';
export const INVOICE_UPDATE_SEND_LOG_SUCCESS =
  'INVOICE_UPDATE_SEND_LOG_SUCCESS';
export const INVOICE_UPDATE_SEND_LOG_FAILURE =
  'INVOICE_UPDATE_SEND_LOG_FAILURE';

export const INVOICE_LINK_PROJECT = 'INVOICE_LINK_PROJECT';
export const INVOICE_LINK_PROJECT_SUCCESS = 'INVOICE_LINK_PROJECT_SUCCESS';
export const INVOICE_LINK_PROJECT_FAILURE = 'INVOICE_LINK_PROJECT_FAILURE';

export const CLEAR_INVOICE_LINKED_PROJECT = 'CLEAR_INVOICE_LINKED_PROJECT';
export const IS_MAIL_SEND_FROM_EDIT = 'IS_MAIL_SEND_FROM_EDIT';

//Project
export const GET_PROJECT_LIST = 'GET_PROJECT_LIST';
export const GET_PROJECT_LIST_SUCCESS = 'GET_PROJECT_LIST_SUCCESS';
export const GET_PROJECT_LIST_FAILURE = 'GET_PROJECT_LIST_FAILURE';

export const GET_PROJECT_SHORT_LIST = 'GET_PROJECT_SHORT_LIST';
export const GET_PROJECT_SHORT_LIST_SUCCESS = 'GET_PROJECT_SHORT_LIST_SUCCESS';
export const GET_PROJECT_SHORT_LIST_FAILURE = 'GET_PROJECT_SHORT_LIST_FAILURE';

export const ADD_PROJECT_REQUEST = 'ADD_PROJECT_REQUEST';
export const ADD_PROJECT_REQUEST_SUCCESS = 'ADD_PROJECT_REQUEST_SUCCESS';
export const ADD_PROJECT_REQUEST_FAILURE = 'ADD_PROJECT_REQUEST_FAILURE';

// export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
// export const UPDATE_PROJECT_REQUEST_SUCCESS = "UPDATE_PROJECT_REQUEST_SUCCESS";
// export const UPDATE_PROJECT_REQUEST_FAILURE = "UPDATE_PROJECT_REQUEST_FAILURE";

export const CHANGE_PROJECT_STATUS_REQUEST = 'CHANGE_PROJECT_STATUS_REQUEST';
export const CHANGE_PROJECT_STATUS_REQUEST_SUCCESS =
  'CHANGE_PROJECT_STATUS_REQUEST_SUCCESS';
export const CHANGE_PROJECT_STATUS_REQUEST_FAILURE =
  'CHANGE_PROJECT_STATUS_REQUEST_FAILURE';

export const GET_PROJECT_STATUS_REQUEST = 'GET_PROJECT_STATUS_REQUEST';
export const GET_PROJECT_STATUS_REQUEST_SUCCESS =
  'GET_PROJECT_STATUS_REQUEST_SUCCESS';
export const GET_PROJECT_STATUS_REQUEST_FAILURE =
  'GET_PROJECT_STATUS_REQUEST_FAILURE';

export const CHANGE_PROJECT_BUDGET = 'CHANGE_PROJECT_BUDGET';
export const CHANGE_PROJECT_BUDGET_SUCCESS = 'CHANGE_PROJECT_BUDGET_SUCCESS';
export const CHANGE_PROJECT_BUDGET_FAILURE = 'CHANGE_PROJECT_BUDGET_FAILURE';

// export const ADD_PROJECT_BUDGET = "ADD_PROJECT_BUDGET";
// export const ADD_PROJECT_BUDGET_SUCCESS = "ADD_PROJECT_BUDGET_SUCCESS";
// export const ADD_PROJECT_BUDGET_FAILURE = "ADD_PROJECT_BUDGET_FAILURE";

export const GET_PROJECT = 'GET_PROJECT';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_PROJECT_FAILURE = 'GET_PROJECT_FAILURE';

export const CHANGE_PROJECT_ASSIGNEE_LIST = 'CHANGE_PROJECT_ASSIGNEE_LIST';
export const CHANGE_PROJECT_ASSIGNEE_LIST_SUCCESS =
  'CHANGE_PROJECT_ASSIGNEE_LIST_SUCCESS';
export const CHANGE_PROJECT_ASSIGNEE_LIST_FAILURE =
  'CHANGE_PROJECT_ASSIGNEE_LIST_FAILURE';
export const ADD_PROJECT_MANAGER_REQUEST = 'ADD_PROJECT_MANAGER_REQUEST';
export const ADD_PROJECT_MANAGER_REQUEST_SUCCESS =
  'ADD_PROJECT_MANAGER_REQUEST_SUCCESS';
export const ADD_PROJECT_MANAGER_REQUEST_FAILURE =
  'ADD_PROJECT_MANAGER_REQUEST_FAILURE';
export const REMOVE_PROJECT_MANAGER_REQUEST = 'REMOVE_PROJECT_MANAGER_REQUEST';
export const REMOVE_PROJECT_MANAGER_REQUEST_SUCCESS =
  'REMOVE_PROJECT_MANAGER_REQUEST_SUCCESS';
export const REMOVE_PROJECT_MANAGER_REQUEST_FAILURE =
  'REMOVE_PROJECT_MANAGER_REQUEST_FAILURE';

//Project New
export const GET_PROJECT_DETAILS = 'GET_PROJECT_DETAILS';
export const GET_PROJECT_DETAILS_SUCCESS = 'GET_PROJECT_DETAILS_SUCCESS';
export const GET_PROJECT_DETAILS_FAILURE = 'GET_PROJECT_DETAILS_FAILURE';

export const GET_PROJECT_BUDGET_AND_BILLABLE =
  'GET_PROJECT_BUDGET_AND_BILLABLE';
export const GET_PROJECT_BUDGET_AND_BILLABLE_SUCCESS =
  'GET_PROJECT_BUDGET_AND_BILLABLE_SUCCESS';
export const GET_PROJECT_BUDGET_AND_BILLABLE_FAILURE =
  'GET_PROJECT_BUDGET_AND_BILLABLE_FAILURE';

export const GET_PROJECT_EXPENSES = 'GET_PROJECT_EXPENSES';
export const GET_PROJECT_EXPENSES_SUCCESS = 'GET_PROJECT_EXPENSES_SUCCESS';
export const GET_PROJECT_EXPENSES_FAILURE = 'GET_PROJECT_EXPENSES_FAILURE';

export const GET_PROJECT_YEARLY_EXPENSES = 'GET_PROJECT_YEARLY_EXPENSES';
export const GET_PROJECT_YEARLY_EXPENSES_SUCCESS =
  'GET_PROJECT_YEARLY_EXPENSES_SUCCESS';
export const GET_PROJECT_YEARLY_EXPENSES_FAILURE =
  'GET_PROJECT_YEARLY_EXPENSES_FAILURE';

export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_REQUEST_SUCCESS = 'CREATE_PROJECT_REQUEST_SUCCESS';
export const CREATE_PROJECT_REQUEST_FAILURE = 'CREATE_PROJECT_REQUEST_FAILURE';

export const CLEAR_CREATED_PROJECT = 'CLEAR_CREATED_PROJECT';

export const DUPLICATE_PROJECT_REQUEST = 'DUPLICATE_PROJECT_REQUEST';
export const DUPLICATE_PROJECT_REQUEST_SUCCESS =
  'DUPLICATE_PROJECT_REQUEST_SUCCESS';
export const DUPLICATE_PROJECT_REQUEST_FAILURE =
  'CREATE_PROJECT_REQUEST_FAILURE';

export const CLEAR_EDITED_PROJECT = 'CLEAR_EDITED_PROJECT';

export const CLEAR_PROJECT_ACCESS_DENIED = 'CLEAR_PROJECT_ACCESS_DENIED';

export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST';
export const UPDATE_PROJECT_REQUEST_SUCCESS = 'UPDATE_PROJECT_REQUEST_SUCCESS';
export const UPDATE_PROJECT_REQUEST_FAILURE = 'UPDATE_PROJECT_REQUEST_FAILURE';

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_REQUEST_SUCCESS = 'DELETE_PROJECT_REQUEST_SUCCESS';
export const DELETE_PROJECT_REQUEST_FAILURE = 'DELETE_PROJECT_REQUEST_FAILURE';

export const GET_ORGANIZATION_MEMBERS_LIST = 'GET_ORGANIZATION_MEMBERS_LIST';
export const GET_ORGANIZATION_MEMBERS_LIST_SUCCESS =
  'GET_ORGANIZATION_MEMBERS_LIST_SUCCESS';
export const GET_ORGANIZATION_MEMBERS_LIST_FAILURE =
  'GET_ORGANIZATION_MEMBERS_LIST_FAILURE';

export const ADD_TEAM_TO_PROJECT = 'ADD_TEAM_TO_PROJECT';
export const ADD_TEAM_TO_PROJECT_SUCCESS = 'ADD_TEAM_TO_PROJECT_SUCCESS';
export const ADD_TEAM_TO_PROJECT_FAILURE = 'ADD_TEAM_TO_PROJECT_FAILURE';

export const ADD_PROJECT_ASSIGNEE = 'ADD_PROJECT_ASSIGNEE';
export const ADD_PROJECT_ASSIGNEE_SUCCESS = 'ADD_PROJECT_ASSIGNEE_SUCCESS';
export const ADD_PROJECT_ASSIGNEE_FAILURE = 'ADD_PROJECT_ASSIGNEE_FAILURE';

export const UPDATE_PROJECT_ASSIGNEE = 'UPDATE_PROJECT_ASSIGNEE';
export const UPDATE_PROJECT_ASSIGNEE_SUCCESS =
  'UPDATE_PROJECT_ASSIGNEE_SUCCESS';
export const UPDATE_PROJECT_ASSIGNEE_FAILURE =
  'UPDATE_PROJECT_ASSIGNEE_FAILURE';

export const DELETE_PROJECT_ASSIGNEE = 'DELETE_PROJECT_ASSIGNEE';
export const DELETE_PROJECT_ASSIGNEE_SUCCESS =
  'DELETE_PROJECT_ASSIGNEE_SUCCESS';
export const DELETE_PROJECT_ASSIGNEE_FAILURE =
  'DELETE_PROJECT_ASSIGNEE_FAILURE';

export const GET_PROJECT_ASSIGNEE_LIST = 'GET_PROJECT_ASSIGNEE_LIST';
export const GET_PROJECT_ASSIGNEE_LIST_SUCCESS =
  'GET_PROJECT_ASSIGNEE_LIST_SUCCESS';
export const GET_PROJECT_ASSIGNEE_LIST_FAILURE =
  'GET_PROJECT_ASSIGNEE_LIST_FAILURE';

export const CLEAR_PROJECT_ASSIGNEE_LIST = 'CLEAR_PROJECT_ASSIGNEE_LIST';

export const GET_PROJECT_TASK_LIST = 'GET_PROJECT_TASK_LIST';
export const GET_PROJECT_TASK_LIST_SUCCESS = 'GET_PROJECT_TASK_LIST_SUCCESS';
export const GET_PROJECT_TASK_LIST_FAILURE = 'GET_PROJECT_TASK_LIST_FAILURE';

export const PROJECT_ADD_TASK = 'PROJECT_ADD_TASK';
export const PROJECT_ADD_TASK_SUCCESS = 'PROJECT_ADD_TASK_SUCCESS';
export const PROJECT_ADD_TASK_FAILURE = 'PROJECT_ADD_TASK_FAILURE';

export const PROJECT_EDIT_TASK = 'PROJECT_EDIT_TASK';
export const PROJECT_EDIT_TASK_SUCCESS = 'PROJECT_EDIT_TASK_SUCCESS';
export const PROJECT_EDIT_TASK_FAILURE = 'PROJECT_EDIT_TASK_FAILURE';

export const PROJECT_DELETE_TASK = 'PROJECT_DELETE_TASK';
export const PROJECT_DELETE_TASK_SUCCESS = 'PROJECT_DELETE_TASK_SUCCESS';
export const PROJECT_DELETE_TASK_FAILURE = 'PROJECT_DELETE_TASK_FAILURE';

export const GET_TASK_ASSIGNEE_LIST = 'GET_TASK_ASSIGNEE_LIST';
export const GET_TASK_ASSIGNEE_LIST_SUCCESS = 'GET_TASK_ASSIGNEE_LIST_SUCCESS';
export const GET_TASK_ASSIGNEE_LIST_FAILURE = 'GET_TASK_ASSIGNEE_LIST_FAILURE';

export const ADD_TASK_ASSIGNEE = 'ADD_TASK_ASSIGNEE';
export const ADD_TASK_ASSIGNEE_SUCCESS = 'ADD_TASK_ASSIGNEE_SUCCESS';
export const ADD_TASK_ASSIGNEE_FAILURE = 'ADD_TASK_ASSIGNEE_FAILURE';

export const DELETE_TASK_ASSIGNEE = 'DELETE_TASK_ASSIGNEE';
export const DELETE_TASK_ASSIGNEE_SUCCESS = 'DELETE_TASK_ASSIGNEE_SUCCESS';
export const DELETE_TASK_ASSIGNEE_FAILURE = 'DELETE_TASK_ASSIGNEE_FAILURE';

//Project Budget and Billing
export const GET_PROJECT_BUDGET = 'GET_PROJECT_BUDGET';
export const GET_PROJECT_BUDGET_SUCCESS = 'GET_PROJECT_BUDGET_SUCCESS';
export const GET_PROJECT_BUDGET_FAILURE = 'GET_PROJECT_BUDGET_FAILURE';

export const ADD_PROJECT_BUDGET = 'ADD_PROJECT_BUDGET';
export const ADD_PROJECT_BUDGET_SUCCESS = 'ADD_PROJECT_BUDGET_SUCCESS';
export const ADD_PROJECT_BUDGET_FAILURE = 'ADD_PROJECT_BUDGET_FAILURE';

export const UPDATE_PROJECT_BUDGET = 'UPDATE_PROJECT_BUDGET';
export const UPDATE_PROJECT_BUDGET_SUCCESS = 'UPDATE_PROJECT_BUDGET_SUCCESS';
export const UPDATE_PROJECT_BUDGET_FAILURE = 'UPDATE_PROJECT_BUDGET_FAILURE';

export const CLEAR_PROJECT_BUDGET = 'CLEAR_PROJECT_BUDGET';

export const GET_PROJECT_BILLING = 'GET_PROJECT_BILLING';
export const GET_PROJECT_BILLING_SUCCESS = 'GET_PROJECT_BILLING_SUCCESS';
export const GET_PROJECT_BILLING_FAILURE = 'GET_PROJECT_BILLING_FAILURE';

export const ADD_PROJECT_BILLING = 'ADD_PROJECT_BILLING';
export const ADD_PROJECT_BILLING_SUCCESS = 'ADD_PROJECT_BILLING_SUCCESS';
export const ADD_PROJECT_BILLING_FAILURE = 'ADD_PROJECT_BILLING_FAILURE';

export const UPDATE_PROJECT_BILLING = 'UPDATE_PROJECT_BILLING';
export const UPDATE_PROJECT_BILLING_SUCCESS = 'UPDATE_PROJECT_BILLING_SUCCESS';
export const UPDATE_PROJECT_BILLING_FAILURE = 'UPDATE_PROJECT_BILLING_FAILURE';

export const CLEAR_PROJECT_BILLING = 'CLEAR_PROJECT_BILLING';

export const GET_PROJECT_INVOICES = 'GET_PROJECT_INVOICES';
export const GET_PROJECT_INVOICES_SUCCESS = 'GET_PROJECT_INVOICES_SUCCESS';
export const GET_PROJECT_INVOICES_FAILURE = 'GET_PROJECT_INVOICES_FAILURE';

//Clients
export const GET_CLIENTS_LIST = 'GET_CLIENTS_LIST';
export const GET_CLIENTS_LIST_SUCCESS = 'GET_CLIENTS_LIST_SUCCESS';
export const GET_CLIENTS_LIST_FAILURE = 'GET_CLIENTS_LIST_FAILURE';

export const GET_CLIENT_DETAILS = 'GET_CLIENT_DETAILS';
export const GET_CLIENT_DETAILS_SUCCESS = 'GET_CLIENT_DETAILS_SUCCESS';
export const GET_CLIENT_DETAILS_FAILURE = 'GET_CLIENT_DETAILS_FAILURE';

export const CREATE_CLIENT = 'CREATE_CLIENT';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_FAILURE = 'CREATE_CLIENT_FAILURE';

export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAILURE = 'UPDATE_CLIENT_FAILURE';

export const DELETE_CLIENT = 'DELETE_CLIENT';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAILURE = 'DELETE_CLIENT_FAILURE';

//Organiztion
export const REQUEST_ORGANIZATION_LIST = 'REQUEST_ORGANIZATION_LIST';
export const REQUEST_ORGANIZATION_LIST_SUCCESS =
  'REQUEST_ORGANIZATION_LIST_SUCCESS';
export const REQUEST_ORGANIZATION_LIST_FAILURE =
  'REQUEST_ORGANIZATION_LIST_FAILURE';

export const CLEAR_ORG_CREATED = 'CLEAR_ORG_CREATED';
//Create
export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_FAILURE = 'CREATE_ORGANIZATION_FAILURE';
//GET types
export const GET_ORGANIZATION_TYPES = 'GET_ORGANIZATION_TYPES';
export const GET_ORGANIZATION_TYPES_SUCCESS = 'GET_ORGANIZATION_TYPES_SUCCESS';
export const GET_ORGANIZATION_TYPES_FAILURE = 'GET_ORGANIZATION_TYPES_FAILURE';
//GET plans
export const GET_ORGANIZATION_PLANS = 'GET_ORGANIZATION_PLANS';
export const GET_ORGANIZATION_PLANS_SUCCESS = 'GET_ORGANIZATION_PLANS_SUCCESS';
export const GET_ORGANIZATION_PLANS_FAILURE = 'GET_ORGANIZATION_PLANS_FAILURE';
//Update
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_FAILURE = 'UPDATE_ORGANIZATION_FAILURE';
//Get Organization members
export const GET_ORGANIZATION_MEMBERS = 'GET_ORGANIZATION_MEMBERS';
export const GET_ORGANIZATION_MEMBERS_SUCCESS =
  'GET_ORGANIZATION_MEMBERS_SUCCESS';
export const GET_ORGANIZATION_MEMBERS_FAILURE =
  'GET_ORGANIZATION_MEMBERS_FAILURE';

export const GET_MEMBERS_COUNT = 'GET_MEMBERS_COUNT';
export const GET_MEMBERS_COUNT_SUCCESS = 'GET_MEMBERS_COUNT_SUCCESS';
export const GET_MEMBERS_COUNT_FAILURE = 'GET_MEMBERS_COUNT_FAILURE';

export const CLEAR_MEMBERS_COUNT = 'CLEAR_MEMBERS_COUNT';

export const SELECT_ORGANIZATION = 'SELECT_ORGANIZATION';

export const GET_ALL_TASK_LIST = 'GET_ALL_TASK_LIST';
export const GET_ALL_TASK_LIST_SUCCESS = 'GET_ALL_TASK_LIST_SUCCESS';
export const GET_ALL_TASK_LIST_FAILURE = 'GET_ALL_TASK_LIST_FAILURE';

//NEW TASKS for Al bait

export const GET_TASKS_LIST = 'GET_TASKS_LIST';
export const GET_TASKS_LIST_SUCCESS = 'GET_TASKS_LIST_SUCCESS';
export const GET_TASKS_LIST_FAILURE = 'GET_TASKS_LIST_FAILURE';

export const CREATE_TASK = 'CREATE_TASK';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const CREATE_TASK_FAILURE = 'CREATE_TASK_FAILURE';

export const GET_TASK_DETAILS = 'GET_TASK_DETAILS';
export const GET_TASK_DETAILS_SUCCESS = 'GET_TASK_DETAILS_SUCCESS';
export const GET_TASK_DETAILS_FAILURE = 'GET_TASK_DETAILS_FAILURE';

export const GET_SUB_TASK_EVIDENCE = 'GET_SUB_TASK_EVIDENCE';
export const GET_SUB_TASK_EVIDENCE_SUCCESS = 'GET_SUB_TASK_EVIDENCE_SUCCESS';
export const GET_SUB_TASK_EVIDENCE_FAILURE = 'GET_SUB_TASK_EVIDENCE_FAILURE';

export const ADD_SUB_TASK = 'ADD_SUB_TASK';
export const ADD_SUB_TASK_SUCCESS = 'ADD_SUB_TASK_SUCCESS';
export const ADD_SUB_TASK_FAILURE = 'ADD_SUB_TASK_FAILURE';

export const EDIT_SUB_TASK = 'EDIT_SUB_TASK';
export const EDIT_SUB_TASK_SUCCESS = 'EDIT_SUB_TASK_SUCCESS';
export const EDIT_SUB_TASK_FAILURE = 'EDIT_SUB_TASK_FAILURE';

export const DELETE_SUB_TASK = 'DELETE_SUB_TASK';
export const DELETE_SUB_TASK_SUCCESS = 'DELETE_SUB_TASK_SUCCESS';
export const DELETE_SUB_TASK_FAILURE = 'DELETE_SUB_TASK_FAILURE';

export const EDIT_TASK = 'EDIT_TASK';
export const EDIT_TASK_SUCCESS = 'EDIT_TASK_SUCCESS';
export const EDIT_TASK_FAILURE = 'EDIT_TASK_FAILURE';

export const UPDATE_PARTIAL_TASK = 'UPDATE_PARTIAL_TASK';
export const UPDATE_PARTIAL_TASK_SUCCESS = 'UPDATE_PARTIAL_TASK_SUCCESS';
export const UPDATE_PARTIAL_TASK_FAILURE = 'UPDATE_PARTIAL_TASK_FAILURE';

export const UPDATE_TASK_ASSIGNEE = 'UPDATE_TASK_ASSIGNEE';
export const UPDATE_TASK_ASSIGNEE_SUCCESS = 'UPDATE_TASK_ASSIGNEE_SUCCESS';
export const UPDATE_TASK_ASSIGNEE_FAILURE = 'UPDATE_TASK_ASSIGNEE_FAILURE';

export const REMOVE_TASK_ASSIGNEE = 'REMOVE_TASK_ASSIGNEE';
export const REMOVE_TASK_ASSIGNEE_SUCCESS = 'REMOVE_TASK_ASSIGNEE_SUCCESS';
export const REMOVE_TASK_ASSIGNEE_FAILURE = 'REMOVE_TASK_ASSIGNEE_FAILURE';

export const DUPLICATE_TASK = 'DUPLICATE_TASK';
export const DUPLICATE_TASK_SUCCESS = 'DUPLICATE_TASK_SUCCESS';
export const DUPLICATE_TASK_FAILURE = 'DUPLICATE_TASK_FAILURE';

export const DELETE_TASK = 'DELETE_TASK';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_FAILURE = 'DELETE_TASK_FAILURE';

export const GET_TASK_LOCATIONS = 'GET_TASK_LOCATIONS';
export const GET_TASK_LOCATIONS_SUCCESS = 'GET_TASK_LOCATIONS_SUCCESS';
export const GET_TASK_LOCATIONS_FAILURE = 'GET_TASK_LOCATIONS_FAILURE';

export const ADD_TASK_LOCATION = 'ADD_TASK_LOCATION';
export const ADD_TASK_LOCATION_SUCCESS = 'ADD_TASK_LOCATION_SUCCESS';
export const ADD_TASK_LOCATION_FAILURE = 'ADD_TASK_LOCATION_FAILURE';

export const UPDATE_TASK_STATUS = 'UPDATE_TASK_STATUS';
export const UPDATE_TASK_STATUS_SUCCESS = 'UPDATE_TASK_STATUS_SUCCESS';
export const UPDATE_TASK_STATUS_FAILURE = 'UPDATE_TASK_STATUS_FAILURE';

export const GET_TASK_TYPE = 'GET_TASK_TYPE';
export const GET_TASK_TYPE_SUCCESS = 'GET_TASK_TYPE_SUCCESS';
export const GET_TASK_TYPE_FAILURE = 'GET_TASK_TYPE_FAILURE';

// OFFICE
export const GET_OFFICES_LIST = 'GET_OFFICES_LIST';
export const GET_OFFICES_LIST_SUCCESS = 'GET_OFFICES_LIST_SUCCESS';
export const GET_OFFICES_LIST_FAILURE = 'GET_OFFICES_LIST_FAILURE';

export const GET_OFFICES_LIST_SHORT = 'GET_OFFICES_LIST_SHORT';
export const GET_OFFICES_LIST_SHORT_SUCCESS = 'GET_OFFICES_LIST_SHORT_SUCCESS';
export const GET_OFFICES_LIST_SHORT_FAILURE = 'GET_OFFICES_LIST_SHORT_FAILURE';

export const CREATE_OFFICE = 'CREATE_OFFICE';
export const CREATE_OFFICE_SUCCESS = 'CREATE_OFFICE_SUCCESS';
export const CREATE_OFFICE_FAILURE = 'CREATE_OFFICE_FAILURE';

export const UPDATE_OFFICE = 'UPDATE_OFFICE';
export const UPDATE_OFFICE_SUCCESS = 'UPDATE_OFFICE_SUCCESS';
export const UPDATE_OFFICE_FAILURE = 'UPDATE_OFFICE_FAILURE';

export const ADD_OFFICE_ASSIGNEE = 'ADD_OFFICE_ASSIGNEE';
export const ADD_OFFICE_ASSIGNEE_SUCCESS = 'ADD_OFFICE_ASSIGNEE_SUCCESS';
export const ADD_OFFICE_ASSIGNEE_FAILURE = 'ADD_OFFICE_ASSIGNEE_FAILURE';

export const REMOVE_OFFICE_ASSIGNEE = 'REMOVE_OFFICE_ASSIGNEE';
export const REMOVE_OFFICE_ASSIGNEE_SUCCESS = 'REMOVE_OFFICE_ASSIGNEE_SUCCESS';
export const REMOVE_OFFICE_ASSIGNEE_FAILURE = 'REMOVE_OFFICE_ASSIGNEE_FAILURE';

export const SET_OFFICE_DETAILS = 'SET_OFFICE_DETAILS';

export const CLEAR_OFFICE_DETAILS = 'CLEAR_OFFICE_DETAILS';

export const CLEAR_OFFICE_CREATED_UPDATED = 'CLEAR_OFFICE_CREATED_UPDATED';

//Get Activity Apps lists
export const GET_APPS_LIST = 'GET_APPS_LIST';
export const GET_APPS_LIST_SUCCESS = 'GET_APPS_LIST_SUCCESS';
export const GET_APPS_LIST_FAILURE = 'GET_APPS_LIST_FAILURE';

//Get Activity Urls lists
export const GET_URL_LIST = 'GET_URL_LIST';
export const GET_URL_LIST_SUCCESS = 'GET_URL_LIST_SUCCESS';
export const GET_URL_LIST_FAILURE = 'GET_URL_LIST_FAILURE';

//Get Activity Screenshots lists
export const GET_SCREENSHOT_LIST = 'GET_SCREENSHOT_LIST';
export const GET_SCREENSHOT_LIST_SUCCESS = 'GET_SCREENSHOT_LIST_SUCCESS';
export const GET_SCREENSHOT_LIST_FAILURE = 'GET_SCREENSHOT_LIST_FAILURE';

//Sort Screenshots
export const SORT_SCREENSHOTS = 'SORT_SCREENSHOTS';
export const SORT_SCREENSHOTS_SUCCESS = 'SORT_SCREENSHOTS_SUCCESS';
export const SORT_SCREENSHOTS_FAILURE = 'SORT_SCREENSHOTS_FAILURE';

//Delete screenshot
export const DELETE_SCREENSHOT = 'DELETE_SCREENSHOT';
export const DELETE_SCREENSHOT_SUCCESS = 'DELETE_SCREENSHOT_SUCCESS';
export const DELETE_SCREENSHOT_FAILURE = 'DELETE_SCREENSHOT_FAILURE';

//User Activity in Screeshot
export const GET_USER_ACTIVITY = 'GET_USER_ACTIVITY';
export const GET_USER_ACTIVITY_SUCCESS = 'GET_USER_ACTIVITY_SUCCESS';
export const GET_USER_ACTIVITY_FAILURE = 'GET_USER_ACTIVITY_FAILURE';

//Get Locations lists
export const GET_LOCATION_LIST = 'GET_LOCATION_LIST';
export const GET_LOCATION_LIST_SUCCESS = 'GET_LOCATION_LIST_SUCCESS';
export const GET_LOCATION_LIST_FAILURE = 'GET_LOCATION_LIST_FAILURE';

// Field Services

// get route map locations
export const GET_ROUTE_MAP_LOCATIONS = 'GET_ROUTE_MAP_LOCATIONS';
export const GET_ROUTE_MAP_LOCATIONS_SUCCESS =
  'GET_ROUTE_MAP_LOCATIONS_SUCCESS';
export const GET_ROUTE_MAP_LOCATIONS_FAILURE =
  'GET_ROUTE_MAP_LOCATIONS_FAILURE';

export const GET_SINGLE_EMPLOYEE_CLOCK_IN_OUT_LIST =
  'GET_SINGLE_EMPLOYEE_CLOCK_IN_OUT_LIST';
export const GET_SINGLE_EMPLOYEE_CLOCK_IN_OUT_LIST_SUCCESS =
  'GET_SINGLE_EMPLOYEE_CLOCK_IN_OUT_LIST_SUCCESS';
export const GET_SINGLE_EMPLOYEE_CLOCK_IN_OUT_LIST_FAILURE =
  'GET_SINGLE_EMPLOYEE_CLOCK_IN_OUT_LIST_FAILURE';

export const GET_TASK_CHECK_IN_OUT_LIST = 'GET_TASK_CHECK_IN_OUT_LIST';
export const GET_TASK_CHECK_IN_OUT_LIST_SUCCESS =
  'GET_TASK_CHECK_IN_OUT_LIST_SUCCESS';
export const GET_TASK_CHECK_IN_OUT_LIST_FAILURE =
  'GET_TASK_CHECK_IN_OUT_LIST_FAILURE';

// Job Sites
export const GET_JOB_SITES_LIST = 'GET_JOB_SITES_LIST';
export const GET_JOB_SITES_LIST_SUCCESS = 'GET_JOB_SITES_LIST_SUCCESS';
export const GET_JOB_SITES_LIST_FAILURE = 'GET_JOB_SITES_LIST_FAILURE';

export const ADD_JOB_SITE = 'ADD_JOB_SITE';
export const ADD_JOB_SITE_SUCCESS = 'ADD_JOB_SITE_SUCCESS';
export const ADD_JOB_SITE_FAILURE = 'ADD_JOB_SITE_FAILURE';

export const UPDATE_JOB_SITE = 'UPDATE_JOB_SITE';
export const UPDATE_JOB_SITE_SUCCESS = 'UPDATE_JOB_SITE_SUCCESS';
export const UPDATE_JOB_SITE_FAILURE = 'UPDATE_JOB_SITE_FAILURE';

export const SET_JOB_SITE_DETAILS = 'SET_JOB_SITE_DETAILS';

export const CLEAR_JOB_SITE_DETAILS = 'CLEAR_JOB_SITE_DETAILS';

export const DELETE_JOB_SITE = 'DELETE_JOB_SITE';
export const DELETE_JOB_SITE_SUCCESS = 'DELETE_JOB_SITE_SUCCESS';
export const DELETE_JOB_SITE_FAILURE = 'DELETE_JOB_SITE_FAILURE';

export const ADD_JOB_SITE_ASSIGNEE = 'ADD_JOB_SITE_ASSIGNEE';
export const ADD_JOB_SITE_ASSIGNEE_SUCCESS = 'ADD_JOB_SITE_ASSIGNEE_SUCCESS';
export const ADD_JOB_SITE_ASSIGNEE_FAILURE = 'ADD_JOB_SITE_ASSIGNEE_FAILURE';

export const REMOVE_JOB_SITE_ASSIGNEE = 'REMOVE_JOB_SITE_ASSIGNEE';
export const REMOVE_JOB_SITE_ASSIGNEE_SUCCESS =
  'REMOVE_JOB_SITE_ASSIGNEE_SUCCESS';
export const REMOVE_JOB_SITE_ASSIGNEE_FAILURE =
  'REMOVE_JOB_SITE_ASSIGNEE_FAILURE';

export const GET_GEOFENCE_CLOCK_IN_OUT_LIST = 'GET_GEOFENCE_CLOCK_IN_OUT_LIST';
export const GET_GEOFENCE_CLOCK_IN_OUT_LIST_SUCCESS =
  'GET_GEOFENCE_CLOCK_IN_OUT_LIST_SUCCESS';
export const GET_GEOFENCE_CLOCK_IN_OUT_LIST_FAILURE =
  'GET_GEOFENCE_CLOCK_IN_OUT_LIST_FAILURE';

// Live Location
export const GET_LATEST_LOCATION_LIST = 'GET_LATEST_LOCATION_LIST';
export const GET_LATEST_LOCATION_LIST_SUCCESS =
  'GET_LATEST_LOCATION_LIST_SUCCESS';
export const GET_LATEST_LOCATION_LIST_FAILURE =
  'GET_LATEST_LOCATION_LIST_FAILURE';

//Get TimeSheet list
export const GET_DAILY_TIMESHEETS_LIST = 'GET_DAILY_TIMESHEETS_LIST';
export const GET_DAILY_TIMESHEETS_LIST_SUCCESS =
  'GET_DAILY_TIMESHEETS_LIST_SUCCESS';
export const GET_DAILY_TIMESHEETS_LIST_FAILURE =
  'GET_DAILY_TIMESHEETS_LIST_FAILURE';
export const CREATE_DAILY_TIMESHEETS_LIST = 'CREATE_DAILY_TIMESHEETS_LIST';
export const CREATE_DAILY_TIMESHEETS_LIST_SUCCESS =
  'CREATE_DAILY_TIMESHEETS_LIST_SUCCESS';
export const CREATE_DAILY_TIMESHEETS_LIST_FAILURE =
  'CREATE_DAILY_TIMESHEETS_LIST_FAILURE';
export const UPDATE_DAILY_TIMESHEETS_LIST = 'UPDATE_DAILY_TIMESHEETS_LIST';
export const UPDATE_DAILY_TIMESHEETS_LIST_SUCCESS =
  'UPDATE_DAILY_TIMESHEETS_LIST_SUCCESS';
export const UPDATE_DAILY_TIMESHEETS_LIST_FAILURE =
  'UPDATE_DAILY_TIMESHEETS_LIST_FAILURE';
export const DELETE_DAILY_TIMESHEETS_LIST = 'DELETE_DAILY_TIMESHEETS_LIST';
export const DELETE_DAILY_TIMESHEETS_LIST_SUCCESS =
  'DELETE_DAILY_TIMESHEETS_LIST_SUCCESS';
export const DELETE_DAILY_TIMESHEETS_LIST_FAILURE =
  'DELETE_DAILY_TIMESHEETS_LIST_FAILURE';

export const GET_OTHER_TIMESHEETS_LIST = 'GET_OTHER_TIMESHEETS_LIST';
export const GET_OTHER_TIMESHEETS_LIST_SUCCESS =
  'GET_OTHER_TIMESHEETS_LIST_SUCCESS';
export const GET_OTHER_TIMESHEETS_LIST_FAILURE =
  'GET_OTHER_TIMESHEETS_LIST_FAILURE';

export const GET_WEEKLY_TIMESHEET_DETAILS = 'GET_WEEKLY_TIMESHEET_DETAILS';
export const GET_WEEKLY_TIMESHEET_DETAILS_SUCCESS =
  'GET_WEEKLY_TIMESHEET_DETAILS_SUCCESS';
export const GET_WEEKLY_TIMESHEET_DETAILS_FAILURE =
  'GET_WEEKLY_TIMESHEET_DETAILS_FAILURE';

export const CLEAR_DAILY_TIMESHEETS_LIST = 'CLEAR_DAILY_TIMESHEETS_LIST';

export const CLEAR_TIMESHEET_LIST = 'CLEAR_TIMESHEET_LIST';

export const CLEAR_MANUAL_TIME_ADDED = 'CLEAR_MANUAL_TIME_ADDED';

// Timesheet Approval
export const GET_TIMESHEET_APPROVAL_AWAITING_LIST =
  'GET_TIMESHEET_APPROVAL_AWAITING_LIST';
export const GET_TIMESHEET_APPROVAL_AWAITING_LIST_SUCCESS =
  'GET_TIMESHEET_APPROVAL_AWAITING_LIST_SUCCESS';
export const GET_TIMESHEET_APPROVAL_AWAITING_LIST_FAILURE =
  'GET_TIMESHEET_APPROVAL_AWAITING_LIST_FAILURE';

export const GET_TIMESHEET_APPROVAL_HISTORY = 'GET_TIMESHEET_APPROVAL_HISTORY';
export const GET_TIMESHEET_APPROVAL_HISTORY_SUCCESS =
  'GET_TIMESHEET_APPROVAL_HISTORY_SUCCESS';
export const GET_TIMESHEET_APPROVAL_HISTORY_FAILURE =
  'GET_TIMESHEET_APPROVAL_HISTORY_FAILURE';

export const APPROVE_OR_REJECT_TIMESHEETS = 'APPROVE_OR_REJECT_TIMESHEETS';
export const APPROVE_OR_REJECT_TIMESHEETS_SUCCESS =
  'APPROVE_OR_REJECT_TIMESHEETS_SUCCESS';
export const APPROVE_OR_REJECT_TIMESHEETS_FAILURE =
  'APPROVE_OR_REJECT_TIMESHEETS_FAILURE';

export const LOAD_MORE_TIMESHEET_APPROVAL_LIST =
  'LOAD_MORE_TIMESHEET_APPROVAL_LIST';
export const LOAD_MORE_TIMESHEET_APPROVAL_LIST_SUCCESS =
  'LOAD_MORE_TIMESHEET_APPROVAL_LIST_SUCCESS';
export const LOAD_MORE_TIMESHEET_APPROVAL_LIST_FAILURE =
  'LOAD_MORE_TIMESHEET_APPROVAL_LIST_FAILURE';

export const CLEAR_LOAD_MORE_TIMESHEET_APPROVAL_LIST =
  'CLEAR_LOAD_MORE_TIMESHEET_APPROVAL_LIST';

// Get work notes
export const GET_WORK_NOTES = 'GET_WORK_NOTES';
export const GET_WORK_NOTES_SUCCESS = 'GET_WORK_NOTES_SUCCESS';
export const GET_WORK_NOTES_FAILURE = 'GET_WORK_NOTES_FAILURE';

// Get all notes
export const GET_ALL_NOTES = 'GET_ALL_NOTES';
export const GET_ALL_NOTES_SUCCESS = 'GET_ALL_NOTES_SUCCESS';
export const GET_ALL_NOTES_FAILURE = 'GET_ALL_NOTES_FAILURE';

export const ADD_NOTES = 'ADD_NOTES';
export const ADD_NOTES_SUCCESS = 'ADD_NOTES_SUCCESS';
export const ADD_NOTES_FAILURE = 'ADD_NOTES_FAILURE';

export const GET_SCREENSHOT_NOTES = 'GET_SCREENSHOT_NOTES';
export const GET_SCREENSHOT_NOTES_SUCCESS = 'GET_SCREENSHOT_NOTES_SUCCESS';
export const GET_SCREENSHOT_NOTES_FAILURE = 'GET_SCREENSHOT_NOTES_FAILURE';

export const GET_REPORT_NOTES = 'GET_REPORT_NOTES';
export const GET_REPORT_NOTES_SUCCESS = 'GET_REPORT_NOTES_SUCCESS';
export const GET_REPORT_NOTES_FAILURE = 'GET_REPORT_NOTES_FAILURE';

// Leave
export const GET_LEAVES_LIST = 'GET_LEAVES_LIST';
export const GET_LEAVES_LIST_SUCCESS = 'GET_LEAVES_LIST_SUCCESS';
export const GET_LEAVES_LIST_FAILURE = 'GET_LEAVES_LIST_FAILURE';

export const APPROVE_OR_REJECT_LEAVE = 'APPROVE_OR_REJECT_LEAVE';
export const APPROVE_OR_REJECT_LEAVE_SUCCESS =
  'APPROVE_OR_REJECT_LEAVE_SUCCESS';
export const APPROVE_OR_REJECT_LEAVE_FAILURE =
  'APPROVE_OR_REJECT_LEAVE_FAILURE';

export const CREATE_LEAVE = 'CREATE_LEAVE';
export const CREATE_LEAVE_SUCCESS = 'CREATE_LEAVE_SUCCESS';
export const CREATE_LEAVE_FAILURE = 'CREATE_LEAVE_FAILURE';

//Schedule
export const ADD_SCHEDULE = 'ADD_SCHEDULE';
export const ADD_SCHEDULE_SUCCESS = 'ADD_SCHEDULE_SUCCESS';
export const ADD_SCHEDULE_FAILURE = 'ADD_SCHEDULE_FAILURE';
export const GET_SCHEDULE_LIST = 'GET_SCHEDULE_LIST';
export const GET_SCHEDULE_LIST_SUCCESS = 'GET_SCHEDULE_LIST_SUCCESS';
export const GET_SCHEDULE_LIST_FAILURE = 'GET_SCHEDULE_LIST_FAILURE';
export const UPDATE_SCHEDULE_LIST = 'UPDATE_SCHEDULE_LIST';
export const UPDATE_SCHEDULE_LIST_SUCCESS = 'UPDATE_SCHEDULE_LIST_SUCCESS';
export const UPDATE_SCHEDULE_LIST_FAILURE = 'UPDATE_SCHEDULE_LIST_FAILURE';
export const DEACTIVATE_SCHEDULE = 'DEACTIVATE_SCHEDULE';
export const DEACTIVATE_SCHEDULE_SUCCESS = 'DEACTIVATE_SCHEDULE_SUCCESS';
export const DEACTIVATE_SCHEDULE_FAILURE = 'DEACTIVATE_SCHEDULE_FAILURE';
export const GET_SCHEDULE = 'GET_SCHEDULE';
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS';
export const GET_SCHEDULE_FAILURE = 'GET_SCHEDULE_FAILURE';
export const RESET_SELECTED_SCHEDULE = 'RESET_SELECTED_SCHEDULE';

//Members
export const GET_MEMBERS = 'GET_MEMBERS';
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS';
export const GET_MEMBERS_FAILURE = 'GET_MEMBERS_FAILURE';

export const GET_INVITED_MEMBERS = 'GET_INVITED_MEMBERS';
export const GET_INVITED_MEMBERS_SUCCESS = 'GET_INVITED_MEMBERS_SUCCESS';
export const GET_INVITED_MEMBERS_FAILURE = 'GET_INVITED_MEMBERS_FAILURE';

export const GET_MEMBER_DETAILS = 'GET_MEMBER_DETAILS';
export const GET_MEMBER_DETAILS_SUCCESS = 'GET_MEMBER_DETAILS_SUCCESS';
export const GET_MEMBER_DETAILS_FAILURE = 'GET_MEMBER_DETAILS_FAILURE';

export const GET_MEMBER_WEEKLY_EARNING = 'GET_MEMBER_WEEKLY_EARNING';
export const GET_MEMBER_WEEKLY_EARNING_SUCCESS =
  'GET_MEMBER_WEEKLY_EARNING_SUCESS';
export const GET_MEMBER_WEEKLY_EARNING_FAILURE =
  'GET_MEMBER_WEEKLY_EARNING_FAILURE';

export const GET_MEMBER_MONTHLY_EARNING = 'GET_MEMBER_MONTHLY_EARNING';
export const GET_MEMBER_MONTHLY_EARNING_SUCCESS =
  'GET_MEMBER_MONTHLY_EARNING_SUCESS';
export const GET_MEMBER_MONTHLY_EARNING_FAILURE =
  'GET_MEMBER_WEEKLY_EARNING_FAILURE';

export const INVITE_MEMBER = 'INVITE_MEMBER';
export const INVITE_MEMBER_SUCCESS = 'INVITE_MEMBER_SUCCESS';
export const INVITE_MEMBER_FAILURE = 'INVITE_MEMBER_FAILURE';

export const GET_PUBLIC_API_KEY = 'GET_PUBLIC_API_KEY';
export const GET_PUBLIC_API_KEY_SUCCESS = 'GET_PUBLIC_API_KEY_SUCCESS';
export const GET_PUBLIC_API_KEY_FAILURE = 'GET_PUBLIC_API_KEY_FAILURE';

export const CREATE_PUBLIC_API_KEY = 'CREATE_PUBLIC_API_KEY';
export const CREATE_PUBLIC_API_KEY_SUCCESS = 'CREATE_PUBLIC_API_KEY_SUCCESS';
export const CREATE_PUBLIC_API_KEY_FAILURE = 'CREATE_PUBLIC_API_KEY_FAILURE';

export const CLEAR_MEMBER_INVITED = 'CLEAR_MEMBER_INVITED';

export const DELETE_MEMBER_INVITATION = 'DELETE_MEMBER_INVITATION';
export const DELETE_MEMBER_INVITATION_SUCCESS =
  'DELETE_MEMBER_INVITATION_SUCCESS';
export const DELETE_MEMBER_INVITATION_FAILURE =
  'DELETE_MEMBER_INVITATION_FAILURE';

export const ADD_MEMBER = 'ADD_MEMBER';
export const ADD_MEMBER_SUCCESS = 'ADD_MEMBER_SUCCESS';
export const ADD_MEMBER_FAILURE = 'ADD_MEMBER_FAILURE';

export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_FAILURE = 'UPDATE_MEMBER_FAILURE';

export const CLEAR_MEMBER_DETAILS_DATA = 'CLEAR_MEMBER_DETAILS_DATA';

export const ARCHIVE_MEMBERS = 'ARCHIVE_MEMBER';
export const ARCHIVE_MEMBERS_SUCCESS = 'ARCHIVE_MEMBER_SUCCESS';
export const ARCHIVE_MEMBERS_FAILURE = 'ARCHIVE_MEMBER_FAILURE';

export const REMOVE_MEMBER = 'REMOVE_MEMBER';
export const REMOVE_MEMBER_SUCCESS = 'REMOVE_MEMBER_SUCCESS';
export const REMOVE_MEMBER_FAILURE = 'REMOVE_MEMBER_FAILURE';

export const SET_PAYMENT_CONFIGURATION = 'SET_PAYMENT_CONFIGURATION';
export const SET_PAYMENT_CONFIGURATION_SUCCESS =
  'SET_PAYMENT_CONFIGURATION_SUCCESS';
export const SET_PAYMENT_CONFIGURATION_FAILURE =
  'SET_PAYMENT_CONFIGURATION_FAILURE';

export const UPDATE_PAYMENT_CONFIGURATION = 'UPDATE_PAYMENT_CONFIGURATION';
export const UPDATE_PAYMENT_CONFIGURATION_SUCCESS =
  'UPDATE_PAYMENT_CONFIGURATION_SUCCESS';
export const UPDATE_PAYMENT_CONFIGURATION_FAILURE =
  'UPDATE_PAYMENT_CONFIGURATION_FAILURE';

//need to remove later
export const REMOVE_MEMBERS = 'REMOVE_MEMBERs';
export const REMOVE_MEMBERS_SUCCESS = 'REMOVE_MEMBERS_SUCCESS';
export const REMOVE_MEMBERS_FAILURE = 'REMOVE_MEMBERS_FAILURE';

export const ADD_OR_REMOVE_ADMIN = 'ADD_OR_REMOVE_ADMIN';
export const ADD_OR_REMOVE_ADMIN_SUCCESS = 'ADD_OR_REMOVE_ADMIN_SUCCESS';
export const ADD_OR_REMOVE_ADMIN_FAILURE = 'ADD_OR_REMOVE_ADMIN_FAILURE';

export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';

export const GET_INVITATION_LINKS = 'GET_INVITATION_LINKS';
export const GET_INVITATION_LINKS_SUCCESS = 'GET_INVITATION_LINKS_SUCCESS';
export const GET_INVITATION_LINKS_FAILURE = 'GET_INVITATION_LINKS_FAILURE';

export const GET_STRIPE_KEY = 'GET_STRIPE_KEY';
export const GET_STRIPE_KEY_SUCCESS = 'GET_STRIPE_KEY_SUCCESS';
export const GET_STRIPE_KEY_FAILURE = 'GET_STRIPE_KEY_FAILURE';

export const SAVE_STRIPE_TOKEN = 'SAVE_STRIPE_TOKEN';
export const SAVE_STRIPE_TOKEN_SUCCESS = 'SAVE_STRIPE_TOKEN_SUCCESS';
export const SAVE_STRIPE_TOKEN_FAILURE = 'SAVE_STRIPE_TOKEN_FAILURE';

export const RETRIEVE_PAYMENT_SOURCE_INFO = 'RETRIEVE_PAYMENT_SOURCE_INFO';
export const RETRIEVE_PAYMENT_SOURCE_INFO_SUCCESS =
  'RETRIEVE_PAYMENT_SOURCE_INFO_SUCCESS';
export const RETRIEVE_PAYMENT_SOURCE_INFO_FAILURE =
  'RETRIEVE_PAYMENT_SOURCE_INFO_FAILURE';

export const REMOVE_PAYMENT_SOURCE = 'REMOVE_PAYMENT_SOURCE';
export const REMOVE_PAYMENT_SOURCE_SUCCESS = 'REMOVE_PAYMENT_SOURCE_SUCCESS';
export const REMOVE_PAYMENT_SOURCE_FAILURE = 'REMOVE_PAYMENT_SOURCE_FAILURE';

export const GET_LTD_INFO = 'GET_LTD_INFO';
export const GET_LTD_INFO_SUCCESS = 'GET_LTD_INFO_SUCCESS';
export const GET_LTD_INFO_FAILURE = 'GET_LTD_INFO_FAILURE';

export const GET_PAYMENT_CONF_HISTORY = 'GET_PAYMENT_CONF_HISTORY';
export const GET_PAYMENT_CONF_HISTORY_SUCCESS =
  'GET_PAYMENT_CONF_HISTORY_SUCCESS';
export const GET_PAYMENT_CONF_HISTORY_FAILURE =
  'GET_PAYMENT_CONF_HISTORY_FAILURE';

export const SAVE_PAYMENT_CONF = 'SAVE_PAYMENT_CONF';
export const SAVE_PAYMENT_CONF_SUCCESS =
  'SAVE_PAYMENT_CONF_SUCCESSSAVE_PAYMENT_CONF_SUCCESS';
export const SAVE_PAYMENT_CONF_FAILURE = 'SAVE_PAYMENT_CONF_FAILURE';

export const GET_CLOCK_IN_OUT_LIST = 'GET_CLOCK_IN_OUT_LIST';
export const GET_CLOCK_IN_OUT_LIST_SUCCESS = 'GET_CLOCK_IN_OUT_LIST_SUCCESS';
export const GET_CLOCK_IN_OUT_LIST_FAILURE = 'GET_CLOCK_IN_OUT_LIST_FAILURE';

//Reports
export const GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT =
  'GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT';
export const GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT_SUCCESS =
  'GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT_SUCCESS';
export const GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT_FAILURE =
  'GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT_FAILURE';

export const GET_MANUAL_TIME_REPORT = 'GET_MANUAL_TIME_REPORT';
export const GET_MANUAL_TIME_REPORT_SUCCESS = 'GET_MANUAL_TIME_REPORT_SUCCESS';
export const GET_MANUAL_TIME_REPORT_FAILURE = 'GET_MANUAL_TIME_REPORT_FAILURE';

export const GET_URL_USAGE_REPORT = 'GET_URL_USAGE_REPORT';
export const GET_URL_USAGE_REPORT_SUCCESS = 'GET_URL_USAGE_REPORT_SUCCESS';
export const GET_URL_USAGE_REPORT_FAILURE = 'GET_URL_USAGE_REPORT_FAILURE';

export const GET_APP_USAGE_REPORT = 'GET_APP_USAGE_REPORT';
export const GET_APP_USAGE_REPORT_SUCCESS = 'GET_APP_USAGE_REPORT_SUCCESS';
export const GET_APP_USAGE_REPORT_FAILURE = 'GET_APP_USAGE_REPORT_FAILURE';

export const GET_APP_TIME_INFO_REPORT = 'GET_APP_TIME_INFO_REPORT';
export const GET_APP_TIME_INFO_REPORT_SUCCESS =
  'GET_APP_TIME_INFO_REPORT_SUCCESS';
export const GET_APP_TIME_INFO_REPORT_FAILURE =
  'GET_APP_TIME_INFO_REPORT_FAILURE';

export const GET_DUE_PAYMENT_REPORT = 'GET_DUE_PAYMENT_REPORT';
export const GET_DUE_PAYMENT_REPORT_SUCCESS = 'GET_DUE_PAYMENT_REPORT_SUCCESS';
export const GET_DUE_PAYMENT_REPORT_FAILURE = 'GET_DUE_PAYMENT_REPORT_FAILURE';

export const GET_PAID_AMOUNT_REPORT = 'GET_PAID_AMOUNT_REPORT';
export const GET_PAID_AMOUNT_REPORT_SUCCESS = 'GET_PAID_AMOUNT_REPORT_SUCCESS';
export const GET_PAID_AMOUNT_REPORT_FAILURE = 'GET_PAID_AMOUNT_REPORT_FAILURE';

export const GET_ONE_TIME_PAID_REPORT = 'GET_ONE_TIME_PAID_REPORT';
export const GET_ONE_TIME_PAID_REPORT_SUCCESS =
  'GET_ONE_TIME_PAID_REPORT_SUCCESS';
export const GET_ONE_TIME_PAID_REPORT_FAILURE =
  'GET_ONE_TIME_PAID_REPORT_FAILURE';

export const DONT_SHOW_ORGANIZATION_WIZARD = 'DONT_SHOW_ORGANIZATION_WIZARD';
export const DONT_SHOW_ORGANIZATION_WIZARD_SUCCESS =
  'DONT_SHOW_ORGANIZATION_WIZARD_SUCCESS';
export const DONT_SHOW_ORGANIZATION_WIZARD_FAILURE =
  'DONT_SHOW_ORGANIZATION_WIZARD_FAILURE';

export const ADD_MULTIPLE_PROJECTS = 'ADD_MULTIPLE_PROJECTS';
export const ADD_MULTIPLE_PROJECTS_SUCCESS = 'ADD_MULTIPLE_PROJECTS_SUCCESS';
export const ADD_MULTIPLE_PROJECTS_FAILURE = 'ADD_MULTIPLE_PROJECTS_FAILURE';

export const INVITE_MULTIPLE_MEMBERS = 'INVITE_MULTIPLE_MEMBERS';
export const INVITE_MULTIPLE_MEMBERS_SUCCESS =
  'INVITE_MULTIPLE_MEMBERS_SUCCESS';
export const INVITE_MULTIPLE_MEMBERS_FAILURE =
  'INVITE_MULTIPLE_MEMBERS_FAILURE';

export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
export const DELETE_ORGANIZATION_FAILURE = 'DELETE_ORGANIZATION_FAILURE';

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';

export const REDEEM_CODE = 'REDEEM_CODE';
export const REDEEM_CODE_SUCCESS = 'REDEEM_CODE_SUCCESS';
export const REDEEM_CODE_FAILURE = 'REDEEM_CODE_FAILURE';

export const REDEEM_DISCOUNT_CODE = 'REDEEM_DISCOUNT_CODE';
export const REDEEM_DISCOUNT_CODE_SUCCESS = 'REDEEM_DISCOUNT_CODE_SUCCESS';
export const REDEEM_DISCOUNT_CODE_FAILURE = 'REDEEM_DISCOUNT_CODE_FAILURE';

export const REDEEM_LIMITED_TIME_CODE = 'REDEEM_LIMITED_TIME_CODE';
export const REDEEM_LIMITED_TIME_CODE_SUCCESS =
  'REDEEM_LIMITED_TIME_CODE_SUCCESS';
export const REDEEM_LIMITED_TIME_CODE_FAILURE =
  'REDEEM_LIMITED_TIME_CODE_FAILURE';

export const GET_ZAP_API_KEY = 'GET_ZAP_API_KEY';
export const GET_ZAP_API_KEY_SUCCESS = 'GET_ZAP_API_KEY_SUCCESS';
export const GET_ZAP_API_KEY_FAILURE = 'GET_ZAP_API_KEY_FAILURE';

export const GET_ALL_ZAP_KEY = 'GET_ALL_ZAP_KEY';
export const GET_ALL_ZAP_KEY_SUCCESS = 'GET_ALL_ZAP_KEY_SUCCESS';
export const GET_ALL_ZAP_KEY_FAILURE = 'GET_ALL_ZAP_KEY_FAILURE';

// payment
export const GET_PAYABLE_DATA = 'GET_PAYABLE_DATA';
export const GET_PAYABLE_DATA_SUCCESS = 'GET_PAYABLE_DATA_SUCCESS';
export const GET_PAYABLE_DATA_FAILURE = 'GET_PAYABLE_DATA_FAILURE';

export const PAY_INDIVIDUAL_DATA = 'PAY_INDIVIDUAL_DATA';
export const PAY_INDIVIDUAL_DATA_SUCCESS = 'PAY_INDIVIDUAL_DATA_SUCCESS';
export const PAY_INDIVIDUAL_DATA_FAILURE = 'PAY_INDIVIDUAL_DATA_FAILURE';

export const BULK_PAYMENT = 'BULK_PAYMENT';
export const BULK_PAYMENT_SUCCESS = 'BULK_PAYMENT_SUCCESS';
export const BULK_PAYMENT_FAILURE = 'BULK_PAYMENT_FAILURE';

export const ONE_TIME_PAYMENT = 'ONE_TIME_PAYMENT';
export const ONE_TIME_PAYMENT_SUCCESS = 'ONE_TIME_PAYMENT_SUCCESS';
export const ONE_TIME_PAYMENT_FAILURE = 'ONE_TIME_PAYMENT_FAILURE';

// payroll
export const GET_PAYABLES_DATA = 'GET_PAYABLES_DATA';
export const GET_PAYABLES_DATA_SUCCESS = 'GET_PAYABLES_DATA_SUCCESS';
export const GET_PAYABLES_DATA_FAILURE = 'GET_PAYABLES_DATA_FAILURE';

export const SEND_ONE_TIME_PAYMENT = 'SEND_ONE_TIME_PAYMENT';
export const SEND_ONE_TIME_PAYMENT_SUCCESS = 'SEND_ONE_TIME_PAYMENT_SUCCESS';
export const SEND_ONE_TIME_PAYMENT_FAILURE = 'SEND_ONE_TIME_PAYMENT_FAILURE';

export const CLEAR_ONE_TIME_PAYMENT_DATA = 'CLEAR_ONE_TIME_PAYMENT_DATA';

export const SEND_HOURLY_PAYMENT = 'SEND_HOURLY_PAYMENT';
export const SEND_HOURLY_PAYMENT_SUCCESS = 'SEND_HOURLY_PAYMENT_SUCCESS';
export const SEND_HOURLY_PAYMENT_FAILURE = 'SEND_HOURLY_PAYMENT_FAILURE';

export const GET_HOURLY_PAYMENT_HISTORY = 'GET_HOURLY_PAYMENT_HISTORY';
export const GET_HOURLY_PAYMENT_HISTORY_SUCCESS =
  'GET_HOURLY_PAYMENT_HISTORY_SUCCESS';
export const GET_HOURLY_PAYMENT_HISTORY_FAILURE =
  'GET_HOURLY_PAYMENT_HISTORY_FAILURE';

export const GET_ONETIME_PAYMENT_HISTORY = 'GET_ONETIME_PAYMENT_HISTORY';
export const GET_ONETIME_PAYMENT_HISTORY_SUCCESS =
  'GET_ONETIME_PAYMENT_HISTORY_SUCCESS';
export const GET_ONETIME_PAYMENT_HISTORY_FAILURE =
  'GET_ONETIME_PAYMENT_HISTORY_FAILURE';

//Settings
export const GET_WEEKLY_EMAIL_SETTINGS = 'GET_WEEKLY_EMAIL_SETTINGS';
export const GET_WEEKLY_EMAIL_SETTINGS_SUCCESS =
  'GET_WEEKLY_EMAIL_SETTINGS_SUCCESS';
export const GET_WEEKLY_EMAIL_SETTINGS_FAILURE =
  'GET_WEEKLY_EMAIL_SETTINGS_FAILURE';

export const UPDATE_WEEKLY_EMAIL_SETTINGS = 'UPDATE_WEEKLY_EMAIL_SETTINGS';
export const UPDATE_WEEKLY_EMAIL_SETTINGS_SUCCESS =
  'UPDATE_WEEKLY_EMAIL_SETTINGS_SUCCESS';
export const UPDATE_WEEKLY_EMAIL_SETTINGS_FAILURE =
  'UPDATE_WEEKLY_EMAIL_SETTINGS_FAILURE';

export const UPDATE_LOCATION_SETTINGS = 'UPDATE_LOCATION_SETTINGS';
export const UPDATE_LOCATION_SETTINGS_SUCCESS =
  'UPDATE_LOCATION_SETTINGS_SUCCESS';
export const UPDATE_LOCATION_SETTINGS_FAILURE =
  'UPDATE_LOCATION_SETTINGS_FAILURE';

export const UPDATE_GEOFENCE_SETTINGS = 'UPDATE_GEOFENCE_SETTINGS';
export const UPDATE_GEOFENCE_SETTINGS_SUCCESS =
  'UPDATE_GEOFENCE_SETTINGS_SUCCESS';
export const UPDATE_GEOFENCE_SETTINGS_FAILURE =
  'UPDATE_GEOFENCE_SETTINGS_FAILURE';

// Activity Settings
export const GET_SCREENSHOT_SETTINGS = 'GET_SCREENSHOT_SETTINGS';
export const GET_SCREENSHOT_SETTINGS_SUCCESS =
  'GET_SCREENSHOT_SETTINGS_SUCCESS';
export const GET_SCREENSHOT_SETTINGS_FAILURE =
  'GET_SCREENSHOT_SETTINGS_FAILURE';

export const UPDATE_SCREENSHOT_SETTINGS = 'UPDATE_SCREENSHOT_SETTINGS';
export const UPDATE_SCREENSHOT_SETTINGS_SUCCESS =
  'UPDATE_SCREENSHOT_SETTINGS_SUCCESS';
export const UPDATE_SCREENSHOT_SETTINGS_FAILURE =
  'UPDATE_SCREENSHOT_SETTINGS_FAILURE';

export const GET_MEMBERWISE_ACTIVITY_SETTINGS =
  'GET_MEMBERWISE_ACTIVITY_SETTINGS';
export const GET_MEMBERWISE_ACTIVITY_SETTINGS_SUCCESS =
  'GET_MEMBERWISE_ACTIVITY_SETTINGS_SUCCESS';
export const GET_MEMBERWISE_ACTIVITY_SETTINGS_FAILURE =
  'GET_MEMBERWISE_ACTIVITY_SETTINGS_FAILURE';

export const UPDATE_MEMBERWISE_ACTIVITY_SETTINGS =
  'UPDATE_MEMBERWISE_ACTIVITY_SETTINGS';
export const UPDATE_MEMBERWISE_ACTIVITY_SETTINGS_SUCCESS =
  'UPDATE_MEMBERWISE_ACTIVITY_SETTINGS_SUCCESS';
export const UPDATE_MEMBERWISE_ACTIVITY_SETTINGS_FAILURE =
  'UPDATE_MEMBERWISE_ACTIVITY_SETTINGS_FAILURE';

export const GET_IDLE_TIME_SETTINGS = 'GET_IDLE_TIME_SETTINGS';
export const GET_IDLE_TIME_SETTINGS_SUCCESS = 'GET_IDLE_TIME_SETTINGS_SUCCESS';
export const GET_IDLE_TIME_SETTINGS_FAILURE = 'GET_IDLE_TIME_SETTINGS_FAILURE';

export const UPDATE_IDLE_TIME_SETTINGS = 'UPDATE_IDLE_TIME_SETTINGS';
export const UPDATE_IDLE_TIME_SETTINGS_SUCCESS =
  'UPDATE_IDLE_TIME_SETTINGS_SUCCESS';
export const UPDATE_IDLE_TIME_SETTINGS_FAILURE =
  'UPDATE_IDLE_TIME_SETTINGS_FAILURE';

// Timesheet Settings
export const GET_TIMESHEET_SETTINGS = 'GET_TIMESHEET_SETTINGS';
export const GET_TIMESHEET_SETTINGS_SUCCESS = 'GET_TIMESHEET_SETTINGS_SUCCESS';
export const GET_TIMESHEET_SETTINGS_FAILURE = 'GET_TIMESHEET_SETTINGS_FAILURE';

export const UPDATE_TIMESHEET_SETTINGS = 'UPDATE_TIMESHEET_SETTINGS';
export const UPDATE_TIMESHEET_SETTINGS_SUCCESS =
  'UPDATE_TIMESHEET_SETTINGS_SUCCESS';
export const UPDATE_TIMESHEET_SETTINGS_FAILURE =
  'UPDATE_TIMESHEET_SETTINGS_FAILURE';

export const UPDATE_ORGANIZATION_TIMESHEET_APPROVAL_SETTINGS =
  'UPDATE_ORGANIZATION_TIMESHEET_APPROVAL_SETTINGS';
export const UPDATE_ORGANIZATION_TIMESHEET_APPROVAL_SETTINGS_SUCCESS =
  'UPDATE_ORGANIZATION_TIMESHEET_APPROVAL_SETTINGS_SUCCESS';
export const UPDATE_ORGANIZATION_TIMESHEET_APPROVAL_SETTINGS_FAILURE =
  'UPDATE_ORGANIZATION_TIMESHEET_APPROVAL_SETTINGS_FAILURE';

export const GET_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS =
  'GET_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS';
export const GET_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_SUCCESS =
  'GET_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_SUCCESS';
export const GET_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_FAILURE =
  'GET_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_FAILURE';

export const UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS =
  'UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS';
export const UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_SUCCESS =
  'UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_SUCCESS';
export const UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_FAILURE =
  'UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_FAILURE';

//Subscription Plan
export const GET_ORGANIZATION_DETAILS = 'GET_ORGANIZATION_DETAILS';
export const GET_ORGANIZATION_DETAILS_SUCCESS =
  'GET_ORGANIZATION_DETAILS_SUCCESS';
export const GET_ORGANIZATION_DETAILS_FAILURE =
  'GET_ORGANIZATION_DETAILS_FAILURE';

export const GET_CURRENT_SUBSCRIPTION_PLAN = 'GET_CURRENT_SUBSCRIPTION_PLAN';
export const GET_CURRENT_SUBSCRIPTION_PLAN_SUCCESS =
  'GET_CURRENT_SUBSCRIPTION_PLAN_SUCCESS';
export const GET_CURRENT_SUBSCRIPTION_PLAN_FAILURE =
  'GET_CURRENT_SUBSCRIPTION_PLAN_FAILURE';

export const SUBSCRIPTION_REQUEST = 'SUBSCRIPTION_REQUEST';
export const SUBSCRIPTION_REQUEST_SUCCESS = 'SUBSCRIPTION_REQUEST_SUCCESS';
export const SUBSCRIPTION_REQUEST_FAILURE = 'SUBSCRIPTION_REQUEST_FAILURE';

export const CHECK_EXISTING_SUBSCRIPTION = 'CHECK_EXISTING_SUBSCRIPTION';
export const CHECK_EXISTING_SUBSCRIPTION_SUCCESS =
  'CHECK_EXISTING_SUBSCRIPTION_SUCCESS';
export const CHECK_EXISTING_SUBSCRIPTION_FAILURE =
  'CHECK_EXISTING_SUBSCRIPTION_FAILURE';

export const CLEAR_EXISTING_SUBSCRIPTION = 'CLEAR_EXISTING_SUBSCRIPTION';

export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE';

//check payment applicable
export const CHECK_PAYMENT_APPLICABLE = 'CHECK_PAYMENT_APPLICABLE';
export const CHECK_PAYMENT_APPLICABLE_SUCCESS =
  'CHECK_PAYMENT_APPLICABLE_SUCCESS';
export const CHECK_PAYMENT_APPLICABLE_FAILURE =
  'CHECK_PAYMENT_APPLICABLE_FAILURE';

export const CLEAR_PAYMENT_APPLICABLE = 'CLEAR_PAYMENT_APPLICABLE';

//check discount
export const CHECK_DISCOUNT = 'CHECK_DISCOUNT';
export const CHECK_DISCOUNT_SUCCESS = 'CHECK_DISCOUNT_SUCCESS';
export const CHECK_DISCOUNT_FAILURE = 'CHECK_DISCOUNT_FAILURE';

// Subscription
export const GET_ORGANIZATION_SUBSCRIPTION = 'GET_ORGANIZATION_SUBSCRIPTION';
export const GET_ORGANIZATION_SUBSCRIPTION_SUCCESS =
  'GET_ORGANIZATION_SUBSCRIPTION_SUCCESS';
export const GET_ORGANIZATION_SUBSCRIPTION_FAILURE =
  'GET_ORGANIZATION_SUBSCRIPTION_FAILURE';

export const UPDATE_ORGANIZATION_SUBSCRIPTION =
  'UPDATE_ORGANIZATION_SUBSCRIPTION';
export const UPDATE_ORGANIZATION_SUBSCRIPTION_SUCCESS =
  'UPDATE_ORGANIZATION_SUBSCRIPTION_SUCCESS';
export const UPDATE_ORGANIZATION_SUBSCRIPTION_FAILURE =
  'UPDATE_ORGANIZATION_SUBSCRIPTION_FAILURE';

export const ACTIVATE_ORGANIZATION_SUBSCRIPTION =
  'ACTIVATE_ORGANIZATION_SUBSCRIPTION';
export const ACTIVATE_ORGANIZATION_SUBSCRIPTION_SUCCESS =
  'ACTIVATE_ORGANIZATION_SUBSCRIPTION_SUCCESS';
export const ACTIVATE_ORGANIZATION_SUBSCRIPTION_FAILURE =
  'ACTIVATE_ORGANIZATION_SUBSCRIPTION_FAILURE';

export const UPDATE_SUBSCRIPTION_PAYMENT_METHOD =
  'UPDATE_SUBSCRIPTION_PAYMENT_METHOD';
export const UPDATE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS =
  'UPDATE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS';
export const UPDATE_SUBSCRIPTION_PAYMENT_METHOD_FAILURE =
  'UPDATE_SUBSCRIPTION_PAYMENT_METHOD_FAILURE';

export const CLEAR_CURRENT_SUBSCRIPTION = 'CLEAR_CURRENT_SUBSCRIPTION';

export const CLEAR_SUBSCRIPTION_UPDATED = 'CLEAR_SUBSCRIPTION_UPDATED';

export const CLEAR_PAYMENT_METHOD_UPDATED = 'CLEAR_PAYMENT_METHOD_UPDATED';

export const APPLY_COUPON = 'APPLY_COUPON';
export const APPLY_COUPON_SUCCESS = 'APPLY_COUPON_SUCCESS';
export const APPLY_COUPON_FAILURE = 'APPLY_COUPON_FAILURE';

export const CLEAR_DISCOUNT_INFO = 'CLEAR_DISCOUNT_INFO';
export const CLEAR_DISCOUNT_ERROR_MESSAGE = 'CLEAR_DISCOUNT_ERROR_MESSAGE';

export const GET_MONAZIM_LIST = 'GET_MONAZIM_LIST';
export const GET_MONAZIM_LIST_SUCCESS = 'GET_MONAZIM_LIST_SUCCESS';
export const GET_MONAZIM_LIST_FAILURE = 'GET_MONAZIM_LIST_FAILURE';

export const TaskStatusOptions = [
  {
    value: null,
    key: 'all_status',
  },
  {
    value: 'new',
    key: 'new',
  },
  {
    value: 'assigned',
    key: 'assigned',
  },
  {
    value: 'cancelled',
    key: 'cancelled',
  },
  {
    value: 'partial',
    key: 'partial',
  },
  {
    value: 'missed',
    key: 'missed',
  },
  {
    value: 'completed',
    key: 'completed',
  },
];

export const RoleOptions = [
  {
    value: null,
    key: 'all_roles',
  },
  {
    value: 'admin',
    key: 'Admin',
  },
  {
    value: 'gm',
    key: 'GM',
  },
  {
    value: 'agm',
    key: 'AGM',
  },
  {
    value: 'monazim',
    key: 'Monazim',
  },
  {
    value: 'employee',
    key: 'Employee',
  },
];
