import EvidenceModal from 'custom_modules/Tasks/TaskDetails/EvidenceModal';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import avatar5 from '../../assets/img/avatar_5.svg';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent';
import InfoTooltip from '../../components/Tooltip/InfoTooltip';
import {
  CommonImage,
  CommonText,
  Container,
  RoundMiniImage,
  TableText,
} from '../../styledComponents/common';
import { MemberNameDiv } from '../../styledComponents/members';
import { TableItem } from '../../styledComponents/teams';
import { getHours, toHHMMSS } from '../../utils/helper';
import { CommImageNameComp } from '../Members/CommonComponents';
import { ClockInOutTableHeader, ClockInOutTableRow } from './clockInOutStyles';

const ClockInOutTable = props => {
  const { isLoading, history, clockInOutList } = props;
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const { t } = useTranslation();

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };
  return (
    <Container style={{ paddingBottom: '20px' }}>
      {selectedImage && (
        <EvidenceModal
          isOpen={popupOpen}
          toggle={popupToggle}
          evidence={selectedImage}
        />
      )}
      {isLoading ? (
        <ComponentCircleLoader />
      ) : clockInOutList && clockInOutList.length > 0 ? (
        <>
          <ClockInOutTableHeader>
            <TableItem>
              <TableText>{t('member_name')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('user_id')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('clock_in')}</TableText>
            </TableItem>
            <TableItem>
              {/* <InfoTooltip
                mainText={t('clock_out')}
                toolTipText="Clock out occurs when the user stops the timer at the end of the day"
                title
                label
                top="-55px"
                tooltipPadding="5px 8px"
                infoSize="17px"
              /> */}
              <TableText>{t('clock_out')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('office')}</TableText>
            </TableItem>
            {/* <TableItem>
              <TableText>{t('image')}</TableText>
            </TableItem> */}
          </ClockInOutTableHeader>

          {clockInOutList &&
            clockInOutList.length > 0 &&
            clockInOutList.map((instance, index) => {
              return (
                <ClockInOutTableRow key={index}>
                  <TableItem>
                    <MemberNameDiv>
                      <CommImageNameComp
                        imgSource={instance.employee.avatar || avatar5}
                        name={`${instance.employee.first_name}${
                          instance.employee.last_name
                            ? ` ${instance.employee.last_name}`
                            : ``
                        }`}
                        size="36px"
                      />
                    </MemberNameDiv>
                  </TableItem>
                  <TableItem>
                    <TableText name>
                      {instance.employee && instance.employee.user_code
                        ? instance.employee.user_code
                        : '-'}
                    </TableText>
                  </TableItem>
                  <TableItem>
                    <TableText name>
                      {instance.attendance && instance.attendance.clockIn
                        ? moment
                            .unix(instance.attendance.clockIn.timestamp)
                            .format('LT')
                        : '-'}
                    </TableText>
                  </TableItem>

                  <TableItem>
                    <TableText name>
                      {instance.attendance && instance.attendance.clockOut
                        ? moment
                            .unix(instance.attendance.clockOut.timestamp)
                            .format('LT')
                        : '-'}
                    </TableText>
                  </TableItem>
                  <TableItem>
                    <TableText name>
                      {instance.employee &&
                      instance.employee.office &&
                      instance.employee.office.name
                        ? instance.employee.office.name
                        : '-'}
                    </TableText>
                  </TableItem>
                  {/* <TableItem>
                    {instance.attendance.clockIn &&
                      instance.attendance.clockIn.selfie && (
                        <CommonImage
                          height="46px"
                          width="36px"
                          style={{ cursor: `pointer` }}
                          onClick={() => {
                            setSelectedImage(
                              instance.attendance.clockIn.selfie,
                            );
                            popupToggle();
                          }}
                          src={instance.attendance.clockIn.selfie}
                        />
                      )}
                  </TableItem> */}
                </ClockInOutTableRow>
              );
            })}
        </>
      ) : (
        <NoDataComponent
          title="No Attendance In This Day!"
          padding="5vh 0"
          imageHeight="140px"
        />
      )}
    </Container>
  );
};

export default ClockInOutTable;
