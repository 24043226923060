import styled from "styled-components";

export const NoPlanContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  justify-items: center;
  padding: 0 30px;
`;
export const NoPlanArtwork = styled.img`
  height: 185px;
`;
export const NoPlanMainText = styled.div`
  font-size: ${({ theme }) => theme.font.pageText.size};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  text-align: center;
`;
export const NoPlanSecondaryText = styled.div`
  font-size: 13px;
  text-align: center;
  color: ${({ theme }) => theme.colors.main.labelText};
  margin-top: 20px;
`;

export const PausedReasonsDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  grid-column-gap: 20px;
  border-radius: ${({ theme }) => theme.borderRadii.button};
  background-color: #f2f6fc;
  padding: 15px;
`;

export const PaymentInfoContainer = styled.div`
  margin: 0;
`;

// Trial End Bar
export const TrialEndBarWrapper = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5000;
`;
export const TrialEndBarContainer = styled.div`
  padding: 8px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ status, theme }) =>
    status === "suspended" || status === "cancelled" || status === "paused"
      ? theme.colors.main.error
      : theme.colors.main.textSecondary};
`;
export const TrialEndText = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.main.white};
`;

// change subscription current plan badge
export const SubscriptionCurrentPlanBadge = styled.div`
  display: ${({ isCurrent }) => (isCurrent ? `flex` : `none`)};
  justify-content: center;
  align-items: center;
  padding: 6px 12px 2px;
  border-radius: 12px 12px 0px 0px;
  background-color: ${({ plan, theme }) =>
    plan === "Elite"
      ? theme.colors.main.blueLight
      : plan === "Premium"
      ? "#f2f2fb"
      : plan === "Standard"
      ? theme.colors.main.playGrayLight
      : theme.colors.main.primaryLight};
  position: absolute;
  left: 20px;
  top: -25px;
`;
export const CurrentPlanDot = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: ${({ plan, theme }) =>
    plan === "Elite"
      ? theme.colors.main.blue
      : plan === "Premium"
      ? theme.colors.main.purpleMedium
      : plan === "Standard"
      ? theme.colors.main.planGray
      : theme.colors.main.primary};
`;
export const CurrentPlanBadgeText = styled.div`
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: #5d687e;
  margin-left: 5px;
`;

// discount
export const DiscountTextAndTagDiv = styled.div`
  display: flex;
  align-items: center;
`;
export const CouponAlert = styled.div`
  font-size: 13px;
  color: #f15762;
  margin-top: 10px;
`;

export const SeatedDiscountDiv = styled.div`
  border-radius: 6px;
  background-color: #fdf3e4;
  color: #f3a936;
  padding: 12px 30px;
  min-width: 600px;
  font-size: 14px;
  font-weight: 600;
  margin: 20px 30px 10px;
  width: fit-content;
`;

// yearly save badge
export const YearlySaveBadge = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -28px;
  left: -50px;
  padding: 3px 10px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ isActive }) => (isActive ? `#fdf3e4` : `#FFC107`)};
  background-color: ${({ isActive }) => (isActive ? `#FFC107` : `#fdf3e4`)};
  transform: rotate(-25deg);
  transition: all 0.12s ease;
  &::before,
  ::after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &::after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: ${({ isActive }) => (isActive ? `#FFC107` : `#fdf3e4`)};
    border-width: 6px;
    margin-left: -6px;
  }
  &::before {
    border-color: rgba(194, 204, 225, 0);
    border-top-color: ${({ isActive }) => (isActive ? `#FFC107` : `#fdf3e4`)};
    border-width: 7px;
    margin-left: -7px;
  }
`;

// secure payment section
export const SecurePaymentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SecurePaymentIcon = styled.img``;
