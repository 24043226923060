import { fontWeightConfig } from '../common';
import { colors } from './colors';

export const fontsConfiguration = {
  pageTitle: {
    size: '20px',
    weight: fontWeightConfig.semibold,
    color: colors.textMain,
    lineHeight: '34px',
  },
  pageTextImportantRegular: {
    size: '16px',
    weight: fontWeightConfig.regular,
    color: colors.textMain,
    lineHeight: '28px',
  },
  pageTextImportantSemibold: {
    size: '16px',
    weight: fontWeightConfig.semibold,
    color: colors.textMain,
    lineHeight: '28px',
  },
  pageText: {
    size: '14px',
    weight: fontWeightConfig.regular,
    colorMain: colors.textMain,
    colorSecondary: colors.textSecondary,
    colorLabel: colors.labelText,
    lineHeight: '28px',
  },
  cardTitle: {
    size: '18px',
    weight: fontWeightConfig.medium,
    color: colors.textMain,
    lineHeight: '34px',
  },
  inputLabel: {
    size: '12px',
    weight: fontWeightConfig.semibold,
    color: colors.labelText,
  },
  filterLabel: {
    size: '12px',
    weight: fontWeightConfig.semibold,
    color: colors.labelText,
  },
  otherLabel: {
    size: '13px',
    weight: fontWeightConfig.semibold,
    color: colors.labelText,
  },
  buttonText: {
    size: '14px',
    weight: fontWeightConfig.regular,
  },
  placeholder: {
    size: '13px',
    weight: fontWeightConfig.medium,
    color: colors.textSecondary,
  },
};
