import { useTheme } from "styled-components";
import {
  commonContainerStyles,
  commonControlStyles,
  commonOptionStyles,
  commonMenuListStyles,
  commonMenuStyles,
  commonSingleValueStyles,
  commonPlaceholderStyles,
  commonDropdownIndicatorStyles,
  commonInputStyles,
} from "./commonDropdownStyles";

const FilterDropDownStyle = (props) => {
  const theme = useTheme();
  return {
    container: (styles) => {
      return {
        ...commonContainerStyles(styles, theme),
      };
    },
    control: (styles, state) => {
      return {
        ...commonControlStyles(styles, state, theme),
        minHeight: props.height || "38px",
        minWidth: props.width || "230px",
      };
    },
    option: (styles, state) => {
      return {
        ...commonOptionStyles(styles, state, theme),
      };
    },
    menuList: (styles) => {
      return {
        ...commonMenuListStyles(styles, theme),
      };
    },
    menu: (styles, state) => {
      return {
        ...commonMenuStyles(styles, state, theme),
        minWidth: props.menuWidth || "230px",
      };
    },
    valueContainer: (styles) => {
      if (props.valuePadding) {
        return {
          ...styles,
          padding: props.valuePadding,
        };
      }
      return {
        ...styles,
      };
    },
    singleValue: (styles) => ({
      ...commonSingleValueStyles(styles, theme),
    }),
    placeholder: (styles) => ({
      ...commonPlaceholderStyles(styles, theme),
    }),
    indicatorsContainer: (styles) => {
      if (props.height && props.indicatorWidth) {
        return {
          ...styles,
          height: props.height,
          width: props.indicatorWidth,
        };
      }
      return {
        ...styles,
      };
    },
    dropdownIndicator: (styles, state) => {
      return {
        ...commonDropdownIndicatorStyles(styles, state, theme),
      };
    },
    input: (styles, state) => ({
      ...commonInputStyles(styles, state, theme),
    }),
  };
};

export default FilterDropDownStyle;
