export default [
  {
    type: "text",
    fieldName: "first_name",
    options: {
      required: true,
      minLength: 3,
      maxLength: 25
    }
  },
  {
    type: "text",
    fieldName: "last_name",
    options: {
      required: true,
      minLength: 3,
      maxLength: 25
    }
  },
  {
    type: "number",
    fieldName: "phone",
    options: {
      required: false,
      minLength: 3,
      maxLength: 64,
      phone: true
    }
  },
  {
    type: "select",
    fieldName: "timezone"
  }
];
