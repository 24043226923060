import { connect } from "react-redux";

import RouteMapFilter from "./RouteMapFilter";

import { getOrganizationMembersList } from "../../../Projects/projectsActions";
import { getOfficesShortList } from "../../../Offices/officesActions";
import {
  getRouteMapLocations,
  getSingleEmployeeClockInOutList,
  getTaskCheckInOutList,
} from "../routeMapActions";

const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  organizationMembersList: state.project.orgMembersList,
  memberShortListIsLoading: state.project.memberShortListIsLoading,
  officesShortList: state.office.officesShortList,
});

const mapDispatchToProps = (dispatch) => ({
  getRouteMapLocations: (payload) => dispatch(getRouteMapLocations(payload)),
  getOfficesShortList: (payload) => dispatch(getOfficesShortList(payload)),
  getOrganizationMembersList: (details) =>
    dispatch(getOrganizationMembersList({ ...details })),
  getSingleEmployeeClockInOutList: (payload) =>
    dispatch(getSingleEmployeeClockInOutList(payload)),
  getTaskCheckInOutList: (payload) => dispatch(getTaskCheckInOutList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouteMapFilter);
