//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../../utils/axiosApi";

//Constants
import {
  GET_LATEST_LOCATION_LIST,
  GET_LATEST_LOCATION_LIST_SUCCESS,
  GET_LATEST_LOCATION_LIST_FAILURE,
} from "../../../modules/constants";

export function* getLatestLocationListRequest({ payload }) {
  try {
    let url = `albait/latest-locations/?from=${payload.from}&to=${payload.to}`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_LATEST_LOCATION_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_LATEST_LOCATION_LIST_FAILURE,
      payload: err,
    });
  }
}

export default function* liveLocationSaga() {
  yield all([
    takeLatest(GET_LATEST_LOCATION_LIST, getLatestLocationListRequest),
  ]);
}
