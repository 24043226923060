import {
  GET_PUBLIC_API_KEY,
  GET_PUBLIC_API_KEY_SUCCESS,
  GET_PUBLIC_API_KEY_FAILURE,
  CREATE_PUBLIC_API_KEY,
  CREATE_PUBLIC_API_KEY_SUCCESS,
  CREATE_PUBLIC_API_KEY_FAILURE,
} from "../../modules/constants";

import { createNotification } from "../../modules/notificationManager";

// ------------------------------------
// Action handler methods
// ------------------------------------

export const getPublicApiHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const getPublicApiSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    apiKeyCode: data.data.api_key,
  };
};
export const getPublicApiFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    apiKeyCode: null,
  };
};

//CREATE API KEY CODE
export const createPublicApiHandler = (state, action) => {
  return {
    ...state,
    isCreateLoading: true,
  };
};
export const createPublicApiSuccessHandler = (state, action) => {
  const {
    payload: { data }} = action;
  if (data && data.status === 201) {
    return {
      ...state,
      isCreateLoading: false,
      apiKeyCode: data.data.api_key,
    };
  }
  return {
    ...state,
    isCreateLoading: false,
    apiKeyCode: null,
  };
};
export const createPublicApiFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification(
      "error",
      "No internet connection found. Please connect to the internet.",
      3000
    );
    return {
      ...state,
      isCreateLoading: false,
      apiKeyCode: null,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification(
      "error",
      "Sorry! could not get API Key Code. Please try again.",
      2000
    );
  }
  return {
    ...state,
    isCreateLoading: false,
    apiKeyCode: null,
  };
};

const ACTION_HANDLERS = {
  [GET_PUBLIC_API_KEY]: getPublicApiHandler,
  [GET_PUBLIC_API_KEY_SUCCESS]: getPublicApiSuccessHandler,
  [GET_PUBLIC_API_KEY_FAILURE]: getPublicApiFailureHandler,
  [CREATE_PUBLIC_API_KEY]: createPublicApiHandler,
  [CREATE_PUBLIC_API_KEY_SUCCESS]: createPublicApiSuccessHandler,
  [CREATE_PUBLIC_API_KEY_FAILURE]: createPublicApiFailureHandler,
}

// default initial state
const initialState = {
  isLoading: false,
  isCreateLoading: false,
  apiKeyCode: null,
};

export default function publicApiReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}