export default [
  {
    type: "password",
    fieldName: "old_password",
    options: {
      required: true,
      minLength: 8,
      maxLength: 32
    }
  },
  {
    type: "password",
    fieldName: "new_password",
    options: {
      required: true,
      minLength: 8,
      maxLength: 32
    }
  },
  {
    type: "password",
    fieldName: "confirm_password",
    options: {
      required: true,
      minLength: 8,
      maxLength: 32
    }
  }
];
