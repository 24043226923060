import ReactDateRangePicker from 'components/ReactDateRangePicker/ReactDateRangePicker';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AddSingleMemberDropdown from '../../../components/CustomDropdown/AddSingleMemberDropdown';
import CreateProjectDropdown from '../../../components/DropdownStyle/CreateProjectDropdown';
import Select from '../../../components/ReactSelectDropdown';
import { filterOption } from '../../../components/ReactSelectDropdown/ReactSelectDropdown';
import SearchWithButton from '../../../components/Search/SearchWithButton';
import DatePicker from '../../../components/SingleDatePicker/SingleDatePicker';
import { TabButton } from '../../../styledComponents/buttons';
import {
  FilterLabel,
  InputWithLabelSection,
} from '../../../styledComponents/common';
import { TaskFilterContainer } from '../../../styledComponents/taskApproval';
import {
  TabButtonContainer,
  TabSearchGrid,
} from '../../../styledComponents/taskApproval';

const TaskFilter = props => {
  const { t } = useTranslation();
  const [officeOptions, setOfficeOptions] = useState([]);

  const {
    getOrganizationMembersList,
    organizationMembersList,
    getOfficesShortList,
    officesShortList,
    selectedOffice,
    selectOffice,
    selectedMember,
    clearMember,
    selectedDate,
    handleDateChange,
    handleSearchByName,
    handleClearSearch,
    searchTerm,
    onSearchTermChange,
    handleSelectedTab,
    selectedTab,
  } = props;
  const [selectedDates, setSelectedDates] = useState(selectedDate);
  const selectLastMonth = () => {
    setSelectedDates({
      startDate: moment().subtract(29, 'days'),
      endDate: moment(),
    });
  };
  const selectLastWeek = () => {
    setSelectedDates({
      startDate: moment().subtract(7, 'days'),
      endDate: moment(),
    });
  };
  const onDateApply = () => {
    handleDateChange(selectedDates);
  };

  useEffect(() => {
    getOfficesShortList();
    getOrganizationMembersList({ office_id: null });
  }, []);

  useEffect(() => {
    if (officesShortList && officesShortList.length === 1) {
      getOrganizationMembersList({ office_id: officesShortList[0].id });
    }
    if (officesShortList && officesShortList.length > 1) {
      let newArr = [
        {
          label: t('all_offices'),
          value: null,
        },
      ];
      officesShortList.forEach((office, i) => {
        newArr.push({
          label: office.name,
          value: office.id,
        });
      });
      setOfficeOptions(newArr);
    }
  }, [officesShortList]);

  useEffect(() => {
    if (
      selectedOffice &&
      selectedOffice.value &&
      officesShortList &&
      officesShortList.length > 1
    ) {
      getOrganizationMembersList({ office_id: selectedOffice.value });
      console.log(selectedOffice);
    }
  }, [selectedOffice]);

  const onMemberSelect = person => {};

  return (
    <TaskFilterContainer columns="auto 150px 240px 310px">
      <TabSearchGrid>
        <InputWithLabelSection>
          <FilterLabel>{t('type')}</FilterLabel>
          <TabButtonContainer columns="auto auto auto">
            <TabButton
              selected={selectedTab}
              index={'submitted'}
              onClick={() => handleSelectedTab('submitted')}
            >
              {t('submitted')}
            </TabButton>
            <TabButton
              selected={selectedTab}
              index={'approved'}
              onClick={() => handleSelectedTab('approved')}
            >
              {t('approved')}
            </TabButton>
            <TabButton
              selected={selectedTab}
              index={'rejected'}
              onClick={() => handleSelectedTab('rejected')}
            >
              {t('rejected')}
            </TabButton>
          </TabButtonContainer>
        </InputWithLabelSection>
      </TabSearchGrid>
      <InputWithLabelSection>
        <FilterLabel>{t('office')}</FilterLabel>
        <Select
          isSearchable
          value={selectedOffice}
          options={officeOptions}
          placeholder="Select Office..."
          onChange={e => {
            selectOffice(e);
          }}
          styles={CreateProjectDropdown()}
          filterOption={filterOption}
        />
      </InputWithLabelSection>
      {/* <InputWithLabelSection>
        <FilterLabel>{t("assignee")}</FilterLabel>
        <div style={{ width: `40px` }}>
          <AddSingleMemberDropdown
            assigneeManage={selectedMember}
            updateState={onMemberSelect}
            membersList={organizationMembersList}
            clearAssignee={() => clearMember()}
          />
        </div>
      </InputWithLabelSection> */}
      <SearchWithButton
        searchTermName={t('ticket_name')}
        searchInput={searchTerm}
        onInputChange={onSearchTermChange}
        handleSearch={handleSearchByName}
        handleClearSearch={handleClearSearch}
      />
      <InputWithLabelSection>
        <FilterLabel>{t('date_range')}</FilterLabel>
        {/* <DatePicker
          id="createIssuedDate"
          date={selectedDate}
          onDateChange={handleDateChange}
          dateDisplayFormat={'DD/MM/YYYY'}
          placeholder={'Select date'}
          allowPreviousDates
        /> */}
        <ReactDateRangePicker
          startDate={selectedDates.startDate ? selectedDates.startDate : null}
          startDateId="custom-date-range-start-date"
          endDate={selectedDates.endDate ? selectedDates.endDate : null}
          endDateId="custom-date-range-start-date"
          onDatesChange={setSelectedDates}
          dateDisplayFormat="DD/MM/YYYY"
          onDateSelect={onDateApply}
          selectLastWeek={selectLastWeek}
          selectLastMonth={selectLastMonth}
        />
      </InputWithLabelSection>
    </TaskFilterContainer>
  );
};

export default TaskFilter;
