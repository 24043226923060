import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import officeIcon from '../../assets/img/icons/black-site-icon.svg';
import deleteWhiteIcon from '../../assets/img/icons/delete_white.svg';
import editWhiteIcon from '../../assets/img/icons/edit-white.svg';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import SearchWithButton from '../../components/Search/SearchWithButton';
import { PrimaryButton } from '../../styledComponents/buttons';
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonImage,
  CommonText,
  InputField,
} from '../../styledComponents/common';
import {
  DeleteButton,
  EditButton,
  HoveredSection,
  ListHeaderContainer,
  ListItemsContainer,
  NoDataArtwork,
  NoDataContainer,
  OfficesListContainer,
  SingleSiteItem,
} from '../FieldServices/JobSites/jobSitesStyles';
import {
  InfoMapSection,
  InfoSection,
  Tabsection,
  TitleTag,
} from './officesStyles';

const OfficeList = props => {
  const [hoveredId, setHoveredId] = useState(null);

  const [list, setList] = useState([]);

  const { t } = useTranslation();

  const {
    handleChangePage,
    officesList,
    selectedOffice,
    selectOffice,

    isLoading,
    toggleDeleteModal,
    searchTerm,
    onSearchTermChange,
  } = props;

  useEffect(() => {
    if (officesList && officesList.length > 0) {
      setList(officesList);
    } else if (officesList && officesList.length === 0) {
      setList([]);
    }
  }, [officesList]);

  useEffect(() => {
    if (
      searchTerm &&
      searchTerm !== '' &&
      officesList &&
      officesList.length > 0
    ) {
      const originalList = JSON.parse(JSON.stringify(officesList));
      const filteredList = [];
      originalList.forEach(e => {
        if (
          searchTerm &&
          e.name.toLowerCase().indexOf(searchTerm.toLowerCase().trim()) === -1
        ) {
          return;
        }
        filteredList.push(e);
      });
      setList(filteredList);
    } else if (searchTerm === '') {
      setList(officesList);
    }
  }, [searchTerm]);

  const onHoverChange = id => {
    setHoveredId(id);
  };

  return (
    <div>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : officesList && officesList.length > 0 ? (
        <>
          <InfoSection>
            <CommonGrid margin="0 0 20px">
              <CommonText name title fontSize="14px">
                {t('offices')}
              </CommonText>
              {localStorage.getItem('user_role') === 'admin' &&
              !localStorage.getItem('viewer_role') ? (
                <ColoredText
                  fontSize="13px"
                  type="success"
                  hover
                  semibold
                  style={{ cursor: `pointer` }}
                  onClick={() => handleChangePage('details', null)}
                >
                  {t('create_office')}
                </ColoredText>
              ) : null}
            </CommonGrid>
            {/* <SearchWithButton
              itemName={t("office")}
              searchTermName={t("search_office")}
              searchInput={searchTerm}
              onInputChange={onSearchTermChange}
              handleSearch={handleSearchByName}
              handleClearSearch={handleClearSearch}
            /> */}
            <InputField
              placeholder={t('search_office')}
              value={searchTerm}
              onChange={onSearchTermChange}
            />
          </InfoSection>

          <ListItemsContainer>
            {list.map((office, index) => {
              return (
                <SingleSiteItem
                  key={office.id}
                  isActive={
                    selectedOffice &&
                    selectedOffice.id &&
                    selectedOffice.id === office.id
                  }
                  onClick={() => selectOffice(office)}
                  onMouseEnter={() => onHoverChange(office.id)}
                  onMouseLeave={() => onHoverChange(null)}
                >
                  <CommonFlex justifyContent="space-between">
                    <CommonFlex gap="10px">
                      <CommonImage src={officeIcon} alt="" size="35px" />
                      <CommonText fontWeight="600">{office.name}</CommonText>
                    </CommonFlex>
                    {/* <CommonImage
                      src={editIcon}
                      alt="edit"
                      style={{ zIndex: "2", cursor: "pointer" }}
                      onClick={() => handleChangePage("details", office)}
                    /> */}
                  </CommonFlex>
                  <CommonText $label fontWeight="500" margin="5px 0 0 45px">
                    {office.address}
                  </CommonText>
                  {localStorage.getItem('user_role') === 'admin' &&
                  !localStorage.getItem('viewer_role') &&
                  hoveredId &&
                  hoveredId === office.id ? (
                    <HoveredSection>
                      <EditButton
                        onClick={() => handleChangePage('details', office)}
                      >
                        <CommonImage src={editWhiteIcon} alt="" size="26px" />
                      </EditButton>
                      {/* <DeleteButton
                        onClick={() => toggleDeleteModal(office.id)}
                      >
                        <CommonImage src={deleteWhiteIcon} alt="" size="16px" />
                      </DeleteButton> */}
                    </HoveredSection>
                  ) : null}
                </SingleSiteItem>
              );
            })}
          </ListItemsContainer>
        </>
      ) : (
        <NoDataContainer>
          <CommonImage src={officeIcon} alt="" size="35px" />
          <CommonText fontSize="15px" fontWeight="600" margin="20px 0 0">
            No Offices!
          </CommonText>
          <CommonText $label fontSize="13px" fontWeight="500" margin="15px 0 0">
            Add your very first office
          </CommonText>
          <PrimaryButton
            margin="30px 0 0"
            onClick={() => handleChangePage('details', null)}
          >
            {t('create_office')}
          </PrimaryButton>
        </NoDataContainer>
      )}
    </div>
  );
};

export default OfficeList;
