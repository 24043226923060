import styled from "styled-components";
import { Modal, ModalBody } from "reactstrap";

//Profile Picture
export const PictureDiv = styled.div`
  display: grid;
  grid-template-columns: 95px 70%;
  align-items: center;
`;
export const AvatarDiv = styled.div`
  max-width: 95px;
`;
export const PictureParagraph = styled.div`
  color: ${({ theme }) => theme.colors.main.textSecondary};
  margin-left: 20px;
`;

//Delete Component
export const DeleteDiv = styled.div`
  display: grid;
  grid-template-columns: 9fr 2fr;
  grid-gap: 2em;
  align-items: center;
  @media (max-width: 496px) {
    grid-template-columns: 80%;
    align-items: start;
  }
`;

//ORG Component
export const OrgDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 12px;
  align-items: center;
  margin-top: 20px;
`;
export const ProfileOrgDiv = styled.div`
  background-color: ${({ bgColor, theme }) =>
    bgColor || theme.colors.main.blue};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 46px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};

  color: ${({ theme }) => theme.colors.main.white};
`;

// Main Edit Body
export const EmailValue = styled.div`
  background: ${({ theme }) => theme.colors.main.whiteBg};
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.input.borderRadius};
  font-size: ${({ theme }) => theme.input.fontSize};
  color: ${({ color, theme }) => color || theme.colors.main.textSecondary};
  margin: ${({ margin }) => margin || "0"};
  max-width: 300px;
  min-height: 38px;
  padding-left: 10px;
  display: flex;
  align-items: center;
`;
export const ProfileGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-gap: 12px 20px;
  align-items: ${({ alignItems }) => alignItems || "center"};
  max-width: ${({ emailOption, addemailOption }) =>
    emailOption ? "200px" : addemailOption ? "225px" : "600px"};
  margin: ${({ margin }) => margin || "0"};
  @media (max-width: 700px) {
    grid-template-columns: 80%;
    margin-bottom: 25px;
    & input {
      margin-bottom: 0px;
    }
  }
`;
export const EmailDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(100%);
  grid-gap: 2em;
  align-items: center;
`;
export const AddEmailDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(100%);
`;

export const DefaultEmailBadge = styled.div`
  height: 38px;
  width: 110px;
  padding: 0 10px;
  background: ${({ theme }) => theme.colors.main.primaryLight};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  font-size: 13px;
  color: ${({ theme }) => theme.colors.main.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  }
`;
export const EmailOptionButton = styled.button`
  background: ${({ theme }) => theme.colors.main.white};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: ${({ theme }) => theme.button.borderRadius};
  width: 110px;
  height: 38px;
  color: ${({ theme }) => theme.colors.main.labelText};
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    border-color: ${({ theme, deleteEmail }) =>
      deleteEmail ? theme.colors.main.error : theme.colors.main.primary};
    color: ${({ theme, deleteEmail }) =>
      deleteEmail ? theme.colors.main.error : theme.colors.main.primary};
  }
`;

//Delete Accounts

export const ProfileModal = styled(Modal)`
  max-width: 600px;
  margin-top: ${({ marginTop }) => marginTop || "27vh"};
`;
export const ProfileModalBody = styled(ModalBody)`
  padding: 30px 30px 15px;
`;
export const ModalDiv = styled.div`
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: repeat(100%);
  grid-gap: ${({ email }) => (email ? "none" : "20px")};
  align-items: center;
`;
export const Label = styled.label`
  text-align: left;
  color: #2f394e;
  opacity: 0.7;
  font-size: 14px !important;
`;
export const CheckBoxLabel = styled.input`
  height: 14px;
  margin-right: 5px;
  margin-top: 5px;
  background: #20bead;
  border-radius: 4px;
  opacity: 1;
`;

export const FormFeedback = styled.span`
  width: 100%;
  margin-top: 5px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.main.error};
`;

// upload image
export const NoImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  margin-top: -4px;
  cursor: pointer;
`;
export const CameraIcon = styled.img`
  height: 20px;
  width: 20px;
`;
export const UpdateImageHoverDiv = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
`;
export const UploadImageDiv = styled.div`
  &:hover ${UpdateImageHoverDiv} {
    display: flex;
  }
  &:hover {
    cursor: pointer;
    ${CameraIcon} {
      filter: brightness(0) saturate(100%) invert(87%) sepia(46%)
        saturate(3846%) hue-rotate(190deg) brightness(133%) contrast(107%);
    }
  }
`;
export const DropZoneContainer = styled.div``;

export const NotVerified = styled.div`
  background: ${({ theme }) => theme.colors.main.white};
  border: 1px solid ${({ theme }) => theme.colors.main.warning};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  display: grid;
  padding: 0 5px;
  grid-template-columns: 13px auto;
  grid-column-gap: 5px;
  width: 110px;
  height: 38px;
  align-items: center;
  justify-content: center;
`;
export const AlertIcon = styled.img`
  width: 13px;
`;
export const Linediv = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  box-sizing: none;
  width: 111%;
  margin-left: -29px;
`;
