import styled, { css } from 'styled-components';

export const SelectedMonazimWrapper = styled.div`
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(228, 231, 235);
  color: rgb(45, 44, 44);
  font-size: 13px;
  padding: 5px 10px;
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  &:hover {
    border-color: rgba(67, 67, 67, 0.7);
    box-shadow: rgb(245, 245, 245) 0px 0px 0px 4px;
  }
`;

export const HoverContainer = styled.div`
  position: relative;
  &:hover .hover-content {
    visibility: visible;
    opacity: 1;
  }
`;

export const HiddenContent = styled.div`
  position: absolute;
  border: 1px solid;
  padding: 6px 8px;
  border-radius: 5px;
  z-index: 5;
  background-color: white;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s;
  max-height: 150px;
  overflow-y: auto;
`;
