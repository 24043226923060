import { useTheme } from "styled-components";
import {
  commonControlStyles,
  commonOptionStyles,
  commonMenuListStyles,
  commonMenuStyles,
  commonSingleValueStyles,
  commonPlaceholderStyles,
  commonDropdownIndicatorStyles,
  commonInputStyles,
} from "./commonDropdownStyles";

const DropdownStyles = () => {
  const theme = useTheme();
  return {
    control: (styles, state) => {
      return {
        ...commonControlStyles(styles, state, theme),
        height: "45px",
        paddingLeft: "10px",
      };
    },
    option: (styles, state) => {
      return {
        ...commonOptionStyles(styles, state, theme),
        fontSize: "15px",
      };
    },
    menuList: (styles) => {
      return {
        ...commonMenuListStyles(styles, theme),
      };
    },
    menu: (styles, state) => {
      return {
        ...commonMenuStyles(styles, state, theme),
        textAlign: "left",
      };
    },
    singleValue: (styles) => ({
      ...commonSingleValueStyles(styles, theme),
      fontSize: "15px",
    }),
    placeholder: (styles) => ({
      ...commonPlaceholderStyles(styles, theme),
      fontSize: "15px",
    }),
    dropdownIndicator: (styles, state) => {
      return {
        ...commonDropdownIndicatorStyles(styles, state, theme),
      };
    },
    input: (styles, state) => ({
      ...commonInputStyles(styles, state, theme),
      fontSize: "15px",
    }),
  };
};

export default DropdownStyles;
