import { components } from "react-select";

import DropdownLoader from "..//DropdownLoader/DropdownLoader";

export const CustomMenuList = (props) => {
  return (
    <components.MenuList {...props}>
      {props.children}
      {props.selectProps.isLoading && <DropdownLoader loading size={6} />}
    </components.MenuList>
  );
};
