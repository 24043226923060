import {
  ADD_SUB_TASK,
  ADD_TASK_LOCATION,
  CREATE_TASK,
  DELETE_SUB_TASK,
  DELETE_TASK,
  DUPLICATE_TASK,
  EDIT_SUB_TASK,
  EDIT_TASK,
  GET_TASKS_LIST,
  GET_TASK_DETAILS,
  GET_TASK_LOCATIONS,
  GET_TASK_TYPE,
  REMOVE_TASK_ASSIGNEE,
  UPDATE_PARTIAL_TASK,
  UPDATE_TASK_ASSIGNEE,
  UPDATE_TASK_STATUS,
} from '../../modules/constants';

export function getTasksList(value) {
  return {
    type: GET_TASKS_LIST,
    payload: value,
  };
}

export function createTask(value) {
  return {
    type: CREATE_TASK,
    payload: value,
  };
}

export function getTaskDetails(value) {
  return {
    type: GET_TASK_DETAILS,
    payload: value,
  };
}

export function editTask(value) {
  return {
    type: EDIT_TASK,
    payload: value,
  };
}

export function updatePartialTask(value) {
  return {
    type: UPDATE_PARTIAL_TASK,
    payload: value,
  };
}

export function updateTaskAssignee(value) {
  return {
    type: UPDATE_TASK_ASSIGNEE,
    payload: value,
  };
}

export function removeTaskAssignee(value) {
  return {
    type: REMOVE_TASK_ASSIGNEE,
    payload: value,
  };
}

export function addSubTask(value) {
  return {
    type: ADD_SUB_TASK,
    payload: value,
  };
}

export function editSubTask(value) {
  return {
    type: EDIT_SUB_TASK,
    payload: value,
  };
}

export function deleteSubTask(value) {
  return {
    type: DELETE_SUB_TASK,
    payload: value,
  };
}

export function duplicateTask(value) {
  return {
    type: DUPLICATE_TASK,
    payload: value,
  };
}

export function deleteTask(value) {
  return {
    type: DELETE_TASK,
    payload: value,
  };
}

export function getTaskLocation(value) {
  return {
    type: GET_TASK_LOCATIONS,
    payload: value,
  };
}

export function updateTaskApproval(value) {
  return {
    type: UPDATE_TASK_STATUS,
    payload: value,
  };
}
export function addTaskLocation(value) {
  return {
    type: ADD_TASK_LOCATION,
    payload: value,
  };
}

export function getTaskType(value) {
  return {
    type: GET_TASK_TYPE,
    payload: value,
  };
}
