import styled from 'styled-components';

import { RoundImageTooltip } from '../../styledComponents/createProject';

const TooltipText = styled.p`
  margin: 0;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.main.white};
  line-height: 1.8;
`;

const AssigneeTooltip = props => {
  const { toolTipText, phone, width, height, top, left, minCharToShow } = props;
  return (
    <RoundImageTooltip
      customWidth={width || '150px'}
      customHeight={height || '55px'}
      isAssignee
      top={top || '-55px'}
      left={left || '-45px'}
    >
      <TooltipText>
        {toolTipText.length < 25 ||
        (minCharToShow && minCharToShow > toolTipText.length)
          ? toolTipText
          : `${toolTipText.slice(0, minCharToShow || 18)}...`}
      </TooltipText>
      {phone && <TooltipText>{phone}</TooltipText>}
    </RoundImageTooltip>
  );
};

export default AssigneeTooltip;
