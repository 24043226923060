import Footer from 'components/Footer/Footer.jsx';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import routes from 'routes.js';

import AdminNavbar from '../../custom_modules/AdminNavbar';
// import Sidebar from "components/Sidebar/Sidebar.jsx";
import Sidebar from '../../custom_modules/Sidebar';
import { PrivateRoute } from '../../modules/privateRoute';

const Admin = props => {
  const [sidebarMini, setSidebarMini] = useState(false);
  const [navOpen, setNavOpen] = useState(false);

  const adminHistory = useHistory();
  const wrapperRef = useRef(null);

  const { selectedOrganization } = props;

  useEffect(() => {
    // if (localStorage.getItem('is_owner')) {
    if (
      localStorage.getItem('user_role') &&
      localStorage.getItem('user_role') !== 'employee'
    ) {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = '8d702cbe-da60-4e19-8596-efde2eed4d83';
      (function () {
        var d = document;
        var s = d.createElement('script');
        s.src = 'https://client.crisp.chat/l.js';
        s.async = 1;
        d.getElementsByTagName('head')[0].appendChild(s);
      })();
      if (localStorage.getItem('user_fullName')) {
        window.$crisp.push([
          'set',
          'user:nickname',
          localStorage.getItem('user_fullName'),
        ]);
      }
      if (localStorage.getItem('email')) {
        window.$crisp.push([
          'set',
          'user:email',
          localStorage.getItem('email'),
        ]);
      }
    }
    // }
  }, []);

  useEffect(() => {
    if (
      selectedOrganization &&
      selectedOrganization.plan &&
      selectedOrganization.plan.id
    ) {
      localStorage.setItem(
        'selected_organization_plan',
        selectedOrganization.plan.name,
      );
    }
  }, [selectedOrganization]);

  useEffect(() => {
    return adminHistory.listen((location, action) => {
      if (action && action === 'PUSH') {
        if (wrapperRef && wrapperRef.current) {
          document.documentElement.scrollTop = 0;
          document.scrollingElement.scrollTop = 0;
          wrapperRef.current.scrollTop = 0;
        }
      }
    });
  }, [adminHistory]);

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/user') {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const handleMiniClick = () => {
    if (document.body.classList.contains('sidebar-mini')) {
      setSidebarMini(false);
    } else {
      setSidebarMini(true);
    }
    document.body.classList.toggle('sidebar-mini');
  };
  const handleNavOpen = () => {
    setNavOpen(!navOpen);
  };
  const clearNavOpen = () => {
    setNavOpen(false);
  };

  return (
    <div className="wrapper" ref={wrapperRef}>
      <Sidebar
        {...props}
        routes={routes}
        handleMiniClick={handleMiniClick}
        sidebarMini={sidebarMini}
        navOpen={navOpen}
        clearNavOpen={clearNavOpen}
      />
      <div className="main-panel">
        <AdminNavbar
          sidebarState={sidebarMini}
          {...props}
          handleMiniClick={handleMiniClick}
          handleNavOpen={handleNavOpen}
        />
        <Switch>{getRoutes(routes)}</Switch>
        {/* {// we don't want the Footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf("full-screen-map") !==
            -1 ? null : (
              <Footer fluid />
            )} */}
      </div>
    </div>
  );
};

export default Admin;
