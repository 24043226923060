// Effects
import { all, call, put, takeLatest } from "redux-saga/effects";

// Service
import { defaultApi } from "../../utils/axiosApi";
// Constants
import {
  GET_PUBLIC_API_KEY,
  GET_PUBLIC_API_KEY_SUCCESS,
  GET_PUBLIC_API_KEY_FAILURE,
  CREATE_PUBLIC_API_KEY,
  CREATE_PUBLIC_API_KEY_SUCCESS,
  CREATE_PUBLIC_API_KEY_FAILURE,
} from "../../modules/constants";

//Handle login request

export function* getPublicApiKeyCode({ payload }) {
  const { organization_id, page, name } = payload;
  try {
    const url = `${organization_id}/public-api/`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_PUBLIC_API_KEY_SUCCESS,
      payload: { data: response, notify: "GET" },
    });
  } catch (err) {
    yield put({
      type: GET_PUBLIC_API_KEY_FAILURE,
      payload: err,
    });
  }
}

export function* createPublicApiKeyCode({ payload }) {
  try {
    const { organization_id } = payload;
    const url = `${organization_id}/public-api/`;
    const method = "POST";
    const response = yield defaultApi(url, method, {});
    yield put({
      type: CREATE_PUBLIC_API_KEY_SUCCESS,
      payload: { data: response, notify: "POST" },
    });
  } catch (err) {
    yield put({
      type: CREATE_PUBLIC_API_KEY_FAILURE,
      payload: err,
    });
  }
}


export default function* root() {
  yield all([
    takeLatest(GET_PUBLIC_API_KEY, getPublicApiKeyCode),
    takeLatest(CREATE_PUBLIC_API_KEY, createPublicApiKeyCode),
  ]);
}