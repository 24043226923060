import { connect } from "react-redux";

// Component
import ForgotPassword from "./ForgotPassword";
// Actions
import {
  forgotPassword,
  clearForgotPasswordLinkSent,
} from "./forgotPasswordActions";

// Store props to map with current state
const mapStateToProps = (state) => ({ forgot: state.forgot });

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (details) => dispatch(forgotPassword({ ...details })),
  clearForgotPasswordLinkSent: () => dispatch(clearForgotPasswordLinkSent()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
