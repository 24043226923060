import moment from "moment";

import {
  TrialEndBarWrapper,
  TrialEndBarContainer,
  TrialEndText,
} from "../subscriptionStyles";
import {
  ColoredButtonWithIcon,
  ButtonIcon,
} from "../../../styledComponents/buttons";

import crown from "../../../assets/img/common/crown.svg";

const TrialEndBar = (props) => {
  return (
    <TrialEndBarWrapper show>
      <TrialEndBarContainer status={props.subscriptionStatus}>
        <TrialEndText>
          {props.subscriptionStatus === "paused"
            ? `Your current subscription is paused. Organization ${
                props.orgName && `"${props.orgName}"`
              } is archived.`
            : props.subscriptionStatus === "suspended"
            ? `${
                props.trialEndDate &&
                moment(props.trialEndDate).isBefore(moment(), "day")
                  ? `Your trial ended on ${moment(props.trialEndDate).format(
                      "MMMM D, YYYY"
                    )}.`
                  : ``
              } Organization ${
                props.orgName && `"${props.orgName}"`
              } is archived.`
            : props.subscriptionStatus === "cancelled"
            ? `Your current subscription is cancelled. Organization ${
                props.orgName && `"${props.orgName}"`
              } is archived.`
            : `Your trial is ending on ${
                props.trialEndDate &&
                moment(props.trialEndDate).format("MMMM D, YYYY")
              }`}
        </TrialEndText>
        <ColoredButtonWithIcon
          height="34px"
          margin="0 0 0 15px"
          padding="0 20px"
          type={
            (props.subscriptionStatus === "suspended" ||
              props.subscriptionStatus === "paused" ||
              props.subscriptionStatus === "cancelled") &&
            "black"
          }
          status={props.subscriptionStatus}
          onClick={() => {
            if (props.subscriptionStatus === "paused") {
              props.history.push("/user/current-plan");
            } else {
              props.history.push("/user/subscription");
            }
          }}
        >
          {props.subscriptionStatus !== "paused" && (
            <ButtonIcon src={crown} alt="" size="17px" />
          )}

          {props.subscriptionStatus === "paused" ? "View Details" : "UPGRADE"}
        </ColoredButtonWithIcon>
      </TrialEndBarContainer>
    </TrialEndBarWrapper>
  );
};

export default TrialEndBar;
