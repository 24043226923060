import { colors, hoverColors, borderColors } from "./colors";
import { radiusConfig, fontWeightConfig } from "../common";

export const buttonsConfiguration = {
  borderRadius: radiusConfig.button,
  fontSize: "13px",
  height: "40px",
  primary: {
    bgColor: colors.primary,
    textColor: colors.white,
    fontWeight: fontWeightConfig.semibold,
    hoverBgColor: hoverColors.primary,
    disabledBgColor: colors.primaryDisabled,
    height: "40px",
  },
  secondary: {
    bgColor: colors.white,
    borderColor: borderColors.primary,
    textColor: colors.textMain,
    hoverBgColor: colors.primary,
    hoverTextColor: colors.white,
    fontWeight: fontWeightConfig.regular,
    height: "40px",
  },

  // white
  cancel: {
    bgColor: colors.white,
    borderColor: borderColors.secondaryLight,
    textColor: colors.textSecondary,
    fontWeight: fontWeightConfig.regular,
    hoverBorderColor: borderColors.secondary,
  },

  deleteOutline: {
    bgColor: colors.white,
    textColor: colors.error,
    fontWeight: fontWeightConfig.regular,
    borderColor: borderColors.secondaryLight,
    hoverBorderColor: colors.error,
  },

  // colored
  deleteFilled: {
    bgColor: colors.error,
    textColor: colors.white,
    hoverBgColor: hoverColors.error,
    disabledBgColor: colors.errorDisabled,
  },
  blueButton: {
    bgColor: colors.blue,
    textColor: colors.white,
    fontWeight: fontWeightConfig.semibold,
    hoverBgColor: hoverColors.blue,
  },
  grayButton: {
    // mark as paid
    bgColor: colors.gray,
    textColor: colors.white,
    hoverBgColor: hoverColors.gray,
  },
  grayMediumButton: {
    // save as draft
    bgColor: colors.grayMedium,
    textColor: colors.white,
    hoverBgColor: hoverColors.grayMedium,
  },
  blackButton: {
    bgColor: colors.blackButton,
    textColor: colors.white,
    hoverBgColor: hoverColors.blackButton,
  },
  sandButton: {
    bgColor: colors.sandLight,
    textColor: colors.textMain,
    fontWeight: fontWeightConfig.regular,
    hoverBorderColor: borderColors.primary,
  }
};
