import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import evidence from '../../../assets/img/screenshot_1.svg';
import BottomPagination from '../../../components/Pagination/BottomPagination/BottomPagination';
import TopPagination from '../../../components/Pagination/TopPagination/TopPagination';
import { HeaderContainer, PageTitle } from '../../../styledComponents/common';
import ApprovalTaskList from './ApprovalTaskList';
import TaskFilter from './TaskFilter';

const TaskApproval = ({
  selectedOrganization,
  getOrganizationMembersList,
  orgMembersList,
  loadingTaskAssigneeId,
  isLoading,
  officesShortList,
  getOfficesShortList,
  getTasksList,
  updateTaskApproval,
  tasksList,
  createTask,
  totalTasksCount,
  pageSize,
  history,
  editSubTask,
}) => {
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const tabFromParams = urlParams.get('tab');
  if (tabFromParams) {
    const newUrl = `${window.location.pathname}`;
    window.history.replaceState({}, '', newUrl);
  }

  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState('');
  const [selectedDate, setSelectedDate] = useState({
    startDate: tabFromParams ? moment('2024-04-01') : moment(new Date()),
    endDate: moment(new Date()),
  });
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [selectedTab, setSelectedTab] = useState('submitted');

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState('');
  const [expandedTaskId, setExpandedTaskId] = useState(null);
  const [evidenceImage, setEvidenceImage] = useState(null);

  useEffect(() => {
    setSelectedOffice({
      label: t('all_offices'),
      value: null,
    });
    // getTaskLocation();
  }, []);

  const selectLocation = e => {
    setCurrentPageNumber(1);
    setSearchTerm('');
    setCurrentSearchedTerm('');
    setSelectedLocation(e);
  };
  const handleSearchByName = () => {
    if (searchTerm && searchTerm.trim()) {
      setCurrentSearchedTerm(searchTerm);
      setCurrentPageNumber(1);
    }
  };
  const handleClearSearch = () => {
    setCurrentPageNumber(1);
    setSearchTerm('');
    setCurrentSearchedTerm('');
  };

  const handlePageChange = page => {
    setCurrentPageNumber(page);
  };

  const selectOffice = e => {
    setCurrentPageNumber(1);
    setSearchTerm('');
    setCurrentSearchedTerm('');
    setSelectedOffice(e);
  };

  const selectMember = person => {
    if (person.id) {
      setSelectedMember(person);
      setSearchTerm('');
      setCurrentSearchedTerm('');
      setCurrentPageNumber(1);
    }
  };

  const clearMember = () => {
    setSearchTerm('');
    setCurrentSearchedTerm('');
    setCurrentPageNumber(1);
    setSelectedMember('');
  };

  const onSearchTermChange = e => {
    const { value } = e.target;
    if (value !== '') {
      setSelectedMember('');
    }
    setSearchTerm(value);
  };
  const handleDateChange = date => {
    setCurrentPageNumber(1);
    setSelectedDate(date);
  };
  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const handleSelectedTab = selectedTab => {
    setCurrentPageNumber(1);
    setSelectedTab(selectedTab);
  };

  const updateTaskStatusRequest = (
    task_id,
    approval,
    status,
    supervisorNote,
  ) => {
    const details = {
      status,
    };
    if (supervisorNote.trim()) {
      details.supervisor_note = supervisorNote;
    }
    const payload = {
      task_id,
      approval_id: approval?.id,
      details,
    };
    updateTaskApproval(payload);
    setSearchTerm('');
    // setTimeout(() => {
    //   refreshTaskComponent();
    // }, 350);
  };

  // const refreshTaskComponent = () => {
  //   const payload = {
  //     date: selectedDate.format("YYYY-MM-DD"),
  //     page: 1,
  //     approval_status: selectedTab === "submitted" ? "pending" : selectedTab,
  //   };
  //   if (searchTerm && searchTerm.trim()) {
  //     payload.task_name = searchTerm.toLowerCase().trim();
  //     setCurrentSearchedTerm(searchTerm);
  //   } else {
  //     setCurrentSearchedTerm("");
  //   }
  //   if (selectedOffice && selectedOffice.value) {
  //     payload.office_id = selectedOffice.value;
  //   }
  //   if (selectedMember && selectedMember.id) {
  //     payload.assignee_id = selectedMember.id;
  //   }

  //   getTasksList(payload);
  //   setCurrentPageNumber(1);
  // };

  const getTasks = () => {
    const payload = {
      startDate: moment(selectedDate.startDate).format('YYYY-MM-DD'),
      endDate: moment(selectedDate.endDate).format('YYYY-MM-DD'),
      page: currentPageNumber,
      approval_status: selectedTab === 'submitted' ? 'pending' : selectedTab,
    };
    if (selectedOffice && selectedOffice.value) {
      payload.office_id = selectedOffice.value;
    }
    if (selectedLocation && selectedLocation.value) {
      payload.location_id = selectedLocation.value;
    }
    if (selectedMember && selectedMember.id) {
      payload.assignee_id = selectedMember.id;
    }
    if (searchTerm && searchTerm.trim()) {
      payload.task_name = searchTerm.toLowerCase().trim();
    }
    // Remove null or undefined properties
    const filteredPayload = Object.fromEntries(
      Object.entries(payload).filter(([_, v]) => v != null),
    );

    getTasksList(filteredPayload);
  };
  useEffect(() => {
    getTasks();
  }, [
    currentPageNumber,
    selectedDate,
    selectedOffice,
    selectedMember,
    // searchTerm,
    selectedLocation,
    selectedTab,
    currentSearchedTerm,
  ]);
  useEffect(() => {
    if (tabFromParams) {
      handleSelectedTab(tabFromParams);
    }
  }, []);
  return (
    <div className="content">
      <PageTitle>{t('submitted_tickets_for_approval')}</PageTitle>
      <HeaderContainer>
        <TaskFilter
          selectedOrganization={selectedOrganization}
          getOrganizationMembersList={getOrganizationMembersList}
          organizationMembersList={orgMembersList}
          officesShortList={officesShortList}
          getOfficesShortList={getOfficesShortList}
          selectedOffice={selectedOffice}
          selectOffice={selectOffice}
          selectedMember={selectedMember}
          selectMember={selectMember}
          clearMember={clearMember}
          searchTerm={searchTerm}
          onSearchTermChange={onSearchTermChange}
          selectedDate={selectedDate}
          handleDateChange={handleDateChange}
          handleSearchByName={handleSearchByName}
          handleClearSearch={handleClearSearch}
          handleSelectedTab={handleSelectedTab}
          selectedTab={selectedTab}
        />
      </HeaderContainer>
      {totalTasksCount && totalTasksCount > 0 && pageSize ? (
        <TopPagination
          itemName="tasks"
          totalCount={totalTasksCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}
      <ApprovalTaskList
        selectedOrganization={selectedOrganization}
        loadingTaskAssigneeId={loadingTaskAssigneeId}
        expandedTaskId={expandedTaskId}
        setExpandedTaskId={setExpandedTaskId}
        history={history}
        tasksList={tasksList}
        isLoading={isLoading}
        createTask={createTask}
        popupOpen={popupOpen}
        setPopupOpen={setPopupOpen}
        setEvidenceImage={setEvidenceImage}
        handlePageChange={handlePageChange}
        updateTaskStatusRequest={updateTaskStatusRequest}
        selectedTab={selectedTab}
        editSubTask={editSubTask}
        updateTaskApproval={updateTaskApproval}
      />
      {totalTasksCount && totalTasksCount > 0 && pageSize ? (
        <BottomPagination
          totalCount={totalTasksCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}
    </div>
  );
};

export default TaskApproval;
