import styled, { css } from "styled-components";

export const Tabsection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 25px;
  justify-content: start;
  margin: 0 -40px;
  padding: 0 40px;
  border-bottom: ${({ theme }) =>
    `2px solid ${theme.colors.border.secondaryLight}`};
`;
export const TitleTag = styled.div`
  padding: 5px 10px;
  border-bottom: 2px solid;
  border-color: ${({ active, theme }) =>
    active ? theme.colors.main.primary : theme.colors.border.secondaryLight};
  position: relative;
  top: 2px;
  cursor: pointer;
`;
export const InfoMapSection = styled.div`
  display: grid;
  grid-template-columns: 462px 1fr;
`;
export const InfoSection = styled.div`
  padding: 20px 20px 0;
`;
