// light
// common
import { fontWeightConfig, radiusConfig } from './common';
import { buttonsConfiguration as buttonsLight } from './light/buttons';
import { cardsConfiguration as cardsLight } from './light/cards';
import {
  borderColors as borderColorsLight,
  colors as colorsLight,
  dashboardColors,
  hoverColors as hoverColorsLight,
} from './light/colors';
import { dropdownConfiguration as dropdownLight } from './light/dropdown';
import { fontsConfiguration as fontsLight } from './light/fonts';
import { inputConfiguration as inputLight } from './light/inputs';
import { sidebarConfiguration as sidebarLight } from './light/sidebar';

const defaultFontFamily = '"Montserrat", sans-serif';
const fontFamilyArabic = '"Noto Sans Arabic", sans-serif';
// const defaultFontFamily = '"Manrope", sans-serif';

export const lightTheme = {
  defaultFontFamily,
  fontFamilyArabic,
  font: fontsLight,
  button: buttonsLight,
  card: cardsLight,
  input: inputLight,
  dashboardColors: dashboardColors,
  colors: {
    main: colorsLight,
    hover: hoverColorsLight,
    border: borderColorsLight,
  },
  fontWeights: fontWeightConfig,
  borderRadii: radiusConfig,

  sidebar: sidebarLight,
  dropdown: dropdownLight,
};

export const darkTheme = {
  font: {},
  button: {},
};
