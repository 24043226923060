import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

class CustomScrollbars extends React.Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = {
      hover: true,
    };
    this.renderThumb = this.renderThumb.bind(this);
  }

  renderThumb({ style, ...props }) {
    const { hover } = this.state;
    const thumbStyle = {
      backgroundColor: `rgba(0,0,0,0.1)`,
      zIndex: 4,
      borderRadius: "3px",
      cursor: "pointer",
      visibility: hover ? "visible" : "hidden",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  handleMouseEnter = () => {
    this.setState({ hover: true });
  };

  handleMouseLeave = () => {
    this.setState({ hover: false });
  };

  render() {
    return (
      <Scrollbars
        className="sidebar-scrollbar"
        hideTracksWhenNotNeeded
        renderThumbVertical={this.renderThumb}
        renderTrackHorizontal={() => <div></div>}
        renderThumbHorizontal={() => <div></div>}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        {...this.props}
      />
    );
  }
}

export default CustomScrollbars;
