import React from "react";

// reactstrap components
import { Button, Input, Col, Row, Alert, Form, FormGroup, Label } from "reactstrap";
import { Link } from "react-router-dom";

import dealImage from "assets/img/LTD-deal.svg";
import logo from "assets/img/logo/logo.png";
import Loader from "../../modules/loader";
import { validateEmail } from "../../utils/helper";

class RedeemCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: { value: "", error: true, message: "Empty email", show: false },
      coupon: { coupon: "", error: false }
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleCouponChange = this.handleCouponChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    document.body.classList.toggle("login-page");
    const { match } = this.props;
    const {
      params: { confirmation_key }
    } = match;
    this.setState({
      confirmation_key: confirmation_key
    });
  }

  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  componentDidUpdate(prevProps) {
    const { redeemDiscountCode } = this.props;
    if (
      redeemDiscountCode.registerNeeded !== prevProps.redeemDiscountCode.registerNeeded &&
      redeemDiscountCode.registerNeeded === true
    ) {
      let tempEmail = this.state.email;
      tempEmail.message = "Need to register first";
      tempEmail.error = true;
      tempEmail.show = true;
      this.setState({
        email: tempEmail
      });
    }
    if (
      redeemDiscountCode.alreadyApplied !== prevProps.redeemDiscountCode.alreadyApplied &&
      redeemDiscountCode.alreadyApplied === true
    ) {
      let tempEmail = this.state.email;
      tempEmail.message = "A coupon has already been applied from this email ";
      tempEmail.error = true;
      tempEmail.show = true;
      this.setState({
        email: tempEmail
      });
    }
    if (
      redeemDiscountCode.invalidCode !== prevProps.redeemDiscountCode.invalidCode &&
      redeemDiscountCode.invalidCode
    ) {
      let tempCoupon = this.state.coupon;
        if (redeemDiscountCode.invalidCode[0] === "Invalid coupon code") {
          tempCoupon.error = true;
        }
      this.setState({ coupon: tempCoupon });
    }
  }

  handleEmailChange(event) {
    let tempMail = event.target.value;
    let tempError = false;
    let tempMessage = "";
    if (event.target.value === "" || event.target.value === null) {
      tempError = true;
      tempMessage = "Empty email";
    } else if (!validateEmail(event.target.value)) {
      tempError = true;
      tempMessage = "Invalid email";
    } else {
      tempError = false;
      tempMessage = "";
    }
    this.setState({
      email: {
        value: tempMail,
        error: tempError,
        message: tempMessage,
        show: false
      }
    });
  }

  onDismiss(value) {
    let tempEmail = this.state.email;
    tempEmail.show = false;
    if (value === "email") {
      this.setState({ email: tempEmail });
    }
  }

  handleCouponChange(event) {
    const tempCoupon = this.state.coupon;
    if (
      event.target.value === null ||
      event.target.value === "" ||
      event.target.value.length > 48
    ) {
      tempCoupon.error = true;
    } else {
      tempCoupon.error = false;
    }
    tempCoupon.coupon = event.target.value;
    this.setState({
      coupon: tempCoupon
    });
  }

  handleSave = e => {
    e.preventDefault();
    let invalid = false;
    if (this.state.email.error) {
      let tempEmail = this.state.email;
      tempEmail.show = true;
      this.setState({ email: tempEmail });
      invalid = true;
    }
    if (this.state.coupon.length === 1 && this.state.coupon.coupon === "") {
      this.setState({
        coupon: { coupon: "", error: true }
      });
      invalid = true;
    }
    let code = null;
    if (
      this.state.coupon &&
      this.state.coupon.coupon !== "" &&
      !this.state.coupon.error
    ) {
      code = this.state.coupon.coupon;
    } else if (this.state.coupon.error) {
      invalid = true;
    }
    if (invalid) {
      return;
    } else {
      const payload = {
        email: this.state.email.value,
        code: code
      };
      this.props.redeem(payload);
    }
  };
  
  render() {
    return (
      <div className="login-page">
        <Loader open={this.props.redeemDiscountCode.isLoading} />
        <Row className="align-items-center">
          <Col md="6" style={{ borderRight: "3px solid #E8E8E8" }}>
            <img
              src={dealImage}
              style={{ height: "400px" }}
              className="login-img"
              alt="ltd"
            />
          </Col>
          <Col className="mr-auto ml-auto" md="6">
            <Row className="justify-content-sm-center">
              <Col lg="7" md="11" sm="10">
                <div className="card-login">
                  <Row className="text-center">
                    <Col lg="12" md="12">
                      <img src={logo} alt="" />
                    </Col>
                  </Row>
                  {this.props.redeemDiscountCode.redirect ? (
                    <>
                      <h3>Coupon added successfully.</h3>
                      <div className="text-center">
                        <Link
                          className="link-forgot text-center"
                          target="_blank"
                          style={{ color: "#51cbce", fontSize: "16px" }}
                          to="/auth/login"
                        >
                          Go to website
                        </Link>
                      </div>
                    </>
                  ) : (
                    <>
                      <h2>
                        Please{" "}
                        <Link target="_blank" to="/auth/register">
                          Register
                        </Link>{" "}
                        before redeeming coupon code.
                      </h2>
                      <Form onSubmit={e => this.handleSave(e)}>
                        <FormGroup>
                          <Row className="text-center">
                            <Col md="12" sm="12">
                              <Input
                                type="email"
                                style={{ background: "#fff", padding: "10px" }}
                                placeholder="Email address"
                                onChange={e => this.handleEmailChange(e)}
                                value={this.state.email.value}
                                name="email"
                              />
                              <Alert
                                color="danger"
                                isOpen={this.state.email.show}
                                toggle={e => this.onDismiss("email")}
                                className="alert-login-page"
                              >
                                {this.state.email.message}
                              </Alert>
                            </Col>
                            <Col md={12} sm={12} xs={12}>
                              <Row>
                                <Col md={12} sm={12} xs={12}>
                                  <Input
                                    type="text"
                                    style={{
                                      backgroundColor: "white",
                                      border: this.state.coupon.error
                                        ? "1px solid red"
                                        : "none",
                                      marginTop: "10px",
                                      padding: "8px",
                                      height: "38px"
                                    }}
                                    placeholder="Enter coupon"
                                    value={this.state.coupon.coupon || ""}
                                    onChange={e => {
                                      this.handleCouponChange(e);
                                    }}
                                  />
                                  {this.state.coupon.error ? (
                                    <p style={{ color: "red" }}>
                                      Please enter a valid coupon
                                    </p>
                                  ) : null}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col>
                            <Label style={{ textAlign: "left", marginLeft: "20px" }}>
                            <Input
                              type="checkbox"
                              required
                            />
                              By applying coupon, You are agreeing to our
                              <a
                                href="https://apploye.com/terms-of-service/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="link-color"
                              >
                                Terms of Services
                              </a>
                              and{" "}
                              <a
                                href="https://apploye.com/privacy-policy/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="link-color"
                              >
                                Privacy Policy
                              </a>
                              </Label>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6" className="pull-right">
                              <Button
                                type="submit"
                                className="custom-header-button btn-spec-hover"
                                style={{
                                  marginTop: "10px"
                                }}
                              >
                                Apply Coupon
                              </Button>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Form>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default RedeemCode;
