//Effects
import { put, takeLatest, all } from "redux-saga/effects";

//Service
import { defaultApi } from "../../../utils/axiosApi";

//Constants
import {
  GET_SCREENSHOT_SETTINGS,
  GET_SCREENSHOT_SETTINGS_SUCCESS,
  GET_SCREENSHOT_SETTINGS_FAILURE,
  UPDATE_SCREENSHOT_SETTINGS,
  UPDATE_SCREENSHOT_SETTINGS_SUCCESS,
  UPDATE_SCREENSHOT_SETTINGS_FAILURE,
  GET_MEMBERWISE_ACTIVITY_SETTINGS,
  GET_MEMBERWISE_ACTIVITY_SETTINGS_SUCCESS,
  GET_MEMBERWISE_ACTIVITY_SETTINGS_FAILURE,
  UPDATE_MEMBERWISE_ACTIVITY_SETTINGS,
  UPDATE_MEMBERWISE_ACTIVITY_SETTINGS_SUCCESS,
  UPDATE_MEMBERWISE_ACTIVITY_SETTINGS_FAILURE,
  GET_IDLE_TIME_SETTINGS,
  GET_IDLE_TIME_SETTINGS_SUCCESS,
  GET_IDLE_TIME_SETTINGS_FAILURE,
  UPDATE_IDLE_TIME_SETTINGS,
  UPDATE_IDLE_TIME_SETTINGS_SUCCESS,
  UPDATE_IDLE_TIME_SETTINGS_FAILURE,
} from "../../../modules/constants";

export function* getScreenShortSettingsRequest({ payload }) {
  if (!payload) return;
  try {
    const { organization_id, configuration_id } = payload;
    let url = `${organization_id}/features/activity-setting/${configuration_id}/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_SCREENSHOT_SETTINGS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_SCREENSHOT_SETTINGS_FAILURE,
      payload: err,
    });
  }
}

export function* updateScreenShortSettingsRequest({ payload }) {
  if (!payload) return;
  try {
    const { organization_id, configuration_id, ...details } = payload;
    let url = `${organization_id}/features/activity-setting/${configuration_id}/`;
    const response = yield defaultApi(url, "PATCH", details);
    yield put({
      type: UPDATE_SCREENSHOT_SETTINGS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: UPDATE_SCREENSHOT_SETTINGS_FAILURE,
      payload: err,
    });
  }
}

export function* getMembersList({ payload }) {
  const { organization_id, page } = payload;
  try {
    let url = `${organization_id}/features/member-tracking-settings/${
      page ? `?page=${page}` : ``
    }`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_MEMBERWISE_ACTIVITY_SETTINGS_SUCCESS,
      payload: { data: response, notify: "GET" },
    });
  } catch (err) {
    yield put({
      type: GET_MEMBERWISE_ACTIVITY_SETTINGS_FAILURE,
      payload: err,
    });
  }
}

export function* updateMembersListSettingsRequest({ payload }) {
  if (!payload) return;
  try {
    const { organization_id, member_id, ...details } = payload;
    let url = `${organization_id}/features/member-tracking-settings/${member_id}/`;
    const response = yield defaultApi(url, "PATCH", details);

    yield put({
      type: UPDATE_MEMBERWISE_ACTIVITY_SETTINGS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: UPDATE_MEMBERWISE_ACTIVITY_SETTINGS_FAILURE,
      payload: err,
    });
  }
}

export function* getIdleTimeSettingsRequest({ payload }) {
  if (!payload) return;
  try {
    const { organization_id } = payload;
    const url = `${organization_id}/features/idletime-setting/`;
    const response = yield defaultApi(url, "GET");

    yield put({
      type: GET_IDLE_TIME_SETTINGS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_IDLE_TIME_SETTINGS_FAILURE,
      payload: err,
    });
  }
}
export function* updateIdleTimeSettingsRequest({ payload }) {
  if (!payload) return;
  try {
    const { organization_id, ...data } = payload;
    const url = `${organization_id}/features/idletime-setting/`;
    const response = yield defaultApi(url, "PATCH", data);

    yield put({
      type: UPDATE_IDLE_TIME_SETTINGS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: UPDATE_IDLE_TIME_SETTINGS_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_SCREENSHOT_SETTINGS, getScreenShortSettingsRequest),
    takeLatest(UPDATE_SCREENSHOT_SETTINGS, updateScreenShortSettingsRequest),
    takeLatest(GET_MEMBERWISE_ACTIVITY_SETTINGS, getMembersList),
    takeLatest(
      UPDATE_MEMBERWISE_ACTIVITY_SETTINGS,
      updateMembersListSettingsRequest
    ),
    takeLatest(GET_IDLE_TIME_SETTINGS, getIdleTimeSettingsRequest),
    takeLatest(UPDATE_IDLE_TIME_SETTINGS, updateIdleTimeSettingsRequest),
  ]);
}
