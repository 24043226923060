import { t, use } from 'i18next';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import avatar5 from '../../../../assets/img/avatar_5.svg';
import checkedIn from '../../../../assets/img/icons/checked-in.svg';
import checkedOut from '../../../../assets/img/icons/checked-out.svg';
import clockedIn from '../../../../assets/img/icons/clocked-in.svg';
import clockedOut from '../../../../assets/img/icons/clocked-out.svg';
import tick from '../../../../assets/img/icons/tick-white-bold.svg';
import { CardText } from '../../../../components/DashboardCards/cardStyles';
import { CommonImage, CommonText } from '../../../../styledComponents/common';
import { CommImageNameComp } from '../../../Members/CommonComponents';
import {
  CardBlock,
  GreenCircle,
  InfoSectionCard,
  LateTag,
  TimelineBorder,
  TimelineContainer,
  TimelineInfoContainer,
  TimelineTextSection,
  UserActivityContainer,
  UserInfoAndTimelineContainer,
} from '../routeMapStyles';

const TimelineArray = [
  {
    status: 'Clocked in',
    time: '12:15 PM',
    office: 'Al Bait Guests',
    address: 'Al Urubah St., Al Worood Dist Jeddah Rouwais Dist.',
    task: null,
  },
  {
    status: 'Checked In',
    time: '12:15 PM',
    office: 'Al Bait Guests',
    address: 'Al Urubah St., Al Worood Dist Jeddah Rouwais Dist.',
    task: 'Makes beds and changes linens',
  },
  {
    status: 'Checked Out',
    time: '1:50 PM',
    office: 'Al Bait Guests',
    address: 'Al Urubah St., Al Worood Dist Jeddah Rouwais Dist.',
    task: 'Makes beds and changes linens',
  },
  {
    status: 'Clocked Out',
    time: '1:57 PM',
    office: 'Al Bait Guests',
    address: 'Al Urubah St., Al Worood Dist Jeddah Rouwais Dist.',
    task: null,
  },
  {
    status: 'Checked In',
    time: '12:15 PM',
    office: 'Al Bait Guests',
    address: 'Al Urubah St., Al Worood Dist Jeddah Rouwais Dist.',
    task: 'Makes beds and changes linens',
  },
  {
    status: 'Checked Out',
    time: '1:50 PM',
    office: 'Al Bait Guests',
    address: 'Al Urubah St., Al Worood Dist Jeddah Rouwais Dist.',
    task: 'Makes beds and changes linens',
  },
  {
    status: 'Clocked Out',
    time: '1:57 PM',
    office: 'Al Bait Guests',
    address: 'Al Urubah St., Al Worood Dist Jeddah Rouwais Dist.',
    task: null,
  },
  {
    status: 'Checked In',
    time: '12:15 PM',
    office: 'Al Bait Guests',
    address: 'Al Urubah St., Al Worood Dist Jeddah Rouwais Dist.',
    task: 'Makes beds and changes linens',
  },
  {
    status: 'Checked Out',
    time: '1:50 PM',
    office: 'Al Bait Guests',
    address: 'Al Urubah St., Al Worood Dist Jeddah Rouwais Dist.',
    task: 'Makes beds and changes linens',
  },
  {
    status: 'Clocked Out',
    time: '1:57 PM',
    office: 'Al Bait Guests',
    address: 'Al Urubah St., Al Worood Dist Jeddah Rouwais Dist.',
    task: null,
  },
];

const TimelineIcon = status => {
  switch (status) {
    case 'Clocked in':
      return clockedIn;
      break;
    case 'Checked In':
      return checkedIn;
      break;
    case 'Checked Out':
      return checkedOut;
      break;
    case 'Clocked Out':
      return clockedOut;
      break;
  }
};

const SingleTimelineEvent = props => {
  const { type, event, timestamp, lat, long, task, last } = props;

  const { t } = useTranslation();

  if (type && (type === 'clock_in' || type === 'clock_out')) {
    return (
      <TimelineContainer key={timestamp}>
        <CardText name fontSize="13px" title margin="5px 0 0 0">
          {moment.unix(timestamp).format('h:mm A')}
        </CardText>
        <TimelineInfoContainer>
          <CommonImage
            src={type === 'clock_in' ? clockedIn : clockedOut}
            alt=""
          />
          <CardText fontSize="12px" name title>
            {type === 'clock_in' ? t('clocked_in') : t('clocked_out')}
          </CardText>
          {!last && <TimelineBorder />}
          <div style={{ height: '20px' }} />
          {/* <TimelineTextSection>
            <CardText fontSize="12px" name fontWeight="500">
              {e.status === "Clocked in" || e.status === "Clocked Out"
                ? `Office: ${e.office}`
                : `Task: ${e.task}`}
            </CardText>
            <CardText fontSize="13px" label fontWeight="500">
              {e.address}
            </CardText>
          </TimelineTextSection> */}
        </TimelineInfoContainer>
      </TimelineContainer>
    );
  }
  if (event && (event === 'checkin' || event === 'checkout')) {
    return (
      <TimelineContainer key={timestamp}>
        <CardText name fontSize="13px" title margin="5px 0 0 0">
          {moment.unix(timestamp).format('h:mm A')}
        </CardText>
        <TimelineInfoContainer>
          <CommonImage
            src={event === 'checkin' ? checkedIn : checkedOut}
            alt=""
          />
          <CardText fontSize="12px" name title>
            {event === 'checkin' ? t('checked_in') : t('checked_out')}
          </CardText>
          {!last && <TimelineBorder />}

          {task && task.id && (
            <TimelineTextSection>
              <CardText fontSize="12px" name fontWeight="500">
                {`${t('ticket')}: ${task.name}`}
              </CardText>
              {task.location && task.location.id && (
                <CardText fontSize="13px" label fontWeight="500">
                  {task.location.address}
                </CardText>
              )}
            </TimelineTextSection>
          )}
        </TimelineInfoContainer>
      </TimelineContainer>
    );
  }
  return null;
};

const Timeline = props => {
  const { t } = useTranslation();

  const { eventsList, selectedDate, selectedMember } = props;

  return (
    <UserInfoAndTimelineContainer>
      <UserActivityContainer>
        {selectedMember && selectedMember.id ? (
          <CommImageNameComp
            imgSource={
              selectedMember && selectedMember.avatar
                ? selectedMember.avatar
                : avatar5
            }
            name={selectedMember.name}
            size="60px"
          />
        ) : null}

        <CommonText>
          {moment(selectedDate).format('dddd, DD MMMM, YYYY')}
        </CommonText>
        {/* <InfoSectionCard>
          <CardBlock>
            <CardText name fontSize="13px" title nowrap>
              3 PM - 4 PM
            </CardText>
            <CardText fontSize="12px" label title>
              Start - End
            </CardText>
          </CardBlock>
          <CardBlock>
            <CardText name fontSize="13px" title nowrap>
              3:11 PM
              <LateTag>Late</LateTag>
            </CardText>
            <CardText fontSize="12px" label title>
              Checked In
            </CardText>
          </CardBlock>
          <CardBlock>
            <CardText name fontSize="13px" title nowrap>
              3:59 PM
              <GreenCircle>
                <img src={tick} alt="" width="100%" />
              </GreenCircle>
            </CardText>
            <CardText fontSize="12px" label title>
              Checked Out
            </CardText>
          </CardBlock>
          <CardBlock>
            <CardText name fontSize="13px" title nowrap>
              48 Min
            </CardText>
            <CardText fontSize="12px" label title>
              Worked
            </CardText>
          </CardBlock>
        </InfoSectionCard> */}
      </UserActivityContainer>
      {eventsList &&
        eventsList.length > 0 &&
        eventsList.map((event, index) => {
          return (
            <SingleTimelineEvent
              {...event}
              last={index === eventsList.length - 1}
            />
          );
        })}
      {/* {TimelineArray &&
        TimelineArray.length > 0 &&
        TimelineArray.map((e, index) => (
          <TimelineContainer key={index}>
            <CardText name fontSize="13px" title margin="5px 0 0 0">
              {e.time}
            </CardText>
            <TimelineInfoContainer>
              <img src={TimelineIcon(e.status)} alt="" width="100%" />
              <CardText fontSize="12px" color="#4AB37B" title>
                {e.status}
              </CardText>
              <TimelineBorder />
              <TimelineTextSection>
                <CardText fontSize="12px" name fontWeight="500">
                  {e.status === "Clocked in" || e.status === "Clocked Out"
                    ? `Office: ${e.office}`
                    : `Task: ${e.task}`}
                </CardText>
                <CardText fontSize="13px" label fontWeight="500">
                  {e.address}
                </CardText>
              </TimelineTextSection>
            </TimelineInfoContainer>
          </TimelineContainer>
        ))} */}
    </UserInfoAndTimelineContainer>
  );
};

export default Timeline;
