import {
  DropdownContainer,
  SingleOptionDiv,
} from "./autocompleteDropdownStyles";
import {
  CommonFlex,
  CommonText,
  CommonImage,
} from "../../styledComponents/common";

import markerIcon from "../../assets/img/icons/marker.svg";

const AutocompleteDropdown = (props) => {
  const { dropdownOptions, handleSelect } = props;

  return (
    <DropdownContainer>
      {dropdownOptions.map((option) => {
        return (
          <SingleOptionDiv
            onClick={() => handleSelect(option)}
            key={option.place_id}
          >
            <CommonImage src={markerIcon} alt="" />
            <CommonText fontSize="12px" fontWeight="600">
              {option.structured_formatting.main_text}
            </CommonText>
            <CommonText fontSize="11px">
              {option.structured_formatting.secondary_text}
            </CommonText>
          </SingleOptionDiv>
        );
      })}
      <CommonImage
        src="https://storage.googleapis.com/geo-devrel-public-buckets/powered_by_google_on_white.png"
        alt="powered by Google"
        style={{ width: "120px", float: "right", margin: "5px 15px 5px 0" }}
      />
    </DropdownContainer>
  );
};

export default AutocompleteDropdown;
