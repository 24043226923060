import { fontWeightConfig } from '../common';
import { colors } from './colors';

export const sidebarConfiguration = {
  sidebarWidth: '215px',
  sidebarMiniWidth: '80px',
  sidebarBorderColor: 'rgba(133, 145, 158, 0.2)',
  collapseButtonShadow: '0px 6px 6px #698ebe29',
  textColor: '#ffffff',
  backgroundColor: colors.sidebarBackground,
  fontSize: '13px',
  hoverColor: colors.sidebarHover,
  activeColor: colors.sidebarActiveColor,
  singleLink: {
    activeBgColor: colors.sidebarActiveBackgroundColor,
    activeTextColor: colors.white,
    activeBorderRadius: '6px',
    activeHeight: '34px',
  },
  linkGroup: {
    borderColor: 'rgba(133, 145, 158, 0.1)',
    fontWeight: fontWeightConfig.semibold,
  },
  collapseLink: {
    width: '15px',
    height: '15px',
    fontSize: '10px',
    fontWeight: fontWeightConfig.bold,
    textColor: colors.white,
    bgColor: 'rgba(61, 89, 66, 0.3)',
    hoverBgColor: '#3D5942',
    activeBgColor: '#3D5942',
  },
  imageFilter: {
    hover: `brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(80deg) brightness(106%) contrast(101%)`,
    active: `brightness(0) saturate(100%) invert(100%) sepia(100%)
    saturate(0%) hue-rotate(89deg) brightness(104%) contrast(101%)`,
    seeMoreActive: `brightness(0) saturate(100%) invert(66%) sepia(26%) 
    saturate(6964%) hue-rotate(132deg) brightness(102%) contrast(75%)`,
  },
  profileSection: {
    height: '65px',
    nameFontSize: '14px',
    nameTextColor: colors.textSecondary,
  },
};
