// Effects
import { all, call, put, takeLatest } from 'redux-saga/effects';

// Constants
import {
  ADD_MEMBER,
  ADD_MEMBER_FAILURE,
  ADD_MEMBER_SUCCESS,
  ARCHIVE_MEMBERS,
  ARCHIVE_MEMBERS_FAILURE,
  ARCHIVE_MEMBERS_SUCCESS,
  DELETE_MEMBER_INVITATION,
  DELETE_MEMBER_INVITATION_FAILURE,
  DELETE_MEMBER_INVITATION_SUCCESS,
  GET_INVITED_MEMBERS,
  GET_INVITED_MEMBERS_FAILURE,
  GET_INVITED_MEMBERS_SUCCESS,
  GET_MEMBERS,
  GET_MEMBERS_FAILURE,
  GET_MEMBERS_SUCCESS,
  GET_MEMBER_DETAILS,
  GET_MEMBER_DETAILS_FAILURE,
  GET_MEMBER_DETAILS_SUCCESS,
  GET_MEMBER_MONTHLY_EARNING,
  GET_MEMBER_MONTHLY_EARNING_FAILURE,
  GET_MEMBER_MONTHLY_EARNING_SUCCESS,
  GET_MEMBER_WEEKLY_EARNING,
  GET_MEMBER_WEEKLY_EARNING_FAILURE,
  GET_MEMBER_WEEKLY_EARNING_SUCCESS,
  GET_MONAZIM_LIST,
  GET_MONAZIM_LIST_FAILURE,
  GET_MONAZIM_LIST_SUCCESS,
  INVITE_MEMBER,
  INVITE_MEMBER_FAILURE,
  INVITE_MEMBER_SUCCESS,
  REMOVE_MEMBER,
  REMOVE_MEMBER_FAILURE,
  REMOVE_MEMBER_SUCCESS, // payment configuration
  SET_PAYMENT_CONFIGURATION,
  SET_PAYMENT_CONFIGURATION_FAILURE,
  SET_PAYMENT_CONFIGURATION_SUCCESS,
  UPDATE_MEMBER,
  UPDATE_MEMBER_FAILURE,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_PAYMENT_CONFIGURATION,
  UPDATE_PAYMENT_CONFIGURATION_FAILURE,
  UPDATE_PAYMENT_CONFIGURATION_SUCCESS,
} from '../../modules/constants';
// Service
import { defaultApi } from '../../utils/axiosApi';

//Handle login request

export function* getMembersList({ payload }) {
  const { page, name, office_id, role, user_code } = payload;
  try {
    const url = `/albait/users/?page=${page}${name ? `&name=${name}` : ''}${
      office_id ? `&office_id=${office_id}` : ''
    }${role ? `&role=${role}` : ''}${
      user_code ? `&user_code=${user_code}` : ''
    }`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_MEMBERS_SUCCESS,
      payload: { data: response, notify: 'GET' },
    });
  } catch (err) {
    yield put({
      type: GET_MEMBERS_FAILURE,
      payload: err,
    });
  }
}

export function* getInvitedMembersList({ payload }) {
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/members/invitations/`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_INVITED_MEMBERS_SUCCESS,
      payload: { data: response, notify: 'GET' },
    });
  } catch (err) {
    yield put({
      type: GET_INVITED_MEMBERS_FAILURE,
      payload: err,
    });
  }
}

export function* inviteMember({ payload }) {
  const { organization_id, resend, ...details } = payload;
  try {
    const url = `${organization_id}/members/invitations/`;
    const method = 'POST';
    const response = yield defaultApi(url, method, details);
    yield put({
      type: INVITE_MEMBER_SUCCESS,
      payload: { data: response, resend, notify: 'POST' },
    });
  } catch (err) {
    yield put({
      type: INVITE_MEMBER_FAILURE,
      payload: err,
    });
  }
}

export function* deleteMemberInvitation({ payload }) {
  const { organization_id, invitation_id } = payload;
  try {
    const url = `${organization_id}/members/invitations/${invitation_id}/`;
    const method = 'DELETE';
    const response = yield defaultApi(url, method);
    yield call(getInvitedMembersList, { payload });
    yield put({
      type: DELETE_MEMBER_INVITATION_SUCCESS,
      payload: { data: response, notify: 'DELETE' },
    });
  } catch (err) {
    yield put({
      type: DELETE_MEMBER_INVITATION_FAILURE,
      payload: err,
    });
  }
}

export function* getMemberDetails({ payload }) {
  const { user_id } = payload;
  try {
    const url = `/albait/users/${user_id}/`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_MEMBER_DETAILS_SUCCESS,
      payload: { data: response, notify: 'GET' },
    });
  } catch (err) {
    yield put({
      type: GET_MEMBER_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export function* getMemberWeeklyEarning({ payload }) {
  const { organization_id, user_id } = payload;
  try {
    const url = `${organization_id}/members/${user_id}/earnings/week/`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_MEMBER_WEEKLY_EARNING_SUCCESS,
      payload: { data: response, notify: 'GET' },
    });
  } catch (err) {
    yield put({
      type: GET_MEMBER_WEEKLY_EARNING_FAILURE,
      payload: err,
    });
  }
}

export function* getMemberMonthlyEarning({ payload }) {
  const { organization_id, user_id } = payload;
  try {
    const url = `${organization_id}/members/${user_id}/earnings/month/`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_MEMBER_MONTHLY_EARNING_SUCCESS,
      payload: { data: response, notify: 'GET' },
    });
  } catch (err) {
    yield put({
      type: GET_MEMBER_MONTHLY_EARNING_FAILURE,
      payload: err,
    });
  }
}

export function* handleCreateMember({ payload }) {
  try {
    const {
      first_name,
      last_name,
      office,
      email,
      password,
      role,
      user_code,
      phone,
      image,
      monazim_code,
      is_monazim_leader,
    } = payload;
    const data = new FormData();
    data.append('first_name', first_name);
    data.append('last_name', last_name);
    data.append('office', office);
    data.append('email', email);
    data.append('password', password);
    data.append('role', role);
    data.append('user_code', user_code);
    data.append('phone', phone);
    if (image) {
      data.append('image', image);
    }
    if (monazim_code) {
      data.append('monazim_code', monazim_code);
    }
    if (is_monazim_leader || is_monazim_leader === false) {
      data.append('is_monazim_leader', is_monazim_leader);
    }
    const url = `/albait/users/`;
    const method = 'POST';
    const response = yield defaultApi(url, method, data);
    yield put({
      type: ADD_MEMBER_SUCCESS,
      payload: { data: response, notify: 'POST' },
    });
  } catch (err) {
    yield put({
      type: ADD_MEMBER_FAILURE,
      payload: err,
    });
  }
}

export function* updateMemberRequest({ payload }) {
  try {
    const {
      user_id,
      first_name,
      last_name,
      office,
      email,
      password,
      role,
      user_code,
      monazim_code,
      phone,
      image,
      geofence_time_clock,
      is_monazim_leader,
    } = payload;
    // console.log(payload);
    const data = new FormData();
    if (first_name) {
      data.append('first_name', first_name);
    }
    if (last_name) {
      data.append('last_name', last_name);
    }
    if (office) {
      data.append('office', office);
    }
    if (email) {
      data.append('email', email);
    }
    if (password) {
      data.append('password', password);
    }
    if (role) {
      data.append('role', role);
    }
    if (user_code) {
      data.append('user_code', user_code);
    }
    if (monazim_code) {
      data.append('monazim_code', monazim_code);
    }
    if (is_monazim_leader || is_monazim_leader === false) {
      data.append('is_monazim_leader', is_monazim_leader);
    }
    if (phone) {
      data.append('phone', phone);
    }

    if (image !== undefined) {
      data.append('image', image);
    }

    if (geofence_time_clock !== undefined) {
      data.append('geofence_time_clock', geofence_time_clock);
    }

    const url = `/albait/users/${user_id}/`;
    const method = 'PATCH';
    const response = yield defaultApi(url, method, data);
    yield put({
      type: UPDATE_MEMBER_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: UPDATE_MEMBER_FAILURE,
      payload: err,
    });
  }
}

export function* removeMemberRequest({ payload }) {
  const { organization_id, user_id } = payload;
  try {
    const url = `${organization_id}/members/${user_id}/`;
    const method = 'DELETE';
    const response = yield defaultApi(url, method);
    yield call(getMembersList, { payload });
    yield put({
      type: REMOVE_MEMBER_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: REMOVE_MEMBER_FAILURE,
      payload: err,
    });
  }
}

export function* setPaymentConfigurationRequest({ payload }) {
  try {
    const url = `payroll/paymentsettings/create/`;
    const method = 'POST';
    const response = yield defaultApi(url, method, payload);
    const detailsPayload = {
      organization_id: payload.organization_id,
      user_id: payload.user_id,
    };
    yield call(getMemberDetails, { payload: detailsPayload });
    yield put({
      type: SET_PAYMENT_CONFIGURATION_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: SET_PAYMENT_CONFIGURATION_FAILURE,
      payload: err,
    });
  }
}

export function* updatePaymentConfigurationRequest({ payload }) {
  //const { member_id, ...details } = payload;
  try {
    const url = `payroll/paymentsettings/create/`;
    const method = 'PATCH';
    const response = yield defaultApi(url, method, payload);
    yield put({
      type: UPDATE_PAYMENT_CONFIGURATION_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: UPDATE_PAYMENT_CONFIGURATION_FAILURE,
      payload: err,
    });
  }
}

export function* getMonazimList() {
  try {
    const url = `/albait/users/?role=monazim`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_MONAZIM_LIST_SUCCESS,
      payload: { data: response, notify: 'GET' },
    });
  } catch (err) {
    yield put({
      type: GET_MONAZIM_LIST_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_MEMBERS, getMembersList),
    takeLatest(GET_MONAZIM_LIST, getMonazimList),
    takeLatest(GET_INVITED_MEMBERS, getInvitedMembersList),
    takeLatest(INVITE_MEMBER, inviteMember),
    takeLatest(DELETE_MEMBER_INVITATION, deleteMemberInvitation),
    takeLatest(GET_MEMBER_DETAILS, getMemberDetails),
    takeLatest(GET_MEMBER_WEEKLY_EARNING, getMemberWeeklyEarning),
    takeLatest(GET_MEMBER_MONTHLY_EARNING, getMemberMonthlyEarning),
    // Create member
    takeLatest(ADD_MEMBER, handleCreateMember),
    takeLatest(UPDATE_MEMBER, updateMemberRequest),
    takeLatest(REMOVE_MEMBER, removeMemberRequest),
    takeLatest(SET_PAYMENT_CONFIGURATION, setPaymentConfigurationRequest),
    takeLatest(UPDATE_PAYMENT_CONFIGURATION, updatePaymentConfigurationRequest),
  ]);
}
