import moment from 'moment';
import styled from 'styled-components';
import { CommonFlex, CommonText } from 'styledComponents/common';

import tickIcon from '../../../assets/img/Sidebar/clock-in.svg';
import { t } from 'i18next';

const TimeDurarionContainer = styled.div`
  width: 100%;
  margin-top: 2px;
  font-size: 12px;
`;

const TimeDuration = ({ startDate, startTime, endDate, endTime }) => {
  const tempStartTime = moment(startTime, 'HH:mm:ss');
  const startDateTime = moment(startDate).set({
    hour: tempStartTime.hour(),
    minute: tempStartTime.minute(),
    second: tempStartTime.second(),
  });

  // Combine end date and time
  const endDateTime = moment(endDate).set({
    hour: moment(endTime, 'HH:mm:ss').hour(),
    minute: moment(endTime, 'HH:mm:ss').minute(),
    second: moment(endTime, 'HH:mm:ss').second(),
  });

  // Calculate the difference in milliseconds
  const timeDiffInMillis = endDateTime.diff(startDateTime);

  // Convert milliseconds to desired time units
  const duration = moment.duration(timeDiffInMillis);

  // Get the duration in hours, minutes, and seconds
  const hours = Math.floor(duration.asMilliseconds() / (1000 * 60 * 60));
  const minutes = duration.minutes() + Math.ceil(duration.seconds() / 60);
  return (
    <TimeDurarionContainer>
      <CommonFlex gap="2px" alignItems="center">
        <img src={tickIcon} alt="" style={{ height: '15px', width: '15px' }} />
        <CommonText fontSize="11px" color="#242bff">
          {t('ticket_duration')} : {hours > 0 ? hours + 'H ' : ''} {minutes + 'Min'}
        </CommonText>
      </CommonFlex>
    </TimeDurarionContainer>
  );
};

export default TimeDuration;
