import { colors, hoverColors, borderColors } from "./colors";
import { fontWeightConfig, radiusConfig } from "../common";

export const cardsConfiguration = {
  bgColor: colors.white,
  borderColor: borderColors.secondary,
  borderRadius: radiusConfig.card,
  padding: "0",
  marginTop: "20px",
  title: {
    size: "18px",
    weight: fontWeightConfig.semibold,
    color: colors.textMain,
  },
};
