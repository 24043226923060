import { connect } from "react-redux";

// Component
import RedeemCode from "./RedeemCode";

// Actions
import { redeem } from "./redeemCodeActions";

// Store props to map with current state
const mapStateToProps = state => ({ redeemCode: state.redeemCode });

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  redeem: details => dispatch(redeem({ ...details }))
});

// connect states and dispatchers with components
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RedeemCode);
