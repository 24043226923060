import {
  ADD_MEMBER,
  CLEAR_MEMBER_DETAILS_DATA,
  CLEAR_MEMBER_INVITED,
  DELETE_MEMBER_INVITATION,
  GET_INVITED_MEMBERS,
  GET_MEMBERS,
  GET_MEMBER_DETAILS,
  GET_MEMBER_MONTHLY_EARNING,
  GET_MEMBER_WEEKLY_EARNING,
  GET_MONAZIM_LIST,
  INVITE_MEMBER,
  REMOVE_MEMBER,
  SET_PAYMENT_CONFIGURATION,
  UPDATE_MEMBER,
  UPDATE_PAYMENT_CONFIGURATION,
} from '../../modules/constants';

// ------------------------------------
// Actions
// ------------------------------------

export function getMembersList(value) {
  return {
    type: GET_MEMBERS,
    payload: value,
  };
}

export function getInvitedMembersList(value) {
  return {
    type: GET_INVITED_MEMBERS,
    payload: value,
  };
}

export function inviteMember(value) {
  return {
    type: INVITE_MEMBER,
    payload: value,
  };
}

export function deleteMemberInvitation(value) {
  return {
    type: DELETE_MEMBER_INVITATION,
    payload: value,
  };
}

export function getMemberDetails(value) {
  return {
    type: GET_MEMBER_DETAILS,
    payload: value,
  };
}

export function getMemberWeeklyEarning(value) {
  return {
    type: GET_MEMBER_WEEKLY_EARNING,
    payload: value,
  };
}

export function getMemberMonthlyEarning(value) {
  return {
    type: GET_MEMBER_MONTHLY_EARNING,
    payload: value,
  };
}

export function addMember(value) {
  return {
    type: ADD_MEMBER,
    payload: value,
  };
}

export function updateMember(value) {
  return {
    type: UPDATE_MEMBER,
    payload: value,
  };
}
export function removeMember(value) {
  return {
    type: REMOVE_MEMBER,
    payload: value,
  };
}
export function setPaymentConfiguration(value) {
  return {
    type: SET_PAYMENT_CONFIGURATION,
    payload: value,
  };
}
export function updatePaymentConfiguration(value) {
  return {
    type: UPDATE_PAYMENT_CONFIGURATION,
    payload: value,
  };
}
export function clearMemberDetailsData() {
  return {
    type: CLEAR_MEMBER_DETAILS_DATA,
  };
}
export function clearMemberInvited() {
  return {
    type: CLEAR_MEMBER_INVITED,
  };
}

export function getMonazimList(value) {
  return {
    type: GET_MONAZIM_LIST,
    payload: value,
  };
}
