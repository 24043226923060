import { components } from "react-select";
import styled from "styled-components";

import { ClearSearchButton } from "../../components/Search/searchStyles";
import { CommonImage } from "../../styledComponents/common";

import indicatorIcon from "../../assets/img/Sidebar/sidebar-arrow-down.svg";
import crossWhite from "../../assets/img/icons/remove_assignee.svg";

const DropdownIndicatorIcon = styled.img`
  height: ${({ size }) => size || "22px"};
  width: ${({ size }) => size || "22px"};
`;

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIndicatorIcon
        src={indicatorIcon}
        alt=""
        size={props.selectProps.indicatorIconSize}
      />
    </components.DropdownIndicator>
  );
};

export const IndicatorSeparator = ({ innerProps }) => <span {...innerProps} />;

export const LoadingIndicator = (props) => {
  return null;
};
export const LoadingMessage = (props) => {
  return null;
};

export const ClearIndicator = (props) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <ClearSearchButton ref={ref} {...restInnerProps}>
      <CommonImage src={crossWhite} alt="clear" />
    </ClearSearchButton>
  );
};
