import styled from "styled-components";

export const SettingsGridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
  grid-gap: 1.5rem;

  @media (min-width: 1400px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;

export const SettingsCard = styled.div`
  min-height: 90px;
  background: ${({ theme }) => theme.colors.main.white};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: ${({ theme }) => theme.card.borderRadius};
  box-shadow: none;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  &:hover {
    transform: translateY(-2px);
    transition: transform 0.3s ease;
  }
`;

export const WeeklyEmailCard = styled(SettingsCard)`
  cursor: default;
  &:hover {
    transform: none;
    transition: none;
  }
`;

export const CheckboxDiv = styled.div`
  height: 19px;
  width: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3px;
  border: 1px solid
    ${({ active, theme }) =>
      active ? theme.colors.border.primary : theme.colors.border.secondary};
  border-radius: 4px;
  background: ${({ active, theme }) =>
    active ? theme.colors.main.primary : theme.colors.main.white};
  cursor: pointer;
  &:hover {
    border-color: ${({ theme }) => theme.colors.border.primary};
  }
`;
export const CheckboxTickIcon = styled.img`
  height: 16px;
  width: 16px;
`;
