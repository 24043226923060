import styled from 'styled-components';

export const CalendarContainer = styled.div`
  height: 720px;
  @media screen and (max-width: 1400px) {
    height: 600px;
  }
`;

export const CalendarToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

export const MonthPickerContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const MonthChangeButton = styled.div`
  width: 36px;
  height: 36px;
  background-color: ${({ theme }) => theme.colors.main.gray};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.hover.gray};
  }
`;

export const MonthChangeIcon = styled.img`
  width: ${({ width }) => (width ? width : '18px')};
  height: ${({ height }) => (height ? height : '18px')};
  transform: ${({ left }) => (left ? `rotate(180deg)` : `rotate(0deg)`)};
  transition: transform 0.8s ease;
`;

export const MonthPickerInput = styled.div`
  margin: 0px 15px;
  font-size: 13px;
  text-align: center;
  border: none;

  &:focus {
    outline: none;
  }
`;

export const timesheetsData = [
  {
    user_id: 'afb373ec-a173-40bb-9a57-b09d8ab23098',
    duration: 32647,
    date: '2023-02-06',
  },
  {
    user_id: 'afb373ec-a173-40bb-9a57-b09d8ab23098',
    duration: 30743,
    date: '2023-02-13',
  },
  {
    user_id: 'afb373ec-a173-40bb-9a57-b09d8ab23098',
    duration: 31982,
    date: '2023-02-14',
  },
  {
    user_id: 'afb373ec-a173-40bb-9a57-b09d8ab23098',
    duration: 29027,
    date: '2023-02-15',
  },
  {
    user_id: 'afb373ec-a173-40bb-9a57-b09d8ab23098',
    duration: 31291,
    date: '2023-02-19',
  },
  {
    user_id: 'afb373ec-a173-40bb-9a57-b09d8ab23098',
    duration: 30221,
    date: '2023-02-02',
  },
  {
    user_id: 'afb373ec-a173-40bb-9a57-b09d8ab23098',
    duration: 17961,
    date: '2023-02-04',
  },
  {
    user_id: 'afb373ec-a173-40bb-9a57-b09d8ab23098',
    duration: 31821,
    date: '2023-02-09',
  },
  {
    user_id: 'afb373ec-a173-40bb-9a57-b09d8ab23098',
    duration: 30958,
    date: '2023-02-01',
  },
  {
    user_id: 'afb373ec-a173-40bb-9a57-b09d8ab23098',
    duration: 36343,
    date: '2023-02-05',
  },
  {
    user_id: 'afb373ec-a173-40bb-9a57-b09d8ab23098',
    duration: 305,
    date: '2023-02-11',
  },
  {
    user_id: 'afb373ec-a173-40bb-9a57-b09d8ab23098',
    duration: 32088,
    date: '2023-02-08',
  },
  {
    user_id: 'afb373ec-a173-40bb-9a57-b09d8ab23098',
    duration: 37766,
    date: '2023-02-12',
  },
  {
    user_id: 'afb373ec-a173-40bb-9a57-b09d8ab23098',
    duration: 2056,
    date: '2023-02-18',
  },
  {
    user_id: 'afb373ec-a173-40bb-9a57-b09d8ab23098',
    duration: 27629,
    date: '2023-02-20',
  },
  {
    user_id: 'afb373ec-a173-40bb-9a57-b09d8ab23098',
    duration: 32115,
    date: '2023-02-22',
  },
  {
    user_id: 'afb373ec-a173-40bb-9a57-b09d8ab23098',
    duration: 10378,
    date: '2023-02-23',
  },
  {
    user_id: 'afb373ec-a173-40bb-9a57-b09d8ab23098',
    duration: 35618,
    date: '2023-02-07',
  },
  {
    user_id: 'afb373ec-a173-40bb-9a57-b09d8ab23098',
    duration: 32158,
    date: '2023-02-16',
  },
];
