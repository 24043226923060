import styled, { css } from "styled-components";

export const TimesheetDetailsPanelContainer = styled.div``;

export const TimesheetDetailsTableRow = styled.div`
  display: grid;
  grid-template-columns: 90px 90px 90px 40px;
  grid-column-gap: 25px;
  justify-content: space-between;
  padding: 10px 25px;
  border-bottom: ${({ header, theme }) =>
    header && `1px solid ${theme.colors.border.secondary}`};
  margin: ${({ margin }) => margin || "0"};
  background-color: ${({ active, theme }) =>
    active ? "#f8f9fc" : theme.colors.main.white};
  transition: background-color 0.5s ease;
`;

export const DetailsCollapseSection = styled.div`
  display: grid;
  max-height: ${({ open, maxHeight }) =>
    open ? (maxHeight ? maxHeight : `500px`) : `0`};
  overflow: hidden;
  transition: max-height 0.5s ease;
`;
export const DetailsInfoContainer = styled.div`
  background-color: #f8f9fc;
  padding: 0px 25px 15px;
`;

export const IdleTimeSectionContainer = styled.div`
  padding: 10px 25px;
  margin: 0 -25px;
  background-color: #faeaea;
`;

export const ExpandButton = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  background-color: ${({ theme }) => theme.colors.main.white};
  border: 1px solid
    ${({ active, theme }) =>
      active ? theme.colors.border.primary : theme.colors.border.secondary};
  cursor: pointer;
  justify-self: flex-end;

  &:hover {
    border-color: ${({ theme }) => theme.colors.border.primary};
  }

  transform: ${({ active }) => (active ? "rotate(90deg)" : "rotate(0deg)")};
  transition: transform 0.35s ease;

  ${({ active }) =>
    !active &&
    css`
      img {
        filter: brightness(0) saturate(100%) invert(99%) sepia(10%)
          saturate(7495%) hue-rotate(178deg) brightness(93%) contrast(89%);
      }
    `};
`;

export const DeleteButton = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  background-color: ${({ theme }) => theme.colors.main.white};
  border: 1px solid
    ${({ theme, active }) =>
      active ? theme.colors.border.error : theme.colors.border.secondary};
  cursor: pointer;
  justify-self: flex-end;

  &:hover {
    border-color: ${({ theme }) => theme.colors.border.error};
  }
`;
