import styled from "styled-components";

export const ToggleButton = styled.div`
  display: grid;
  align-items: center;
  width: ${({ width }) => width || "31px"};
  height: ${({ height }) => height || "18px"};
  background-color: ${({ active, theme }) =>
    active ? theme.colors.main.primary : theme.colors.border.secondary};
  padding: ${({ padding }) => padding || "0 4px"};
  margin: ${({ margin }) => margin || "0"};
  border-radius: ${({ borderRadius }) => borderRadius || "10px"};
  cursor: pointer;
`;
export const ToggleButtonRound = styled.div`
  width: ${({ size }) => size || "10px"};
  height: ${({ size }) => size || "10px"};
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  background-color: ${({ theme }) => theme.colors.main.white};
  margin-left: ${({ active }) => active && `auto`};
`;
