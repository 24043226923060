
import ContentLoader from "react-content-loader";

const TopProjectsLoader = (props) => {
  return (
    <ContentLoader
      speed={2}
      width={610}
      height={217}
      viewBox="0 0 610 217"
      backgroundColor="#e8eaee"
      foregroundColor="#f3f3f3"
      {...props}
    >
      <circle id="Ellipse_599" data-name="Ellipse 599" cx="158.97" cy="7" r="7" />
      <circle cx="159.06" cy="7.24" r="7.21" />
      <rect x="192.07" y="0.03" width="149.7" height="11.7" rx="5.85" />
      <circle cx="159.18" cy="48.96" r="7.21" />
      <rect x="192.18" y="41.75" width="149.7" height="11.7" rx="5.85" />
      <circle cx="157.9" cy="91.1" r="7.21" />
      <rect x="190.9" y="83.88" width="149.7" height="11.7" rx="5.85" />
      <circle cx="159.18" cy="131" r="7.21" />
      <rect x="192.18" y="123.78" width="149.7" height="11.7" rx="5.85" />
      <circle cx="158.94" cy="171.89" r="7.21" />
      <rect x="191.95" y="164.68" width="149.7" height="11.7" rx="5.85" />
      <circle cx="58.16" cy="89.16" r="58.16" />
    </ContentLoader>
  );
};

export default TopProjectsLoader;
