import styled from "styled-components";

export const DropdownContainer = styled.div`
  display: block;
  background-color: ${({ theme }) => theme.colors.main.white};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  // max-width: 100%;
  outline: none;
  overflow-wrap: break-word;
  position: absolute;
  top: 73px;
  width: max-content;
  max-width: 650px;
  z-index: 10;
  max-height: 300px;
  overflow: auto;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
  border-radius: 10px;
  transition-property: box-shadow, opacity;
  padding: 5px 0;
`;

export const SingleOptionDiv = styled.div`
  display: flex;
  grid-column-gap: 10px;
  align-items: center;
  flex: 1 1 100%;
  padding: 0 15px;
  min-height: 40px;
  position: relative;
  cursor: pointer;
  background-color: ${({ theme }) => theme.dropdown.bgColor};
  &:hover {
    background-color: ${({ theme }) => theme.dropdown.option.hoverBgColor};
  }
`;
