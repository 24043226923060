import { REDEEM_LIMITED_TIME_CODE } from "modules/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function redeem(value) {
  return {
    type: REDEEM_LIMITED_TIME_CODE,
    payload: value,
  };
}

export const actions = {
  redeem,
};
