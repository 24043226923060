import CircularProgressbarNewWithChildren from 'components/CircularProgressbar/CircularProgressbarSmallWithChildren';
import AddMultipleAssigneeDropdown from 'components/CustomDropdown/AddMultipleAssigneeDropdown';
import ComponentCircleLoader from 'components/Loaders/ComponentCircleLoader';
import StaticTaskLocationModal from 'custom_modules/TaskLocations/StaticTaskLocationModal';
import { t } from 'i18next';
import i18next from 'i18next';
import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { TaskStatus, TaskStatusButton } from 'styledComponents/tasks';
import { toHmins } from 'utils/helper';

import officeIcon from '../../../assets/img/Sidebar/offices-icon.svg';
import calendarIcon from '../../../assets/img/icons/Calendar.svg';
import documentIcon from '../../../assets/img/icons/Document.svg';
import assigneeIcon from '../../../assets/img/icons/assignee.svg';
import phoneIcon from '../../../assets/img/icons/phone.svg';
import tick from '../../../assets/img/icons/tick-white-bold.svg';
import userIcon from '../../../assets/img/icons/user.svg';
import userTagIcon from '../../../assets/img/icons/userTag.svg';
import statusIcon from '../../../assets/img/statusIcon.svg';
import BackButtonComponent from '../../../components/BackButtonComponent/BackButtonComponent';
import { CardText } from '../../../components/DashboardCards/cardStyles';
import { ListUpperSection } from '../../../styledComponents/ProjectsNew';
import { WhiteButton } from '../../../styledComponents/buttons';
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonText,
  Container,
  ContentHeader,
  InputLabel,
  PageTitle,
} from '../../../styledComponents/common';
import {
  CardBlock,
  GreenCircle,
  InfoSectionCard,
  LateTag,
} from '../../FieldServices/RouteMapNew/routeMapStyles';
import ChangeTimelineModal from '../ChangeTimelineModal';
import ApprovalSection from './approvalSection';
import Comment from './comment';
import {
  FullWidthWithTopBorder,
  LabelTextBlock,
  TaskDetailsInfoSection,
} from './taskDetailsStyles';
import TaskEvidence from './taskEvidence';

const TaskDetails = ({
  isLoading,
  organizationMembersList,
  officesShortList,
  addTaskLocationLoading,
  getTaskDetails,
  taskDetails,
  editTask,
  callback,
  locationsList,
  createdTaskLocationInfo,
  memberShortListIsLoading,
  getOrganizationMembersList,
  getTaskType,
  createTask,
  getTaskLocation,
  addTaskLocation,
  getOfficesShortList,
  taskTypeIsLoading,
  taskTypeList,
  history,
  location,
  approvalMode,
  updateTaskApproval,
  selectedTab = 'submitted',
  updateTaskAssignee,
  removeTaskAssignee,
  updatePartialTask,
}) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedEvidence, setSelectedEvidence] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [expandedSubTaskId, setExpandedSubTaskId] = useState(null);
  const [seeLocationModalOpen, setSeeLocationModalOpen] = useState(false);
  const [changeTimelineModalOpen, setChangeTimelineModalOpen] = useState(false);
  const [selectedTaskLocation, setSelectedTaskLocation] = useState(null);

  const canUpdateSubTask =
    taskDetails?.approval_status === 'pending' &&
    selectedTab === 'submitted' &&
    (localStorage.getItem('user_role') !== 'employee' ||
      (taskDetails?.assignees &&
        taskDetails?.assignees.length > 0 &&
        taskDetails?.assignees[0].id === localStorage.getItem('user_id')));

  const popupToggle = index => {
    setPopupOpen(!popupOpen);
    setSelectedEvidence(index);
  };

  const updateDate = payload => {
    updatePartialTask({ ...payload, task_id: taskDetails.id });
  };

  const handleShowLocation = location => {
    setSelectedTaskLocation(location);

    setTimeout(() => {
      setSeeLocationModalOpen(true);
    }, 300);
  };

  const completedSubTasks = taskDetails?.sub_tasks?.filter(
    sub_task => sub_task.status === 'completed',
  ).length;
  const totalSubTasks = taskDetails?.sub_tasks?.length;
  const percentage =
    totalSubTasks > 0
      ? Math.ceil((completedSubTasks * 100) / totalSubTasks)
      : taskDetails?.status === 'completed'
      ? 100
      : 0;
  const lastCheckinout = taskDetails?.checkinout?.sort(
    (a, b) => b.checkin_timestamp - a.checkin_timestamp,
  )[0];

  const updateTaskStatusRequest = data => {
    const payload = { task_id: taskDetails?.id, data };
    updateTaskApproval(payload);
  };
  useEffect(() => {
    if (!approvalMode) {
      if (location.search) {
        const queryParsed = queryString.parse(location.search);
        setTaskId(queryParsed.id);
        getTaskDetails({ task_id: queryParsed.id });
      }
      getTaskType();
    }
  }, []);
  useEffect(() => {
    if (!approvalMode) {
      taskDetails?.office?.id &&
        getOrganizationMembersList({ office_id: taskDetails?.office?.id });
    }
  }, [taskDetails?.office?.id]);

  return (
    <div className="content">
      <StaticTaskLocationModal
        isOpen={seeLocationModalOpen}
        toggle={() => {
          setSeeLocationModalOpen(false);
          setSelectedTaskLocation(null);
        }}
        taskLocation={selectedTaskLocation}
      />
      {/* {!approvalMode && (
        <ChangeTimelineModal
          isOpen={changeTimelineModalOpen}
          toggle={() => setChangeTimelineModalOpen(!changeTimelineModalOpen)}
          updateDate={updateDate}
        />
      )} */}
      {!approvalMode && (
        <>
          <PageTitle>{t('ticket_details')}</PageTitle>
          <ContentHeader>
            <BackButtonComponent
              onClick={() => history.push('/user/tickets')}
              subTitle={t('back_to_all_tickets')}
            />
          </ContentHeader>
        </>
      )}
      {isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <Container padding="30px" gap="25px">
          <ListUpperSection style={{ gridTemplateColumns: `1fr auto` }}>
            <CommonText name title fontSize="16px">
              {taskDetails?.name}
            </CommonText>
          </ListUpperSection>
          <TaskDetailsInfoSection>
            <TaskDetailsSingleInfo
              icon={statusIcon}
              title="ticket_no"
              value={
                <CommonFlex>
                  {taskDetails?.task_code}
                  <TaskStatus
                    status={taskDetails?.status ? taskDetails?.status : 'new'}
                  >
                    {i18next.language && i18next.language === 'en'
                      ? t(taskDetails?.status).toUpperCase()
                      : t(taskDetails?.status)}
                  </TaskStatus>
                </CommonFlex>
              }
            />{' '}
            <TaskDetailsSingleInfo
              icon={phoneIcon}
              title="phone"
              value={<CommonText>{taskDetails?.created_by?.phone}</CommonText>}
            />
            <TaskDetailsSingleInfo
              icon={assigneeIcon}
              title="assignee"
              value={
                localStorage.getItem('user_role') === 'admin' && (
                  <AddMultipleAssigneeDropdown
                    numberOfVisibleMembers={8}
                    isLoading={false}
                    hasPermission={
                      !approvalMode &&
                      taskDetails?.status !== 'partial' &&
                      taskDetails?.status !== 'completed' &&
                      taskDetails?.status !== 'missed' &&
                      taskDetails?.status !== 'cancelled'
                    }
                    assigneesList={taskDetails?.assignees?.map(assignee => {
                      return {
                        ...assignee,
                        name:
                          `${assignee.first_name} ${assignee.last_name}`.trim() ||
                          assignee.first_name,
                      };
                    })}
                    membersList={organizationMembersList?.filter(
                      member =>
                        member.role !== 'monazim' && member.role !== 'admin',
                    )}
                    handleAssign={assignee =>
                      updateTaskAssignee({
                        ...assignee,
                        task_id: taskDetails.id,
                      })
                    }
                    handleRemove={assignee =>
                      removeTaskAssignee({
                        ...assignee,
                        task_id: taskDetails.id,
                      })
                    }
                  />
                )
              }
            />
            {/* <TaskDetailsSingleInfo
              icon={calendarIcon}
              title="timeline"
              onClick={() =>
                taskDetails?.status !== 'partial' &&
                taskDetails?.status !== 'completed' &&
                taskDetails?.status !== 'cancelled' &&
                setChangeTimelineModalOpen(true)
              }
              value={
                <CommonText>
                  {moment(taskDetails?.required_start_date).format(
                    'D MMM, h:mm A',
                  ) +
                    ' - ' +
                    moment(taskDetails?.required_end_date).format(
                      'D MMM, h:mm A',
                    )}
                </CommonText>
              }
            /> */}
            <TaskDetailsSingleInfo
              icon={officeIcon}
              title="office"
              value={<CommonText>{taskDetails?.office?.name}</CommonText>}
            />
            <TaskDetailsSingleInfo
              icon={userTagIcon}
              title="monazim_code"
              value={
                <CommonText>{taskDetails?.created_by?.monazim_code}</CommonText>
              }
            />
            <TaskDetailsSingleInfo
              icon={userIcon}
              title="monazim"
              value={
                <CommonText>
                  {' '}
                  {taskDetails?.created_by?.first_name}{' '}
                  {taskDetails?.created_by?.last_name}
                </CommonText>
              }
            />
            <TaskDetailsSingleInfo
              icon={officeIcon}
              title="created_at"
              value={
                <CommonText>
                  {moment(taskDetails?.created_at).format('D MMM, h:mm A')}
                </CommonText>
              }
            />
          </TaskDetailsInfoSection>{' '}
          <TaskDetailsSingleInfo
            icon={documentIcon}
            title="description"
            value={<CommonText>{taskDetails?.description}</CommonText>}
          />
          <InfoSectionCard style={{ maxWidth: `530px` }}>
            <CardBlock>
              <CardText name fontSize="13px" title nowrap>
                {taskDetails?.required_start_date &&
                taskDetails?.required_end_date
                  ? toHmins(
                      moment
                        .duration(
                          moment(taskDetails?.required_end_date).diff(
                            moment(taskDetails?.required_start_date),
                          ),
                        )
                        .asSeconds(),
                    )
                  : '-'}
              </CardText>
              <CardText fontSize="12px" label title>
                {t('ticket_duration')}
              </CardText>
            </CardBlock>{' '}
            <CardBlock>
              <CardText name fontSize="13px" title nowrap>
                {lastCheckinout?.checkin_timestamp ? (
                  <>
                    {moment
                      .unix(lastCheckinout.checkin_timestamp)
                      .format('h:mm a')}
                    {moment
                      .unix(lastCheckinout.checkin_timestamp)
                      .isAfter(moment(taskDetails?.required_start_date)) ? (
                      <LateTag>{t('late')}</LateTag>
                    ) : (
                      <GreenCircle>
                        <img src={tick} alt="" width="100%" />
                      </GreenCircle>
                    )}
                  </>
                ) : (
                  `-`
                )}
              </CardText>
              <CardText fontSize="12px" label title>
                {t('checked_in')}
              </CardText>
            </CardBlock>
            <CardBlock>
              <CardText name fontSize="13px" title nowrap>
                {lastCheckinout?.checkout_timestamp ? (
                  <>
                    {moment
                      .unix(lastCheckinout.checkout_timestamp)
                      .format('h:mm a')}
                    {moment
                      .unix(lastCheckinout.checkout_timestamp)
                      .isAfter(moment(taskDetails?.required_end_date)) ? (
                      <LateTag>{t('late')}</LateTag>
                    ) : (
                      <GreenCircle>
                        <img src={tick} alt="" width="100%" />
                      </GreenCircle>
                    )}
                  </>
                ) : (
                  `-`
                )}
              </CardText>
              <CardText fontSize="12px" label title>
                {t('checked_out')}
              </CardText>
            </CardBlock>
            <CardBlock>
              <CardText name fontSize="13px" title nowrap>
                {lastCheckinout?.checkin_timestamp
                  ? toHmins(
                      (lastCheckinout?.checkout_timestamp
                        ? lastCheckinout?.checkout_timestamp
                        : moment().unix()) - lastCheckinout?.checkin_timestamp,
                    )
                  : `-`}
              </CardText>
              <CardText fontSize="12px" label title>
                {t('worked')}
              </CardText>
            </CardBlock>
          </InfoSectionCard>
          <CommonFlex>
            <CommonText Text name title fontSize="16px">
              {t('location')}
            </CommonText>
            {/* <LabelTextBlock style={{ marginTop: `10px` }}>
              <InputLabel>{t('location_address')}:</InputLabel>
              <CommonText name>{taskDetails?.location?.name}</CommonText>
            </LabelTextBlock> */}
            {taskDetails?.location && (
              <ColoredText
                type="warning"
                hover
                onClick={() => handleShowLocation(taskDetails?.location)}
                fontSize="14px"
                style={{ cursor: 'pointer' }}
              >
                {t('see_location')}
              </ColoredText>
            )}
          </CommonFlex>{' '}
          <FullWidthWithTopBorder>
            <TaskEvidence
              title="evidence_from_site"
              evidences={taskDetails?.monazimevidences}
            />
          </FullWidthWithTopBorder>
          <FullWidthWithTopBorder>
            <TaskEvidence
              title="evidence_by_employee"
              evidences={taskDetails?.evidences}
            />
          </FullWidthWithTopBorder>
          <FullWidthWithTopBorder>
            <CommonText name title fontSize="16px">
              {t('comments')}
            </CommonText>
            <Comment
              type="employee_note"
              comment={taskDetails?.employee_note}
            />
            <Comment
              type="supervisor_note"
              comment={taskDetails?.supervisor_note}
            />
          </FullWidthWithTopBorder>
          {
            // taskDetails?.status === 'completed' &&
            approvalMode &&
              taskDetails?.approval_status === 'pending' &&
              localStorage.getItem('user_role') !== 'employee' &&
              selectedTab === 'submitted' &&
              taskDetails?.assignees &&
              taskDetails?.assignees.length > 0 &&
              taskDetails?.assignees[0].id !==
                localStorage.getItem('user_id') && (
                <FullWidthWithTopBorder max={'auto'}>
                  <CommonGrid columns={'3fr 1fr'}>
                    <ApprovalSection
                      task_id={taskDetails?.id}
                      history={history}
                      updateTaskStatusRequest={updateTaskStatusRequest}
                      percentage={percentage}
                    />
                  </CommonGrid>
                </FullWidthWithTopBorder>
              )
          }
        </Container>
      )}
    </div>
  );
};

export default TaskDetails;

const TaskDetailsSingleInfo = ({ icon, title, value, onClick }) => {
  return (
    <CommonGrid columns="200px 1fr">
      <CommonGrid columns="15px 1fr" alignItem="center">
        <img src={icon} alt="" style={{ height: '16px', width: '16px' }} />
        <CommonText> {t(title)}</CommonText>
      </CommonGrid>
      <CommonFlex
        onClick={() => {
          onClick && onClick();
        }}
      >
        {value}
      </CommonFlex>
    </CommonGrid>
  );
};
