//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  GET_TRIAL_INFO,
  GET_TRIAL_INFO_SUCCESS,
  GET_TRIAL_INFO_FAILURE,
} from "../../modules/constants";

//Handle login request
export function* getTrialInfo() {
  try {
    let url = `billing/trial-info/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_TRIAL_INFO_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_TRIAL_INFO_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([takeLatest(GET_TRIAL_INFO, getTrialInfo)]);
}
