import {
  GET_CLOCK_IN_OUT_LIST,
  GET_CLOCK_IN_OUT_LIST_FAILURE,
  GET_CLOCK_IN_OUT_LIST_SUCCESS,
  LOGIN_PROFILE_CREATED_RESET,
} from '../../modules/constants';

export const clockInOutListRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const clockInOutListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    // clockInOutListCount: data.data.count,
    // clockInOutPageSize: data.data.page_size,
    clockInOutList: data.data,
  };
};
export const clockInOutListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    clockInOutListCount: null,
    clockInOutPageSize: null,
    clockInOutList: [],
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...initialState,
  };
};

const ACTION_HANDLERS = {
  [GET_CLOCK_IN_OUT_LIST]: clockInOutListRequestHandler,
  [GET_CLOCK_IN_OUT_LIST_SUCCESS]: clockInOutListRequestSuccessHandler,
  [GET_CLOCK_IN_OUT_LIST_FAILURE]: clockInOutListRequestFailureHandler,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

const initialState = {
  isLoading: false,
  clockInOutList: [],

  // pagination
  clockInOutListCount: null,
  clockInOutPageSize: null,
};

export default function clockInOutReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
