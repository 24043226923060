let colorList = ["#EAF9F7", "#ECF0FC", "#FBF3DA"];

export function getRandomColor() {
  var min = 1,
    max = 4,
    random;

  do {
    random = Math.floor(Math.random() * (max - min)) + min;
  } while (random === getRandomColor.last);
  getRandomColor.last = random;

  return colorList[random - 1];
}
