import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";
import copyright from "../../assets/img/icons/copyright.svg";

class Footer extends React.Component {
  render() {
    return (
      <footer
        style={{ backgroundColor: "#ffffff" }}
        className={"footer" + (this.props.default ? " footer-default" : "")}
      >
        <Container fluid={this.props.fluid ? true : false}>
          <Row>
            <nav className="footer-nav">
              <ul>
                <li />
              </ul>
            </nav>
            <div className="credits ml-auto">
              <span className="copyright">
                <img
                  src={copyright}
                  alt=""
                  style={{ height: "16px", marginTop: "-2px" }}
                />{" "}
                {1900 + new Date().getYear()} Apploye Inc.
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
