import {
  ADD_OFFICE_ASSIGNEE,
  ADD_OFFICE_ASSIGNEE_FAILURE,
  ADD_OFFICE_ASSIGNEE_SUCCESS,
  CLEAR_OFFICE_CREATED_UPDATED,
  CLEAR_OFFICE_DETAILS,
  CREATE_OFFICE,
  CREATE_OFFICE_FAILURE,
  CREATE_OFFICE_SUCCESS,
  GET_OFFICES_LIST,
  GET_OFFICES_LIST_FAILURE,
  GET_OFFICES_LIST_SHORT,
  GET_OFFICES_LIST_SHORT_FAILURE,
  GET_OFFICES_LIST_SHORT_SUCCESS,
  GET_OFFICES_LIST_SUCCESS,
  LOGIN_PROFILE_CREATED_RESET,
  REMOVE_OFFICE_ASSIGNEE,
  REMOVE_OFFICE_ASSIGNEE_FAILURE,
  REMOVE_OFFICE_ASSIGNEE_SUCCESS,
  SET_OFFICE_DETAILS,
  UPDATE_OFFICE,
  UPDATE_OFFICE_FAILURE,
  UPDATE_OFFICE_SUCCESS,
} from '../../modules/constants';
import { createNotification } from '../../modules/notificationManager';

// get offices list
export const getOfficesListRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const getOfficesListRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      officesList: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    officesList: [],
  };
};
export const getOfficesListRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    officesList: [],
  };
};

// get offices short list
export const getOfficesShortListRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const getOfficesShortListRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      officesShortList: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    officesShortList: [],
  };
};
export const getOfficesShortListRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    officesShortList: [],
  };
};

// create office
export const createOfficeRequest = (state, action) => {
  return {
    ...state,
    addOrUpdateOfficeLoading: true,
    officeCreateUpdateSuccess: false,
    createdOffice: null,
  };
};
export const createOfficeRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 201 && data.data) {
    createNotification('success', 'Office created successfully', 3000);
    const details = JSON.parse(JSON.stringify(data.data));
    return {
      ...state,
      addOrUpdateOfficeLoading: false,
      officeCreateUpdateSuccess: true,
      createdOffice: details,
      officesList: [details, ...state.officesList],
      // officeDetails: { ...details },
    };
  }
  return {
    ...state,
    addOrUpdateOfficeLoading: false,
  };
};
export const createOfficeRequestFailure = (state, action) => {
  createNotification(
    'error',
    'Could not create office. Please try again!',
    3000,
  );
  return {
    ...state,
    addOrUpdateOfficeLoading: false,
  };
};

// update office
export const updateOfficeRequest = (state, action) => {
  return {
    ...state,
    addOrUpdateOfficeLoading: true,
    officeCreateUpdateSuccess: false,
    createdOffice: null,
  };
};
export const updateOfficeRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200 && data.data) {
    createNotification('success', 'Office updated successfully', 3000);
    const currentList = JSON.parse(JSON.stringify(state.officesList));
    const details = JSON.parse(JSON.stringify(data.data));
    const index = state.officesList.findIndex(
      office => office.id === details.id,
    );
    currentList[index] = details;
    return {
      ...state,
      addOrUpdateOfficeLoading: false,
      officeCreateUpdateSuccess: true,
      officesList: currentList,
      officeDetails: { ...details },
    };
  }
  return {
    ...state,
    addOrUpdateOfficeLoading: false,
  };
};
export const updateOfficeRequestFailure = (state, action) => {
  createNotification(
    'error',
    'Could not update office. Please try again!',
    3000,
  );
  return {
    ...state,
    addOrUpdateOfficeLoading: false,
  };
};

// set office details
export const setOfficeDetails = (state, action) => {
  const { payload } = action;
  if (payload && payload.id) {
    return {
      ...state,
      officeDetails: { ...payload },
    };
  }
  return {
    ...state,
  };
};

// clear office details
export const clearOfficeDetails = (state, action) => {
  return {
    ...state,
    officeDetails: null,
  };
};

// clear office created or updated
export const clearOfficeCreatedUpdated = (state, action) => {
  return {
    ...state,
    createdOffice: null,
    officeCreateUpdateSuccess: false,
  };
};

export const resetStateBeforeLogout = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

export const addOfficeAssigneeRequest = (state, action) => {
  return {
    ...state,
    isAssigneesLoading: true,
  };
};
export const addOfficeAssigneeRequestSuccess = (state, action) => {
  const {
    payload: { office_id, data },
  } = action;
  if (data.status === 201 && data.data) {
    createNotification('success', 'Assigned successfully', 3000);
    let officeDetails;
    const newList = state.officesList.map(office => {
      if (office.id === office_id) {
        console.log(
          '🚀 ~ newList ~ office.monazim:',
          office.monazim,
          data?.data?.user,
        );
        office.monazim = [...office.monazim, data?.data?.user];
        console.log('🚀🚀 ~ newList ~ office.monazim:', office.monazim);
        officeDetails = { ...office };
      }
      return office;
    });
    console.log('🚀 ~ newList ~ officeDetails:', officeDetails);
    return {
      ...state,
      officesList: [...newList],
      officeDetails,
      isAssigneesLoading: false,
    };
  }
  return {
    ...state,
    isAssigneesLoading: false,
  };
};
export const addOfficeAssigneeRequestFailure = (state, action) => {
  createNotification(
    'error',
    'Could not update office. Please try again!',
    3000,
  );
  return {
    ...state,
    isAssigneesLoading: false,
  };
};

export const removeOfficeAssigneeRequest = (state, action) => {
  return {
    ...state,
    isAssigneesLoading: true,
  };
};
export const removeOfficeAssigneeRequestSuccess = (state, action) => {
  const {
    payload: { office_id, assignee_id, data },
  } = action;
  console.log('🚀 ~ removeOfficeAssigneeRequestSuccess ~ data:', data);
  if (data.status === 204) {
    createNotification('success', 'Assignee removed successfully', 3000);
    let officeDetails;
    const newList = state.officesList.map(office => {
      if (office.id === office_id) {
        office.monazim = office.monazim.filter(
          assignee => assignee.id !== assignee_id,
        );
        officeDetails = office;
      }
      return office;
    });
    return {
      ...state,
      officesList: [...newList],
      officeDetails,
      isAssigneesLoading: false,
    };
  }
  return {
    ...state,
    isAssigneesLoading: false,
  };
};
export const removeOfficeAssigneeRequestFailure = (state, action) => {
  createNotification(
    'error',
    'Could not update office. Please try again!',
    3000,
  );
  return {
    ...state,
    isAssigneesLoading: false,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_OFFICES_LIST]: getOfficesListRequest,
  [GET_OFFICES_LIST_SUCCESS]: getOfficesListRequestSuccess,
  [GET_OFFICES_LIST_FAILURE]: getOfficesListRequestFailure,

  [GET_OFFICES_LIST_SHORT]: getOfficesShortListRequest,
  [GET_OFFICES_LIST_SHORT_SUCCESS]: getOfficesShortListRequestSuccess,
  [GET_OFFICES_LIST_SHORT_FAILURE]: getOfficesShortListRequestFailure,

  [CREATE_OFFICE]: createOfficeRequest,
  [CREATE_OFFICE_SUCCESS]: createOfficeRequestSuccess,
  [CREATE_OFFICE_FAILURE]: createOfficeRequestFailure,

  [UPDATE_OFFICE]: updateOfficeRequest,
  [UPDATE_OFFICE_SUCCESS]: updateOfficeRequestSuccess,
  [UPDATE_OFFICE_FAILURE]: updateOfficeRequestFailure,

  [ADD_OFFICE_ASSIGNEE]: addOfficeAssigneeRequest,
  [ADD_OFFICE_ASSIGNEE_SUCCESS]: addOfficeAssigneeRequestSuccess,
  [ADD_OFFICE_ASSIGNEE_FAILURE]: addOfficeAssigneeRequestFailure,

  [REMOVE_OFFICE_ASSIGNEE]: removeOfficeAssigneeRequest,
  [REMOVE_OFFICE_ASSIGNEE_SUCCESS]: removeOfficeAssigneeRequestSuccess,
  [REMOVE_OFFICE_ASSIGNEE_FAILURE]: removeOfficeAssigneeRequestFailure,

  [SET_OFFICE_DETAILS]: setOfficeDetails,

  [CLEAR_OFFICE_DETAILS]: clearOfficeDetails,

  [CLEAR_OFFICE_CREATED_UPDATED]: clearOfficeCreatedUpdated,

  [LOGIN_PROFILE_CREATED_RESET]: resetStateBeforeLogout,
};

// default initial state
const initialState = {
  isLoading: false,
  officesList: [],
  officesShortList: [],
  createdOffice: null,

  officeDetails: null,
  addOrUpdateOfficeLoading: false,

  officeCreateUpdateSuccess: false,
};

export default function officesReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
