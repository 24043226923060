import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Plus from '../../assets/img/icons/plus_white.svg';
import BottomPagination from '../../components/Pagination/BottomPagination/BottomPagination';
import TopPagination from '../../components/Pagination/TopPagination/TopPagination';
import SearchWithButton from '../../components/Search/SearchWithButton';
import {
  ButtonIcon,
  ColoredButtonWithIcon,
  TabButton,
  TabButtonContainer,
} from '../../styledComponents/buttons';
import {
  CommonGrid,
  FilterLabel,
  HeaderContainer,
  InputField,
  InputWithLabelSection,
  PageTitle,
} from '../../styledComponents/common';
import { TabLeftGrid } from '../../styledComponents/members';
import DeleteInvitationModal from './DeleteInvitationModal';
import MemberDeleteModal from './MemberDeleteModal';
import MembersFilter from './MembersFilter';
import MembersTable from './MembersTable';

const Members = ({
  officesShortList,
  getOfficesShortList,
  getMembersList,
  history,
  getInvitedMembersList,
  membersList,
  isLoading,
  updateMember,
  updatedData,
  // pagination
  totalMembersCount,
  pageSize,
  updateMemberLoading,
}) => {
  const { t } = useTranslation();
  const [memberList, setMemberList] = useState([]);
  const [tabSelect, setTabSelect] = useState('active');
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState('');

  const [userCodeSearchTerm, setUserCodeSearchTerm] = useState('');
  const [currentUserCodeSearchedTerm, setCurrentUserCodeSearchedTerm] =
    useState('');

  const [selectedRole, setSelectedRole] = useState({
    label: t('all_roles'),
    value: null,
  });

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  useEffect(() => {
    setSelectedOffice({
      label: t('all_offices'),
      value: null,
    });
    getMembersList({ page: 1 });
  }, []);

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      setMemberList(membersList);
    } else if (membersList && membersList.length === 0) {
      setMemberList([]);
    }
  }, [membersList]);

  const handleSearchByName = () => {
    const payload = {
      page: 1,
    };
    if (searchTerm && searchTerm.trim()) {
      payload.name = searchTerm.toLowerCase().trim();
      setCurrentSearchedTerm(searchTerm);
    } else {
      setCurrentSearchedTerm('');
    }
    if (selectedOffice && selectedOffice.value) {
      payload.office_id = selectedOffice.value;
    }
    if (selectedRole && selectedRole.value) {
      payload.role = selectedRole.value;
    }
    if (userCodeSearchTerm && userCodeSearchTerm.trim()) {
      payload.user_code = userCodeSearchTerm.toLowerCase().trim();
    }
    getMembersList(payload);

    setCurrentPageNumber(1);
  };
  const handleClearSearch = () => {
    if (currentSearchedTerm) {
      const payload = {
        page: 1,
      };
      if (selectedOffice && selectedOffice.value) {
        payload.office_id = selectedOffice.value;
      }
      if (selectedRole && selectedRole.value) {
        payload.role = selectedRole.value;
      }
      if (userCodeSearchTerm && userCodeSearchTerm.trim()) {
        payload.user_code = userCodeSearchTerm.toLowerCase().trim();
      }
      getMembersList(payload);

      setCurrentPageNumber(1);
    }
    setTimeout(() => {
      setSearchTerm('');
      setCurrentSearchedTerm('');
    }, 100);
  };

  const handleSearchByUserCode = () => {
    const payload = {
      page: 1,
    };
    if (userCodeSearchTerm && userCodeSearchTerm.trim()) {
      payload.user_code = userCodeSearchTerm.toLowerCase().trim();
      setCurrentUserCodeSearchedTerm(userCodeSearchTerm);
    } else {
      setCurrentUserCodeSearchedTerm('');
    }
    if (selectedOffice && selectedOffice.value) {
      payload.office_id = selectedOffice.value;
    }
    if (selectedRole && selectedRole.value) {
      payload.role = selectedRole.value;
    }
    if (searchTerm && searchTerm.trim()) {
      payload.name = searchTerm.toLowerCase().trim();
    }
    getMembersList(payload);

    setCurrentPageNumber(1);
  };
  const handleClearUserCodeSearch = () => {
    if (currentUserCodeSearchedTerm) {
      const payload = {
        page: 1,
      };
      if (selectedOffice && selectedOffice.value) {
        payload.office_id = selectedOffice.value;
      }
      if (selectedRole && selectedRole.value) {
        payload.role = selectedRole.value;
      }
      if (searchTerm && searchTerm.trim()) {
        payload.name = searchTerm.toLowerCase().trim();
      }
      getMembersList(payload);

      setCurrentPageNumber(1);
    }
    setTimeout(() => {
      setUserCodeSearchTerm('');
      setCurrentUserCodeSearchedTerm('');
    }, 100);
  };

  const handlePageChange = page => {
    // console.log(page);
    const payload = {
      page: page,
    };
    if (selectedOffice && selectedOffice.value) {
      payload.office_id = selectedOffice.value;
    }
    if (searchTerm && searchTerm.trim()) {
      payload.name = searchTerm.toLowerCase().trim();
    }
    if (userCodeSearchTerm && userCodeSearchTerm.trim()) {
      payload.user_code = userCodeSearchTerm.toLowerCase().trim();
    }
    if (selectedRole && selectedRole.value) {
      payload.role = selectedRole.value;
    }
    getMembersList(payload);
    setCurrentPageNumber(page);
  };

  const selectOffice = e => {
    const payload = {
      page: 1,
    };
    if (e && e.value) {
      payload.office_id = e.value;
    }
    if (selectedRole && selectedRole.value) {
      payload.role = selectedRole.value;
    }
    getMembersList(payload);
    setCurrentPageNumber(1);
    setSearchTerm('');
    setCurrentSearchedTerm('');
    setUserCodeSearchTerm('');
    setCurrentUserCodeSearchedTerm('');
    setSelectedOffice(e);
  };

  const handleRoleChange = e => {
    const payload = {
      page: 1,
    };
    if (e && e.value) {
      payload.role = e.value;
    }
    if (selectedOffice && selectedOffice.value) {
      payload.office_id = selectedOffice.value;
    }
    if (searchTerm && searchTerm.trim()) {
      payload.name = searchTerm.toLowerCase().trim();
    }
    if (userCodeSearchTerm && userCodeSearchTerm.trim()) {
      payload.user_code = userCodeSearchTerm.toLowerCase().trim();
    }
    getMembersList(payload);
    setCurrentPageNumber(1);
    setSelectedRole(e);
  };

  const onSearchTermChange = e => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const onUserCodeSearchTermChange = e => {
    const { value } = e.target;
    setUserCodeSearchTerm(value);
  };

  const onGeoFenceTimeClockChange = (user_id, value) => {
    updateMember({
      user_id: user_id,
      geofence_time_clock: value,
    });
  };

  return (
    <div className="content">
      {/* <MemberDeleteModal
        isOpen={isDeleteModalVisible}
        toggle={() => setIsDeleteModalVisible(!isDeleteModalVisible)}
        handleDelete={handleDeleteMember}
        isLoading={deleteMemberLoading}
      />
      <DeleteInvitationModal
        isOpen={isDeleteInvitationModalVisible}
        toggle={() =>
          setIsDeleteInvitationModalVisible(!isDeleteInvitationModalVisible)
        }
        handleDelete={handleDeleteInvitation}
        isLoading={deleteInvitationLoading}
      /> */}
      <CommonGrid alignItem="center">
        <PageTitle>{t('members')}</PageTitle>
        {localStorage.getItem('user_role') &&
          localStorage.getItem('user_role') === 'admin' &&
          !localStorage.getItem('viewer_role') && (
            <ColoredButtonWithIcon
              onClick={() => history.push('/user/member-add-update')}
            >
              <ButtonIcon src={Plus} />
              {t('add_user')}
            </ColoredButtonWithIcon>
          )}
      </CommonGrid>

      <HeaderContainer columns="1fr auto">
        <MembersFilter
          officesShortList={officesShortList}
          getOfficesShortList={getOfficesShortList}
          selectedOffice={selectedOffice}
          selectOffice={selectOffice}
          searchTerm={searchTerm}
          onSearchTermChange={onSearchTermChange}
          handleSearchByName={handleSearchByName}
          handleClearSearch={handleClearSearch}
          userCodeSearchTerm={userCodeSearchTerm}
          onUserCodeSearchTermChange={onUserCodeSearchTermChange}
          handleSearchByUserCode={handleSearchByUserCode}
          handleClearUserCodeSearch={handleClearUserCodeSearch}
          selectedRole={selectedRole}
          onRoleChange={handleRoleChange}
        />
      </HeaderContainer>
      {/* {selectedOrganization && selectedOrganization.role !== "member" && (
        <HeaderContainer>
          <TabLeftGrid>
            {selectedOrganization.plan &&
              selectedOrganization.plan.name !== "Solo" && (
                <>
                  <InputWithLabelSection>
                    <FilterLabel>Type</FilterLabel>
                    <TabButtonContainer>
                      <TabButton
                        onClick={() => onTabSwitch("active")}
                        selected={tabSelect}
                        index={"active"}
                      >
                        Active
                      </TabButton>
                      <TabButton
                        onClick={() => onTabSwitch("invited")}
                        selected={tabSelect}
                        index={"invited"}
                      >
                        Invited
                      </TabButton>
                    </TabButtonContainer>
                  </InputWithLabelSection>

                  {tabSelect && tabSelect === "active" && (
                    <SearchWithButton
                      itemName="Member"
                      searchTermName="member name"
                      searchInput={searchTerm}
                      onInputChange={onSearchTermChange}
                      handleSearch={handleSearchByName}
                      handleClearSearch={handleClearSearch}
                    />
                  )}
                </>
              )}

            <InputWithLabelSection>
                <FilterLabel>Search Member</FilterLabel>
                <InputField
                  type="text"
                  placeholder={`Search member by ${
                    tabSelect === "active" ? "name" : "email"
                  }...`}
                  value={searchTerm}
                  onChange={(e) => onSearchTermChange(e)}
                />
              </InputWithLabelSection>
          </TabLeftGrid>

          <ColoredButtonWithIcon
            onClick={() => history.push("/user/member-invitation")}
          >
            <ButtonIcon src={Plus} alt="" />
            Invite Member
          </ColoredButtonWithIcon>
        </HeaderContainer>
      )} */}

      {totalMembersCount && totalMembersCount > 0 && pageSize ? (
        <TopPagination
          itemName={t('members')}
          totalCount={totalMembersCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}

      <MembersTable
        membersList={memberList}
        history={history}
        searchTerm={searchTerm}
        isLoading={isLoading}
        updateMemberLoading={updateMemberLoading}
        onGeoFenceTimeClockChange={onGeoFenceTimeClockChange}
      />
      {totalMembersCount && totalMembersCount > 0 && pageSize ? (
        <BottomPagination
          totalCount={totalMembersCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}
    </div>
  );
};

export default Members;
