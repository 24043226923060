import { css } from "@emotion/core";
import PulseLoader from "react-spinners/PulseLoader";
import { CommonIconWhButton } from "../../styledComponents/common";

const customCss = css`
  margin: auto;
  text-align: center;
`;

const AssignButtonLoader = (props) => {
  return (
    <CommonIconWhButton
      padding={props.padding || `10px`}
      borderColor={props.borderColor}
      style={{
        height: props.height || `100%`,
        minWidth: props.width || "160px",
      }}
    >
      <PulseLoader
        size={10}
        color={props.color || "#c2cce1"}
        loading
        css={customCss}
      />
    </CommonIconWhButton>
  );
};

export default AssignButtonLoader;
