import styled, { css } from "styled-components";

// COMMON STYLES
export const PaginationButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ size }) => size || "32px"};
  width: ${({ size }) => size || "32px"};
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadii.button};
  border: 1px solid
    ${({ theme, selected }) =>
      selected
        ? theme.colors.border.primary
        : theme.colors.border.secondaryLight};
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.main.primary : theme.colors.main.white};
  font-size: 12px;
  font-weight: ${({ selected, theme }) =>
    selected ? theme.fontWeights.semibold : theme.fontWeights.regular};
  color: ${({ theme, selected }) =>
    selected ? theme.colors.main.white : theme.colors.main.textSecondary};
  &:hover {
    border-color: ${({ theme }) => theme.colors.main.primary};
  }
  ${({ previous }) =>
    previous &&
    css`
      transform: rotate(180deg);
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

// BOTTOM PAGINATION STYLES
export const BottomPaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`;
export const BottomPaginationButtonsContainer = styled.div`
  display: flex;
  grid-column-gap: 10px;
`;
export const BottomPaginationDot = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 8px;
  font-size: 18px;
`;

// TOP PAGINATION STYLES
export const TopPaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ margin }) => margin || "20px 0 0 0"};
  padding: ${({ padding }) => padding || "0"};
`;
export const DisplayCountTextSection = styled.div``;

export const TopPaginationSection = styled.div`
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.borderRadii.card};
  background-color: ${({ theme }) => theme.colors.main.white};
  padding: 5px;
`;

// OLD CODES //

export const PaginationWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: ${({ columns }) => columns || "40%"};
  @media screen and (max-width: 1400px) {
    grid-template-columns: ${({ columns }) => columns || "60%"};
  }
`;
export const PaginationContainer = styled.div`
  height: 46px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  background-color: #fff;
  border: 0.5px solid #c2cce1;
  border-radius: 10px;
`;
export const PaginationDiv = styled.div`
  width: 100%;
  display: flex;
  // grid-template-columns: 40% 20% 40%;
  justify-content: space-between;
  align-items: center;
`;

export const PaginationSection = styled.div`
  display: flex;
  align-items: center;
`;
export const ItemsPerPageText = styled.span`
  font-size: 14px;
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  color: rgba(47, 57, 78, 0.7);
  margin: ${({ margin }) => margin || "0"};
`;
export const ItemsPerPageNumber = styled.div``;

export const PageChangeButton = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eaf9f7;
  border-radius: 6px;
  cursor: pointer;
`;
export const PageChangeButtonIcon = styled.img`
  width: 12px;
  height: 8px;
`;

export const JumpToPageText = styled.span`
  font-size: 14px;
  color: rgba(47, 57, 78, 0.7);
`;
export const JumpToPageInput = styled.input`
  width: 46px;
  height: 32px;
  background: #fff;
  border: 0.5px solid #e4e7eb;
  border-radius: 8px;
  padding-left: 4px;
  text-align: center;
`;
export const JumpToPageButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 32px;
  background-color: #20bead;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  &:hover {
    background-color: #1cb1a1;
  }
`;
