import TimeDurationBig from 'custom_modules/Timesheet/Daily/TimeDurationBig';
import TimeRangeComponentTimelineModal from 'custom_modules/Timesheet/Daily/TimeRangeComponentTimelineModal';
import { t } from 'i18next';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import styled from 'styled-components';

import ButtonTextLoader from '../../components/Loaders/ButtonTextLoader';
import { ColoredButton, WhiteButton } from '../../styledComponents/buttons';
import {
  CommonFlex,
  CommonGrid,
  FormAlert,
  InputLabel,
  InputWithLabelSection,
  RedStar,
} from '../../styledComponents/common';
import { ModalButtonSection } from '../../styledComponents/members';
import { TimeRangeSection } from './CreateTask/createTaskStyles';

const DateRangePickerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isApply, theme }) =>
    isApply ? theme.button.primary.bgColor : theme.button.secondary.bgColor};
  border: 1px solid
    ${({ isApply, theme }) => !isApply && theme.button.secondary.borderColor};
  color: ${({ isApply, theme }) =>
    isApply
      ? theme.button.primary.textColor
      : theme.button.secondary.textColor};
  height: 36px;
  padding: 0px 10px;
  cursor: pointer;
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  border-radius: ${({ theme }) => theme.button.borderRadius};
  margin: ${({ margin }) => margin || '0'};
  &:hover {
    background-color: ${({ isApply, theme }) =>
      isApply
        ? theme.button.primary.hoverBgColor
        : theme.button.secondary.hoverBgColor};
    color: ${({ theme }) => theme.button.secondary.hoverTextColor};
  }
`;
const ChangeTimelineModal = ({
  isOpen,
  toggle,
  updateDate,
  isLoading,
  updating,
}) => {
  const defaultTimeDifference = 60;

  const [startDate, setStartDate] = useState(
    moment(new Date()).add(5, 'minutes'),
  );
  const [endDate, setEndDate] = useState(moment(new Date()).add(65, 'minutes'));

  const [startTime, setStartTime] = useState(
    moment().add(5, 'minutes').format('HH:mm'),
  );
  const [endTime, setEndTime] = useState(
    moment().add(65, 'minutes').format('HH:mm'),
  );
  const [saveErrors, setSaveErrors] = useState({});
  const [updateWithDefaultTime, setUpdateWithDefaultTime] = useState(false);
  console.log(startDate, startTime, endDate, endTime);
  const onStartDateChange = date => {
    let errors = { ...saveErrors };
    delete errors['timeDifferenceStartTime'];
    setSaveErrors(errors);
    setStartDate(date);
  };
  const onEndDateChange = date => {
    let errors = { ...saveErrors };
    delete errors['timeDifferenceEndTime'];
    setSaveErrors(errors);
    setEndDate(date);
  };
  const handleStartTime = time => {
    if (time) {
      let errors = { ...saveErrors };
      delete errors['timeDifferenceStartTime'];
      setSaveErrors(errors);
      const start_time = time.replace(/ /g, '');
      setStartTime(moment(start_time, 'h:mmA').format('HH:mm:ss'));
    } else {
      setStartTime('');
    }
  };
  const handleEndTime = time => {
    if (time) {
      let errors = { ...saveErrors };
      delete errors['timeDifferenceEndTime'];
      setSaveErrors(errors);
      const end_time = time.replace(/ /g, '');
      setEndTime(moment(end_time, 'h:mmA').format('HH:mm:ss'));
    } else {
      setEndTime('');
    }
  };
  const selectLastWeek = () => {
    setStartDate(moment().subtract(6, 'days').format('YYYY-MM-DD'));
    setEndDate(moment().format('YYYY-MM-DD'));
  };
  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;

    if (!startDate) {
      formIsValid = false;
    }
    if (!startTime || !endTime) {
      formIsValid = false;
    }
    if (
      moment(endDate.format('YYYY-MM-DD') + ' ' + endTime).diff(
        moment(startDate.format('YYYY-MM-DD') + ' ' + startTime),
      ) < 1
    ) {
      formIsValid = false;
      saveErrors['timeDifferenceEndTime'] = t(
        'end_time_must_be_after_start_time',
      );
    } else if (
      moment(endDate.format('YYYY-MM-DD') + ' ' + endTime).diff(
        moment(startDate.format('YYYY-MM-DD') + ' ' + startTime),
      ) < 1800000
    ) {
      formIsValid = false;
      saveErrors['timeDifferenceEndTime'] = t(
        'Minimum 30 minutes time difference required',
      );
    }
    if (
      moment(startDate.format('YYYY-MM-DD') + ' ' + startTime).diff(moment()) <
      1
    ) {
      formIsValid = false;
      saveErrors['timeDifferenceStartTime'] = t('past_time_cant_be_set');
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleDefaultTimes = minutes => {
    setStartDate(moment(new Date()).add(minutes, 'minutes'));
    setEndDate(
      moment(new Date()).add(minutes + defaultTimeDifference, 'minutes'),
    );
    setStartTime(moment(new Date()).add(minutes, 'minutes').format('HH:mm:ss'));
    setEndTime(
      moment(new Date())
        .add(minutes + defaultTimeDifference, 'minutes')
        .format('HH:mm:ss'),
    );
    setUpdateWithDefaultTime(true);
  };

  const handleUpdate = () => {
    if (checkError()) {
      if (startDate && startTime && endDate && endTime) {
        const required_start_date = moment(
          startDate.format('YYYY-MM-DD') + ' ' + startTime,
        );
        const required_end_date = moment(
          endDate.format('YYYY-MM-DD') + ' ' + endTime,
        );
        updateDate({ required_start_date, required_end_date });
        setUpdateWithDefaultTime(false);
        toggle();
      }
    }
  };

  useEffect(() => {
    updateWithDefaultTime && handleUpdate();
  }, [updateWithDefaultTime]);
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ width: `80vw !important`, maxWidth: `800px` }}
    >
      <ModalBody style={{ padding: `60px` }}>
        <CommonGrid columns="1fr 1fr" style={{ width: '200px !important' }}>
          <CommonFlex direction="column" gap="25px">
            <CommonFlex
              justifyContent="space-between"
              wrap="wrap"
              gap="20px 40px"
            >
              <DateRangePickerButton onClick={() => handleDefaultTimes(5)}>
                {t('start_now')}
              </DateRangePickerButton>
              <DateRangePickerButton onClick={() => handleDefaultTimes(30)}>
                {t('start_within_30_min')}
              </DateRangePickerButton>
              <DateRangePickerButton onClick={() => handleDefaultTimes(120)}>
                {t('start_within_2_h')}
              </DateRangePickerButton>
              <DateRangePickerButton onClick={() => handleDefaultTimes(180)}>
                {t('start_within_3_h')}
              </DateRangePickerButton>
            </CommonFlex>
            <InputWithLabelSection>
              <TimeRangeSection>
                <InputLabel>
                  {t('start_date')}
                  <RedStar>*</RedStar>
                </InputLabel>
                <InputLabel>
                  {t('start_time')}
                  <RedStar>*</RedStar>
                </InputLabel>
              </TimeRangeSection>
              <TimeRangeComponentTimelineModal
                initialTime={startTime}
                selectedDate={startDate}
                onDateChange={onStartDateChange}
                setTime={handleStartTime}
              />
              {saveErrors &&
                saveErrors['timeDifferenceStartTime'] &&
                saveErrors['timeDifferenceStartTime'] !== undefined && (
                  <FormAlert>{saveErrors['timeDifferenceStartTime']}</FormAlert>
                )}
            </InputWithLabelSection>
            <CommonFlex direction="column" alignItems="start" gap="5px">
              <InputWithLabelSection>
                <TimeRangeSection>
                  <InputLabel>
                    {t('end_date')}
                    <RedStar>*</RedStar>
                  </InputLabel>
                  <InputLabel>
                    {t('end_time')}
                    <RedStar>*</RedStar>
                  </InputLabel>
                </TimeRangeSection>
                <TimeRangeComponentTimelineModal
                  initialTime={endTime}
                  selectedDate={endDate}
                  onDateChange={onEndDateChange}
                  setTime={handleEndTime}
                  mode="end"
                />
                {saveErrors &&
                  saveErrors['timeDifferenceEndTime'] &&
                  saveErrors['timeDifferenceEndTime'] !== undefined && (
                    <FormAlert>{saveErrors['timeDifferenceEndTime']}</FormAlert>
                  )}
              </InputWithLabelSection>
            </CommonFlex>
          </CommonFlex>
          <CommonFlex
            direction="column"
            gap="25px"
            justifyContent="space-between"
          >
            <TimeDurationBig
              startDate={startDate}
              endDate={endDate}
              startTime={startTime}
              endTime={endTime}
            />
            <CommonFlex>
              <WhiteButton type="cancel" onClick={toggle}>
                {t('cancel')}
              </WhiteButton>
              <ColoredButton
                type="black"
                padding={isLoading ? '0 15px' : '0 30px'}
                onClick={() => handleUpdate()}
              >
                {updating ? (
                  <ButtonTextLoader loadingText="Updating" fontSize="13px" />
                ) : (
                  t('update')
                )}
              </ColoredButton>
            </CommonFlex>
          </CommonFlex>
        </CommonGrid>
        <ModalButtonSection></ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default ChangeTimelineModal;
