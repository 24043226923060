import ReactDateRangePicker from 'components/ReactDateRangePicker/ReactDateRangePicker';
import { TaskStatusOptions } from 'modules/constants';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AddSingleMemberDropdown from '../../components/CustomDropdown/AddSingleMemberDropdown';
import CreateProjectDropdown from '../../components/DropdownStyle/CreateProjectDropdown';
import FilterDropDownStyle from '../../components/DropdownStyle/FilterDropDownStyle';
import Select from '../../components/ReactSelectDropdown';
import { filterOption } from '../../components/ReactSelectDropdown/ReactSelectDropdown';
import SearchWithButton from '../../components/Search/SearchWithButton';
import {
  FilterLabel,
  InputWithLabelSection,
} from '../../styledComponents/common';
import { FilterContainer } from '../../styledComponents/tasks';

const TaskFilter = props => {
  const { t } = useTranslation();
  const [officeOptions, setOfficeOptions] = useState([
    {
      label: t('all_offices'),
      value: null,
    },
  ]);

  const [memberOptions, setMemberOptions] = useState([]);
  const statusOptions = TaskStatusOptions?.map(option => ({
    ...option,
    label: t(option.key),
  }));

  const {
    selectedOrganization,
    getOrganizationMembersList,
    organizationMembersList,
    getOfficesShortList,
    officesShortList,
    selectedOffice,
    selectOffice,
    selectedMember,
    selectProject,
    selectMember,
    clearMember,
    taskStatus,
    handleStatusChange,
    selectedDate,
    handleDateChange,
    handleSearchByName,
    handleClearSearch,
    searchTerm,
    onSearchTermChange,
  } = props;
  const [selectedDates, setSelectedDates] = useState(selectedDate);
  const selectLastMonth = () => {
    setSelectedDates({
      startDate: moment().subtract(29, 'days'),
      endDate: moment(),
    });
  };
  const selectLastWeek = () => {
    setSelectedDates({
      startDate: moment().subtract(7, 'days'),
      endDate: moment(),
    });
  };
  const onDateApply = () => {
    handleDateChange(selectedDates);
  };
  useEffect(() => {
    getOfficesShortList();
    getOrganizationMembersList({ office_id: null });
  }, []);

  useEffect(() => {
    if (officesShortList && officesShortList.length === 1) {
      getOrganizationMembersList({ office_id: officesShortList[0].id });
    }
    if (officesShortList && officesShortList.length >= 1) {
      let newArr = [
        {
          label: t('all_offices'),
          value: null,
        },
      ];
      officesShortList.forEach((office, i) => {
        newArr.push({
          label: office.name,
          value: office.id,
        });
      });
      setOfficeOptions(newArr);
    }
  }, [officesShortList]);

  useEffect(() => {
    if (
      selectedOffice &&
      selectedOffice.value &&
      officesShortList &&
      officesShortList.length > 1
    ) {
      getOrganizationMembersList({ office_id: selectedOffice.value });
    }
  }, [selectedOffice]);

  //   useEffect(() => {
  //     if (projectAssigneeList && projectAssigneeList.length > 0) {
  //       let assigneeList = [];
  //       projectAssigneeList.map((assignee) => {
  //         assigneeList.push({
  //           id: assignee.user.id,
  //           name: `${assignee.user.first_name}${
  //             assignee.user.last_name ? ` ${assignee.user.last_name}` : ``
  //           }`,
  //           avatar: assignee.user.avatar ? assignee.user.avatar : avatar5,
  //         });
  //       });
  //       setMemberOptions(assigneeList);
  //     } else if (projectAssigneeList && projectAssigneeList.length === 0) {
  //       setMemberOptions([]);
  //     }
  //   }, [projectAssigneeList]);

  //   useEffect(() => {
  //     if ((selectedProject && !selectedProject.value) || !selectedProject) {
  //       setMemberOptions(organizationMembersList);
  //     }
  //   }, [selectedProject]);

  const onMemberSelect = person => {
    selectMember(person);
  };

  const clearSelectedMember = () => {
    clearMember();
  };

  return (
    <FilterContainer admin={localStorage.getItem('user_role') === 'admin'}>
      {localStorage.getItem('user_role') === 'admin' && (
        <InputWithLabelSection>
          <FilterLabel>{t('office')}</FilterLabel>
          <Select
            isSearchable
            value={selectedOffice}
            options={officeOptions}
            placeholder="Select Office..."
            onChange={e => {
              selectOffice(e);
            }}
            styles={CreateProjectDropdown()}
            filterOption={filterOption}
          />
        </InputWithLabelSection>
      )}

      <InputWithLabelSection>
        <FilterLabel>{t('assignee')}</FilterLabel>
        <div style={{ width: `40px` }}>
          <AddSingleMemberDropdown
            assigneeManage={selectedMember}
            updateState={onMemberSelect}
            membersList={organizationMembersList}
            clearAssignee={() => clearMember()}
          />
        </div>
      </InputWithLabelSection>
      <SearchWithButton
        itemName={t('ticket')}
        searchTermName={t('ticket_name')}
        searchInput={searchTerm}
        onInputChange={onSearchTermChange}
        handleSearch={handleSearchByName}
        handleClearSearch={handleClearSearch}
      />
      <InputWithLabelSection>
        <FilterLabel>{t('date_range')}</FilterLabel>
        {/* <DatePicker
          id="createIssuedDate"
          date={selectedDate}
          onDateChange={handleDateChange}
          dateDisplayFormat={'DD/MM/YYYY'}
          placeholder={'Select date'}
          allowPreviousDates
        /> */}
        <ReactDateRangePicker
          startDate={selectedDates.startDate ? selectedDates.startDate : null}
          startDateId="custom-date-range-start-date"
          endDate={selectedDates.endDate ? selectedDates.endDate : null}
          endDateId="custom-date-range-start-date"
          onDatesChange={setSelectedDates}
          dateDisplayFormat="DD/MM/YYYY"
          onDateSelect={onDateApply}
          selectLastWeek={selectLastWeek}
          selectLastMonth={selectLastMonth}
        />
      </InputWithLabelSection>
      <InputWithLabelSection>
        <FilterLabel>{t('status')}</FilterLabel>
        <Select
          isSearchable={false}
          value={taskStatus}
          options={statusOptions}
          placeholder="Status"
          onChange={e => {
            handleStatusChange(e);
          }}
          styles={FilterDropDownStyle({
            height: '40px',
            width: '130px',
            menuWidth: '130px',
          })}
        />
      </InputWithLabelSection>
    </FilterContainer>
  );
};

export default TaskFilter;
