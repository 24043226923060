import {
  REQUEST_USER_PROFILE,
  CREATE_USER_PROFILE,
  DELETE_ACCOUNT,
  GET_USER_EMAILS,
  ADD_EMAIL,
  DELETE_EMAIL,
  MAKE_EMAIL_DEFAULT,
} from "modules/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function profileOperation(value) {
  return {
    type: REQUEST_USER_PROFILE,
    payload: value,
  };
}

export function profileCreate(value) {
  return {
    type: CREATE_USER_PROFILE,
    payload: value,
  };
}

export function profileDelete(value) {
  return {
    type: DELETE_ACCOUNT,
    payload: value,
  };
}

export function getUserEmails() {
  return {
    type: GET_USER_EMAILS,
  };
}

export function addEmail(value) {
  return {
    type: ADD_EMAIL,
    payload: value,
  };
}

export function deleteEmail(value) {
  return {
    type: DELETE_EMAIL,
    payload: value,
  };
}

export function makeEmailDefault(value) {
  return {
    type: MAKE_EMAIL_DEFAULT,
    payload: value,
  };
}

export const actions = {
  profileOperation,
  profileCreate,
  profileDelete,
  getUserEmails,
  addEmail,
  deleteEmail,
  makeEmailDefault,
};
