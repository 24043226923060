import { MonthChangeIcon } from 'custom_modules/Leave/LeaveCalendar/leaveCalendarStyles';
import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { CommonFlex } from 'styledComponents/common';
import { ArrowIconContainer } from 'styledComponents/tasks';

import RotateLeftIcon from '../../../assets/img/icons/RotateLeft.svg';
import RotateRightIcon from '../../../assets/img/icons/RotateRight.svg';
import RightArrow from '../../../assets/img/icons/arrow_right_white.svg';

const EvidencesModal = ({
  isOpen,
  toggle,
  isLoading,
  evidences,
  width,
  selectedEvidence,
}) => {
  const [active, setActive] = useState(selectedEvidence);
  const [rotation, setRotation] = useState(0);

  const goToNext = () => {
    setActive((active + 1) % evidences.length);
    setRotation(0);
  };
  const goToPrevious = () => {
    setActive((active + evidences.length - 1) % evidences.length);
    setRotation(0);
  };
  const rotateLeft = () => {
    setRotation(rotation - 90);
  };

  const rotateRight = () => {
    setRotation(rotation + 90);
  };

  useEffect(() => {
    setActive(selectedEvidence);
    setRotation(0);
  }, [selectedEvidence]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: `5vh`, width: `70vw`, maxWidth: `70vw` }}
    >
      <ModalBody style={{ padding: `15px 25px`, display: `grid` }}>
        <CommonFlex justifyContent="space-between">
          <ArrowIconContainer onClick={() => goToPrevious()}>
            <MonthChangeIcon
              src={RightArrow}
              alt=""
              width="18px"
              height="18px"
              left
            />
          </ArrowIconContainer>
          <CommonFlex
            style={{
              height: '70vh',
              width: '70vh',
              margin: `0 auto`,
              position: 'relative',
              overflow: 'hidden',
              // backgroundColor: '#f0f0f0',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {evidences?.map((evidence, index) =>
              index === active ? (
                <div
                  key={index}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: `translate(-50%, -50%) rotate(${rotation}deg)`,
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <img
                    src={evidence.image}
                    alt=""
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </div>
              ) : (
                <React.Fragment key={index}></React.Fragment>
              ),
            )}
          </CommonFlex>
          <CommonFlex direction="column">
            <ArrowIconContainer onClick={() => goToNext()}>
              <MonthChangeIcon
                src={RightArrow}
                alt=""
                width="18px"
                height="18px"
              />
            </ArrowIconContainer>

            <CommonFlex
              direction="column"
              style={{
                position: 'absolute',
                top: '60%',
              }}
            >
              <div onClick={rotateLeft} style={{ cursor: 'pointer' }}>
                <MonthChangeIcon
                  src={RotateLeftIcon}
                  alt="Rotate Left"
                  width="30px"
                  height="30px"
                />
              </div>
              <div onClick={rotateRight} style={{ cursor: 'pointer' }}>
                <MonthChangeIcon
                  src={RotateRightIcon}
                  alt="Rotate Right"
                  width="30px"
                  height="30px"
                />
              </div>
            </CommonFlex>
          </CommonFlex>
        </CommonFlex>
      </ModalBody>
    </Modal>
  );
};

export default EvidencesModal;
