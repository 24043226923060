import {
  GET_JOB_SITES_LIST,
  GET_JOB_SITES_LIST_SUCCESS,
  GET_JOB_SITES_LIST_FAILURE,
  ADD_JOB_SITE,
  ADD_JOB_SITE_SUCCESS,
  ADD_JOB_SITE_FAILURE,
  UPDATE_JOB_SITE,
  UPDATE_JOB_SITE_SUCCESS,
  UPDATE_JOB_SITE_FAILURE,
  DELETE_JOB_SITE,
  DELETE_JOB_SITE_SUCCESS,
  DELETE_JOB_SITE_FAILURE,
  ADD_JOB_SITE_ASSIGNEE,
  ADD_JOB_SITE_ASSIGNEE_SUCCESS,
  ADD_JOB_SITE_ASSIGNEE_FAILURE,
  REMOVE_JOB_SITE_ASSIGNEE,
  REMOVE_JOB_SITE_ASSIGNEE_SUCCESS,
  REMOVE_JOB_SITE_ASSIGNEE_FAILURE,
  SET_JOB_SITE_DETAILS,
  CLEAR_JOB_SITE_DETAILS,
  LOGIN_PROFILE_CREATED_RESET,
} from "../../../modules/constants";

import { createNotification } from "../../../modules/notificationManager";

export const jobSitesListRequestHandler = (state, action) => {
  return {
    ...state,
    sitesListLoading: true,
  };
};
export const jobSitesListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      sitesListLoading: false,
      jobSitesList: data.data,
    };
  }
  return {
    ...state,
    sitesListLoading: false,
    jobSitesList: [],
  };
};
export const jobSitesListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    sitesListLoading: false,
    jobSitesList: [],
  };
};

export const addJobSiteRequestHandler = (state, action) => {
  return {
    ...state,
    addOrUpdateJobSiteLoading: true,
  };
};
export const addJobSiteRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;

  if (data.status === 201 && data.data) {
    createNotification("success", "Job site added successfully.", 3000);
    const details = JSON.parse(JSON.stringify(data.data));
    details.location = {
      lat: details.lat,
      lng: details.long,
    };
    details.assignees = [];
    return {
      ...state,
      addOrUpdateJobSiteLoading: false,
      jobSitesList: [details, ...state.jobSitesList],
      jobSiteDetails: { ...details },
    };
  }
  return {
    ...state,
    addOrUpdateJobSiteLoading: false,
  };
};
export const addJobSiteRequestFailureHandler = (state, action) => {
  createNotification("error", "Could not add jobsite. Please try again!", 3000);
  return {
    ...state,
    addOrUpdateJobSiteLoading: false,
  };
};

export const updateJobSiteRequestHandler = (state, action) => {
  return {
    ...state,
    addOrUpdateJobSiteLoading: true,
  };
};
export const updateJobSiteRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;

  if (data.status === 200 && data.data) {
    createNotification("success", "Job site updated successfully.", 3000);
    const currentList = JSON.parse(JSON.stringify(state.jobSitesList));
    const details = JSON.parse(JSON.stringify(data.data));
    details.location = {
      lat: details.lat,
      lng: details.long,
    };
    currentList.some((jobsite, index) => {
      if (jobsite.id === data.data.id) {
        currentList[index] = details;
        return true;
      }
      return false;
    });

    return {
      ...state,
      addOrUpdateJobSiteLoading: false,
      jobSitesList: [...currentList],
      jobSiteDetails: { ...details },
    };
  }
  return {
    ...state,
    addOrUpdateJobSiteLoading: false,
  };
};
export const updateJobSiteRequestFailureHandler = (state, action) => {
  createNotification(
    "error",
    "Could not update jobsite. Please try again!",
    3000
  );
  return {
    ...state,
    addOrUpdateJobSiteLoading: false,
  };
};

export const deleteJobSiteRequestHandler = (state, action) => {
  return {
    ...state,
    deleteJobSiteLoading: true,
  };
};
export const deleteJobSiteRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, jobsite_id },
  } = action;

  if (data.status === 204 && jobsite_id) {
    createNotification("success", "Job site deleted successfully.", 3000);
    let currentList = JSON.parse(JSON.stringify(state.jobSitesList));

    currentList = currentList.filter((jobSite) => jobSite.id !== jobsite_id);

    return {
      ...state,
      deleteJobSiteLoading: false,
      jobSitesList: [...currentList],
    };
  }
  return {
    ...state,
    deleteJobSiteLoading: false,
  };
};
export const deleteJobSiteRequestFailureHandler = (state, action) => {
  createNotification(
    "error",
    "Could not delete jobsite. Please try again!",
    3000
  );
  return {
    ...state,
    deleteJobSiteLoading: false,
  };
};

export const addJobSiteAssigneeRequestHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    assigneeIsLoading: true,
    loadingAssigneeId: payload.user_id,
  };
};
export const addJobSiteAssigneeRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, jobsite_id, user },
  } = action;

  if (data.status === 201 && user && jobsite_id) {
    const assigneeData = {
      id: user.id,
      first_name: user.name.split(" ")[0],
      last_name: user.name.split(" ")[1],
      avatar: user.avatar,
    };

    const currentList = [...state.jobSitesList];
    currentList.some((jobsite) => {
      if (jobsite.id === jobsite_id) {
        jobsite.assignees = [...jobsite.assignees, assigneeData];
        return true;
      }
      return false;
    });

    const currentDetails = { ...state.jobSiteDetails };
    if (currentDetails.id === jobsite_id) {
      currentDetails.assignees = [...currentDetails.assignees, assigneeData];
    }

    return {
      ...state,
      assigneeIsLoading: false,
      loadingAssigneeId: null,
      jobSitesList: [...currentList],
      jobSiteDetails: { ...currentDetails },
    };
  }
  return {
    ...state,
    assigneeIsLoading: false,
    loadingAssigneeId: null,
  };
};
export const addJobSiteAssigneeRequestFailureHandler = (state, action) => {
  createNotification(
    "error",
    "Could not add assignee to jobsite. Please try again!",
    3000
  );
  return {
    ...state,
    assigneeIsLoading: false,
    loadingAssigneeId: null,
  };
};

export const removeJobSiteAssigneeRequestHandler = (state, action) => {
  return {
    ...state,
    assigneeIsLoading: true,
  };
};
export const removeJobSiteAssigneeRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, jobsite_id, user_id },
  } = action;

  if (data && data.status === 204 && jobsite_id && user_id) {
    const currentList = [...state.jobSitesList];

    currentList.some((jobsite) => {
      if (jobsite.id === jobsite_id) {
        const filteredAssignees = jobsite.assignees.filter(
          (assignee) => assignee.id !== user_id
        );
        jobsite.assignees = [...filteredAssignees];
        return true;
      }
      return false;
    });

    const currentDetails = { ...state.jobSiteDetails };
    if (currentDetails.id === jobsite_id) {
      const filteredAssignees = currentDetails.assignees.filter(
        (assignee) => assignee.id !== user_id
      );
      currentDetails.assignees = [...filteredAssignees];
    }
    return {
      ...state,
      assigneeIsLoading: false,
      jobSitesList: [...currentList],
      jobSiteDetails: { ...currentDetails },
    };
  }
  return {
    ...state,
    assigneeIsLoading: false,
  };
};
export const removeJobSiteAssigneeRequestFailureHandler = (state, action) => {
  createNotification(
    "error",
    "Could not remove assignee from jobsite. Please try again!",
    3000
  );
  return {
    ...state,
    assigneeIsLoading: false,
  };
};

export const setJobSiteDetailsHandler = (state, action) => {
  const { payload } = action;

  if (payload && payload.id) {
    return {
      ...state,
      jobSiteDetails: { ...payload },
    };
  }
  return {
    ...state,
  };
};
export const clearJobSiteDetailsHandler = (state, action) => {
  return {
    ...state,
    jobSiteDetails: null,
  };
};

export const resetStateBeforeLogout = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_JOB_SITES_LIST]: jobSitesListRequestHandler,
  [GET_JOB_SITES_LIST_SUCCESS]: jobSitesListRequestSuccessHandler,
  [GET_JOB_SITES_LIST_FAILURE]: jobSitesListRequestFailureHandler,

  [ADD_JOB_SITE]: addJobSiteRequestHandler,
  [ADD_JOB_SITE_SUCCESS]: addJobSiteRequestSuccessHandler,
  [ADD_JOB_SITE_FAILURE]: addJobSiteRequestFailureHandler,

  [UPDATE_JOB_SITE]: updateJobSiteRequestHandler,
  [UPDATE_JOB_SITE_SUCCESS]: updateJobSiteRequestSuccessHandler,
  [UPDATE_JOB_SITE_FAILURE]: updateJobSiteRequestFailureHandler,

  [DELETE_JOB_SITE]: deleteJobSiteRequestHandler,
  [DELETE_JOB_SITE_SUCCESS]: deleteJobSiteRequestSuccessHandler,
  [DELETE_JOB_SITE_FAILURE]: deleteJobSiteRequestFailureHandler,

  [ADD_JOB_SITE_ASSIGNEE]: addJobSiteAssigneeRequestHandler,
  [ADD_JOB_SITE_ASSIGNEE_SUCCESS]: addJobSiteAssigneeRequestSuccessHandler,
  [ADD_JOB_SITE_ASSIGNEE_FAILURE]: addJobSiteAssigneeRequestFailureHandler,

  [REMOVE_JOB_SITE_ASSIGNEE]: removeJobSiteAssigneeRequestHandler,
  [REMOVE_JOB_SITE_ASSIGNEE_SUCCESS]:
    removeJobSiteAssigneeRequestSuccessHandler,
  [REMOVE_JOB_SITE_ASSIGNEE_FAILURE]:
    removeJobSiteAssigneeRequestFailureHandler,

  [SET_JOB_SITE_DETAILS]: setJobSiteDetailsHandler,

  [CLEAR_JOB_SITE_DETAILS]: clearJobSiteDetailsHandler,

  [LOGIN_PROFILE_CREATED_RESET]: resetStateBeforeLogout,
};

// default initial state
const initialState = {
  sitesListLoading: false,
  addOrUpdateJobSiteLoading: false,
  deleteJobSiteLoading: false,

  assigneeIsLoading: false,
  loadingAssigneeId: null,

  jobSitesList: [],
  jobSiteDetails: null,
};

export default function clientReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
