import { connect } from 'react-redux';

// Component
import Dashboard from './Dashboard';
// Actions
import {
  getDashboardLateCount,
  getDashboardPerformance,
  getDashboardStatus,
  getDashboardTaskProgress,
  getDashboardTaskSummary,
  getDashboardTopLowPerformers,
  getDashboardUserAnalytics,
} from './dashboardActions';

// Store props to map with current state
const mapStateToProps = state => ({
  dashboardData: state.dashboard,
  employeeStatusIsloading: state.dashboard.employeeStatusIsloading,
  employee_status: state.dashboard.employee_status,
  taskProgressIsLoading: state.dashboard.taskProgressIsLoading,
  task_progress_overview: state.dashboard.task_progress_overview,
  performanceComparisonIsLoading:
    state.dashboard.performanceComparisonIsLoading,
  performance_comparison: state.dashboard.performance_comparison,
  lateCountIsloading: state.dashboard.lateCountIsloading,
  late_count_comparison: state.dashboard.late_count_comparison,
  topLowPerformersIsLoading: state.dashboard.topLowPerformersIsLoading,
  top_low_performers: state.dashboard.top_low_performers,
  taskSummaryIsLoading: state.dashboard.taskSummaryIsLoading,
  task_Summary: state.dashboard.task_Summary,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getDashboardLateCount: details =>
    dispatch(getDashboardLateCount({ ...details })),
  getDashboardPerformance: details =>
    dispatch(getDashboardPerformance({ ...details })),
  getDashboardStatus: details => dispatch(getDashboardStatus({ ...details })),
  getDashboardTaskProgress: details =>
    dispatch(getDashboardTaskProgress({ ...details })),
  getDashboardTaskSummary: details =>
    dispatch(getDashboardTaskSummary({ ...details })),
  getDashboardTopLowPerformers: details =>
    dispatch(getDashboardTopLowPerformers({ ...details })),
  // getDashboardUserAnalytics: details =>
  //   dispatch(getDashboardUserAnalytics({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
