import {
  GET_WEEKLY_EMAIL_SETTINGS,
  UPDATE_WEEKLY_EMAIL_SETTINGS,
  UPDATE_LOCATION_SETTINGS,
  UPDATE_GEOFENCE_SETTINGS,
} from "../../modules/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function getWeeklyEmailSettings(value) {
  return {
    type: GET_WEEKLY_EMAIL_SETTINGS,
    payload: value,
  };
}

export function updateWeeklyEmailSettings(value) {
  return {
    type: UPDATE_WEEKLY_EMAIL_SETTINGS,
    payload: value,
  };
}

export function updateLocationSettings(value) {
  return {
    type: UPDATE_LOCATION_SETTINGS,
    payload: value,
  };
}

export function updateGeofenceSettings(value) {
  return {
    type: UPDATE_GEOFENCE_SETTINGS,
    payload: value,
  };
}
