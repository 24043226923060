import { connect } from 'react-redux';

import { getOfficesShortList } from '../../Offices/officesActions';
import { getOrganizationMembersList } from '../../Projects/projectsActions';
import {
  addTaskLocation,
  createTask,
  getTaskLocation,
  getTaskType,
} from '../tasksActions';
// Component
import CreateTask from './CreateTask';

// Store props to map with current state
const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  orgMembersList: state.project.orgMembersList,
  officesShortList: state.office.officesShortList,
  memberShortListIsLoading: state.project.memberShortListIsLoading,
  isLoading: state.task.isLoading,
  addTaskLocationLoading: state.task.addTaskLocationLoading,
  createdTaskInfo: state.task.createdTaskInfo,
  locationsList: state.task.locationsList,
  createdTaskLocationInfo: state.task.createdTaskLocationInfo,
  taskTypeIsLoading: state.task.taskTypeIsLoading,
  taskTypeList: state.task.taskTypeList,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getOrganizationMembersList: params =>
    dispatch(getOrganizationMembersList(params)),
  createTask: params => dispatch(createTask(params)),
  getTaskLocation: params => dispatch(getTaskLocation(params)),
  addTaskLocation: params => dispatch(addTaskLocation(params)),
  getOfficesShortList: params => dispatch(getOfficesShortList(params)),
  getTaskType: params => dispatch(getTaskType(params)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(CreateTask);
