import { SelectedMonazimWrapper } from 'custom_modules/Members/memberStyles';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';

import Avatar5 from '../../assets/img/avatar_5.svg';
import Assign from '../../assets/img/icons/assign.svg';
import removeAssignee from '../../assets/img/icons/remove_assignee.svg';
import searchIcon from '../../assets/img/icons/search.svg';
import {
  AssignListContainer,
  AssignListItem,
  AssignListText,
  AssigneeSearchBarContainer,
  AssigneeSearchBarWrapper,
  AssigneeSearchIcon,
  AssigneeSearchInput,
  CommonFlex,
  CommonText,
  SearchBarWithAssignListContainer,
} from '../../styledComponents/common';
import {
  AssigneeRoundImage,
  RoundImageCross,
  RoundPersonImage,
} from '../../styledComponents/createProject';
import AssigneeTooltip from '../Tooltip/AssigneeTooltip';

const SelectSingleMonazimDropdown = ({
  assigneeManage,
  index,
  updateState,
  membersList,
  clearAssignee,
}) => {
  console.log('🚀 ~ assigneeManage:', assigneeManage);
  const [addAssignee, setAddAssignee] = useState(false);
  const wrapperRef1 = useRef(null);
  const wrapperRef2 = useRef(null);
  const [addList, setAddList] = useState([]);
  console.log('🚀 ~ addList:', addList);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      let newArr = membersList;
      if (assigneeManage) {
        newArr = newArr.filter(item => item.id !== assigneeManage.id);
      }
      setAddList(newArr);
    } else if (membersList && membersList.length === 0) {
      setAddList([]);
    }
  }, [membersList, assigneeManage]);

  useEffect(() => {
    if (
      searchTerm &&
      searchTerm !== '' &&
      membersList &&
      membersList.length > 0
    ) {
      let originalList = JSON.parse(JSON.stringify(membersList));
      let filteredList = [];
      originalList.forEach(e => {
        if (
          searchTerm &&
          e.name.toLowerCase().indexOf(searchTerm.toLowerCase().trim()) === -1
        ) {
          return;
        }
        filteredList.push(e);
      });
      if (assigneeManage) {
        filteredList = filteredList.filter(
          item => item.id !== assigneeManage.id,
        );
      }
      setAddList(filteredList);
    } else if (searchTerm === '') {
      let newArr = membersList;
      if (assigneeManage) {
        newArr = newArr.filter(item => item.id !== assigneeManage.id);
      }
      setAddList(newArr);
    }
  }, [searchTerm]);

  const onSearchTermChange = e => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  //function
  useOutsideClickHandler(wrapperRef1, wrapperRef2);

  const showAssignee = () => {
    setAddAssignee(!addAssignee);
  };

  function useOutsideClickHandler(ref1, ref2) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref1.current &&
          !ref1.current.contains(event.target) &&
          ref2.current &&
          !ref2.current.contains(event.target)
        ) {
          setAddAssignee(false);
          setSearchTerm('');
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref1, ref2]);
  }
  return (
    <div style={{ position: `relative` }}>
      <SelectedMonazimWrapper
        index={0}
        ref={wrapperRef2}
        onClick={() => showAssignee()}
      >
        {assigneeManage ? (
          <>
            <CommonText onClick={() => showAssignee()}>
              {assigneeManage.name}
            </CommonText>
            {/* {assigneeManage.name && (
              <AssigneeTooltip
                toolTipText={assigneeManage.name}
                height="35px"
                width="200px"
                top="-42px"
                left="-80px"
                minCharToShow={30}
              />
            )}
            <RoundPersonImage
              src={assigneeManage.avatar ? assigneeManage.avatar : Avatar5}
              alt=""
              onClick={() => showAssignee()}
            />
            <RoundImageCross onClick={() => clearAssignee()}>
              <img
                src={removeAssignee}
                style={{ width: '100%', height: '100%' }}
              />
            </RoundImageCross> */}
          </>
        ) : (
          <CommonText color="#746F6B" onClick={() => showAssignee()}>
            {t('select_monazim')}
          </CommonText>
        )}
      </SelectedMonazimWrapper>

      {addAssignee && (
        <AssignListContainer ref={wrapperRef1}>
          <SearchBarWithAssignListContainer>
            <AssigneeSearchBarWrapper>
              <AssigneeSearchBarContainer>
                <AssigneeSearchIcon src={searchIcon} alt="" />
                <AssigneeSearchInput
                  value={searchTerm}
                  onChange={e => onSearchTermChange(e)}
                  placeholder="Search..."
                  autoFocus
                />
              </AssigneeSearchBarContainer>
            </AssigneeSearchBarWrapper>
            {addList &&
              addList.length > 0 &&
              addList.map((person, i) => (
                <AssignListItem
                  key={i}
                  onClick={() => {
                    updateState(person, index);
                    setAddAssignee(false);
                    setSearchTerm('');
                  }}
                  columns="30px auto"
                  padding="7px 8px"
                >
                  <AssigneeRoundImage index={0} size="30px">
                    <RoundPersonImage
                      src={person.avatar ? person.avatar : Avatar5}
                      alt="addPeople"
                      size="30px"
                      borderColor="#c2cce1"
                    />
                  </AssigneeRoundImage>
                  <CommonFlex direction="column" gap="1px" alignItems="start">
                    <AssignListText>{person.name}</AssignListText>
                    <AssignListText fontSize="11px">
                      {person.monazim_code}
                    </AssignListText>
                  </CommonFlex>
                </AssignListItem>
              ))}
          </SearchBarWithAssignListContainer>
        </AssignListContainer>
      )}
    </div>
  );
};

export default SelectSingleMonazimDropdown;
