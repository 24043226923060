import { connect } from 'react-redux';

// import { getOrganizationMembersList } from '../../../Projects/projectsActions';
import {
  clearOfficeDetails,
  createOffice,
  updateOffice,
} from '../officesActions';
import OfficeDetails from './OfficeDetails';
import { getMembersList } from 'custom_modules/Members/membersActions';

const mapStateToProps = state => ({
  addOrUpdateOfficeLoading: state.office.addOrUpdateOfficeLoading,
  officeDetails: state.office.officeDetails,
  officeCreateUpdateSuccess: state.office.officeCreateUpdateSuccess,
  monazimList: state.member.monazimList,
});

const mapDispatchToProps = dispatch => ({
  createOffice: payload => dispatch(createOffice(payload)),
  updateOffice: payload => dispatch(updateOffice(payload)),
  clearOfficeDetails: () => dispatch(clearOfficeDetails()),
  getMembersList: (details) => dispatch(getMembersList({ ...details })),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfficeDetails);
