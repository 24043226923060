//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../../utils/axiosApi";

//Constants
import {
  // DONT_SHOW_ORGANIZATION_WIZARD,
  // DONT_SHOW_ORGANIZATION_WIZARD_SUCCESS,
  // DONT_SHOW_ORGANIZATION_WIZARD_FAILURE,
  // ADD_MULTIPLE_PROJECTS,
  // ADD_MULTIPLE_PROJECTS_SUCCESS,
  // ADD_MULTIPLE_PROJECTS_FAILURE,
  // INVITE_MULTIPLE_MEMBERS,
  // INVITE_MULTIPLE_MEMBERS_SUCCESS,
  // INVITE_MULTIPLE_MEMBERS_FAILURE,
  CHECK_ONBOARDING_APPLICABLE,
  CHECK_ONBOARDING_APPLICABLE_SUCCESS,
  CHECK_ONBOARDING_APPLICABLE_FAILURE,
  SAVE_ONBOARDING_DATA,
  SAVE_ONBOARDING_DATA_SUCCESS,
  SAVE_ONBOARDING_DATA_FAILURE,
} from "../../../modules/constants";

// export function* dontShowWizardAgain({ payload }) {
//   if (!payload) return;
//   try {
//     let url = `onboard/${payload.id}/`;
//     const response = yield defaultApi(url, "PATCH", payload.data);
//     yield put({
//       type: DONT_SHOW_ORGANIZATION_WIZARD_SUCCESS,
//       payload: { data: response.data },
//     });
//   } catch (err) {
//     yield put({
//       type: DONT_SHOW_ORGANIZATION_WIZARD_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* addMultipleProjects({ payload }) {
//   if (!payload) return;
//   const { organization_id, ...details } = payload;
//   try {
//     let url = `${organization_id}/projects/`;
//     const response = yield defaultApi(url, "POST", details);
//     yield put({
//       type: ADD_MULTIPLE_PROJECTS_SUCCESS,
//       payload: { data: response.data },
//     });
//   } catch (err) {
//     yield put({
//       type: ADD_MULTIPLE_PROJECTS_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* inviteMultipleMembers({ payload }) {
//   if (!payload) return;
//   const { organization_id, ...details } = payload;
//   try {
//     let url = `${organization_id}/members/invitations/`;
//     const response = yield defaultApi(url, "POST", details);
//     yield put({
//       type: INVITE_MULTIPLE_MEMBERS_SUCCESS,
//       payload: { data: response.data },
//     });
//   } catch (err) {
//     yield put({
//       type: INVITE_MULTIPLE_MEMBERS_FAILURE,
//       payload: err,
//     });
//   }
// }

export function* checkOnboardingApplicable() {
  try {
    let url = `onboarding/check/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: CHECK_ONBOARDING_APPLICABLE_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: CHECK_ONBOARDING_APPLICABLE_FAILURE,
      payload: err,
    });
  }
}
export function* saveOnboardingData({ payload }) {
  if (!payload) return;
  try {
    let url = `onboarding/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: SAVE_ONBOARDING_DATA_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: SAVE_ONBOARDING_DATA_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    // takeLatest(DONT_SHOW_ORGANIZATION_WIZARD, dontShowWizardAgain),
    // takeLatest(ADD_MULTIPLE_PROJECTS, addMultipleProjects),
    // takeLatest(INVITE_MULTIPLE_MEMBERS, inviteMultipleMembers),
    takeLatest(CHECK_ONBOARDING_APPLICABLE, checkOnboardingApplicable),
    takeLatest(SAVE_ONBOARDING_DATA, saveOnboardingData),
  ]);
}
