import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import avatar5 from '../../../assets/img/avatar_5.svg';
import LeftArrow from '../../../assets/img/icons/arrow_left_white.svg';
import RightArrow from '../../../assets/img/icons/arrow_right_white.svg';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import RightSlidingPanel from '../../../components/RightSlidingPanel/RightSlidingPanel';
import { CommonGrid, PageTitle } from '../../../styledComponents/common';
import {
  TimesheetContainer,
  TotalTimeContainer,
  TotalTimeDiv,
  TotalTimeLabel,
  TotalTimeText,
} from '../../../styledComponents/timesheet';
import { getHours, toHHMMSS } from '../../../utils/helper';
import TimesheetDetails from '../TimesheetDetailsPanel';
import TimesheetFilter from '../TimesheetFilter';
import TimeSpan from './TimeSpan';

moment.locale('en', {
  week: {
    dow: 1,
    doy: 4,
  },
});
const localizer = momentLocalizer(moment);

const CalendarContainer = styled.div`
  height: 720px;
  @media screen and (max-width: 1400px) {
    height: 600px;
  }
`;
const CalendarToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;
const MonthPickerContainer = styled.div`
  display: flex;
  align-items: center;
`;
const MonthChangeButton = styled.div`
  width: 36px;
  height: 36px;
  background-color: ${({ theme }) => theme.colors.main.gray};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.hover.gray};
  }
`;
const MonthChangeIcon = styled.img`
  width: 18px;
  height: 18px;
`;
const MonthPickerInput = styled.div`
  margin: 0px 15px;
  font-size: 13px;
  text-align: center;
  border: none;
  &:focus {
    outline: none;
  }
`;

const getTimes = date => {
  let startDate = moment(date).startOf('month');
  const endDate = moment(date).endOf('month');
  const times = [];
  while (
    startDate.isSameOrBefore(endDate) &&
    startDate.isSameOrBefore(moment())
  ) {
    times.push({
      title: '0h 00m',
      start: startDate.toDate(),
      end: startDate.toDate(),
      allDay: true,
    });
    startDate = moment(startDate).add(1, 'day');
  }
  return times;
};

const CustomMonthPickerInput = ({ value, onClick }) => (
  <MonthPickerInput onClick={onClick}>{value}</MonthPickerInput>
);

const CustomToolbar = props => {
  const { t } = useTranslation();
  const { timesheetsData, goToBack, goToNext, onMonthSelect, selectedDate } =
    props;

  return (
    <CalendarToolbarContainer>
      <TotalTimeContainer>
        <TotalTimeDiv monthly>
          <TotalTimeLabel>{t('total_hour')}</TotalTimeLabel>{' '}
          <TotalTimeText>
            {timesheetsData &&
            timesheetsData.durations &&
            timesheetsData.durations.length > 0
              ? getHours(
                  toHHMMSS(
                    timesheetsData.durations
                      .slice(1)
                      .reduce(
                        (prev, cur) => cur + prev,
                        timesheetsData.durations[0],
                      ),
                  ),
                )
              : '0 h 00 m'}
          </TotalTimeText>
        </TotalTimeDiv>
      </TotalTimeContainer>
      <MonthPickerContainer>
        <MonthChangeButton onClick={() => goToBack()}>
          <MonthChangeIcon src={LeftArrow} alt="" />
        </MonthChangeButton>
        <DatePicker
          selected={moment(selectedDate).toDate()}
          onChange={date => onMonthSelect(date)}
          customInput={<CustomMonthPickerInput />}
          dateFormat="MMMM, yyyy"
          showMonthYearPicker
          popperModifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: 'viewport',
            },
          }}
        />
        <MonthChangeButton onClick={() => goToNext()}>
          <MonthChangeIcon src={RightArrow} alt="" width="18px" height="18px" />
        </MonthChangeButton>
      </MonthPickerContainer>
    </CalendarToolbarContainer>
  );
};

const Monthly = props => {
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedDate, setSelectedDate] = useState(
    moment().format('YYYY-MM-DD'),
  );
  const [selectedMember, setSelectedMember] = useState('');
  const [selectedTask, setSelectedTask] = useState('');

  const [selectedTimezone, setSelectedTimezone] = useState(null);

  const [timesheetsData, setTimesheetsData] = useState(null);

  // details panel
  const [detailsUserData, setDetailsUserData] = useState(null);
  const [detailsPanelIsOpen, setDetailsPanelIsOpen] = useState(false);

  const {
    isLoading,

    selectedOrganization,
    profileData,

    clearTimesheetList,
    getOtherTimesheets,
    timesheetsList,

    getDailyTimesheets,
    dailyTimesheetLoading,
    dailyTimesheets,
  } = props;

  useEffect(() => {
    return () => {
      clearTimesheetList();
    };
  }, []);
  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      if (selectedOrganization.timezone) {
        setSelectedTimezone({
          type: 'Organization',
          timezone: selectedOrganization.timezone,
          offset: selectedOrganization.timezoneoffset,
        });
      }

      setSelectedProject('');
      setSelectedTask('');
      setSelectedMember('');

      const startDate = moment(selectedDate)
        .startOf('month')
        .format('YYYY-MM-DD');
      const endDate = moment(selectedDate).endOf('month').format('YYYY-MM-DD');
      let payload = {
        organization_id: selectedOrganization.id,
        start_date: startDate,
        end_date: endDate,
      };
      getOtherTimesheets(payload);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    const durations = [];
    const times = getTimes(selectedDate);
    if (timesheetsList && timesheetsList.length > 0) {
      timesheetsList.forEach(timesheet => {
        durations.push(timesheet.duration);

        times.some(item => {
          if (moment(item.start).format('YYYY-MM-DD') === timesheet.date) {
            if (timesheet.duration !== 0) {
              item.title = getHours(toHHMMSS(timesheet.duration));
            }
            return true;
          }
          return false;
        });
        if (
          detailsUserData &&
          detailsUserData.id === timesheet.user_id &&
          detailsUserData.date &&
          moment(detailsUserData.date).format('YYYY-MM-DD') === timesheet.date
        ) {
          setDetailsUserData({
            ...detailsUserData,
            totalTime: getHours(toHHMMSS(timesheet.duration)),
          });
        }
      });
      setTimesheetsData({
        durations,
        timesheets: [...times],
      });
    } else if (timesheetsList && timesheetsList.length === 0) {
      setTimesheetsData({
        durations,
        timesheets: times,
      });
    }
  }, [timesheetsList]);

  const selectProject = e => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    const startDate = moment(selectedDate)
      .startOf('month')
      .format('YYYY-MM-DD');
    const endDate = moment(selectedDate).endOf('month').format('YYYY-MM-DD');
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: startDate,
        end_date: endDate,
      };
      if (selectedMember) {
        payload.user_id = selectedMember.id;
      }
      if (e && e.value) {
        payload.project_id = e.value;
      }
      if (selectedTimezone && selectedTimezone.type === 'Member') {
        payload.timezone = selectedTimezone.timezone;
      }
      getOtherTimesheets(payload);
    }

    if (
      e &&
      e.value &&
      selectedProject &&
      selectedProject.value &&
      e.value !== selectedProject.value
    ) {
      setSelectedProject(e);
      setSelectedTask('');
    } else if ((e && !e.value) || e == null) {
      setSelectedMember('');
      setSelectedTask('');
      setSelectedProject(e);
    } else {
      setSelectedProject(e);
    }
  };
  const selectTask = e => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    const startDate = moment(selectedDate)
      .startOf('month')
      .format('YYYY-MM-DD');
    const endDate = moment(selectedDate).endOf('month').format('YYYY-MM-DD');
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: startDate,
        end_date: endDate,
      };
      if (selectedMember) {
        payload.user_id = selectedMember.id;
      }
      if (e && e.value) {
        payload.task_id = e.value;
      }
      if (selectedTimezone && selectedTimezone.type === 'Member') {
        payload.timezone = selectedTimezone.timezone;
      }
      getOtherTimesheets(payload);
    }

    setSelectedTask(e);
  };
  const selectTeamMember = e => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    const startDate = moment(selectedDate)
      .startOf('month')
      .format('YYYY-MM-DD');
    const endDate = moment(selectedDate).endOf('month').format('YYYY-MM-DD');
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: startDate,
        end_date: endDate,
        user_id: e.id,
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (
        selectedTimezone &&
        selectedTimezone.type === 'Member' &&
        e.timezone &&
        e.timezone.tz
      ) {
        payload.timezone = e.timezone.tz;
        setSelectedTimezone({
          type: 'Member',
          timezone: e.timezone.tz,
          offset: e.timezone.offset,
        });
      }
      setSelectedMember(e);
      getOtherTimesheets(payload);
    }
  };

  const clearMember = () => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    const startDate = moment(selectedDate)
      .startOf('month')
      .format('YYYY-MM-DD');
    const endDate = moment(selectedDate).endOf('month').format('YYYY-MM-DD');
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: startDate,
        end_date: endDate,
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedOrganization.timezone) {
        setSelectedTimezone({
          type: 'Organization',
          timezone: selectedOrganization.timezone,
          offset: selectedOrganization.timezoneoffset,
        });
      }
      getOtherTimesheets(payload);
    }
    setSelectedMember('');
  };

  const goToNext = () => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    const date = moment(selectedDate).add(1, 'M').format('YYYY-MM-DD');
    const startDate = moment(date).startOf('month').format('YYYY-MM-DD');
    const endDate = moment(date).endOf('month').format('YYYY-MM-DD');
    setSelectedDate(date);
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: startDate,
        end_date: endDate,
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedMember && selectedMember.id) {
        payload.user_id = selectedMember.id;
      }
      if (selectedTimezone && selectedTimezone.type === 'Member') {
        payload.timezone = selectedTimezone.timezone;
      }
      getOtherTimesheets(payload);
    }
  };
  const goToBack = () => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    const date = moment(selectedDate).subtract(1, 'M').format('YYYY-MM-DD');
    const startDate = moment(date).startOf('month').format('YYYY-MM-DD');
    const endDate = moment(date).endOf('month').format('YYYY-MM-DD');
    setSelectedDate(date);
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: startDate,
        end_date: endDate,
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedMember && selectedMember.id) {
        payload.user_id = selectedMember.id;
      }
      if (selectedTimezone && selectedTimezone.type === 'Member') {
        payload.timezone = selectedTimezone.timezone;
      }
      getOtherTimesheets(payload);
    }
  };
  const onMonthSelect = date => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    const startDate = moment(date).format('YYYY-MM-DD');
    const endDate = moment(date).endOf('month').format('YYYY-MM-DD');
    setSelectedDate(date);
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: startDate,
        end_date: endDate,
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedMember && selectedMember.id) {
        payload.user_id = selectedMember.id;
      }
      if (selectedTimezone && selectedTimezone.type === 'Member') {
        payload.timezone = selectedTimezone.timezone;
      }
      getOtherTimesheets(payload);
    }
    setSelectedDate(moment(date).format('YYYY-MM-DD'));
  };

  const onTimezoneSelect = timezone => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    const startDate = moment(selectedDate)
      .startOf('month')
      .format('YYYY-MM-DD');
    const endDate = moment(selectedDate).endOf('month').format('YYYY-MM-DD');
    setSelectedTimezone(timezone);
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: startDate,
        end_date: endDate,
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedMember && selectedMember.id !== '') {
        payload.user_id = selectedMember.id;
      }
      if (timezone && timezone.type === 'Member') {
        payload.timezone = timezone.timezone;
      }
      getOtherTimesheets(payload);
    }
  };

  const toggleDetailsPanel = value => {
    setDetailsPanelIsOpen(value);
    if (!value) {
      setDetailsUserData(null);
    }
  };
  const getTimesheetDetails = (date, userId) => {
    if (selectedOrganization && selectedOrganization.id && date && userId) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: date.format('YYYY-MM-DD'),
        user_id: userId,
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedTimezone && selectedTimezone.timezone) {
        payload.timezone = selectedTimezone.timezone;
      }
      getDailyTimesheets(payload);
      toggleDetailsPanel(true);
    }
  };
  const handleSetUserData = (date, hours) => {
    if (date && hours) {
      setDetailsUserData({
        id: selectedMember
          ? selectedMember.id
          : localStorage.getItem('user_id'),
        name: selectedMember
          ? selectedMember.name
          : profileData && profileData.id
          ? `${profileData.first_name}${
              profileData.last_name ? ` ${profileData.last_name}` : ''
            }`
          : null,
        avatar: selectedMember
          ? selectedMember.avatar
            ? selectedMember.avatar
            : avatar5
          : profileData && profileData.thumbnail
          ? profileData.thumbnail
          : avatar5,
        date: date,
        totalTime: hours,
      });
    }
  };

  return (
    <div className="content">
      <RightSlidingPanel
        isOpen={detailsPanelIsOpen}
        closePanel={() => toggleDetailsPanel(false)}
        width="500px"
      >
        {dailyTimesheetLoading ? (
          <ComponentCircleLoader padding="35vh 0" />
        ) : (
          <>
            <TimesheetDetails
              timesheetsList={dailyTimesheets}
              userData={detailsUserData}
              selectedProject={selectedProject}
              selectedTask={selectedTask}
              timezone={selectedTimezone && selectedTimezone.timezone}
              fromPage="other"
            />
          </>
        )}
      </RightSlidingPanel>
      <CommonGrid alignItem="center">
        <PageTitle>Monthly Timesheet</PageTitle>
      </CommonGrid>
      <TimesheetFilter
        page="monthly"
        selectedDate={moment(selectedDate)}
        selectedProject={selectedProject}
        selectProject={selectProject}
        selectedMember={selectedMember}
        selectMember={selectTeamMember}
        clearMember={clearMember}
        selectedTask={selectedTask}
        selectTask={selectTask}
        selectedOrganization={selectedOrganization}
        selectTimezone={onTimezoneSelect}
        selectedTimezone={selectedTimezone}
      />
      <TimesheetContainer>
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <CalendarContainer>
            <Calendar
              localizer={localizer}
              events={timesheetsData ? timesheetsData.timesheets : []}
              startAccessor="start"
              endAccessor="end"
              date={moment(selectedDate).toDate()}
              onNavigate={() => {}}
              views={['month']}
              culture="en-GB"
              formats={{ dateFormat: 'D' }}
              components={{
                event: event => {
                  return (
                    <TimeSpan
                      event={event}
                      selectedMember={selectedMember}
                      getTimesheetDetails={getTimesheetDetails}
                      handleSetUserData={handleSetUserData}
                    />
                  );
                },
                toolbar: () => {
                  return (
                    <CustomToolbar
                      timesheetsData={timesheetsData}
                      goToNext={goToNext}
                      goToBack={goToBack}
                      onMonthSelect={onMonthSelect}
                      selectedDate={selectedDate}
                    />
                  );
                },
              }}
              tooltipAccessor={() => null}
              eventPropGetter={() => {
                return {
                  className: 'monthly-timesheet-time',
                };
              }}
            />
          </CalendarContainer>
        )}
      </TimesheetContainer>
    </div>
  );
};

export default Monthly;
