//Effects
import { put, takeLatest, all, take } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  GET_WEEKLY_EMAIL_SETTINGS,
  GET_WEEKLY_EMAIL_SETTINGS_SUCCESS,
  GET_WEEKLY_EMAIL_SETTINGS_FAILURE,
  UPDATE_WEEKLY_EMAIL_SETTINGS,
  UPDATE_WEEKLY_EMAIL_SETTINGS_SUCCESS,
  UPDATE_WEEKLY_EMAIL_SETTINGS_FAILURE,
  UPDATE_LOCATION_SETTINGS,
  UPDATE_LOCATION_SETTINGS_SUCCESS,
  UPDATE_LOCATION_SETTINGS_FAILURE,
  UPDATE_GEOFENCE_SETTINGS,
  UPDATE_GEOFENCE_SETTINGS_SUCCESS,
  UPDATE_GEOFENCE_SETTINGS_FAILURE,
} from "../../modules/constants";

export function* getWeeklyEmailSettingsRequest({ payload }) {
  if (!payload) return;
  try {
    const { organization_id, configuration_id } = payload;
    let url = `${organization_id}/features/email-setting/${configuration_id}/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_WEEKLY_EMAIL_SETTINGS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_WEEKLY_EMAIL_SETTINGS_FAILURE,
      payload: err,
    });
  }
}

export function* updateWeeklyEmailSettingsRequest({ payload }) {
  if (!payload) return;
  try {
    const { organization_id, configuration_id, ...details } = payload;
    let url = `${organization_id}/features/email-setting/${configuration_id}/`;
    const response = yield defaultApi(url, "PATCH", details);
    yield put({
      type: UPDATE_WEEKLY_EMAIL_SETTINGS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: UPDATE_WEEKLY_EMAIL_SETTINGS_FAILURE,
      payload: err,
    });
  }
}

export function* updateLocationSettingsRequest({ payload }) {
  if (!payload) return;
  try {
    const { organization_id, configuration_id, ...details } = payload;
    let url = `${organization_id}/features/location-tracking-setting/${configuration_id}/`;
    const response = yield defaultApi(url, "PATCH", details);
    yield put({
      type: UPDATE_LOCATION_SETTINGS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: UPDATE_LOCATION_SETTINGS_FAILURE,
      payload: err,
    });
  }
}

export function* updateGeofenceSettingsRequest({ payload }) {
  if (!payload) return;
  try {
    const { organization_id, configuration_id, ...details } = payload;
    let url = `${organization_id}/features/geofence-time-clock-setting/${configuration_id}/`;
    const response = yield defaultApi(url, "PATCH", details);
    yield put({
      type: UPDATE_GEOFENCE_SETTINGS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: UPDATE_GEOFENCE_SETTINGS_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_WEEKLY_EMAIL_SETTINGS, getWeeklyEmailSettingsRequest),
    takeLatest(UPDATE_WEEKLY_EMAIL_SETTINGS, updateWeeklyEmailSettingsRequest),
    takeLatest(UPDATE_LOCATION_SETTINGS, updateLocationSettingsRequest),
    takeLatest(UPDATE_GEOFENCE_SETTINGS, updateGeofenceSettingsRequest),
  ]);
}
