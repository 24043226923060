import { useState, useEffect } from "react";

// reactstrap components
import { Col, Row, Form, FormGroup, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";

import Select from "../../components/ReactSelectDropdown";

import logo from "assets/img/logo/logo.svg";
import appsumoLogo from "assets/img/appsumo.png";
import dealArtwork from "../../assets/img/common/partnership_artwork.svg";
import { createNotification } from "../../modules/notificationManager";
import { validateEmail } from "../../utils/helper";

import DropdownStyles from "../../components/DropdownStyle/CouponDropdownStyle";

//styled components
import { LoginText, LoginPageLink } from "../../styledComponents/login";
import {
  CustomInput,
  SingleColorButton,
  CustomFormAlert,
} from "../../styledComponents/common";

import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";

const RedeemCode = ({
  history,
  redeemLimitedTimeCode,
  redeem,
  isLoading,
  getOrganizationList,
  organizationList,
  isOrgListLoading,
}) => {
  const [email, setEmail] = useState("");
  const [coupon, setCoupon] = useState("");
  const [saveErrors, setSaveErrors] = useState({});
  const [orgOptions, setOrgOptions] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState({
    id: "",
    name: "",
  });
  const [noOrg, setNoOrg] = useState(false);

  useEffect(() => {
    document.body.classList.toggle("login-page");
    let token = localStorage.getItem("access_token");
    if (!token) {
      history.push("/auth/login?redirect=appsumo");
    }
    getOrganizationList();
    let userEmail = localStorage.getItem("email");
    if (userEmail) {
      setEmail(userEmail);
    }
    return () => {
      document.body.classList.toggle("login-page");
    };
  }, []);

  useEffect(() => {
    if (organizationList && organizationList.length > 0) {
      setNoOrg(false);
      let options = [];
      organizationList.map((org) => {
        if (org.role === "owner") {
          options.push({
            value: org.id,
            label: org.name,
          });
        }
      });
      setOrgOptions(options);
    } else if (organizationList && organizationList.length === 0) {
      setNoOrg(true);
    }
  }, [organizationList]);

  useEffect(() => {
    let saveErrors = {};
    if (redeemLimitedTimeCode.registerNeeded) {
      saveErrors["email"] =
        "There is no account with this email. Please register first";
    }
    setSaveErrors(saveErrors);
  }, [redeemLimitedTimeCode.registerNeeded]);

  useEffect(() => {
    if (redeemLimitedTimeCode.redirect) {
      history.push("/user/current-plan");
    }
  }, [redeemLimitedTimeCode.redirect]);

  useEffect(() => {
    let saveErrors = {};
    if (
      redeemLimitedTimeCode.errorMessage &&
      redeemLimitedTimeCode.errorMessage ===
        "cannot redeem multiple plans coupon"
    ) {
      saveErrors["wrongOrg"] =
        "This code is only for Apploye TimeTrack. Please change the plan of your organization to Apploye TimeTrack. Click here to change it.";
    }
    setSaveErrors(saveErrors);
  }, [redeemLimitedTimeCode.errorMessage]);

  useEffect(() => {
    let saveErrors = {};
    if (
      redeemLimitedTimeCode.invalidCodes &&
      redeemLimitedTimeCode.invalidCodes.length > 0
    ) {
      if (
        redeemLimitedTimeCode.invalidCodes[0] ===
        "cannot redeem seated type of appsumo platform coupon when there is an existing discount"
      ) {
        saveErrors["coupon"] =
          "An AppSumo code has already been applied with this email";
      } else {
        saveErrors["coupon"] = redeemLimitedTimeCode.invalidCodes[0];
      }
      setSaveErrors(saveErrors);
    }
  }, [redeemLimitedTimeCode.invalidCodes]);

  const handleEmailChange = (event) => {
    setSaveErrors({});
    setEmail(event.target.value);
  };

  const handleCouponChange = (event) => {
    setSaveErrors({});
    setCoupon(event.target.value);
  };

  const handleOrgChange = (e) => {
    setSaveErrors({});
    setSelectedOrg({
      id: e.value,
      name: e.label,
    });
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!email || !coupon) {
      if (!email) {
        formIsValid = false;
        saveErrors["email"] = "Email Required";
      }
      if (!coupon) {
        formIsValid = false;
        saveErrors["coupon"] = "AppSumo Code Required";
      }
    } else if (!validateEmail(email)) {
      formIsValid = false;
      saveErrors["email"] = "Please enter a valid email address";
    }
    if (selectedOrg.id === "") {
      formIsValid = false;
      saveErrors["organization"] = "Please select an organization";
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (checkError()) {
      const payload = {
        email: email,
        codes: [coupon],
        organization_id: selectedOrg.id,
      };
      redeem(payload);
    }
  };
  return (
    <div className="login-page">
      <Row className="align-items-center" style={{ margin: 0 }}>
        <Col md={6} className="login-artwork" style={{ padding: 0 }}>
          <Row className="login-img-container">
            <img
              style={{ width: "70%", marginBottom: "20px" }}
              alt=""
              src={dealArtwork}
            />
            <span className="auth-artwork-text">
              #1 Time Tracker App for desk, mobile & remote teams.
            </span>
          </Row>
        </Col>
        <Col md={6}>
          <Row className="justify-content-sm-center align-items-center">
            <Col xl={7} lg={8} md={10} sm={10}>
              <div className="text-center">
                <Row>
                  <Col lg="12" md="12">
                    <Row
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <img style={{ width: "45%" }} src={logo} alt="" />
                      <div
                        style={{
                          width: "4px",
                          height: "30px",
                          background: "#C2CCE1",
                          borderRadius: "10px",
                        }}
                      ></div>
                      <img style={{ width: "45%" }} src={appsumoLogo} alt="" />
                    </Row>
                  </Col>
                  <Col lg="12" md="12"></Col>
                </Row>
                {/* <Row>
                  <Col lg="12" md="12" style={{ marginTop: "25px" }}>
                    <LoginText>
                      Please <Link to="/auth/register">Register</Link> before
                      redeeming AppSumo code
                    </LoginText>
                  </Col>
                </Row> */}
                <Form
                  style={{ marginTop: "40px" }}
                  className="login-form"
                  onSubmit={(e) => handleSave(e)}
                >
                  <FormGroup>
                    <CustomInput
                      type="text"
                      maxLength="255"
                      placeholder="Email..."
                      onChange={(e) => handleEmailChange(e)}
                      value={email}
                      name="email"
                    />
                    <CustomFormAlert
                      isOpen={saveErrors && saveErrors["email"] !== undefined}
                    >
                      {saveErrors["email"]}
                    </CustomFormAlert>
                  </FormGroup>
                  <FormGroup>
                    <Select
                      name="organization_name"
                      id="organizationName"
                      placeholder="Select Organization..."
                      options={orgOptions}
                      onChange={(e) => handleOrgChange(e)}
                      styles={DropdownStyles()}
                    />
                    <CustomFormAlert
                      isOpen={
                        saveErrors && saveErrors["organization"] !== undefined
                      }
                    >
                      {saveErrors["organization"]}
                    </CustomFormAlert>
                    <CustomFormAlert isOpen={noOrg}>
                      <Link
                        style={{
                          color: "#F15762",
                          textDecoration: "underline",
                        }}
                        to="/user/organizations"
                      >
                        You don't have an organization. Click here to create
                        one.
                      </Link>
                    </CustomFormAlert>
                    <CustomFormAlert
                      isOpen={
                        saveErrors && saveErrors["wrongOrg"] !== undefined
                      }
                    >
                      <Link
                        style={{
                          color: "#F15762",
                          textDecoration: "underline",
                        }}
                        to="/user/organizations"
                      >
                        {saveErrors["wrongOrg"]}
                      </Link>
                    </CustomFormAlert>
                  </FormGroup>
                  <FormGroup>
                    <CustomInput
                      type="text"
                      maxLength="255"
                      placeholder="AppSumo Code"
                      onChange={(e) => handleCouponChange(e)}
                      value={coupon}
                      name="coupon"
                    />
                    <CustomFormAlert
                      isOpen={saveErrors && saveErrors["coupon"] !== undefined}
                    >
                      {saveErrors["coupon"]}
                    </CustomFormAlert>
                  </FormGroup>
                  <FormGroup
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Row>
                      <Col>
                        <Label
                          style={{
                            textAlign: "left",
                            marginLeft: "20px",
                          }}
                        >
                          <Input type="checkbox" required />
                          By applying AppSumo Code, You are agreeing to our
                          <a
                            href="https://apploye.com/terms-of-service/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link-color"
                          >
                            Terms of Services
                          </a>
                          and{" "}
                          <a
                            href="https://apploye.com/privacy-policy/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link-color"
                          >
                            Privacy Policy
                          </a>
                        </Label>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <SingleColorButton
                      type="submit"
                      style={{
                        width: "100%",
                        padding: isLoading && "6px 22px",
                      }}
                    >
                      {isLoading ? (
                        <ButtonTextLoader
                          loadingText="Applying Code"
                          fontSize="18px"
                          fontWeight="400"
                        />
                      ) : (
                        "Apply AppSumo Code"
                      )}
                    </SingleColorButton>
                  </FormGroup>
                </Form>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default RedeemCode;
