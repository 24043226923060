import {
  DASHBOARD_TOP_LOW_PERFORMERS,
  GET_DASHBOARD_LATE_COUNT,
  GET_DASHBOARD_PERFORMANCE, //new apis
  GET_DASHBOARD_STATUS,
  GET_DASHBOARD_TASK_PROGRESS,
  GET_DASHBOARD_TASK_SUMMARY,
  GET_DASHBOARD_USER_ANALYTICS,
} from '../../modules/constants';

// ------------------------------------
// Actions
// ------------------------------------

export function getDashboardStatus(value) {
  return {
    type: GET_DASHBOARD_STATUS,
    payload: value,
  };
}
export function getDashboardTaskProgress(value) {
  return {
    type: GET_DASHBOARD_TASK_PROGRESS,
    payload: value,
  };
}

export function getDashboardTaskSummary(value) {
  return {
    type: GET_DASHBOARD_TASK_SUMMARY,
    payload: value,
  };
}
export function getDashboardPerformance(value) {
  return {
    type: GET_DASHBOARD_PERFORMANCE,
    payload: value,
  };
}
export function getDashboardLateCount(value) {
  return {
    type: GET_DASHBOARD_LATE_COUNT,
    payload: value,
  };
}
export function getDashboardTopLowPerformers(value) {
  return {
    type: DASHBOARD_TOP_LOW_PERFORMERS,
    payload: value,
  };
}
export function getDashboardUserAnalytics(value) {
  return {
    type: GET_DASHBOARD_USER_ANALYTICS,
    payload: value,
  };
}
