import { t } from 'i18next';
import { CommonFlex, CommonGrid, CommonText } from 'styledComponents/common';

const Comment = ({ type, comment }) => {
  return (
    <>
      {comment ? (
        <CommonGrid>
          <CommonFlex direction="column" alignItems="start" gap="2px">
            <CommonText fontWeight={600}>
              {type === 'employee_note' && t('employee_note')}
              {type === 'supervisor_note' && t('supervisor_note')}
            </CommonText>
            <CommonText fontWeight={400}>{comment}</CommonText>
          </CommonFlex>
        </CommonGrid>
      ) : (
        <></>
      )}
    </>
  );
};

export default Comment;
