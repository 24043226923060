import { useState } from "react";

import {
  BillingQues,
  BillingTooltip,
  BillingTooltipText,
} from "../../styledComponents/createProject";

const HelpTooltip = (props) => {
  const [active, setActive] = useState(false);

  const {
    infoSize,
    top,
    left,
    width,
    tooltipPadding,
    bgColor,
    toolTipText,
    moreText,
  } = props;

  return (
    <BillingQues
      size={infoSize}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      ?
      {active && (
        <BillingTooltip
          top={top}
          left={left}
          width={width}
          padding={tooltipPadding}
          bgColor={bgColor}
        >
          <BillingTooltipText>{toolTipText}</BillingTooltipText>
          {moreText && <BillingTooltipText>{moreText}</BillingTooltipText>}
        </BillingTooltip>
      )}
    </BillingQues>
  );
};

export default HelpTooltip;
