import { useState } from "react";
import { isInclusivelyBeforeDay, SingleDatePicker } from "react-dates";
import datePickerIcon from "../../assets/img/icons/date_picker_icon.svg";
import moment from "moment";

const CustomInputIcon = () => {
  return <img src={datePickerIcon} style={{ marginTop: "-2px" }} />;
};

const DatePicker = ({
  date,
  onDateChange,
  dateDisplayFormat,
  placeholder,
  allowPreviousDates,
  disableFutureDates,
  disableCurrentAndFutureDates,
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <SingleDatePicker
      numberOfMonths={1}
      onDateChange={(date) => onDateChange(date)}
      onFocusChange={({ focused }) => setFocused(focused)}
      focused={focused}
      date={date}
      displayFormat={dateDisplayFormat}
      showDefaultInputIcon={false}
      customInputIcon={<CustomInputIcon />}
      inputIconPosition="after"
      hideKeyboardShortcutsPanel
      placeholder={placeholder}
      readOnly
      isOutsideRange={
        disableFutureDates
          ? (day) => !isInclusivelyBeforeDay(day, moment())
          : allowPreviousDates
          ? () => false
          : disableCurrentAndFutureDates
          ? (day) => !isInclusivelyBeforeDay(day, moment().subtract(1, "day"))
          : (day) => {
              if (day.isSameOrBefore(moment().subtract(1, "day"))) {
                return true;
              }
              return false;
            }
      }
    />
  );
};

export default DatePicker;
