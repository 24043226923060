import Offices from 'custom_modules/Offices';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import avatar5 from '../../assets/img/avatar_5.svg';
import AddSingleMemberDropdown from '../../components/CustomDropdown/AddSingleMemberDropdown';
import FilterDropDownStyle from '../../components/DropdownStyle/FilterDropDownStyle';
import { FilterInnerSection } from '../../styledComponents/Activity';
// import TaskDropdown from "./TaskDropdown";
import {
  FilterLabel,
  InputWithLabelSection,
} from '../../styledComponents/common';
import ProjectDropdown from './ProjectDropdown';

const CommonFilter = props => {
  const [memberOptions, setMemberOptions] = useState([]);

  const {
    selectedOrganization,
    getOrganizationMembersList,

    organizationMembersList,
    getProjectAssigneeList,
    projectAssigneeList,
    getTaskAssigneeList,
    taskAssigneeList,

    selectedProject,
    selectedTask,
    selectProject,
    selectTask,
    selectedMember,
    selectMember,
    clearMember,
    columns,
    isLocation,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      getOrganizationMembersList(payload);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (organizationMembersList && organizationMembersList.length > 0) {
      setMemberOptions(organizationMembersList);
    } else if (
      organizationMembersList &&
      organizationMembersList.length === 0
    ) {
      setMemberOptions([]);
    }
  }, [organizationMembersList]);

  useEffect(() => {
    if (projectAssigneeList && projectAssigneeList.length > 0) {
      let assigneeList = [];
      projectAssigneeList.map(assignee => {
        assigneeList.push({
          id: assignee.user.id,
          name: `${assignee.user.first_name}${
            assignee.user.last_name ? ` ${assignee.user.last_name}` : ``
          }`,
          avatar: assignee.user.avatar ? assignee.user.avatar : avatar5,
          timezone: assignee.user.timezone || null,
        });
      });
      setMemberOptions(assigneeList);
    } else if (projectAssigneeList && projectAssigneeList.length === 0) {
      setMemberOptions([]);
    }
  }, [projectAssigneeList]);

  useEffect(() => {
    if (taskAssigneeList && taskAssigneeList.length > 0) {
      let assigneeList = [];
      taskAssigneeList.map(assignee => {
        assigneeList.push({
          id: assignee.id,
          name: `${assignee.first_name}${
            assignee.last_name ? ` ${assignee.last_name}` : ``
          }`,
          avatar: assignee.avatar ? assignee.avatar : avatar5,
          timezone: assignee.timezone || null,
        });
      });
      setMemberOptions(assigneeList);
    } else if (taskAssigneeList && taskAssigneeList.length === 0) {
      setMemberOptions([]);
    }
  }, [taskAssigneeList]);

  useEffect(() => {
    if ((selectedProject && !selectedProject.value) || !selectedProject) {
      setMemberOptions(organizationMembersList);
    }
  }, [selectedProject]);

  useEffect(() => {
    if (
      selectedTask &&
      !selectedTask.value &&
      projectAssigneeList &&
      projectAssigneeList.length > 0
    ) {
      let assigneeList = [];
      projectAssigneeList.map(assignee => {
        assigneeList.push({
          id: assignee.user.id,
          name: `${assignee.user.first_name}${
            assignee.user.last_name ? ` ${assignee.user.last_name}` : ``
          }`,
          avatar: assignee.user.avatar ? assignee.user.avatar : avatar5,
        });
      });
      setMemberOptions(assigneeList);
    }
  }, [selectedTask]);

  const onProjectSelect = e => {
    if (selectedOrganization && selectedOrganization.id && e && e.value) {
      let payload = {
        organization_id: selectedOrganization.id,
        project_id: e.value,
      };
      getProjectAssigneeList(payload);
    }
    if (selectProject) {
      selectProject(e);
    }
  };

  const onTaskSelect = e => {
    if (selectedOrganization && selectedOrganization.id && e && e.value) {
      let payload = {
        organization_id: selectedOrganization.id,
        project_id: selectedProject.value,
        task_id: e.value,
      };
      getTaskAssigneeList(payload);
    }
    if (selectTask) {
      selectTask(e);
    }
  };

  const onMemberSelect = person => {
    if (selectMember) {
      selectMember(person);
    }
  };

  const clearSelectedMember = () => {
    clearMember();
  };
  const { t } = useTranslation();
  return (
    <FilterInnerSection columns={columns || '230px 230px 57px'}>
      <InputWithLabelSection>
        <FilterLabel>{t('offices')}</FilterLabel>
        <ProjectDropdown
          selectedOrganization={selectedOrganization}
          selectedProject={selectedProject}
          selectProject={onProjectSelect}
          selectedMember={selectedMember}
          clearable
          style={FilterDropDownStyle({ height: '40px', width: '180px' })}
        />
      </InputWithLabelSection>

      {/* {!isLocation && (
        <InputWithLabelSection>
          <FilterLabel>{t("task")}</FilterLabel>
          <TaskDropdown
            selectedOrganization={selectedOrganization}
            selectedProject={selectedProject}
            selectedTask={selectedTask}
            selectTask={onTaskSelect}
            selectedMember={selectedMember}
            style={FilterDropDownStyle({ height: "40px", width: "180px" })}
          />
        </InputWithLabelSection>
      )} */}

      <InputWithLabelSection>
        <FilterLabel>{t('assignee')}</FilterLabel>
        <div style={{ width: `40px` }}>
          <AddSingleMemberDropdown
            assigneeManage={selectedMember}
            updateState={onMemberSelect}
            membersList={memberOptions}
            clearAssignee={() => clearSelectedMember()}
          />
        </div>
      </InputWithLabelSection>
    </FilterInnerSection>
  );
};

export default CommonFilter;
