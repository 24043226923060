//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  GET_ORGANIZATION_SUBSCRIPTION,
  GET_ORGANIZATION_SUBSCRIPTION_SUCCESS,
  GET_ORGANIZATION_SUBSCRIPTION_FAILURE,
  UPDATE_ORGANIZATION_SUBSCRIPTION,
  UPDATE_ORGANIZATION_SUBSCRIPTION_SUCCESS,
  UPDATE_ORGANIZATION_SUBSCRIPTION_FAILURE,
  ACTIVATE_ORGANIZATION_SUBSCRIPTION,
  ACTIVATE_ORGANIZATION_SUBSCRIPTION_SUCCESS,
  ACTIVATE_ORGANIZATION_SUBSCRIPTION_FAILURE,
  UPDATE_SUBSCRIPTION_PAYMENT_METHOD,
  UPDATE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS,
  UPDATE_SUBSCRIPTION_PAYMENT_METHOD_FAILURE,
  APPLY_COUPON,
  APPLY_COUPON_SUCCESS,
  APPLY_COUPON_FAILURE,
} from "../../modules/constants";

export function* getOrganizationSubscription({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    let url = `billing/${organization_id}/subscriptions/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_ORGANIZATION_SUBSCRIPTION_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_ORGANIZATION_SUBSCRIPTION_FAILURE,
      payload: err,
    });
  }
}

export function* updateOrganizationSubscription({ payload }) {
  if (!payload) return;
  const { organization_id, ...details } = payload;
  try {
    let url = `billing/${organization_id}/subscriptions/`;
    const response = yield defaultApi(url, "PATCH", details);
    yield put({
      type: UPDATE_ORGANIZATION_SUBSCRIPTION_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: UPDATE_ORGANIZATION_SUBSCRIPTION_FAILURE,
      payload: err,
    });
  }
}

export function* updateSubscriptionPaymentMethod({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `billing/${organization_id}/subscriptions/change-payment-info/`;
    const response = yield defaultApi(url, "POST");
    yield put({
      type: UPDATE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS,
      payload: { data: response, orgId: organization_id },
    });
  } catch (err) {
    yield put({
      type: UPDATE_SUBSCRIPTION_PAYMENT_METHOD_FAILURE,
      payload: err,
    });
  }
}

export function* activateOrganizationSubscription({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `billing/${organization_id}/subscriptions/activate/`;
    const response = yield defaultApi(url, "POST");
    yield put({
      type: ACTIVATE_ORGANIZATION_SUBSCRIPTION_SUCCESS,
      payload: { data: response, orgId: organization_id },
    });
  } catch (err) {
    yield put({
      type: ACTIVATE_ORGANIZATION_SUBSCRIPTION_FAILURE,
      payload: err,
    });
  }
}

export function* applyCoupon({ payload }) {
  try {
    const { selected_plan_id, selected_plan_interval, ...details } = payload;
    let url = "/billing/discounts/";
    const response = yield defaultApi(url, "POST", details);
    yield put({
      type: APPLY_COUPON_SUCCESS,
      payload: {
        data: response,
        selected_plan_id,
        selected_plan_interval,
        organization_id: payload.organization_id,
      },
    });
  } catch (err) {
    yield put({
      type: APPLY_COUPON_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_ORGANIZATION_SUBSCRIPTION, getOrganizationSubscription),
    takeLatest(
      UPDATE_ORGANIZATION_SUBSCRIPTION,
      updateOrganizationSubscription
    ),
    takeLatest(
      UPDATE_SUBSCRIPTION_PAYMENT_METHOD,
      updateSubscriptionPaymentMethod
    ),
    takeLatest(
      ACTIVATE_ORGANIZATION_SUBSCRIPTION,
      activateOrganizationSubscription
    ),
    takeLatest(APPLY_COUPON, applyCoupon),
  ]);
}
