import { useLoadScript } from '@react-google-maps/api';
import moment from 'moment-timezone';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonIcon, ColoredButtonWithIcon } from 'styledComponents/buttons';

import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import RightSlidingPanel from '../../../components/RightSlidingPanel/RightSlidingPanel';
import {
  CommonFlex,
  CommonText,
  Container,
  PageTitle,
} from '../../../styledComponents/common';
import { getDistanceFromCoords } from '../../../utils/helper';
import { DataAndMapContainer } from '../JobSites/jobSitesStyles';
import RouteMapFilter from './RouteMapFilter/filterContainer';
import RouteMapGoogleMap from './RouteMapGoogleMap/RouteMapGoogleMap';
import Timeline from './Timeline/timelineContainer';
import { MapAndAlertContainer, MapTopAlert } from './routeMapStyles';

const mapLibraries = ['geometry'];
const mapIds = [process.env.REACT_APP_JOB_SITES_MAP_ID];

const RouteMap = props => {
  const { t } = useTranslation();
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedOffice, setSelectedOffice] = useState(null);

  const [map, setMap] = useState(null);
  // const [linePoints, setLinePoints] = useState([]);
  const [markers, setMarkers] = useState([]);

  const [taskCheckInOutMarkers, setTaskCheckInOutMarkers] = useState([]);
  const [clockInOutMarkers, setClockInOutMarkers] = useState([]);
  const [routes, setRoutes] = useState([]);

  const [eventsList, setEventsList] = useState([]);

  const [userCurrentLocation, setUserCurrentLocation] = useState(null);

  // timeline panel
  const [timelineData, setTimelineData] = useState(null);
  const [timelineIsOpen, setTimelineIsOpen] = useState(false);

  const { isLoaded, loadError } = useLoadScript({
    id: 'route-map-google-maps-script-loader',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY,
    libraries: mapLibraries,
    mapIds,
  });

  const {
    locationsIsLoading,

    // getRouteMapLocations,
    // getSingleEmployeeClockInOutList,
    // getTaskCheckInOutList,

    routeMapLocations,
    clockInOutList,
    taskCheckInOutList,
  } = props;

  const buildMarkerIcon = (location, nextLocation, options = {}) => {
    if (map) {
      const g = window.google.maps;
      let anchor;
      let fillColor = '#3887EE';
      let scale = options.scale || 3;
      let fillOpacity = 1;

      const heading = g.geometry.spherical
        .computeHeading(location, nextLocation)
        .toFixed(1);

      return {
        // path,
        rotation: parseFloat(heading),
        scale,
        anchor,
        fillColor,
        fillOpacity,
        strokeColor: '#fff',
        strokeWeight: 1,
        strokeOpacity: 1,
      };
    }
    return null;
  };

  const calculateRoute = useMemo(() => {
    const processLocations = () => {
      const polylines = [];
      const directionMarkers = [];

      let remainingLocations = [...routeMapLocations];

      let i = 0;

      while (i < clockInOutList.length) {
        let clockInTimestamp = null;
        let clockOutTimestamp = null;

        let iterationValue = 1;

        if (clockInOutList[i].type === 'clock_in') {
          clockInTimestamp = clockInOutList[i].timestamp;
          if (
            clockInOutList[i + 1] &&
            clockInOutList[i + 1].type === 'clock_out'
          ) {
            clockOutTimestamp = clockInOutList[i + 1].timestamp;
            iterationValue = 2;
          }
        }

        const locations = [];

        const removedIndices = new Set();

        if (clockInTimestamp) {
          if (clockInOutList[i]) {
            locations.push({
              lat: parseFloat(clockInOutList[i].lat),
              lng: parseFloat(clockInOutList[i].long),
            });
          }

          for (let j = 0; j < remainingLocations.length; j++) {
            const location = remainingLocations[j];
            const data = {
              id: location.id,
              location: {
                lat: parseFloat(location.lat),
                lng: parseFloat(location.long),
              },
              timestamp: location.timestamp,
            };
            if (j > 0 && j < remainingLocations.length - 1) {
              if (directionMarkers.length > 0) {
                const lastMarkerLoc =
                  directionMarkers[directionMarkers.length - 1];
                const dist = getDistanceFromCoords(
                  lastMarkerLoc.location,
                  data.location,
                );
                if (dist > 150) {
                  const nextLocation = {
                    lat: parseFloat(remainingLocations[j + 1].lat),
                    lng: parseFloat(remainingLocations[j + 1].long),
                  };
                  data.icon = buildMarkerIcon(data.location, nextLocation);
                  directionMarkers.push(data);
                }
              } else {
                const nextLocation = {
                  lat: parseFloat(remainingLocations[j + 1].lat),
                  lng: parseFloat(remainingLocations[j + 1].long),
                };
                directionMarkers.push({
                  ...data,
                  icon: buildMarkerIcon(data.location, nextLocation),
                });
              }
            }

            if (locations.length > 0 && j < remainingLocations.length - 1) {
              const lastLoc = locations[locations.length - 1];
              const dist = getDistanceFromCoords(lastLoc, data.location);

              if (dist <= 15) {
                removedIndices.add(j);
                continue;
              }
            }

            if (!clockOutTimestamp && !clockInOutList[i + 1]) {
              locations.push(data.location);
              removedIndices.add(j);
            } else if (clockOutTimestamp) {
              if (
                location.timestamp >= clockInTimestamp &&
                location.timestamp <= clockOutTimestamp
              ) {
                locations.push(data.location);
                removedIndices.add(j);
              }
            } else {
              if (j < remainingLocations.length - 1) {
                if (
                  location.timestamp >= clockInTimestamp &&
                  clockInOutList[i + 1] &&
                  location.timestamp <= clockInOutList[i + 1].timestamp
                ) {
                  locations.push(data.location);
                  removedIndices.add(j);
                }
              }
            }
          }

          if (clockOutTimestamp && clockInOutList[i + 1]) {
            locations.push({
              lat: parseFloat(clockInOutList[i + 1].lat),
              lng: parseFloat(clockInOutList[i + 1].long),
            });
          }

          remainingLocations = remainingLocations.filter(
            (_, index) => !removedIndices.has(index),
          );

          polylines.push({
            points: locations,
          });
        }
        i += iterationValue;
      }

      return {
        polylines,
        directionMarkers,
      };
    };

    if (
      clockInOutList &&
      clockInOutList.length > 0 &&
      routeMapLocations &&
      routeMapLocations.length > 0
    ) {
      return processLocations();
    }

    return [];
  }, [clockInOutList, routeMapLocations]);

  useEffect(() => {
    const { polylines, directionMarkers } = calculateRoute;
    if (polylines && polylines.length > 0) {
      setRoutes(polylines);
    } else {
      setRoutes([]);
    }

    if (directionMarkers && directionMarkers.length > 0) {
      setMarkers(directionMarkers);
    } else {
      setMarkers([]);
    }
    // setRoutes(calculateRoute);
  }, [calculateRoute]);

  useEffect(() => {
    let events = [];
    if (taskCheckInOutList && taskCheckInOutList.length > 0) {
      events = [...events, ...taskCheckInOutList];
      const taskMarkers = [];
      taskCheckInOutList.forEach(event => {
        taskMarkers.push({
          location: {
            lat: parseFloat(event.lat),
            lng: parseFloat(event.long),
          },
          timestamp: event.timestamp,
          event: event.event,
        });
      });
      setTaskCheckInOutMarkers(taskMarkers);
    } else if (taskCheckInOutList && taskCheckInOutList.length === 0) {
      events = [...events, ...[]];
      setTaskCheckInOutMarkers([]);
    }
    if (clockInOutList && clockInOutList.length > 0) {
      events = [...events, ...clockInOutList];
      const attendanceMarkers = [];
      clockInOutList.forEach(event => {
        attendanceMarkers.push({
          location: {
            lat: parseFloat(event.lat),
            lng: parseFloat(event.long),
          },
          timestamp: event.timestamp,
          event: event.type,
        });
      });
      setClockInOutMarkers(attendanceMarkers);
    } else if (clockInOutList && clockInOutList.length === 0) {
      events = [...events, ...[]];
      setClockInOutMarkers([]);
    }

    setEventsList(events.sort((a, b) => a.timestamp - b.timestamp));
  }, [taskCheckInOutList, clockInOutList]);

  // useEffect(() => {
  //   console.log(clockInOutList);
  // }, [clockInOutList]);

  useEffect(() => {
    fetch('https://hutils.loxal.net/whois')
      .then(resp => resp.json())
      .then(res => {
        if (res && res.ip) {
          setUserCurrentLocation({
            lat: res.latitude,
            lng: res.longitude,
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (map && userCurrentLocation) {
      if (!routes || (routes && !routes.length)) {
        map.panTo(userCurrentLocation);
      }
    }
  }, [map, userCurrentLocation]);

  // useEffect(() => {
  //   if (routeMapLocations && routeMapLocations.length > 0) {
  //     // let totalDuration = 0;
  //     const userData = routeMapLocations[0].employee;
  //     const points = [];
  //     const markers = [];
  //     routeMapLocations.forEach((location, index) => {
  //       const data = {
  //         id: location.id,
  //         location: {
  //           lat: parseFloat(location.lat),
  //           lng: parseFloat(location.long),
  //         },
  //         timestamp: location.timestamp,
  //       };
  //       if (index === 0) {
  //         data.start = true;
  //         markers.push(data);
  //       } else if (index === routeMapLocations.length - 1) {
  //         data.end = true;
  //         markers.push(data);
  //       } else {
  //         const lastMarkerLoc = markers[markers.length - 1];
  //         const dist = getDistanceFromCoords(
  //           lastMarkerLoc.location,
  //           data.location,
  //         );
  //         if (dist > 150) {
  //           const nextLocation = {
  //             lat: parseFloat(routeMapLocations[index + 1].lat),
  //             lng: parseFloat(routeMapLocations[index + 1].long),
  //           };
  //           data.icon = buildMarkerIcon(data.location, nextLocation);
  //           markers.push(data);
  //         }
  //       }

  //       if (
  //         !points.length ||
  //         index === 0 ||
  //         index === routeMapLocations.length - 1
  //       ) {
  //         points.push(data.location);
  //       } else {
  //         const lastPointLoc = points[points.length - 1];
  //         const dist = getDistanceFromCoords(lastPointLoc, data.location);
  //         if (dist > 15) {
  //           points.push(data.location);
  //         }
  //       }
  //     });

  //     setLinePoints(points);
  //     setMarkers(markers);
  //   } else if (routeMapLocations && routeMapLocations.length === 0) {
  //     setLinePoints([]);
  //     setMarkers([]);
  //   }
  // }, [routeMapLocations]);

  const toggleTimeline = value => {
    setTimelineIsOpen(value);
    if (!value) {
      setTimelineData(null);
    }
  };

  return (
    <div className="content">
      <RightSlidingPanel
        isOpen={timelineIsOpen}
        closePanel={() => toggleTimeline(false)}
        width="530px"
      >
        <Timeline
          eventsList={eventsList}
          selectedDate={selectedDate}
          selectedMember={selectedMember}
        />
      </RightSlidingPanel>
      <PageTitle>{t('route_map')}</PageTitle>
      <CommonFlex justifyContent="space-between">
        <RouteMapFilter
          selectedMember={selectedMember}
          setSelectedMember={setSelectedMember}
          selectedOffice={selectedOffice}
          setSelectedOffice={setSelectedOffice}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
        <ColoredButtonWithIcon
          style={{ alignSelf: 'center' }}
          onClick={() => toggleTimeline(true)}
        >
          {t('see_details')}
        </ColoredButtonWithIcon>
      </CommonFlex>

      <MapAndAlertContainer>
        {locationsIsLoading ? (
          <MapTopAlert fontSize="15px" height="48px">
            <ComponentCircleLoader padding="10px 0" size={16} color="#fff" />
          </MapTopAlert>
        ) : !routeMapLocations ||
          (routeMapLocations && !routeMapLocations.length) ? (
          <MapTopAlert fontSize="15px" height="48px">
            {t('There_is_no_location_data_for_this_user')}
          </MapTopAlert>
        ) : null}
        {loadError ? (
          <div>Map cannot be loaded right now! Please try again later</div>
        ) : isLoaded ? (
          <RouteMapGoogleMap
            map={map}
            setMap={setMap}
            // linePoints={linePoints}
            routes={routes}
            markers={markers}
            selectedTimesheet={null}
            taskMarkers={taskCheckInOutMarkers}
            clockInOutMarkers={clockInOutMarkers}
          />
        ) : (
          <ComponentCircleLoader />
        )}
      </MapAndAlertContainer>
    </div>
  );
};

export default RouteMap;
