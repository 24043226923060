import {
  all,
  call,
  put,
  takeLatest,
  throttle,
  delay,
} from "redux-saga/effects";

import { defaultApi } from "../../utils/axiosApi";

import {
  GET_LIVE_MEMBERS_LIST,
  GET_LIVE_MEMBERS_LIST_SUCCESS,
  GET_LIVE_MEMBERS_LIST_FAILURE,
  GET_LAST_FIVE_MIN_ACTIVE_LIST,
  GET_LAST_FIVE_MIN_ACTIVE_LIST_SUCCESS,
  GET_LAST_FIVE_MIN_ACTIVE_LIST_FAILURE,
  GET_INSTANT_SCREENSHOTS_LIST,
  GET_INSTANT_SCREENSHOTS_LIST_SUCCESS,
  GET_INSTANT_SCREENSHOTS_LIST_FAILURE,
  TAKE_INSTANT_SCREENSHOT,
  TAKE_INSTANT_SCREENSHOT_SUCCESS,
  TAKE_INSTANT_SCREENSHOT_FAILURE,
} from "../../modules/constants";

export function* getLiveMembersList({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  yield delay(1000);
  try {
    let url = `${organization_id}/live-feed/members/`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_LIVE_MEMBERS_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_LIVE_MEMBERS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getLastFiveMinActiveList({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  yield delay(1000);
  try {
    let url = `${organization_id}/live-feed/past-five-min/`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_LAST_FIVE_MIN_ACTIVE_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_LAST_FIVE_MIN_ACTIVE_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getInstantScreenshotsList({ payload }) {
  if (!payload) return;
  const { organization_id, date } = payload;
  yield delay(800);
  try {
    let url = `${organization_id}/live-feed/live-screenshot-requests/${
      date ? `?date=${date}` : ""
    }`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_INSTANT_SCREENSHOTS_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_INSTANT_SCREENSHOTS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* takeInstantScreenshot({ payload }) {
  if (!payload) return;
  const { organization_id, ...data } = payload;
  try {
    const url = `${organization_id}/live-feed/live-screenshot-requests/`;
    const method = "POST";
    const response = yield defaultApi(url, method, data);
    yield put({
      type: TAKE_INSTANT_SCREENSHOT_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: TAKE_INSTANT_SCREENSHOT_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    throttle(10000, GET_LIVE_MEMBERS_LIST, getLiveMembersList),
    throttle(10000, GET_LAST_FIVE_MIN_ACTIVE_LIST, getLastFiveMinActiveList),
    throttle(500, GET_INSTANT_SCREENSHOTS_LIST, getInstantScreenshotsList),
    takeLatest(TAKE_INSTANT_SCREENSHOT, takeInstantScreenshot),
  ]);
}
