import { connect } from 'react-redux';

// Actions
import { getOfficesShortList } from '../Offices/officesActions';
// Component
import ClockInOut from './ClockInOut';
import { getClockInOutList } from './clockInOutActions';

// Store props to map with current state
const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  clockInOutList: state.clockInOut.clockInOutList,
  isLoading: state.clockInOut.isLoading,

  officesShortList: state.office.officesShortList,

  // pagination
  clockInOutListCount: state.clockInOut.clockInOutListCount,
  clockInOutPageSize: state.clockInOut.clockInOutPageSize,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getClockInOutList: payload => dispatch(getClockInOutList(payload)),
  getOfficesShortList: payload => dispatch(getOfficesShortList(payload)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(ClockInOut);
