import React, { useState, useEffect, useRef, useTransition } from "react";

import { PageTitle, Container } from "../../styledComponents/common";

import ProfilePicture from "./ProfilePicture";
import UserDeleteProfile from "./UserDeleteProfile";
import UserOrg from "./UserOrganization";
import UserProfileEdit from "./UserProfileEdit";
import ChangePasswordComponent from "../ChangePassword/ChangePassword";
import { useTranslation } from 'react-i18next'

const UserProfile = (props) => {
  const {t} = useTranslation();
  useEffect(() => {
    const { profileOperation, auth, getUserEmails } = props;

    if (auth.auth.is_profile_created === "True") {
      let payload = {
        method: "GET",
      };
      profileOperation(payload);
    }
    getUserEmails();
  }, []);

  return (
    <>
      <div className="content">
        <PageTitle>{t('my_profile')}</PageTitle>
        <Container
          padding="30px"
          marginTop="20px"
          style={{ position: "relative" }}
        >
          <ProfilePicture
            {...props.profile.profileData}
            history={props.history}
            redirect={props.redirect}
            handleSave={props.profileOperation}
          />
          <UserProfileEdit
            history={props.history}
            redirect={props.redirect}
            organizations={
              props.organizations && props.organizations.data
                ? props.organizations.data.map((o) => o.name)
                : []
            }
            emails={props.profile.userEmails}
            getUserEmails={props.getUserEmails}
            requestUserEmails={props.profile.requestUserEmails}
            addEmail={props.addEmail}
            deleteEmail={props.deleteEmail}
            makeEmailDefault={props.makeEmailDefault}
            {...props.profile.profileData}
            handleSave={props.profileOperation}
            loginProfileCreatedReset={props.loginProfileCreatedReset}
            updateIsLoading={props.updateIsLoading}
          />
        </Container>
        <Container padding="30px" marginTop="20px">
          <ChangePasswordComponent
            changePasswordAction={props.changePasswordAction}
            passwordChanged={props.passwordChanged}
            loginProfileCreatedReset={props.loginProfileCreatedReset}
            history={props.history}
            redirect={props.redirect}
          />
        </Container>
        {/* <Container padding="30px" marginTop="20px">
          <UserOrg
            organizations={props.organizations ? props.organizations.list : []}
          />
        </Container>
        <Container padding="30px" marginTop="20px">
          <UserDeleteProfile profileDelete={props.profileDelete} />
        </Container> */}
      </div>
    </>
  );
};
export default UserProfile;
