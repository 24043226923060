import { connect } from "react-redux";

// Component
import RedeemCode from "./RedeemDiscountCode";

// Actions
import { redeem } from "./redeemDiscountCodeActions";

// Store props to map with current state
const mapStateToProps = state => ({ redeemDiscountCode: state.redeemDiscountCode });

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  redeem: details => dispatch(redeem({ ...details }))
});

// connect states and dispatchers with components
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RedeemCode);
