import styled, { css } from "styled-components";

export const FilterContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 230px) auto 1fr;
  justify-content: start;
  grid-column-gap: 40px;
  margin-top: 30px;
`;
export const InvoiceTableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 100px 1.8fr minmax(100px, 150px) minmax(
      160px,
      1fr
    );
  grid-column-gap: 20px;
  justify-content: space-between;
  padding: 0 30px;
  border-bottom: ${({ heading, theme }) =>
    heading && `1px solid ${theme.colors.border.secondary}`};
  :nth-of-type(even) {
    background-color: ${({ heading }) => !heading && `#f8f9fc`};
  }
`;

export const TableItem = styled.div`
  display: grid;
  padding: ${(props) => (props.buttonPadding ? `8px 0` : `15px 0`)};
  align-content: center;
  ${({ hoverPointer }) =>
    hoverPointer &&
    css`
      cursor: pointer;
    `}
`;

export const StatusBox = styled.div`
  font-size: 13.5px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  color: ${({ theme }) => theme.colors.main.white};
  padding: 5px 10px;
  margin-right: auto;
  text-align: center;
  text-transform: capitalize;
  min-width: 120px;
  background-color: ${({ status, theme }) =>
    status === "draft"
      ? theme.colors.main.grayMedium
      : status === "sent"
      ? theme.colors.main.blue
      : status === "partial"
      ? theme.colors.main.warning
      : theme.colors.main.primary};
`;

export const CreateInvoiceSubTitle = styled.p`
  font-size: ${({ theme }) => theme.font.pageTextImportantSemibold.size};
  font-weight: ${({ theme }) => theme.font.pageTextImportantSemibold.weight};
  color: ${({ theme }) => theme.font.pageTextImportantSemibold.color};
  margin: ${({ margin }) => margin || `30px 0 0 0`};
`;

export const CreateInvoiceItemContainer = styled.div`
  display: grid;
  grid-template-columns:
    55px minmax(120px, 150px) minmax(100px, 400px) minmax(80px, 100px) minmax(
      80px,
      100px
    )
    minmax(110px, 130px) minmax(70px, 100px) minmax(70px, 100px) 130px;
  grid-gap: 25px;
  justify-content: space-between;
  align-items: center;
  padding: ${({ heading }) => (heading ? "15px" : "8px 15px")};
  margin-top: ${({ marginTop }) => marginTop || "15px"};
  background-color: ${(props) => props.heading && `#F8F9FC`};
  @media (max-width: 1380px) {
    grid-template-columns: 50px 110px 1fr 75px 100px minmax(95px, 105px) 60px 60px 115px;
    grid-gap: 15px;
  }
`;

export const ToggleButton = styled.div`
  display: grid;
  align-items: center;
  width: 30px;
  height: 16px;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.main.success : theme.colors.border.secondary};
  padding: 0 3px;
  border-radius: 10px;
  cursor: pointer;
`;
export const ToggleButtonRound = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  margin-left: ${(props) => props.active && `auto`};
`;

export const AddItemContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: start;
  padding: 30px;
  border-bottom: 1px solid #c2cce1;
`;

export const AddItem = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || "20px auto"};
  /* justify-content: start; */
  align-items: center;
  grid-column-gap: ${({ gap }) => gap || "10px"};
  cursor: pointer;
`;

export const CalculationContainer = styled.div`
  display: grid;
  grid-template-columns: 40px 130px ${({ grand }) => (grand ? "auto" : "110px")} 40px 40px minmax(
      auto,
      200px
    );
  grid-gap: ${({ gap }) => gap || "20px 20px"};
  justify-content: end;
  align-items: center;
  padding: ${({ padding, grand }) =>
    padding ? padding : grand ? `15px 10px` : `10px`};
  background-color: ${({ grand }) => grand && `#f8f9fc`};
  margin-top: ${({ grand }) => grand && `20px`};
`;

export const TaxTypeButton = styled.div`
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: ${({ theme }) => theme.borderRadii.button};
  border: ${({ active, theme }) =>
    `1px solid ${
      active ? theme.colors.border.primary : theme.colors.border.secondaryLight
    }`};
  color: ${({ active, theme }) =>
    active ? theme.colors.main.primary : theme.colors.main.labelText};
  font-size: ${({ fontSize }) => fontSize || `14px`};
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  margin-left: ${({ percent }) => !percent && `-10px`};
  cursor: pointer;
  &:hover {
    border-color: ${({ theme }) => theme.colors.border.primary};
  }
`;

export const LinkedProjectSection = styled.div`
  padding: 15px 20px;
  margin: 20px 0;
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: ${({ theme }) => theme.card.borderRadius};
  background-color: ${({ theme }) => theme.colors.main.primaryLight};
`;

export const PreviewContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(900px, 1150px);
  justify-content: center;
  grid-gap: 15px;
  padding: 20px;
  @media (max-width: 1440px) {
    grid-template-columns: 1fr;
  }
`;

export const TypeSpan = styled.span`
  padding: 3px 11px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.main.white};
  font-size: 12px;
  text-transform: capitalize;
  background-color: ${({ status, theme }) =>
    status === "draft"
      ? theme.colors.main.grayMedium
      : status === "sent"
      ? theme.colors.main.blue
      : status === "partial"
      ? theme.colors.main.warning
      : theme.colors.main.primary};
  position: relative;
  top: -22px;
`;

export const PreviewInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
`;

export const PreviewInfoTextContainer = styled.div`
  display: grid;
  grid-template-columns: 20px 70px minmax(130px, 1fr) 80px 155px 130px;
  grid-gap: 20px 17px;
  padding: 15px 0;
  border-bottom: 1px solid #c2cce1;
  justify-content: space-between;
`;

export const PreviewInfoTotalContainer = styled.div`
  display: grid;
  grid-template-columns: 155px 130px;
  grid-gap: 20px 17px;
  padding: 15px 0;
  border-bottom: 1px solid #c2cce1;
  justify-content: end;
`;
export const PreviewHistoryContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  justify-content: space-between;
  grid-gap: 20px 17px;
  padding: ${({ info }) => (info ? `15px 0` : `20px 0`)};
  border-bottom: ${({ info, theme }) =>
    !info && `1px solid ${theme.colors.border.secondary}`};
  border-top: ${({ info, index, theme }) =>
    info && index !== 0 && `1px solid ${theme.colors.border.secondaryLight}`};
`;

export const PreviewDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  justify-content: space-between;
  padding: 25px 0 30px 0;
`;
export const PreviewDetailsElement = styled.div`
  display: grid;
  grid-gap: 10px;
  align-content: start;
`;
export const ClientViewContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
`;
export const ModalGreenSection = styled.div`
  display: grid;
  grid-template-columns: ${({ details }) =>
    !details ? `auto auto auto` : `auto auto`};
  justify-content: ${(props) =>
    !props.details ? `space-between` : `space-evenly`};
  grid-gap: 10px 15px;
  padding: 15px 20px;
  margin: 20px 0;
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: ${({ theme }) => theme.borderRadii.card};
  background-color: ${({ theme }) => theme.colors.main.primaryLight};
  text-align: center;
`;

export const ModalButtonSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px 15px;
  padding: 15px 30px 0 30px;
  border-top: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  justify-content: ${({ details, justifyContent }) =>
    justifyContent ? justifyContent : !details ? `center` : `space-between`};
  margin-top: ${({ details }) => (!details ? `20px` : `35px`)};
`;

export const ReorderSelectSection = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-column-gap: 15px;
  justify-content: space-between;
  align-items: center;
`;

export const SubmitButtonContainer = styled.div`
  display: grid;
  grid-column: 1 / span 6;
  grid-template-columns: auto auto auto;
  grid-column-gap: 15px;
  justify-content: start;
  padding-left: 50px;
`;
export const SendMailDetails = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  grid-gap: 12px 25px;
  padding: 20px;
  background-color: #fcfdfe;
  border: 1px solid #e4e7eb;
  border-radius: 4px;
`;

export const CopyURLSection = styled.div`
  margin-top: 10px;
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 15px;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.input.borderColor.default};
  border-radius: ${({ theme }) => theme.input.borderRadius};
`;
export const SendEmailSubmitSection = styled.div`
  display: flex;
  flex-flow: row wrap;
  grid-gap: 10px 15px;
  padding: 10px 30px;
  margin-top: 25px;
`;
export const SaveCrossSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 18px;
  justify-content: start;
  align-items: center;
  @media (max-width: 1380px) {
    grid-column-gap: 15px;
  }
`;
export const SaveSection = styled.div`
  display: grid;
  justify-content: start;
  align-items: end;
  margin-top: 10px;
`;
export const SendMailText = styled.div`
  border: 1px solid ${({ theme }) => theme.input.borderColor.default};
  border-radius: ${({ theme }) => theme.input.borderRadius};
  padding: 10px 15px;
  color: ${({ theme }) => theme.input.textColor};
`;
