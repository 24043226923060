import { Modal, ModalBody } from "reactstrap";
import { ModalButtonSection } from "../../styledComponents/members";
import { CommonText } from "../../styledComponents/common";
import { WhiteButton, ColoredButton } from "../../styledComponents/buttons";

import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";

const DeleteTaskModal = ({
  isOpen,
  toggle,
  handleDelete,
  isLoading,
  deleteIsLoading,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: `25vh` }}>
      <ModalBody style={{ padding: `20px` }}>
        <CommonText style={{ paddingLeft: `15px` }}>
          Are you sure you want to delete this task?
        </CommonText>
        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={toggle}>
            Cancel
          </WhiteButton>
          <ColoredButton
            type="delete"
            padding={isLoading ? "0 15px" : "0 30px"}
            onClick={() => handleDelete()}
          >
            {deleteIsLoading ? (
              <ButtonTextLoader loadingText="Deleting" fontSize="13px" />
            ) : (
              "Delete"
            )}
          </ColoredButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default DeleteTaskModal;
