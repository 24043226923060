import { connect } from 'react-redux';

import { getOfficesShortList } from '../../Offices/officesActions';
import { addMember, updateMember, getMemberDetails } from '../membersActions';
// Component
import AddMember from './AddMember';

// Store props to map with current state
const mapStateToProps = state => ({
  officesShortList: state.office.officesShortList,
  createdMemberInfo: state.member.createdMemberInfo,
  isLoading: state.member.isLoading,
  createMemberLoading: state.member.createMemberLoading,
  memberDetails: state.member.memberDetails,
  updateMemberLoading: state.member.updateMemberLoading,
  updatedData: state.member.updatedData,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getOfficesShortList: params => dispatch(getOfficesShortList(params)),
  addMember: params => dispatch(addMember(params)),
  updateMember: params => dispatch(updateMember(params)),
  getMemberDetails: params => dispatch(getMemberDetails(params)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(AddMember);
