import { connectRouter } from 'connected-react-router';
import liveLocationReducer from 'custom_modules/FieldServices/LiveLocation/liveLocationReducer';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

//import activityReducer from "./custom_modules/Activity/activityReducer";
import activityReducer from './custom_modules/ActivityNew/activityReducer';
import clientReducer from './custom_modules/Clients/clientsReducer';
import clockInOutReducer from './custom_modules/ClockInOut/clockInOutReducer';
import dashboardReducer from './custom_modules/Dashboard/dashboardReducer';
import geofenceClockInOutReducer from './custom_modules/FieldServices/GeofenceClockInOut/geofenceClockInOutReducer';
import jobSitesReducer from './custom_modules/FieldServices/JobSites/jobSitesReducer';
import routeMapReducer from './custom_modules/FieldServices/RouteMapNew/routeMapReducer';
import forgotPasswordReducer from './custom_modules/ForgotPassword/forgotPasswordReducer';
import integrationsReducer from './custom_modules/IntegrationsNew/integrationsReducer';
import invoiceReducer from './custom_modules/Invoice/invoiceReducer';
import leaveReducer from './custom_modules/Leave/leaveReducer';
import liveFeedReducer from './custom_modules/LiveFeed/liveFeedReducer';
import authReducer from './custom_modules/Login/loginReducer';
import memberReducer from './custom_modules/Members/membersReducer';
import officesReducer from './custom_modules/Offices/officesReducers';
import onboardingReducer from './custom_modules/Onboarding/OnboardingSteps/onboardingStepsReducer';
import requestJoinOrgReducer from './custom_modules/Onboarding/RequestJoinOrg/requestJoinOrgReducer';
import organizationReducer from './custom_modules/Organization/OrganizationReducer';
import payrollReducer from './custom_modules/Payroll/payrollReducer';
import projectReducer from './custom_modules/Projects/projectsReducer';
import publicApiReducer from './custom_modules/PublicApi/publicApiReducer';
import redeemLimitedTimeCodeReducer from './custom_modules/RedeemAppsumo/redeemCodeReducer';
import redeemDiscountCodeReducer from './custom_modules/RedeemBlackFriday/redeemDiscountCodeReducer';
import redeemCodeReducer from './custom_modules/RedeemCode/redeemCodeReducer';
// import reportReducer from "./custom_modules/Reports/ReportsReducer";
import reportReducer from './custom_modules/ReportsNew/reportsReducer';
import resetPasswordReducer from './custom_modules/ResetPassword/resetPasswordReducer';
import scheduleReducer from './custom_modules/Schedule/scheduleReducer';
import activitySettingsReducer from './custom_modules/Settings/ActivitySettings/activitySettingsReducer';
import timesheetSettingsReducer from './custom_modules/Settings/TimesheetSettings/timesheetSettingsReducer';
import settingsReducer from './custom_modules/Settings/settingsReducer';
import signUpReducer from './custom_modules/SignUp/signUpReducer';
import subscriptionReducer from './custom_modules/Subscription/subscriptionReducer';
import taskReducer from './custom_modules/Tasks/tasksReducer';
import teamReducer from './custom_modules/Teams/teamsReducer';
import timesheetReducer from './custom_modules/Timesheet/timesheetReducer';
import timesheetApprovalReducer from './custom_modules/TimesheetApproval/timesheetApprovalReducer';
import profileReducer from './custom_modules/UserProfile/userProfileReducer';
import verificationCodeReducer from './custom_modules/VerificationCode/verificationCodeReducer';
import verifyReducer from './custom_modules/VerifyUser/verifyReducer';
import workNotesReducer from './custom_modules/WorkNotes/workNotesReducer';
import adminReducer from './layouts/Admin/adminReducer';

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  whitelist: ['auth'],
};

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth: persistReducer(authPersistConfig, authReducer),
    form: formReducer,
    requestJoinOrg: requestJoinOrgReducer,
    onboarding: onboardingReducer,
    signUp: signUpReducer,
    dashboard: dashboardReducer,
    forgot: forgotPasswordReducer,
    resetPassword: resetPasswordReducer,
    verificationCode: verificationCodeReducer,
    verify: verifyReducer,
    profile: profileReducer,
    liveFeed: liveFeedReducer,
    project: projectReducer,
    organization: organizationReducer,
    office: officesReducer,
    task: taskReducer,
    clockInOut: clockInOutReducer,
    leave: leaveReducer,
    report: reportReducer,
    client: clientReducer,
    activity: activityReducer,
    routeMap: routeMapReducer,
    jobSites: jobSitesReducer,
    liveLocation: liveLocationReducer,
    geofenceClockInOut: geofenceClockInOutReducer,
    timesheet: timesheetReducer,
    timesheetApproval: timesheetApprovalReducer,
    workNotes: workNotesReducer,
    schedule: scheduleReducer,
    team: teamReducer,
    invoice: invoiceReducer,
    member: memberReducer,
    redeemCode: redeemCodeReducer,
    redeemDiscountCode: redeemDiscountCodeReducer,
    redeemLimitedTimeCode: redeemLimitedTimeCodeReducer,
    payroll: payrollReducer,
    integrations: integrationsReducer,
    publicApi: publicApiReducer,
    subscription: subscriptionReducer,
    activitySettings: activitySettingsReducer,
    timesheetSettings: timesheetSettingsReducer,
    admin: adminReducer,
    settings: settingsReducer,
  });
