import { toast } from "react-toastify";
import styled from "styled-components";

const MessageContainer = styled.div`
  display: grid;
  grid-row-gap: 8px;
`;
const MessageTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #fff;
`;
const MessageBody = styled.div`
  font-size: 13px;
  color: rgba(255, 255, 255, 0.8);
`;

const Message = ({ title, body, closeToast, toastProps }) => {
  return (
    <MessageContainer>
      <MessageTitle>{title}!</MessageTitle>
      <MessageBody>{body}</MessageBody>
    </MessageContainer>
  );
};

export const createNotification = (type, message, timeout, subText) => {
  switch (type) {
    case "info":
      toast.info(<Message title="Info" body={message} />, {
        autoClose: { timeout },
      });
      break;
    case "success":
      toast.success(<Message title="Success" body={message} />, {
        autoClose: { timeout },
      });
      break;
    case "warning":
      toast.warn(<Message title="Warning" body={message} />, {
        autoClose: { timeout },
      });
      break;
    case "error":
      toast.error(<Message title="Error" body={message} />, {
        autoClose: { timeout },
      });
      break;
    default:
      toast(<Message title="Notification" body={message} />, {
        autoClose: { timeout },
      });
      break;
  }
};
