import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_REQUEST_SUCCESS,
  FORGOT_PASSWORD_REQUEST_FAILURE,
  CLEAR_FORGOT_PASSWORD_LINK_SENT,
} from "../../modules/constants";
import { createNotification } from "../../modules/notificationManager";

// ------------------------------------
// Action handler methods
// ------------------------------------
export const handleForgotPasswordRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    linkSent: false,
    errorMessage: null,
  };
};

export const handleForgotPasswordRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      redirect: true,
      linkSent: true,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const handleForgotPasswordRequestFailure = (state, action) => {
  if (action.payload.response === undefined) {
    createNotification("error", "No internet connection found", 3000);
    return {
      ...state,
      isLoading: false,
    };
  }
  const {
    payload: {
      response: { data, status },
    },
  } = action;
  if (status === 400) {
    return {
      ...state,
      isLoading: false,
      errorMessage: data.failure,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const handleClearForgotPasswordLinkSent = (state, action) => {
  return {
    redirect: false,
    isLoading: false,
    linkSent: false,
    errorMessage: null,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [FORGOT_PASSWORD_REQUEST]: handleForgotPasswordRequest,
  [FORGOT_PASSWORD_REQUEST_SUCCESS]: handleForgotPasswordRequestSuccess,
  [FORGOT_PASSWORD_REQUEST_FAILURE]: handleForgotPasswordRequestFailure,

  [CLEAR_FORGOT_PASSWORD_LINK_SENT]: handleClearForgotPasswordLinkSent,
};

// default initial state
const initialState = {
  redirect: false,
  isLoading: false,
  linkSent: false,
  errorMessage: null,
};

export default function forgotPasswordReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
