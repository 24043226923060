import { t } from 'i18next';
import moment from 'moment';
import styled from 'styled-components';
import { CommonFlex, CommonText } from 'styledComponents/common';

const TimeDurationContainer = styled.div`
  width: 300px;
  margin-top: 2px;
  font-size: 12px;
  background-color: #dbe6d5;
  padding: 40px;
  border-radius: 6px;
`;

const TimeDurationBig = ({ startDate, startTime, endDate, endTime }) => {
  const tempStartTime = moment(startTime, 'HH:mm:ss');
  const startDateTime = moment(startDate).set({
    hour: tempStartTime.hour(),
    minute: tempStartTime.minute(),
    second: tempStartTime.second(),
  });

  // Combine end date and time
  const endDateTime = moment(endDate).set({
    hour: moment(endTime, 'HH:mm:ss').hour(),
    minute: moment(endTime, 'HH:mm:ss').minute(),
    second: moment(endTime, 'HH:mm:ss').second(),
  });

  // Calculate the difference in milliseconds
  const timeDiffInMillis = endDateTime.diff(startDateTime);

  // Convert milliseconds to desired time units
  const duration = moment.duration(timeDiffInMillis);

  // Get the duration in hours, minutes, and seconds
  const hours = Math.floor(duration.asMilliseconds() / (1000 * 60 * 60));
  const minutes = duration.minutes() + Math.ceil(duration.seconds() / 60);
  return (
    <TimeDurationContainer>
      <CommonFlex direction="column" gap="2px" alignItems="center">
        {' '}
        <CommonText fontSize="20px" fontWeight="500" color="#434343">
          {hours > 0 ? hours + 'H ' : ''} {minutes + 'Min'}
        </CommonText>
        <CommonText fontSize="20px" fontWeight="500" color="#434343">
          {t('ticket_duration')}
        </CommonText>
        {/* <img src={tickIcon} alt="" style={{ height: '15px', width: '15px' }} /> */}
      </CommonFlex>
    </TimeDurationContainer>
  );
};

export default TimeDurationBig;
