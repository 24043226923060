import { colors, borderColors, hoverBorderColors } from "./colors";
import { fontWeightConfig, radiusConfig } from "../common";

export const dropdownConfiguration = {
  textColor: colors.textMain,
  fontSize: "13px",
  borderColor: {
    default: borderColors.secondaryLight,
    hover: hoverBorderColors.secondaryLight,
    focus: borderColors.primary,
  },
  focusShadow: `0 0 0 4px ${colors.focusShadow}`,
  borderRadius: radiusConfig.dropdown,
  bgColor: colors.white,
  placeholder: {
    color: colors.placeholderText,
  },
  option: {
    textColor: colors.placeholderText,
    selectedTextColor: colors.white,
    selectedFontWeight: fontWeightConfig.regular,
    selectedBgColor: colors.primary,
    hoverBgColor: colors.primaryLight,
  },
  menu: {
    borderColor: borderColors.primary,
    borderRadius: radiusConfig.dropdown,
  },
  scrollbar: {
    borderRadius: "6px",
    width: "6px",
    trackBgColor: "#eff1f8",
    thumbBgColor: "#d2d9ea",
    thumbBorderRadius: "3px",
    thumbHoverBgColor: "#c9d1e3",
  },
};
