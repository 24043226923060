import styled from "styled-components";
import moment from "moment-timezone";
import { getRandomColor } from "../../../utils/getRandomColor";

const TimeDiv = styled.div`
  height: 36px;
  width: 90px;
  border-radius: ${({ theme }) => theme.borderRadii.button};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.font.pageText.size};
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  background: ${() => getRandomColor()};
`;

const TimeBlankDiv = styled.div`
  height: 36px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.font.pageText.size};
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  background: ${({ theme }) => theme.colors.main.white};
`;

const TimeSpan = (props) => {
  const { event, selectedMember, getTimesheetDetails, handleSetUserData } =
    props;

  return (
    <>
      {event.title === "0h 00m" ? (
        <TimeBlankDiv>-</TimeBlankDiv>
      ) : (
        <TimeDiv
          style={{ cursor: "pointer" }}
          onClick={() => {
            getTimesheetDetails(
              moment(event.event && event.event.start),
              selectedMember
                ? selectedMember.id
                : localStorage.getItem("user_id")
            );
            handleSetUserData(
              moment(event.event && event.event.start),
              event.title
            );
          }}
        >
          {event.title}
        </TimeDiv>
      )}
    </>
  );
};

export default TimeSpan;
