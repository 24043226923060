//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

import {
  GET_WORK_NOTES,
  GET_WORK_NOTES_SUCCESS,
  GET_WORK_NOTES_FAILURE,
} from "../../modules/constants";

export function* getWorkNotesRequest({ payload }) {
  if (!payload) return;
  const { organization_id, date, user_id, timezone, limit, offset } = payload;
  try {
    const url = `${organization_id}/worknotes/?start=${date}&end=${date}&tz=${timezone}&offset=${offset}&limit=${limit}${
      user_id ? `&user_id=${user_id}` : ""
    }`;

    const response = yield defaultApi(url, "GET");

    yield put({
      type: GET_WORK_NOTES_SUCCESS,
      payload: { data: response, timezone },
    });
  } catch (err) {
    yield put({
      type: GET_WORK_NOTES_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([takeLatest(GET_WORK_NOTES, getWorkNotesRequest)]);
}
