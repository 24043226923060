import React, { useEffect, useState, useRef } from "react";
import TimePickerInput from "./TimePickerInput";
import AmPmInput from "./AmPmInput";
import { doubleChar, isOnMobileDevice, getDatePartsByProps } from "./actions";
import {
  TimePickerWrapper,
  TimePickerContainer,
  MobileInputContainer,
  MobileInput,
  TimeInputContainer,
} from "./timePickerStyles";

function TimePicker(props) {
  const {
    hour12Format,
    value,
    onChange,
    allowDelete,
    onFinishInput,
    focusInput,
    setFocusInput,
  } = props;
  // const [isMobile, setIsMobile] = useState(isOnMobileDevice());

  const dateParts = getDatePartsByProps(value, hour12Format);
  const [hour, setHour] = useState(dateParts.hour);
  const [minute, setMinutes] = useState(dateParts.minute);
  const [amPm, setAmPM] = useState(dateParts.amPm);
  // const [valueMobile, setValueMobile] = useState(value);
  const hourRef = useRef(null);
  const minuteRef = useRef(null);
  const amPmRef = useRef(null);

  const hourRange = hour12Format
    ? { start: 1, end: 12 }
    : { start: 0, end: 23 };

  const focusElementByRef = (ref) => {
    ref.current && ref.current.focus();
  };

  const blurElementByRef = (ref) => {
    ref.current && ref.current.blur();
  };

  const focusMinute = () => focusElementByRef(minuteRef);

  // const updateTouchDevice = () => setIsMobile(isOnMobileDevice());

  const toggleAmPm = () => setAmPM(amPm === "AM" ? "PM" : "AM");

  useEffect(() => {
    if (focusInput && hourRef) {
      focusElementByRef(hourRef);
      setFocusInput(false);
    }
  }, [focusInput]);

  useEffect(() => {
    if (hour !== "" && minute !== "" /* && !isMobile */) {
      let hour24Format = !hour12Format && doubleChar(hour);
      let hour12Am = amPm === "AM" && hour === "12" && "00";
      const calculateHour =
        parseInt(hour) + (amPm === "PM" && hour !== "12" ? 12 : 0);
      let dateString24 =
        doubleChar((hour24Format || hour12Am || calculateHour).toString()) +
        ":" +
        minute;
      let hour24 = dateString24.substring(0, 2);
      let hour12 = doubleChar(
        parseInt(hour24) < 12 ? hour24 : parseInt(hour24) - 12
      );
      let amPmString = parseInt(hour24) < 12 ? "AM" : "PM";
      onChange(dateString24);
    }
  }, [hour, minute, amPm]);

  useEffect(() => {
    // if (!isMobile) {
    const dateParts = getDatePartsByProps(value, hour12Format);
    setHour(dateParts.hour);
    setMinutes(dateParts.minute);
    setAmPM(dateParts.amPm);
    // }
  }, [value]);

  // useEffect(() => {
  //   window.addEventListener("resize", updateTouchDevice);
  //   return () => {
  //     window.removeEventListener("resize", updateTouchDevice);
  //   };
  // }, []);

  return (
    <TimePickerWrapper>
      <TimePickerContainer>
        {/* {isMobile ? (
          <MobileInputContainer>
            <MobileInput
              type="time"
              value={valueMobile}
              onChange={(e) => {
                setValueMobile(e.target.value);
                onChange(e.target.value);
              }}
            />
          </MobileInputContainer>
        ) : ( */}
        <React.Fragment>
          <TimePickerInput
            inputRef={hourRef}
            value={hour}
            placeholder="- -"
            setValue={setHour}
            allowDelete={allowDelete}
            moveNext={focusMinute}
            range={hourRange}
            toggleAmPm={toggleAmPm}
          />
          <TimePickerInput
            inputRef={minuteRef}
            value={minute}
            placeholder="- -"
            setValue={setMinutes}
            allowDelete={allowDelete}
            moveNext={
              hour12Format
                ? () => focusElementByRef(amPmRef)
                : () => blurElementByRef(minuteRef)
            }
            movePrev={() => focusElementByRef(hourRef)}
            range={{ start: 0, end: 59 }}
          />
          {hour12Format && (
            <TimeInputContainer>
              <AmPmInput
                inputRef={amPmRef}
                amPm={amPm}
                movePrev={focusMinute}
                moveNext={() =>
                  onFinishInput ? onFinishInput() : blurElementByRef(amPmRef)
                }
                toggleAmPm={toggleAmPm}
                setAmPM={(amPm) => setAmPM(amPm)}
              />
            </TimeInputContainer>
          )}
        </React.Fragment>
        {/* )} */}
      </TimePickerContainer>
    </TimePickerWrapper>
  );
}

export default TimePicker;
