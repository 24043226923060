import { getMonazimList } from 'custom_modules/Members/membersActions';
import { connect } from 'react-redux';

import { getOrganizationMembersList } from '../Projects/projectsActions';
// Component
import Offices from './Offices';
import {
  addOfficeAssignee,
  clearOfficeCreatedUpdated,
  getOfficesList,
  removeOfficeAssignee,
  setOfficeDetails,
} from './officesActions';

// Store props to map with current state
const mapStateToProps = state => ({
  orgMembersList: state.project.orgMembersList,
  isLoading: state.office.isLoading,
  isAssigneesLoading: state.office.isAssigneesLoading,
  officesList: state.office.officesList,
  createdOffice: state.office.createdOffice,
  officeDetails: state.office.officeDetails,
  memberShortListIsLoading: state.project.memberShortListIsLoading,
  monazimList: state.member.monazimList,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getOrganizationMembersList: params =>
    dispatch(getOrganizationMembersList(params)),
  getOfficesList: params => dispatch(getOfficesList(params)),
  setOfficeDetails: params => dispatch(setOfficeDetails(params)),
  clearOfficeCreatedUpdated: () => dispatch(clearOfficeCreatedUpdated()),
  getMonazimList: params => dispatch(getMonazimList(params)),
  addOfficeAssignee: params => dispatch(addOfficeAssignee(params)),
  removeOfficeAssignee: params => dispatch(removeOfficeAssignee(params)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Offices);
