import { all, fork } from 'redux-saga/effects';

import activitySagas from './custom_modules/ActivityNew/activitySagas';
import clientSagas from './custom_modules/Clients/clientsSagas';
import clockInOutSagas from './custom_modules/ClockInOut/clockInOutSagas';
import dashboardSagas from './custom_modules/Dashboard/dashboardSagas';
import geofenceClockInOutSagas from './custom_modules/FieldServices/GeofenceClockInOut/geofenceClockInOutSagas';
import jobSitesSagas from './custom_modules/FieldServices/JobSites/jobSitesSagas';
import liveLocationSaga from './custom_modules/FieldServices/LiveLocation/liveLocationSagas';
// field services
import routeMapSagas from './custom_modules/FieldServices/RouteMapNew/routeMapSagas';
import forgotPasswordSagas from './custom_modules/ForgotPassword/forgotPasswordSagas';
import integrationsSagas from './custom_modules/IntegrationsNew/integrationsSagas';
import invoiceSagas from './custom_modules/Invoice/invoiceSagas';
import leaveSagas from './custom_modules/Leave/leaveSagas';
import liveFeedSagas from './custom_modules/LiveFeed/liveFeedSagas';
// auth
import loginSagas from './custom_modules/Login/loginSagas';
import memberSagas from './custom_modules/Members/membersSagas';
import officeSagas from './custom_modules/Offices/officesSagas';
import onboardingSaga from './custom_modules/Onboarding/OnboardingSteps/onboardingStepsSagas';
import requestJoinOrgSaga from './custom_modules/Onboarding/RequestJoinOrg/requestJoinOrgSaga';
import organizationSagas from './custom_modules/Organization/OrganizationSagas';
import payrollSaga from './custom_modules/Payroll/payrollSagas';
import projectSagas from './custom_modules/Projects/projectsSagas';
import publicApiSagas from './custom_modules/PublicApi/publicApiSagas';
import redeemLimitedTimeCodeSaga from './custom_modules/RedeemAppsumo/redeemCodeSaga';
import redeemDiscountCodeSaga from './custom_modules/RedeemBlackFriday/redeemDiscountCodeSaga';
import redeemCodeSaga from './custom_modules/RedeemCode/redeemCodeSaga';
import reportSagas from './custom_modules/ReportsNew/reportsSagas';
import resetPasswordSagas from './custom_modules/ResetPassword/resetPasswordSagas';
import scheduleSagas from './custom_modules/Schedule/scheduleSagas';
import activitySettingsSaga from './custom_modules/Settings/ActivitySettings/activitySettingsSagas';
import timesheetSettingsSaga from './custom_modules/Settings/TimesheetSettings/timesheetSettingsSagas';
import settingsSaga from './custom_modules/Settings/settingsSagas';
import signUpSagas from './custom_modules/SignUp/signUpSagas';
import subscriptionSaga from './custom_modules/Subscription/subscriptionSagas';
import taskSagas from './custom_modules/Tasks/tasksSagas';
import teamSagas from './custom_modules/Teams/teamsSagas';
// timesheet
import timesheetSagas from './custom_modules/Timesheet/timesheetSagas';
import timesheetApprovalSagas from './custom_modules/TimesheetApproval/timesheetApprovalSagas';
// profile
import profileSagas from './custom_modules/UserProfile/userProfileSagas';
import verificationCodeSagas from './custom_modules/VerificationCode/verificationCodeSagas';
import verifySagas from './custom_modules/VerifyUser/verifySagas';
import workNotesSagas from './custom_modules/WorkNotes/workNotesSagas';
import adminSaga from './layouts/Admin/adminSagas';

/**
 * rootSaga
 */
export default function* root() {
  yield all([
    fork(loginSagas),
    fork(signUpSagas),
    fork(forgotPasswordSagas),
    fork(verificationCodeSagas),
    fork(dashboardSagas),
    fork(resetPasswordSagas),
    fork(verifySagas),
    fork(profileSagas),
    fork(projectSagas),
    fork(organizationSagas),
    fork(officeSagas),
    fork(taskSagas),
    fork(clockInOutSagas),
    fork(leaveSagas),
    fork(reportSagas),
    fork(clientSagas),
    fork(activitySagas),
    fork(routeMapSagas),
    fork(jobSitesSagas),
    fork(liveLocationSaga),
    fork(geofenceClockInOutSagas),
    fork(timesheetSagas),
    fork(timesheetApprovalSagas),
    fork(workNotesSagas),
    fork(scheduleSagas),
    fork(liveFeedSagas),
    fork(memberSagas),
    fork(teamSagas),
    fork(integrationsSagas),
    fork(invoiceSagas),
    fork(requestJoinOrgSaga),
    fork(onboardingSaga),
    fork(redeemCodeSaga),
    fork(redeemDiscountCodeSaga),
    fork(redeemLimitedTimeCodeSaga),
    fork(publicApiSagas),
    fork(payrollSaga),
    fork(subscriptionSaga),
    fork(adminSaga),
    fork(settingsSaga),
    fork(activitySettingsSaga),
    fork(timesheetSettingsSaga),
  ]);
}
